import baseAPI, { handleDefaultError, handleDefaultSuccess } from '@api/baseAPI';
import { GetSubBrandsRequest, GetSubBrandsResponse } from './types';

const API_SUB_BRANDS = '/api/delivery/sub_brands';

export const getSubBrands = async (request?: GetSubBrandsRequest) =>
  await baseAPI
    .get<GetSubBrandsResponse>(API_SUB_BRANDS, { params: request })
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getDineFeeSetting } from '@api/dineIn';
import { DineInFeeSettingResponse } from '@api/dineIn/types';
import { QUERY_KEY_DINE_IN_FEE_SETTING } from '@constants/queryKey';

export interface DineInSettingQueryKeys {
  key: string;
}

type UseQueryDineInSettingOptions = UseQueryOptions<
  DineInFeeSettingResponse,
  APIDefaultErrors,
  DineInFeeSettingResponse,
  DineInSettingQueryKeys[]
>;

const useQueryDineInSetting = (config?: UseQueryDineInSettingOptions) => {
  const { enabled, ...otherConfig } = config ?? {};

  const queryKeys: DineInSettingQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_DINE_IN_FEE_SETTING,
      },
    ],
    []
  );

  const { data, isFetching, isError, isSuccess, error, refetch } = useQuery(queryKeys, getDineFeeSetting, {
    enabled,
    ...otherConfig,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  };
};

export default useQueryDineInSetting;

import i18n from '@utils/i18n';

export const TOKEN_COOKIE_KEY = 'jwt';
export const TOKEN_EXPIRES_COOKIE_KEY = 'jwt_expires';
export const TOKEN_REFRESH_COOKIE_KEY = 'jwt_refresh';
export const USER_OTP_REQUEST_TIME_KEY = 'user_otp_req_time';
export const USER_LAST_SHOWN_ONGOING_ORDER_TIME_KEY = 'user_last_shown_ongoing_order_time';
export const PREVIEW_DEV_DOMAIN = 'preview_dev_domain';

export const GUEST_OPEN_BILL_UUID_KEY = 'open_bill_uuid';
export const GUEST_CLOSE_BILL_UUID_KEY = 'closed_bill_uuid';

export const DINE_IN_TYPE = 'dine_in_type';

export const GENDER_OPTIONS = [
  {
    label: i18n.t('sessions.gender_male_label'),
    value: 'male',
  },
  {
    label: i18n.t('sessions.gender_female_label'),
    value: 'female',
  },
];

export const RESEND_OTP_TIME = 60; // in seconds
export const BIRTHDAY_FORMAT = 'dd/MM/yyyy';

export const SUBDOMAIN_DEFAULT = 'runchise.id';

import React from 'react';
import { Stack, Typography } from '@components/common';
import { Skeleton } from '@mui/material';

const PopularProductListLoader: React.FC = () => {
  return (
    <Stack direction={'column'} spacing={'xs'} marginBottom={'m'}>
      <Typography variant={'medium'} size={'hs'} color={'uiDarkPrimary'}>
        <Skeleton variant={'text'} width={100} />
      </Typography>
      <Stack spacing={'xs'} overflow={'auto'} direction={'row'}>
        {new Array(5).fill(null).map((_, idx) => (
          <Stack
            borderWidth={'bolder'}
            borderColor={'borderSubtle'}
            borderRadius={'default'}
            paddingTop={'xs'}
            paddingBottom={'s'}
            key={idx}
          >
            <Stack direction={'column'} width={144} alignItems={'center'} spacing={'m'}>
              <Skeleton variant={'rectangular'} width={125} height={125} />
              <Stack direction={'column'} alignItems={'center'}>
                <Typography variant={'bold'} size={'s'} textAlign={'center'}>
                  <Skeleton variant={'text'} width={120} />
                </Typography>
                <Typography size={'s'} variant={'light'} textAlign={'center'}>
                  <Skeleton variant={'text'} width={120} />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default PopularProductListLoader;

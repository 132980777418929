import React, { ReactNode, useState } from 'react';
import { CustomTextField, Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import { CardActionArea, TextFieldProps } from '@mui/material';

interface Props {
  defaultValue: string;
  placePredictions: google.maps.places.AutocompletePrediction[];
  getPlacePredictions: (opt: google.maps.places.AutocompletionRequest) => void;
  onSelectOption: (item: google.maps.places.AutocompletePrediction) => void;
  latitude: number;
  longitude: number;
  label?: ReactNode;
  onClose?: () => void;
}

const DeliveryAddressAutoComplete: React.FC<Props> = (props) => {
  const {
    latitude,
    longitude,
    defaultValue,
    placePredictions = [],
    onSelectOption,
    getPlacePredictions,
    label,
    onClose,
  } = props;

  const [showList, setShowList] = useState(false);

  const handleOnChangeLocationAddress: TextFieldProps['onChange'] = (e) => {
    setShowList(true);
    getPlacePredictions({
      input: e.target.value,
      componentRestrictions: {
        country: 'id',
      },
      origin: {
        lat: latitude,
        lng: longitude,
      },
    });
  };

  const handleOnClickOption = (item: google.maps.places.AutocompletePrediction) => () => {
    onSelectOption(item);
    setShowList(false);
    onClose?.();
  };

  return (
    <Stack flexGrow={1}>
      <Stack spacing={'xs'} paddingY={'s'}>
        {label && (
          <Typography size={'hs'} variant={'bold'}>
            {label}
          </Typography>
        )}
        <CustomTextField defaultValue={defaultValue} autoFocus onChange={handleOnChangeLocationAddress} />
      </Stack>
      <Stack background={'uiLightSecondary'} spacing={'xxs'} paddingX={'xxs'} borderRadius={'default'}>
        {showList &&
          placePredictions.map((item, index) => (
            <CardActionArea key={index} onClick={handleOnClickOption(item)}>
              <Stack
                background={'uiLightPrimary'}
                padding={'m'}
                borderRadius={'default'}
                direction={'row'}
                spacing={'s'}
              >
                <LocationMarkerIcon />
                <Stack overflow={'auto'}>
                  <Typography noWrap variant={'medium'}>
                    {item.structured_formatting.main_text}
                  </Typography>
                  <Typography noWrap textOverflow={'ellipsis'} size={'xs'} variant={'medium'}>
                    {item.description}
                  </Typography>
                </Stack>
              </Stack>
            </CardActionArea>
          ))}
      </Stack>
    </Stack>
  );
};

export default DeliveryAddressAutoComplete;

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getDeliveryLalamoveQuotation } from '@api/order';
import { GetDeliveryLalamoveQuotationRequest, GetDeliveryQuotationResponse } from '@api/order/types';
import { QUERY_KEY_DELIVERY_LALAMOVE_QUOTATION } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface DeliveryLalamoveQuotationQueryKey {
  key: string;
  payload: GetDeliveryLalamoveQuotationRequest;
}

type UseQueryDeliveryQuotationOptions = UseQueryOptions<
  GetDeliveryQuotationResponse,
  APIDefaultErrors,
  GetDeliveryQuotationResponse,
  DeliveryLalamoveQuotationQueryKey[]
>;

const useQueryDeliveryLalamoveQuotation = (
  params: GetDeliveryLalamoveQuotationRequest,
  config?: UseQueryDeliveryQuotationOptions
) => {
  const { isLoggedIn } = getUserCookies();
  const queryKeys: DeliveryLalamoveQuotationQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_DELIVERY_LALAMOVE_QUOTATION,
        payload: params,
      },
    ],
    [params]
  );

  const { data, isFetching, isError, isSuccess, refetch } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getDeliveryLalamoveQuotation(payload),
    {
      enabled: !!isLoggedIn,
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};

export default useQueryDeliveryLalamoveQuotation;

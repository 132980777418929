import { CSSProperties } from 'react';
import Token from '@constants/token';
import { css } from '@emotion/react';
import { TokenBorderRadius } from '@interfaces/token';

const container = css`
  overflow: hidden;
`;

const rotate = (value: number) => css`
  transform: rotate(${value}deg);
`;

const disabled = (value: boolean) => css`
  opacity: ${value ? 0.5 : 1};
  filter: grayscale ${value ? 0 : 1};
`;

const opacity = (value: number) => css`
  opacity: ${value};
`;
const objectFit = (value: CSSProperties['objectFit']) => css`
  object-fit: ${value};
`;
const borderRadius = (value: TokenBorderRadius) => css`
  border-radius: ${Token.borderRadius[value]};
`;

const minHeight = (value: CSSProperties['minHeight']) => css`
  min-height: ${value};
`;

const maxHeight = (value: CSSProperties['maxHeight']) => css`
  max-height: ${value};
`;

const flexShrink = (value: CSSProperties['flexShrink']) => css`
  flex-shrink: ${value};
`;

const styles = {
  container,
  rotate,
  disabled,
  opacity,
  objectFit,
  borderRadius,
  minHeight,
  maxHeight,
  flexShrink,
};

export default styles;

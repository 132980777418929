import type { TokenColor } from '@interfaces/token';
import styles from './styles';

export interface LineSeparatorProps {
  color?: TokenColor;
  opacity?: number;
  alignment?: 'vertical' | 'horizontal';
  size?: number;
  width?: string;
}

const LineSeparator = ({
  color = 'uiDarkPrimary',
  opacity = 1,
  alignment = 'horizontal',
  size = 1,
  width,
}: LineSeparatorProps) => (
  <div css={[styles.line(alignment, size, width), color && styles.color(color), opacity && styles.opacity(opacity)]} />
);

export default LineSeparator;

import { useContext, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions, useQuery } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getProductDetail } from '@api/products';
import { GetProductDetailRequest, GetProductDetailResponse } from '@api/products/types';
import { PRODUCT_INVALID } from '@constants/errorType';
import { QUERY_KEY_PRODUCTS } from '@constants/queryKey';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';
import { useSubBrandInfo } from '@hooks/subBrands';
import { useQueryBrandInfo } from '@queries/commons';

export interface GetProductDetailQueryKeys {
  key?: string;
  payload?: Partial<GetProductDetailRequest>;
}

type UseQueryProductDetailOptions = UseQueryOptions<
  GetProductDetailResponse,
  APIDefaultErrors,
  GetProductDetailResponse,
  GetProductDetailQueryKeys[]
>;

const useQueryProductDetail = (params?: Partial<GetProductDetailRequest>, config?: UseQueryProductDetailOptions) => {
  const { storageState } = useCommonStore();
  const { data: brandInfoData } = useQueryBrandInfo();
  const { closedBillToken, openBillToken } = useCheckDineIn();
  const { id, name } = useSubBrandInfo();
  const snackbarContext = useContext(SnackbarContext);
  const { showBoundary } = useErrorBoundary();
  const { t } = useTranslation();
  const locationId = storageState.activeLocation?.id;
  const allowMultiBrand = brandInfoData?.brand?.allowMultiBrand;

  const queryKeys: GetProductDetailQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_PRODUCTS,
        payload: {
          locationId,
          subBrandId: id,
          subBrandName: name,
          enableSubBrand: allowMultiBrand,
          ...(openBillToken
            ? {
                uuid: openBillToken,
              }
            : {}),
          ...(closedBillToken
            ? {
                closedBillToken,
              }
            : {}),
          ...params,
        },
      },
    ],
    [allowMultiBrand, closedBillToken, locationId, openBillToken, params, id, name]
  );

  const { data, isFetching, isLoading, isError, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getProductDetail(payload),
    {
      enabled: !!params?.id && !!id && !!locationId,
      onError: ({ message = t('error.general_error_label') }) => {
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
        const exception = {
          message,
          name: PRODUCT_INVALID,
        };
        showBoundary(exception);
      },
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useQueryProductDetail;

import React, { createContext, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { AuthFormDialog } from '@components/auth';
import { QUERY_KEY_ORDER_DETAIL } from '@constants/queryKey';
import { useCurrentProfile } from '@hooks/auth';
import { useFirebase } from '@hooks/common';
import { generateQueryKey } from '@queries/order/useQueryOrderDetail';

const initialConfig = {};

interface AuthDialogConfig {
  openDialog?: (callbackOnSuccess?: VoidFunction) => void;
  closeDialog?: VoidFunction;
}

export interface AuthDialogContextValue {
  openDialog?: () => void;
  closeDialog?: () => void;
}

export const AuthDialogContext = createContext<AuthDialogConfig>(initialConfig);

const AuthDialogProvider: React.FC = ({ children }) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [onSuccessLogin, setOnSuccessLogin] = useState<VoidFunction>();

  const [{ detail }] = useCurrentProfile();

  /**Initiate Firebase here */
  useFirebase({
    enabled: !!detail?.id,
    onMessage: (message) => {
      const orderId = message?.data?.customer_order_id;
      const queryKeys = orderId ? [generateQueryKey(orderId)] : [{ key: QUERY_KEY_ORDER_DETAIL }];
      queryClient.refetchQueries(queryKeys);
    },
  });

  const handleOpenDialog = useCallback((callbackOnSuccess?: VoidFunction) => {
    setIsOpen(true);
    setOnSuccessLogin(callbackOnSuccess);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsOpen(false);
    setOnSuccessLogin(undefined);
  }, []);

  const providerValue = {
    openDialog: handleOpenDialog,
    closeDialog: handleCloseDialog,
  };

  return (
    <AuthDialogContext.Provider value={providerValue}>
      {children}
      <AuthFormDialog isOpen={isOpen} onSuccessLogin={onSuccessLogin} closeModal={handleCloseDialog} />
    </AuthDialogContext.Provider>
  );
};

export default AuthDialogProvider;

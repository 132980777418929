import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryServerBrandInfo } from '@queries/commons';
import { localeOptions } from '@utils/i18n';

export interface SeoTagsReturnProps {
  title: string;
  metaTags: Array<{
    name: string;
    content?: string;
  }>;
  linkTags: Array<{
    rel: string;
    href?: string;
    media?: string;
  }>;
}

const useMetaTags = ({ pageTitle = '', brandUrl = '' }): SeoTagsReturnProps => {
  const { t, i18n } = useTranslation();
  const { pathname } = useRouter();
  const { data } = useQueryServerBrandInfo(brandUrl);
  const url = `${brandUrl}${pathname}`;

  const tagLocale = useMemo(() => {
    if (i18n.language && localeOptions?.[i18n.language]) {
      return localeOptions?.[i18n.language];
    }

    return localeOptions['en'];
  }, [i18n.language]);

  return useMemo(() => {
    const title = data?.brand?.name
      ? t('general.meta_title', { brandName: data.brand.name })
      : `${t('general.runchise')} - ${pageTitle}`;

    const description = data?.brand?.metaDescription ?? t('general.meta_description', { brandName: data?.brand?.name });
    const imageUrl = data?.brand?.logoUrl;
    const faviconUrl = data?.brand?.iconUrl;

    return {
      title,
      metaTags: [
        { name: 'title', content: title },
        { name: 'twitter:title', content: title },
        { name: 'og:title', content: title },
        { name: 'og:locale', content: tagLocale },
        { name: 'og:type', content: 'website' },
        { name: 'og:sitename', content: title },
        ...(imageUrl
          ? [
              { name: 'og:image', content: imageUrl },
              { name: 'twitter:image', content: imageUrl },
            ]
          : []),
        { name: 'og:url', content: url },
        { name: 'description', content: description },
        { name: 'og:description', content: description },
        { name: 'twitter:description', content: description },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:domain', content: brandUrl },
        { name: 'mobile-web-app-capable', content: 'yes' },
      ],
      linkTags: [
        /**
         * IOS & SAFARI doesn't support icons in PWA manifest.json, rumors said only the latest version supported it at v15.4
         * https://blog.logrocket.com/when-will-safari-finally-get-it-together/
         *  */
        { rel: 'apple-touch-icon', href: imageUrl ?? '/logo/192x192.png' },
        // TODO-PWA: Wait until BE support iOS splash screen
        // {
        //   rel: 'apple-touch-startup-image',
        //   href: 'https://runchise-backoffice-staging-public-assets-v2.s3.ap-southeast-1.amazonaws.com/pwa_testing/apple-splash-1242-2688.jpg',
        //   media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
        // },
        // {
        //   rel: 'apple-touch-startup-image',
        //   href: 'https://runchise-backoffice-staging-public-assets-v2.s3.ap-southeast-1.amazonaws.com/pwa_testing/apple-splash-828-1792.jpg',
        //   media: '(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
        // },
        { rel: 'icon', href: faviconUrl ?? imageUrl ?? '/logo/favicon.ico' },
        { rel: 'mask-icon', href: imageUrl ?? '/logo/logo.png' },
        { rel: 'shortcut icon', href: faviconUrl ?? imageUrl ?? '/favicon.ico' },
      ],
    };
  }, [
    brandUrl,
    data?.brand?.iconUrl,
    data?.brand?.logoUrl,
    data?.brand?.metaDescription,
    data?.brand?.name,
    pageTitle,
    t,
    tagLocale,
    url,
  ]);
};

export default useMetaTags;

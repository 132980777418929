import { addSeconds, differenceInSeconds, isFuture } from 'date-fns';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { getOTP } from '@api/auth';
import { OtpChannel } from '@api/auth/types';
import { getDineInCustomerCheck } from '@api/dineIn';
import { Button, DefaultLoading, Stack, Typography } from '@components/common';
import { LineSeparator } from '@components/common';
import { PhoneNumber } from '@components/common/PhoneInputField';
import { RESEND_OTP_TIME, USER_OTP_REQUEST_TIME_KEY } from '@constants/auth';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';
import routes from '@constants/routes';
import { useCheckDineIn } from '@hooks/dineIn';
import { useOtpChannel } from '@hooks/sessions';
import { useGuestStore } from '@hooks/storage';
import { setInternalCookie } from '@utils/auth';
import { OTPInput } from '..';

interface Props {
  phoneNumber?: PhoneNumber;
  isLoading?: boolean;
  onSubmit?: (otp: string) => void;
  onResendOTP?: () => void;
  resetPhoneNumber?: () => void;
  setShowGuestNameInput?: Dispatch<SetStateAction<boolean>>;
  showGuestNameInput?: boolean;
  onSuccessLogin?: () => void;
  closeModal?: () => void;
}

const OTPForm: React.FC<Props> = ({
  phoneNumber,
  isLoading: isLoadingSubmitOTP,
  onSubmit,
  resetPhoneNumber,
  setShowGuestNameInput,
  showGuestNameInput,
  onSuccessLogin,
  closeModal,
}) => {
  const snackbarContext = useContext(SnackbarContext);

  const completePhoneNumber = `${phoneNumber?.countryCode}${phoneNumber?.contactNumber}`;

  const { t } = useTranslation();
  const [otp, setOtp] = useState('');
  const [resendTimer, setResendTimer] = useState<number>(0);
  const router = useRouter();
  const [resend, setResend] = useState<boolean>(false);
  const [otpChannel, setOtpChannel] = useState<OtpChannel>('whatsapp');
  const { label: otpChannelLabel } = useOtpChannel(otpChannel);

  const { isOpenBill, isDineIn, isClosedBill, closedBillToken, openBillToken } = useCheckDineIn();

  const cookies = parseCookies();
  const userOTPRequestTime = cookies[`${USER_OTP_REQUEST_TIME_KEY}/${completePhoneNumber}`];
  const { setGuestInfo } = useGuestStore();

  const { mutate: fetchOTP, isLoading: isLoadingResendOTP } = useMutation(getOTP, {
    onSuccess: async (_, request) => {
      const time = new Date().getTime();
      const reqCompletePhoneNumber = `${request?.phoneNumber.countryCode}${request?.phoneNumber.contactNumber}`;
      const cookieKey = `${USER_OTP_REQUEST_TIME_KEY}/${reqCompletePhoneNumber}`;
      const exp = addSeconds(new Date(), RESEND_OTP_TIME);
      setInternalCookie(null, cookieKey, String(time), {
        expires: new Date(exp),
        secure: true,
        sameSite: true,
        path: '/',
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const onResendOTP = (channel: OtpChannel) => () => {
    if (phoneNumber) {
      setResend(true);
      fetchOTP({
        phoneNumber,
        otpChannel: channel,
      });
      setOtp('');
      setOtpChannel(channel);
    }
  };

  const handleOnSubmit = useCallback(() => {
    onSubmit?.(otp);
  }, [onSubmit, otp]);

  useEffect(() => {
    if (otp?.length === 6) {
      handleOnSubmit();
    }
  }, [handleOnSubmit, otp]);

  useEffect(() => {
    if (userOTPRequestTime) {
      const requestTime = new Date(Number(userOTPRequestTime));
      const resendTime = addSeconds(requestTime, RESEND_OTP_TIME);
      if (isFuture(resendTime)) {
        setResendTimer(differenceInSeconds(resendTime, new Date()));
      }
    }
  }, [userOTPRequestTime]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        if (resendTimer <= 0) {
          clearInterval(timer);
          return;
        }

        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);

  const handleResetPhoneNumber = () => {
    resetPhoneNumber?.();
  };

  const { mutate: mutateCustomerCheck, isLoading: isLoadingCustomerCheck } = useMutation(getDineInCustomerCheck, {
    onSuccess: async (response, request) => {
      if (response.isRegistered && isDineIn) {
        setGuestInfo({
          name: response.fullname,
          contactNumber: request?.phoneNumber,
          countryCode: request?.phoneNumberCountryCode,
        });
        if (!showGuestNameInput) {
          onSuccessLogin?.();
          closeModal?.();
          return;
        }

        router.replace({
          pathname: routes.PRODUCT_LIST,
          query: {
            ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
            ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          },
        });
        return;
      }

      if (!showGuestNameInput) {
        setShowGuestNameInput?.(true);
        return;
      }
      router.replace({
        pathname: routes.GUEST,
        query: {
          ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
          ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          contactNumber: request?.phoneNumber,
          countryCode: request?.phoneNumberCountryCode,
        },
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const isLoading = isLoadingResendOTP || isLoadingSubmitOTP || isLoadingCustomerCheck;

  const onContinueAsGuest = useCallback(() => {
    if (phoneNumber && isDineIn) {
      mutateCustomerCheck({
        phoneNumber: phoneNumber.contactNumber,
        phoneNumberCountryCode: phoneNumber.countryCode,
      });
    }
  }, [mutateCustomerCheck, isDineIn, phoneNumber]);

  return (
    <Stack direction={'column'} padding={'m'} spacing={'2xxl'} alignItems={'center'}>
      <Stack spacing={'xxs'}>
        <Typography align={'center'} size={'xxl'} variant={'bold'}>
          {t('sessions.input_otp_page_title')}
        </Typography>
        <Stack direction={'row'} spacing={'xxs'} justifyContent={'center'}>
          <Typography color={'uiDarkSecondary'}>
            {t('sessions.input_otp_page_description', { channel: otpChannelLabel })}
          </Typography>
          <Typography variant={'medium'} textDecoration={'underline'} color={'uiPrimaryMain'}>
            {completePhoneNumber}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={'xs'} alignItems={'center'}>
        <Stack spacing={'xxl'} alignItems={'center'}>
          <OTPInput otp={otp} disabled={isLoadingSubmitOTP} length={6} onChangeOTP={setOtp} />
          {!isLoading && !resendTimer && (
            <Stack alignItems={'center'} spacing={'m'}>
              <Button onClick={onResendOTP('whatsapp')} variant={'text'}>
                <Typography variant={'bold'} size={'hm'}>
                  {t('sessions.resend_otp_wa_label')}
                </Typography>
              </Button>
              {phoneNumber?.countryCode === '62' && (
                <>
                  <Typography color={'uiDarkPrimary'}>{t('sessions.or_title')}</Typography>
                  <Button onClick={onResendOTP('sms')} variant={'text'}>
                    <Typography variant={'bold'} size={'hm'}>
                      {t('sessions.resend_otp_sms_label')}
                    </Typography>
                  </Button>
                </>
              )}
            </Stack>
          )}
          {!isLoading && resendTimer > 0 && (
            <Stack>
              <Typography align={'center'} color={'uiPrimaryMain'} size={'hm'}>
                {t('sessions.dont_receive_otp_label')}
              </Typography>
              <Typography align={'center'} color={'uiPrimaryMain'} size={'hm'}>
                {t('sessions.resend_otp_timer_label', { time: resendTimer })}
              </Typography>
            </Stack>
          )}

          {isLoading && <DefaultLoading size={'xxxl'} />}
        </Stack>

        <Button onClick={handleResetPhoneNumber} variant={'text'}>
          <Typography color={'uiDarkSecondary'}>{t('sessions.change_phone_number_label')}</Typography>
        </Button>

        {isDineIn && !isLoading && resendTimer === 0 && resend && (
          <>
            <Stack paddingTop={'m'} direction={'row'} columnGap={'s'} width={'100%'} alignItems={'center'}>
              <LineSeparator opacity={0.2} color={'textMuted'} width={'100%'} />
              <Typography color={'uiDarkPrimary'}>{t('sessions.or_title')}</Typography>
              <LineSeparator opacity={0.2} color={'textMuted'} width={'100%'} />
            </Stack>

            <Stack paddingTop={'m'}>
              <Button onClick={onContinueAsGuest} variant={'text'}>
                <Typography variant={'bold'} size={'hm'}>
                  {t('sessions.continue_as_guest_label')}
                </Typography>
              </Button>
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default OTPForm;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const FavoriteIcon: FC<Props> = ({ width = 24, height = 24, color = 'uiRedPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'280 280 515 515'}>
        <g>
          <ellipse fill={'#FFFFFF'} cx={'535.39'} cy={'556.21'} rx={'186.77'} ry={'192.99'} />
          <g>
            <path
              fill={fillColor}
              d={
                'M551.8,795.41c-7.99,0-15.97,0-23.96,0c-6.91-0.78-13.83-1.43-20.72-2.37 c-49.29-6.73-93.86-25.05-131.21-57.93c-76.3-67.18-105.4-151.63-84.87-251.2c23.37-113.32,126.93-197.28,242.68-199.28 c47.38-0.82,92.13,9.68,132.97,33.94c70.51,41.88,112.57,103.5,125.57,184.67c1.31,8.17,2.07,16.42,3.08,24.64 c0,7.99,0,15.97,0,23.96c-0.37,2.1-0.84,4.2-1.1,6.31c-1.16,9.39-1.72,18.89-3.49,28.16c-9.92,52.07-33.45,97.1-71.13,134.43 c-39.84,39.47-87.58,63.49-143.18,71.59C568.26,793.52,560.02,794.39,551.8,795.41z M566.06,673.86c20.61,0,41.26,0.78,61.82-0.22 c21.14-1.03,36.53-11.14,39.22-35.15c2.58-22.93,6.43-45.72,9.39-68.61c1.49-11.52,2.97-23.13,3.12-34.72 c0.21-16.07-13.26-28.96-29.23-29.45c-20.94-0.64-41.88-1.35-62.83-2.04c-4.87-0.16-6.31-1.79-5.13-6.61 c1.35-5.48,3.04-10.87,4.74-16.26c5.39-17.11,8.99-34.27,5.18-52.42c-2.38-11.36-9.16-18.21-19.43-21.98 c-10.58-3.88-18.43,0.9-20.5,12.01c-0.58,3.09-0.75,6.26-1.2,9.38c-3.86,26.53-16.27,48.62-35.33,67.1 c-8.83,8.56-18.02,16.76-27.15,25c-5.15,4.64-8.92,10-8.94,17.13c-0.11,33.77-0.29,67.55-0.03,101.32 c0.13,17.12,19.35,35.46,36.4,35.49C532.79,673.89,549.43,673.86,566.06,673.86z M399.83,595.76c0,21.11-0.01,42.22,0,63.34 c0,10.09,2.91,13.15,12.99,13.23c13.3,0.11,26.59,0.05,39.89,0.03c11.12-0.02,14.21-3.09,14.22-14.25 c0.02-30.25,0.02-60.51,0.02-90.76c0-12.14,0.1-24.27-0.06-36.4c-0.11-8.29-3.79-11.95-11.92-12.01 c-14.46-0.11-28.92-0.13-43.38-0.02c-8.17,0.06-11.73,3.83-11.74,12.03C399.81,552.54,399.83,574.15,399.83,595.76z'
              }
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  );
};

export default FavoriteIcon;

import Token from '@constants/token';
import { css } from '@emotion/react';
import { TokenFontSize } from '@interfaces/token';

const container = css`
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`;

const fontSize = (value: TokenFontSize) => css`
  .MuiSvgIcon-root {
    font-size: ${Token.fontSize[value]}px;
  }
`;

const styles = {
  container,
  fontSize,
};

export default styles;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const ShoppingBasketIcon: FC<Props> = ({ width = 20, height = 20, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 20 20'} fill={'none'}>
        <path
          d={
            'M19.65 6.93088C19.3575 6.58956 18.9186 6.37014 18.4798 6.37014H17.2608L12.5555 0.56778C12.263 0.202085 11.751 0.128946 11.3609 0.397123C10.9952 0.689679 10.9221 1.20165 11.1903 1.59173C11.1903 1.61611 11.2147 1.61611 11.2147 1.64049L15.091 6.37014H4.65652L8.50851 1.64049C8.82544 1.29917 8.80106 0.762818 8.45975 0.445882C8.11843 0.128946 7.58208 0.153326 7.26514 0.494641C7.24076 0.519021 7.21638 0.543401 7.192 0.56778L2.48673 6.39452H1.51154C0.682631 6.39452 0 7.07715 0 7.90606C0 8.00358 0 8.1011 0.0243797 8.19862L1.97475 18.0236C2.16979 19.0476 3.07184 19.779 4.12017 19.779H15.8712C16.9195 19.779 17.8215 19.0476 18.0166 18.0236L19.967 8.19862C20.0645 7.75978 19.9426 7.29657 19.65 6.93088ZM7.46018 14.903C7.46018 15.3662 7.07011 15.7563 6.60689 15.7563C6.14368 15.7563 5.7536 15.3662 5.7536 14.903V11.4411C5.7536 10.9779 6.14368 10.5878 6.60689 10.5878C7.07011 10.5878 7.46018 10.9779 7.46018 11.4411V14.903ZM10.849 14.903C10.849 15.3662 10.4589 15.7563 9.99567 15.7563C9.53245 15.7563 9.14238 15.3662 9.14238 14.903V11.4411C9.14238 10.9779 9.53245 10.5878 9.99567 10.5878C10.4589 10.5878 10.849 10.9779 10.849 11.4411V14.903ZM14.2134 14.903C14.2134 15.3662 13.8233 15.7563 13.3601 15.7563C12.8968 15.7563 12.5068 15.3662 12.5068 14.903V11.4411C12.5068 10.9779 12.8968 10.5878 13.3601 10.5878C13.8233 10.5878 14.2134 10.9779 14.2134 11.4411V14.903Z'
          }
          fill={fillColor}
        />
        <defs>
          <rect width={width} height={height} fill={fillColor} />
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default ShoppingBasketIcon;

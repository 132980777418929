import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OtpChannel } from '@api/auth/types';

const useOtpChannel = (channel: OtpChannel) => {
  const { t } = useTranslation();
  const label = useMemo(() => {
    if (channel === 'whatsapp') {
      return t('sessions.whatsapp');
    }

    return t('sessions.sms');
  }, [channel, t]);

  return {
    label,
  };
};

export default useOtpChannel;

import { useMemo } from 'react';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';

const useSubBrandInfo = () => {
  const { storageState, isFinishInitiated } = useCommonStore();
  const { data: brandInfoData } = useQueryBrandInfo();

  return useMemo(() => {
    if (brandInfoData?.brand?.allowMultiBrand) {
      if (isFinishInitiated && storageState.subBrand) {
        return {
          id: storageState.subBrand.id,
          name: storageState.subBrand.name,
        };
      }
      return {
        id: brandInfoData.brand.mainSubBrandId,
        name: brandInfoData.brand.name,
      };
    }

    return {
      id: brandInfoData?.brand.mainSubBrandId,
      name: brandInfoData?.brand.name,
    };
  }, [
    brandInfoData?.brand?.allowMultiBrand,
    brandInfoData?.brand.mainSubBrandId,
    brandInfoData?.brand.name,
    isFinishInitiated,
    storageState.subBrand,
  ]);
};

export default useSubBrandInfo;

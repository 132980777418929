import { Ref, forwardRef } from 'react';
import { APP_MAX_WIDTH } from '@constants/common';
import { Popover as MUIPopover, PopoverProps } from '@mui/material';

const Popover = forwardRef(function popoverRef({ children, ...props }: PopoverProps, ref?: Ref<HTMLDivElement>) {
  return (
    <MUIPopover
      ref={ref}
      PaperProps={{
        ...props.PaperProps,
        style: {
          width:
            props.anchorEl && 'clientWidth' in props.anchorEl
              ? props.anchorEl?.clientWidth || APP_MAX_WIDTH
              : APP_MAX_WIDTH,
          boxShadow: 'none',
          ...props.PaperProps?.style,
        },
      }}
      anchorOrigin={{
        ...props.anchorOrigin,
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        ...props.transformOrigin,
        vertical: 'top',
        horizontal: 'left',
      }}
      {...props}
    >
      {children}
    </MUIPopover>
  );
});

export default Popover;

import Token from '@constants/token';
import { css } from '@emotion/react';
import { TokenSpacing } from '@interfaces/token';

const container = css`
  padding: 0;
  & .MuiSvgIcon-root': {
    font-size: 32px;
  }
`;

const paddingRight = (value: TokenSpacing) => css`
  padding-right: ${Token.spacing[value]}px;
`;

const styles = {
  container,
  paddingRight,
};

export default styles;

import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { useQueryProductCartList } from '@queries/products';
import { useQueryProducts } from '@queries/products';
import { getOrderRequestProducts, getPricesFromCart } from '@utils/product';

const useProductCartPrices = () => {
  const { storageState, isFinishInitiated } = useCommonStore();
  const { data } = useQueryProducts();
  const router = useRouter();
  const { productCartList, refetchProductList, isFetching } = useQueryProductCartList();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    isFinishInitiated: isFinishLoadCartStore,
    branchCart,
    updateProductCartItem,
  } = useCartStore(activeLocationId);

  useEffect(() => {
    if (!isFetching && !router.query?.orderId) {
      const branchCartProductIds = Object.keys(branchCart) || [];
      branchCartProductIds.some((productId) => {
        const matchedProduct = productCartList.find(({ id }) => Number(productId) === id);
        if (!matchedProduct || matchedProduct?.outOfStockFlag) {
          updateProductCartItem(Number(productId), {});
        }
      });
    }
  }, [branchCart, isFetching, productCartList, router.query?.orderId, updateProductCartItem]);

  const prices = useMemo(() => getPricesFromCart(branchCart, productCartList), [branchCart, productCartList]);
  const products = useMemo(() => getOrderRequestProducts(branchCart, productCartList), [branchCart, productCartList]);
  const favoriteProductIds = useMemo(() => data?.favoriteMenu?.productIds || [], [data?.favoriteMenu?.productIds]);

  return {
    prices,
    products,
    isLoading: !isFinishInitiated && !isFinishLoadCartStore,
    favoriteProductIds,
    refetchProductList,
  };
};

export default useProductCartPrices;

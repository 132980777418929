import { addMinutes, addSeconds, format, roundToNearestMinutes } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@components/common';
import { ChevronIcon, ClockIcon } from '@components/icons';
import { OrderPlacementFormShape } from '@containers/order/OrderPlacementContainer/forms/useFormOrderPlacement';
import { useQueryBrandInfo } from '@queries/commons';
import PickupSelectTimeFormDialog, { PickupTime, PickupTimeOption } from './PickupSelectTimeFormDialog';

const PickupTimeInput: React.FC = () => {
  const { t } = useTranslation();

  const [openSelectTimeDialog, setOpenSelectTimeDialog] = useState(false);
  const [selectedPickupTime, setSelectedPickupTime] = useState(() => 'asap' as PickupTime);
  const [customTime, setCustomTime] = useState<Date | null>(null);
  const [dateNow, setDateNow] = useState(new Date());

  const { setValue, watch } = useFormContext<OrderPlacementFormShape>();

  const pickupTime = watch('pickup.time');

  const { data } = useQueryBrandInfo();

  const processTime = data?.brand?.processTime || 30; // default processTime 30 minutes
  const processTimeInSeconds = processTime * 60;

  const roundToNearest15Minutes = (time: Date | number) => roundToNearestMinutes(time, { nearestTo: 15 });
  const timeOptions = [
    {
      key: 'asap',
      label: t('order.asap_time_select_label'),
    },
    {
      key: 'one-hour-from-now',
      label: format(roundToNearest15Minutes(addMinutes(dateNow, 60 + processTime)), 'HH:mm'),
    },
    {
      key: 'one-and-half-hour-from-now',
      label: format(roundToNearest15Minutes(addMinutes(dateNow, 90 + processTime)), 'HH:mm'),
    },
    {
      key: 'two-hours-from-now',
      label: format(roundToNearest15Minutes(addMinutes(dateNow, 120 + processTime)), 'HH:mm'),
    },
  ] as PickupTimeOption[];

  const customMinTime = addMinutes(dateNow, processTime);

  useEffect(() => {
    // re-render per 30 seconds
    const updateDateNow = setInterval(() => {
      setDateNow(new Date());
    }, 30000);

    return clearInterval(updateDateNow);
  }, []);

  useEffect(() => {
    if (pickupTime > 0 && selectedPickupTime === 'asap') {
      setSelectedPickupTime('custom');
      setCustomTime(addSeconds(new Date(), pickupTime));
    }
  }, [pickupTime, selectedPickupTime]);

  const onOpenSelectTimeDialog = () => {
    setOpenSelectTimeDialog(true);
  };

  const onCloseSelectTimeDialog = () => {
    setOpenSelectTimeDialog(false);
  };

  const onChangePickupTimeType = useCallback(
    (type: PickupTime) => {
      setSelectedPickupTime(type);

      if (type === 'asap') {
        return setValue('pickup.time', 0);
      }
      if (type === 'one-hour-from-now') {
        return setValue('pickup.time', processTimeInSeconds + 3600);
      }
      if (type === 'one-and-half-hour-from-now') {
        return setValue('pickup.time', processTimeInSeconds + 5400);
      }

      if (type === 'two-hours-from-now') {
        return setValue('pickup.time', processTimeInSeconds + 7200);
      }
    },
    [processTimeInSeconds, setValue]
  );

  const stringSelectedTime = useMemo(() => {
    if (selectedPickupTime === 'asap') {
      return t('order.asap_time_select_label');
    }

    if (selectedPickupTime === 'one-hour-from-now') {
      return format(roundToNearest15Minutes(addMinutes(dateNow, 60 + processTime)), 'HH:mm');
    }

    if (selectedPickupTime === 'one-and-half-hour-from-now') {
      return format(roundToNearest15Minutes(addMinutes(dateNow, 90 + processTime)), 'HH:mm');
    }

    if (selectedPickupTime === 'two-hours-from-now') {
      return format(roundToNearest15Minutes(addMinutes(dateNow, 120 + processTime)), 'HH:mm');
    }

    const customTimeString = customTime ? `(${format(new Date(customTime), 'HH:mm')})` : '';

    return t('order.custom_time_select_label', { time: customTimeString });
  }, [customTime, dateNow, processTime, selectedPickupTime, t]);

  return (
    <React.Fragment>
      <Stack
        direction={'row'}
        padding={'hs'}
        borderRadius={'default'}
        borderWidth={'bolder'}
        borderColor={'borderSubtle'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onClick={onOpenSelectTimeDialog}
      >
        <Stack direction={'row'} spacing={'xs'} alignItems={'center'}>
          <ClockIcon />
          <Typography color={'uiDarkStain'} variant={'medium'}>
            {stringSelectedTime}
          </Typography>
        </Stack>
        <IconButton padding={0}>
          <ChevronIcon rotate={-90} />
        </IconButton>
      </Stack>
      <PickupSelectTimeFormDialog
        open={openSelectTimeDialog}
        onClose={onCloseSelectTimeDialog}
        onChangePickupTimeType={onChangePickupTimeType}
        pickupTimeType={selectedPickupTime}
        customTime={customTime}
        onChangeCustomTime={setCustomTime}
        timeOptions={timeOptions}
        customMinTime={customMinTime}
      />
    </React.Fragment>
  );
};

export default PickupTimeInput;

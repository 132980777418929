import { differenceInSeconds } from 'date-fns';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, IconButton, Stack, TextField, Transition, Typography } from '@components/common';
import { CheckmarkIcon, ChevronIcon } from '@components/icons';
import DeliveryAddressNavbar from '@components/order/DeliveryAddressNavbar';
import { OrderPlacementFormShape } from '@containers/order/OrderPlacementContainer/forms/useFormOrderPlacement';
import { StaticTimePicker } from '@mui/lab';

export type PickupTime = 'asap' | 'one-hour-from-now' | 'one-and-half-hour-from-now' | 'two-hours-from-now' | 'custom';

export type PickupTimeOption = {
  key: PickupTime;
  label: string;
};
interface Props {
  open: boolean;
  onClose: () => void;
  onChangePickupTimeType: (value: PickupTime) => void;
  pickupTimeType?: PickupTime;
  customTime?: Date | null;
  onChangeCustomTime?: (value: Date | null) => void;
  customMinTime: Date;
  timeOptions: PickupTimeOption[];
}

const PickupSelectTimeFormDialog: React.FC<Props> = (props) => {
  const {
    open,
    timeOptions = [],
    customMinTime,
    onClose,
    onChangePickupTimeType,
    pickupTimeType,
    customTime,
    onChangeCustomTime,
  } = props;

  const [openCustomTimeSelect, setOpenCustomTimeSelect] = useState(false);

  const { setValue } = useFormContext<OrderPlacementFormShape>();

  const [tempCustomTime, setTempCustomTime] = useState<Date | null>(new Date());

  const { t } = useTranslation();

  const handleOnOpenCustomTimeSelect = () => {
    setOpenCustomTimeSelect(true);
  };
  const handleOnCloseCustomTimeSelect = () => {
    setOpenCustomTimeSelect(false);
  };

  /**
   * Method to handle when user select the pickup time
   * This method will open time picker when user choose custom
   * Else It will set the pickup time type
   * @constructor
   * @param {PickupTime} pickupTime - pickup Time
   */
  const handleOnSelectTimeOptions = (type: PickupTime) => () => {
    if (type === 'custom') {
      handleOnOpenCustomTimeSelect();
    } else {
      onChangePickupTimeType(type);
    }
  };

  const handleSubmitCustomTime = () => {
    setValue('pickup.time', differenceInSeconds(tempCustomTime as Date, new Date()));
    onChangeCustomTime?.(tempCustomTime);
    handleOnCloseCustomTimeSelect();
    onChangePickupTimeType('custom');
  };

  const customTimeString = customTime ? `(${format(new Date(customTime), 'HH:mm')})` : '';

  return (
    <Dialog fullScreen open={open} onClose={() => onClose()} TransitionComponent={Transition}>
      <DeliveryAddressNavbar onPressBack={onClose} />
      <Stack padding={'m'} spacing={'xxl'}>
        <Typography size={'xxl'} variant={'bold'}>
          {t('order.pickup_time_select_title_label')}
        </Typography>

        <Stack spacing={'s'}>
          {timeOptions?.map((time, index) => (
            <Stack
              key={index}
              borderRadius={'default'}
              borderWidth={'bolder'}
              borderColor={'borderSubtle'}
              background={'borderSubtle'}
              onClick={handleOnSelectTimeOptions(time?.key)}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                borderRadius={'default'}
                borderWidth={'bold'}
                borderColor={pickupTimeType === time?.key ? 'uiPrimaryMain' : 'uiLightPrimary'}
                padding={'hs'}
                background={'uiLightPrimary'}
              >
                <Typography variant={'medium'}>{time?.label}</Typography>
                {pickupTimeType === time?.key && <CheckmarkIcon color={'uiPrimaryMain'} />}
              </Stack>
            </Stack>
          ))}
          <Stack
            borderRadius={'default'}
            borderWidth={'bolder'}
            borderColor={'borderSubtle'}
            background={'borderSubtle'}
            onClick={handleOnSelectTimeOptions('custom')}
          >
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              borderRadius={'default'}
              borderWidth={'bold'}
              borderColor={pickupTimeType === 'custom' ? 'uiPrimaryMain' : 'uiLightPrimary'}
              padding={'hs'}
              background={'uiLightPrimary'}
            >
              <Typography variant={'medium'}>
                {t('order.custom_time_select_label', { time: customTimeString })}
              </Typography>
              <IconButton padding={0}>
                <ChevronIcon rotate={-90} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Dialog
        open={openCustomTimeSelect}
        onClose={handleOnCloseCustomTimeSelect}
        fullWidth
        PaperProps={{
          style: {
            margin: 0,
            padding: 0,
            width: '100%',
          },
        }}
      >
        <Stack paddingX={'xs'} paddingY={'m'} spacing={'xl'}>
          <StaticTimePicker
            ampm={false}
            ampmInClock={false}
            value={tempCustomTime}
            onChange={setTempCustomTime}
            renderInput={(params) => <TextField {...params} />}
            minTime={customMinTime}
          />
          <Button variant={'contained'} onClick={handleSubmitCustomTime}>
            <Typography>{t('order.confirm_custom_time_label')}</Typography>
          </Button>
        </Stack>
      </Dialog>
    </Dialog>
  );
};

export default PickupSelectTimeFormDialog;

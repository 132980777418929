import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@components/common';
import { SearchIcon } from '@components/icons';
import { NAVBAR_HEIGHT, PRODUCT_INPUT_SEARCH_HEIGHT } from '@constants/common';
import routes from '@constants/routes';
import Token from '@constants/token';
import { usePersistentQueryParams } from '@hooks/common';
import { InputBase } from '@mui/material';

const ProductInputSearch: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const goToSearchPage = () =>
    router.push({
      pathname: routes.PRODUCT_SEARCH,
      query,
    });

  return (
    <Stack
      background={'uiLightPrimary'}
      top={NAVBAR_HEIGHT + Token.spacing.s}
      position={'sticky'}
      zIndex={1}
      paddingY={'m'}
      height={`${PRODUCT_INPUT_SEARCH_HEIGHT - Token.spacing.m * 2}px`}
    >
      <Stack
        borderWidth={'thin'}
        borderColor={'borderDivide'}
        background={'uiLightStain'}
        padding={'xxs'}
        borderRadius={'default'}
      >
        <Stack alignItems={'center'} direction={'row'} spacing={'xs'}>
          <SearchIcon opacity={0.5} />
          <InputBase
            size={'small'}
            fullWidth
            value={''}
            inputProps={{ style: { paddingBottom: 0 } }}
            onChange={goToSearchPage}
            placeholder={t('products.search_placeholder')}
            onClick={goToSearchPage}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProductInputSearch;

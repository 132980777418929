import Token from '@constants/token';
import { css } from '@emotion/react';
import type { TokenColor } from '@interfaces/token';
import { LineSeparatorProps } from '.';

const color = (colorType: TokenColor) => css`
  background-color: ${Token.color[colorType]};
`;

const opacity = (value: number) => css`
  opacity: ${value};
`;

const line = (alignment: LineSeparatorProps['alignment'], size: LineSeparatorProps['size'], width?: string) => {
  if (alignment === 'horizontal') {
    return css`
      min-height: ${size}px;
      height: ${size}px;
      width: ${width ?? 'auto'};
    `;
  }

  return css`
    min-width: ${size}px;
    width: ${size}px;
    height: auto;
  `;
};

const styles = {
  color,
  opacity,
  line,
};

export default styles;

import React, { useCallback } from 'react';
import { DecimalNumber } from '@api/baseAPI/types';
import { PaymentMethod, PaymentMethodType } from '@api/order/types';
import { SwipeableDrawer } from '@components/common';
import CreditCardForm from './CreditCardForm';
import DanaForm from './DanaForm';
import OvoForm from './OvoForm';
import VirtualAccountForm from './VirtualAccountForm';

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  activeFormType?: PaymentMethodType;
  value: PaymentMethod;
  totalAmount: DecimalNumber;
}
const AddPaymentMethodDrawer: React.FC<Props> = (props) => {
  const { open, onClose, onOpen, onSubmit, value, activeFormType = 'credit-card' } = props;

  const handleSubmit = useCallback(
    (paymentMethod: PaymentMethod) => {
      onSubmit(paymentMethod);
      onClose();
    },
    [onClose, onSubmit]
  );

  const renderForm = useCallback(
    (formType: PaymentMethodType) => {
      if (formType === 'dana') {
        return <DanaForm onSubmit={handleSubmit} value={value} onClose={onClose} />;
      }

      if (formType === 'ovo') {
        return <OvoForm onSubmit={handleSubmit} value={value} onClose={onClose} />;
      }

      if (formType === 'va-transfer') {
        return (
          <VirtualAccountForm onSubmit={handleSubmit} value={value} onClose={onClose} totalAmount={props.totalAmount} />
        );
      }

      return <CreditCardForm onSubmit={handleSubmit} value={value} onClose={onClose} />;
    },
    [handleSubmit, onClose, props.totalAmount, value]
  );

  return (
    <SwipeableDrawer
      draggable={false}
      anchor={'bottom'}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableBackdropTransition
    >
      {renderForm(activeFormType)}
    </SwipeableDrawer>
  );
};

export default AddPaymentMethodDrawer;

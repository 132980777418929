import React from 'react';
import { Stack, Typography } from '@components/common';
import { Divider, Skeleton } from '@mui/material';

const ProductDrawerLoader: React.FC = () => {
  return (
    <Stack padding={'m'} spacing={'xxl'}>
      {new Array(1).fill(null).map((_, index) => (
        <Stack key={index} direction={'column'} spacing={'s'}>
          <Stack direction={'column'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} marginBottom={'xxs'}>
              <Stack direction={'column'}>
                <Typography size={'xl'} variant={'bolder'}>
                  <Skeleton variant={'text'} width={120} />
                </Typography>
                <Typography size={'hs'} color={'uiPrimaryMain'}>
                  <Skeleton variant={'text'} width={80} />
                </Typography>
              </Stack>
              <Typography size={'xxl'} variant={'regular'}>
                <Skeleton variant={'text'} width={80} />
              </Typography>
            </Stack>
            <Divider />
          </Stack>
          <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'} spacing={'xs'}>
            {new Array(3).fill(null).map((_, tIdx) => (
              <Skeleton key={`${index}-t${tIdx}`} variant={'rectangular'} width={60} height={17} />
            ))}
          </Stack>

          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Skeleton variant={'rectangular'} width={100} height={47} />

            <Skeleton variant={'rectangular'} width={90} height={30} />
          </Stack>
        </Stack>
      ))}

      <Skeleton variant={'rectangular'} width={'100%'} height={40} />
    </Stack>
  );
};

export default ProductDrawerLoader;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const SearchIcon: FC<Props> = ({ width = 18, height = 18, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 18 18'}>
        <path
          fill={fillColor}
          d={
            'M7.04171 2.32476C8.24455 2.32476 9.44722 2.78485 10.3686 3.70627C12.2111 5.54858 12.2111 8.51907 10.3686 10.3619C8.52615 12.2042 5.55566 12.2042 3.71299 10.3619C1.87139 8.51925 1.87139 5.54983 3.71388 3.70734H3.71495C4.63619 2.78591 5.83904 2.32476 7.04171 2.32476ZM7.04171 1.17578C5.54002 1.17578 4.03797 1.74698 2.89646 2.88867V2.88938C0.612194 5.17383 0.613083 8.89418 2.89646 11.1776C5.04277 13.324 8.45753 13.4536 10.7567 11.5665L13.8243 14.6332C14.0474 14.8711 14.4243 14.8769 14.6549 14.646C14.8854 14.4147 14.879 14.0387 14.6401 13.8154L11.5741 10.7491C13.4611 8.44974 13.3325 5.0348 11.1861 2.88867C10.0437 1.74591 8.54286 1.17578 7.04171 1.17578Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default SearchIcon;

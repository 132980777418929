import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LocationDetail } from '@api/locations/types';
import { Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import routes from '@constants/routes';
import { useCommonStore } from '@hooks/storage';
import { LocationCard, LocationStatusBadge } from '..';

interface Props {
  locationList: LocationDetail[];
}

const LocationList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { locationList = [] } = props;
  const { updateStorage } = useCommonStore();

  const router = useRouter();
  const onPress = (location: LocationDetail) => async () => {
    await updateStorage({ activeLocation: location });
    router.push(routes.PRODUCT_LIST);
  };

  if (locationList.length > 0) {
    return (
      <Stack height={'100%'}>
        <Stack background={'borderSubtle'} padding={'xxs'} borderRadius={'default'}>
          <Stack width={'100%'} direction={'column'} spacing={'xxs'}>
            {locationList.map((location, index) => (
              <LocationCard onPress={onPress(location)} key={index} location={location} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      borderWidth={'bolder'}
      borderRadius={'default'}
      borderColor={'uiLightStain'}
      direction={'row'}
      alignItems={'center'}
      paddingX={'xs'}
      paddingY={'xs'}
      spacing={'xs'}
    >
      <LocationMarkerIcon color={'uiDarkSecondary'} />
      <Stack width={'100%'} direction={'column'} flexGrow={1} overflow={'hidden'}>
        <Typography noWrap textOverflow={'ellipsis'} color={'uiDarkSecondary'} variant={'medium'} size={'hs'}>
          {t('locations.no_location')}
        </Typography>
      </Stack>
      <LocationStatusBadge status={'closed'} />
    </Stack>
  );
};

export default LocationList;

import localforage from 'localforage';
import {
  GUEST_DATABASE_DESCRIPTION,
  GUEST_DATABASE_NAME,
  GUEST_DATABASE_VERSION,
  GUEST_STORE_NAME,
} from '@constants/storage';
import { GuestInfo } from './types';

const getStorageKey = () => GUEST_STORE_NAME;

const guestCartStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: GUEST_DATABASE_NAME,
  version: GUEST_DATABASE_VERSION,
  storeName: GUEST_STORE_NAME,
  description: GUEST_DATABASE_DESCRIPTION,
});

const getItem = async (): Promise<GuestInfo | undefined> => {
  return await guestCartStore
    .getItem(getStorageKey())
    .then((response) => response as GuestInfo)
    .catch((err) => {
      console.error('guestStore getItem', err);
      return undefined;
    });
};

const setItem = async (val: GuestInfo) => await guestCartStore.setItem(getStorageKey(), val);

const deleteItem = async () => {
  const storage = await getItem();
  if (storage) {
    return await guestCartStore.removeItem(getStorageKey());
  }
};

const clear = async () => await guestCartStore.clear();
const keys = async () => await guestCartStore.keys();

const guestStore = {
  getItem,
  setItem,
  deleteItem,
  clear,
  keys,
};

export default guestStore;

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getDineInCustomerOrderPrice } from '@api/dineIn';
import { GetDineInCustomerOrderPriceRequest, GetDineInCustomerOrderPriceResponse } from '@api/dineIn/types';
import { MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS } from '@constants/dineIn';
import { QUERY_KEY_CUSTOMER_ORDER_PRICE } from '@constants/queryKey';

interface GetCustomerOrderPriceQueryKey {
  key: string;
  payload: Partial<GetDineInCustomerOrderPriceRequest>;
}

type UseQueryCustomerOrderPriceOptions = UseQueryOptions<
  GetDineInCustomerOrderPriceResponse,
  APIDefaultErrors,
  GetDineInCustomerOrderPriceResponse,
  GetCustomerOrderPriceQueryKey[]
>;

const useQueryDineInCustomerOrderPrice = (
  params: Partial<GetDineInCustomerOrderPriceRequest>,
  config?: UseQueryCustomerOrderPriceOptions
) => {
  const queryKeys: GetCustomerOrderPriceQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_CUSTOMER_ORDER_PRICE,
        payload: params,
      },
    ],
    [params]
  );

  const { error, refetch, data, isFetching, isError, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getDineInCustomerOrderPrice(payload),
    {
      ...MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS,
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    refetch,
    error,
  };
};

export default useQueryDineInCustomerOrderPrice;

import { addMonths } from 'date-fns';
import { useCallback } from 'react';
import { CreateSessionResponse } from '@api/auth/types';
import { TOKEN_COOKIE_KEY, TOKEN_EXPIRES_COOKIE_KEY, TOKEN_REFRESH_COOKIE_KEY } from '@constants/auth';
import { setInternalCookie } from '@utils/auth';

export const setupSession = (res: CreateSessionResponse) => {
  const { exp, accessToken, refreshToken } = res.auth;

  // set it to 3 month
  const expires = addMonths(new Date(), 3);

  setInternalCookie(null, TOKEN_COOKIE_KEY, accessToken, {
    secure: true,
    sameSite: true,
    path: '/',
    expires,
  });
  setInternalCookie(null, TOKEN_EXPIRES_COOKIE_KEY, exp, {
    secure: true,
    sameSite: true,
    path: '/',
    expires,
  });
  setInternalCookie(null, TOKEN_REFRESH_COOKIE_KEY, refreshToken, {
    secure: true,
    sameSite: true,
    path: '/',
    expires,
  });
};

const useSetupSession = () => {
  return useCallback(setupSession, []);
};

export default useSetupSession;

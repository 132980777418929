import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getLocationDetail } from '@api/locations';
import { GetLocationDetailRequest, GetLocationDetailResponse } from '@api/locations/types';
import { QUERY_KEY_GET_LOCATION_DETAIL } from '@constants/queryKey';

interface GetLocationDetailQueryKeys {
  key: string;
  payload?: Partial<GetLocationDetailRequest>;
}

type UseQueryLocationDetailOptions = UseQueryOptions<
  GetLocationDetailResponse,
  APIDefaultErrors,
  GetLocationDetailResponse,
  GetLocationDetailQueryKeys[]
>;

const useQueryLocationDetail = (params?: Partial<GetLocationDetailRequest>, config?: UseQueryLocationDetailOptions) => {
  const queryKeys: GetLocationDetailQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_LOCATION_DETAIL,
        payload: params,
      },
    ],
    [params]
  );

  const { data, isFetching, isError } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getLocationDetail(payload),
    {
      enabled: !!params?.id,
      cacheTime: 600_000,
      staleTime: 600_000,
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
  };
};

export default useQueryLocationDetail;

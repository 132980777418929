import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const CheckmarkIcon: FC<Props> = ({ width = 18, height = 14, color = 'uiSuccessMain', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 18 14'} fill={'none'}>
        <path
          d={'M0 7.57431L6.16399 13.7501L18 1.92576L16.3007 0.25L6.16399 10.375L1.67572 5.88676L0 7.57431Z'}
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default CheckmarkIcon;

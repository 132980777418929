import Token from '@constants/token';
import { TokenBorderRadius } from '@interfaces/token';

const borderRadius = (value: TokenBorderRadius) => ({
  borderRadius: Token.borderRadius[value],
});

const styles = {
  borderRadius,
};

export default styles;

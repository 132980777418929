import { useCallback, useMemo } from 'react';
import { GetOrderDetailResponse } from '@api/order/types';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';
import { useQueryProducts } from '@queries/products';
import cartStore from '@storage/cartStore';
import {
  getCartFromOrderDetail,
  getProductListFromOrderDetailResponse,
  getProductListFromResponse,
  productQueryParams,
} from '@utils/product';

interface Props {
  orderDetail?: GetOrderDetailResponse;
}

const useResetCartFromOrder = ({ orderDetail }: Props) => {
  const { updateStorage, isFetching: isFetchingCommonStore } = useCommonStore();
  const { isClosedBill, isOpenBill } = useCheckDineIn();

  const typeOrder = useMemo(() => {
    if (isOpenBill && !isClosedBill) {
      return 'open_bill';
    }
    if (!isOpenBill && isClosedBill) {
      return 'close_bill';
    }
    return 'online_ordering';
  }, [isClosedBill, isOpenBill]);

  const productParams = useMemo(() => productQueryParams(orderDetail), [orderDetail]);

  const { isFetching, data, queryKeys } = useQueryProducts(productParams, {
    enabled: !!orderDetail?.id,
  });

  const availableProductListForReorder = useMemo(() => {
    const availableProductList = getProductListFromResponse(data);
    const orderProductList = getProductListFromOrderDetailResponse(orderDetail);

    return availableProductList.flatMap((product) => {
      const foundOrder = orderProductList.find((orderProduct) => orderProduct.id === product.id);

      return foundOrder || [];
    });
  }, [data, orderDetail]);

  const handleResetCart = useCallback(async () => {
    if (orderDetail?.location?.id) {
      await cartStore.setItem(
        orderDetail.location.id,
        getCartFromOrderDetail(availableProductListForReorder, queryKeys),
        typeOrder
      );
      await updateStorage({ activeLocation: orderDetail.location });
    }
  }, [orderDetail?.location, availableProductListForReorder, queryKeys, typeOrder, updateStorage]);

  const canReorder = useMemo(() => !!availableProductListForReorder?.length, [availableProductListForReorder?.length]);

  return useMemo(
    () => ({
      canReorder,
      handleResetCart,
      isLoading: isFetching || isFetchingCommonStore,
    }),
    [canReorder, handleResetCart, isFetching, isFetchingCommonStore]
  );
};

export default useResetCartFromOrder;

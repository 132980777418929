import { Box } from '@components/common';
import { ChefHatIcon } from '@components/icons';

const EndIcon = () => (
  <Box
    display={'flex'}
    width={27}
    height={27}
    alignItems={'center'}
    justifyContent={'center'}
    padding={'xxxs'}
    borderRadius={'circle'}
    background={'uiLightPrimary'}
  >
    <ChefHatIcon width={22} />
  </Box>
);

export default EndIcon;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
  contentColor?: TokenColor;
}

const ArrowIcon: FC<Props> = ({ width = 32, height = 33, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 32 33'} fill={'none'}>
        <path
          d={'M27 16.0508H5'}
          stroke={fillColor}
          strokeWidth={'2.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M14 7.04492L5 16.0508L14 25.0566'}
          stroke={fillColor}
          strokeWidth={'2.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    </BaseIcon>
  );
};

export default ArrowIcon;

const config = {
  apiUrl: {
    runchise: process.env.NEXT_PUBLIC_BACKEND_URL,
  },
  apiKey: {
    googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    xenditApiKey: process.env.NEXT_PUBLIC_XENDIT_API_KEY,
  },
  appId: {
    oneSignalAppId: process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID,
  },
  firebase: {
    firebaseVapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY,
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  },
  contact: {
    email: process.env.NEXT_PUBLIC_RUNCHISE_CONTACT_EMAIL,
    website: 'https://www.runchise.com',
  },
  featureToggle: {
    enableGrabExpress: process.env.NEXT_PUBLIC_ENABLE_GRAB_EXPRESS === 'true',
    enableXenditSavedCc: process.env.NEXT_PUBLIC_ENABLE_XENDIT_SAVED_CC === 'true',
  },
};

export default config;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';
import * as Sentry from '@sentry/browser';
import { captureMessage } from '@utils/sentry';

const useDetectRouteChanges = () => {
  const router = useRouter();

  const previousPath = useRef(router.asPath);
  const previousQuery = useRef(router.query);

  const handleRouteChange = useCallback(
    (url: string) => {
      if (
        (previousQuery.current?.[OPEN_BILL_QUERY_PARAMS] && !router.query?.[OPEN_BILL_QUERY_PARAMS]) ||
        (previousQuery.current?.[CLOSED_BILL_QUERY_PARAMS] && !router.query?.[CLOSED_BILL_QUERY_PARAMS])
      ) {
        Sentry.withScope((scope) => {
          scope.setContext('traceRouteChanges', {
            previousRoute: previousPath.current,
            previousQuery: previousQuery.current,
            newRoute: url,
          });
          captureMessage('Token removed detected');
        });
      }
      previousPath.current = url;
      previousQuery.current = router.query;
    },
    [router.query]
  );

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [handleRouteChange, router.events]);
};

export default useDetectRouteChanges;

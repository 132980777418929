import React, { useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { updateProfile } from '@api/auth';
import { UserProfile } from '@api/auth/types';
import { Button, Dialog, RHFTextField, Stack, Transition, Typography } from '@components/common';
import { useQueryProfileDetail } from '@queries/auth';
import { DeliveryAddressNavbar } from '..';
import useForm, { FormProps } from './forms/useForm';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSuccessSubmit: (profile: Partial<UserProfile>) => void;
}

const UpdateProfileNameFormDialog: React.FC<Props> = ({ open = false, onClose, onSuccessSubmit }) => {
  const queryClient = useQueryClient();

  const snackbarContext = useContext(SnackbarContext);
  const { t } = useTranslation();
  const { control, handleSubmit, getValues } = useForm();

  const { queryKeys } = useQueryProfileDetail({
    enabled: false,
  });

  const { isLoading, mutate } = useMutation(updateProfile, {
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
    onSuccess: () => {
      onSuccessSubmit(getValues());
      snackbarContext?.openSnackbar?.({
        message: t('sessions.success_update_profile_label'),
        alertProps: {
          severity: 'success',
        },
      });
      queryClient.invalidateQueries(queryKeys);
    },
  });

  const onSubmit: SubmitHandler<FormProps> = (data) => {
    mutate({
      name: data.name,
    });
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DeliveryAddressNavbar onPressBack={onClose} />
      <Stack direction={'column'} padding={'m'} spacing={'m'}>
        <Typography size={'xxl'} variant={'bold'}>
          {t('order.pickup_required_form_title')}
        </Typography>

        <Stack spacing={'hs'}>
          <RHFTextField
            inputProps={{
              placeholder: t('sessions.name_placeholder'),
            }}
            control={control}
            name={'name'}
            label={t('sessions.profile_name_label')}
          />
        </Stack>

        <Button onClick={handleSubmit(onSubmit)} variant={'contained'} padding={'m'} isLoading={isLoading}>
          <Typography size={'hm'} variant={'medium'}>
            {t('order.delivery_address_submit_button_label')}
          </Typography>
        </Button>
      </Stack>
    </Dialog>
  );
};

export default UpdateProfileNameFormDialog;

import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GetOrderDetailResponse } from '@api/order/types';
import { Button, Stack, Typography } from '@components/common';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS, TABLE_NO_QUERY_PARAMS } from '@constants/dineIn';
import { ORDER_FINAL_STATUS_LIST } from '@constants/order';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useResetCartFromOrder } from '@hooks/orders';

interface Props {
  orderDetail: GetOrderDetailResponse;
}

const OrderDetailAction = ({ orderDetail }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const orderLocationId = orderDetail.location?.id || 0;

  const { isOpen } = useRedirectByLocationSchedule({ locationId: orderLocationId, skipRedirect: true });
  const { isLoading, handleResetCart, canReorder } = useResetCartFromOrder({ orderDetail });

  const handleReorder = useCallback(async () => {
    handleResetCart();

    if ('closedBill' in orderDetail && orderDetail.type === 'closed_bill') {
      if (orderDetail?.status === 'waiting_payment') {
        router.push({
          pathname: routes.CLOSED_BILL_DETAIL,
          query: {
            [CLOSED_BILL_QUERY_PARAMS]: orderDetail?.closedBill?.closedBillToken,
            [TABLE_NO_QUERY_PARAMS]: orderDetail?.closedBill?.tableNo,
            orderId: orderDetail?.id,
          },
        });
        return;
      }

      router.push({
        pathname: routes.PRODUCT_CART,
        query: {
          [CLOSED_BILL_QUERY_PARAMS]: orderDetail?.closedBill?.closedBillToken,
          [TABLE_NO_QUERY_PARAMS]: orderDetail?.closedBill?.tableNo,
        },
      });
      return;
    }

    router.push({
      pathname: routes.PRODUCT_CART,
    });
  }, [handleResetCart, orderDetail, router]);

  const { type: orderType } = orderDetail;
  const isOrderStatusOnGoing = orderDetail.status && !ORDER_FINAL_STATUS_LIST.includes(orderDetail?.status);

  const getTrackOrderProps = useCallback(() => {
    if (orderType === 'delivery' || orderType === 'self_pickup') {
      return {
        label: t('order.track_order_button_label'),
        href: {
          pathname: routes.ORDER_TRACKING,
          query: {
            orderId: orderDetail.id,
          },
        },
      };
    }

    if (
      'openBill' in orderDetail &&
      orderType === 'merged_open_bill_order' &&
      (orderDetail?.status === 'opened' ||
        orderDetail?.status === 'waiting_for_cash' ||
        orderDetail?.status === 'waiting_payment')
    ) {
      return {
        label: t('order.continue_payment'),
        href: {
          pathname: routes.OPEN_BILL_DETAIL,
          query: {
            orderId: orderDetail.id,
            [OPEN_BILL_QUERY_PARAMS]: orderDetail.openBill?.uuid,
          },
        },
      };
    }
  }, [orderDetail, orderType, t]);

  const renderOrderAction = useCallback(() => {
    const validOrderType = orderType === 'delivery' || orderType === 'self_pickup' || orderType === 'closed_bill';
    const hasOngoingClosedOrderWaitingPayment =
      'closedBill' in orderDetail && orderType === 'closed_bill' && orderDetail?.status === 'waiting_payment';

    if (!isOrderStatusOnGoing && orderType === 'merged_open_bill_order') {
      return <Typography variant={'medium'}>{t('dine_in.order_again_description')}</Typography>;
    }

    if (canReorder && validOrderType && (!isOrderStatusOnGoing || hasOngoingClosedOrderWaitingPayment)) {
      return (
        <Stack flexGrow={1}>
          <Button
            onClick={handleReorder}
            disabled={!isOpen || isLoading}
            variant={'contained'}
            padding={Token.spacing.m}
            color={'secondary'}
            isLoading={isLoading}
          >
            <Typography variant={'medium'}>
              {hasOngoingClosedOrderWaitingPayment ? t('order.continue_payment') : t('order.reorder_button_label')}
            </Typography>
          </Button>
        </Stack>
      );
    }

    const trackOrderProps = getTrackOrderProps();
    if (isOrderStatusOnGoing && trackOrderProps) {
      return (
        <Stack flexGrow={1}>
          <Link href={trackOrderProps.href} passHref>
            <Button variant={'contained'} padding={Token.spacing.m}>
              <Typography variant={'medium'}>{trackOrderProps.label}</Typography>
            </Button>
          </Link>
        </Stack>
      );
    }

    return null;
  }, [
    canReorder,
    getTrackOrderProps,
    handleReorder,
    isLoading,
    isOpen,
    isOrderStatusOnGoing,
    orderDetail,
    orderType,
    t,
  ]);

  return (
    <Stack direction={'row'} spacing={'m'}>
      {renderOrderAction()}
    </Stack>
  );
};

export default OrderDetailAction;

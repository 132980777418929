import { FieldError } from 'react-hook-form';

export const getRhfError = <T>(errors?: FieldError | T, key?: keyof T) => {
  if (key) {
    const currentError = (errors as T)?.[key as keyof T];

    if (currentError && 'message' in currentError) {
      return (currentError as unknown as FieldError)?.message;
    }

    return;
  }

  if (errors && 'message' in errors) {
    return errors?.message;
  }

  if (errors) {
    const errorKeys = Object.keys(errors);
    const errorMessages = errorKeys.map((errorKey) => {
      if (errorKey in errors) {
        const currentError = (errors as T)[errorKey as keyof T];
        if (currentError && 'message' in currentError) {
          return (currentError as unknown as FieldError)?.message;
        }
      }
    });

    return errorMessages.join(',');
  }
};

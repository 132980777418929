import React, { useMemo } from 'react';
import { CustomerOrderDetail, OrderType } from '@api/order/types';
import { Stack } from '@components/common';
import {
  CheckmarkIcon,
  CloseIcon,
  DeliveryManIcon,
  FlagIcon,
  PickupManIcon,
  ShoppingBasketIcon,
} from '@components/icons';
import { TokenColor } from '@interfaces/token';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const orderSteps = ['checkmark', 'shopping-basket', 'delivery-man', 'flag'];
const orderFailedSteps = ['checkmark', 'flag'];

interface Props {
  type: OrderType;
  status: CustomerOrderDetail['status'];
}
const OrderStatusStepper: React.FC<Props> = ({ status, type }) => {
  const activeStep = useMemo(() => {
    if (
      status === 'unpaid' ||
      status === 'waiting_payment' ||
      status === 'paid' ||
      status === 'waiting_restaurant_confirmation' ||
      status === 'refunded'
    ) {
      return 0;
    }

    if (status === 'restaurant_confirmed' || status === 'waiting_food_to_be_ready') {
      return 1;
    }

    if (status === 'delivery_processing' || status === 'waiting_driver_to_pickup' || status === 'waiting_pickup') {
      return 2;
    }
    return 3;
  }, [status]);

  const renderChatIcon = (index: number) => {
    const color = index > activeStep ? 'uiDarkSecondary' : 'uiLightPrimary';

    if (index === 0) {
      return <CheckmarkIcon color={color} />;
    }
    if (index === 1) {
      if (status === 'refunded' || status === 'expired' || status === 'cancelled') {
        return <CloseIcon color={'uiLightPrimary'} />;
      }
      return <ShoppingBasketIcon color={color} />;
    }

    if (index === 2) {
      if (type === 'self_pickup') {
        return <PickupManIcon color={color} />;
      }
      return <DeliveryManIcon color={color} />;
    }

    return <FlagIcon color={color} />;
  };

  const steps = status === 'refunded' || status === 'expired' || status === 'cancelled' ? orderFailedSteps : orderSteps;

  return (
    <Stack paddingX={'xxl'}>
      <Stepper activeStep={1} connector={<QontoConnector />} alternativeLabel>
        {steps.map((_, index) => {
          const isActive = index <= activeStep;
          const isOrderFailedSteps =
            (status === 'refunded' || status === 'expired' || status === 'cancelled') && index === 1;
          const defaultBorderColor: TokenColor = isActive ? 'uiPrimaryMain' : 'uiDarkSecondary';
          const defaultBgColor: TokenColor = isActive ? 'uiPrimaryMain' : 'uiLightPrimary';
          const borderColor: TokenColor = isOrderFailedSteps ? 'uiRedPrimary' : defaultBorderColor;
          const bgColor: TokenColor = isOrderFailedSteps ? 'uiRedPrimary' : defaultBgColor;

          const StepComponent = () => {
            return (
              <Stack
                zIndex={1}
                borderRadius={'rounded'}
                borderWidth={'bold'}
                padding={'xxxs'}
                borderColor={borderColor}
              >
                <Stack
                  width={35}
                  height={35}
                  alignItems={'center'}
                  justifyContent={'center'}
                  background={bgColor}
                  borderRadius={'rounded'}
                >
                  {renderChatIcon(index)}
                </Stack>
              </Stack>
            );
          };

          return (
            <Step key={index} active={isActive}>
              <StepLabel StepIconComponent={StepComponent}></StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

export default OrderStatusStepper;

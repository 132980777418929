import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getProducts } from '@api/products';
import { GetProductsErrorResponse, GetProductsRequest, GetProductsResponse } from '@api/products/types';
import { QUERY_KEY_PRODUCTS } from '@constants/queryKey';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';
import { useSubBrandInfo } from '@hooks/subBrands';
import { useQueryBrandInfo } from '@queries/commons';

export interface GetProductsQueryKeys {
  key: string;
  payload?: Partial<GetProductsRequest>;
}

type UseQueryProductsOptions = UseQueryOptions<
  GetProductsResponse,
  APIDefaultErrors | GetProductsErrorResponse,
  GetProductsResponse,
  GetProductsQueryKeys[]
>;

const useQueryProducts = (params?: Partial<GetProductsRequest>, config?: UseQueryProductsOptions) => {
  const { storageState, isFinishInitiated } = useCommonStore();
  const { data: brandInfoData } = useQueryBrandInfo();
  const { closedBillToken, openBillToken } = useCheckDineIn();
  const locationId = storageState.activeLocation?.id;
  const allowMultiBrand = brandInfoData?.brand?.allowMultiBrand;
  const { id, name } = useSubBrandInfo();

  const queryKeys: GetProductsQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_PRODUCTS,
        payload: {
          locationId,
          ...(allowMultiBrand
            ? {
                subBrandId: id,
                subBrandName: name,
              }
            : {}),
          enableSubBrand: allowMultiBrand,
          ...(openBillToken
            ? {
                uuid: openBillToken,
              }
            : {}),
          ...(closedBillToken
            ? {
                closedBillToken,
              }
            : {}),
          ...params,
        },
      },
    ],
    [locationId, id, allowMultiBrand, openBillToken, closedBillToken, params, name]
  );

  const { data, isFetching } = useQuery(queryKeys, ({ queryKey: [{ payload }] }) => getProducts(payload), {
    /**
     * Only on dine in & multi brand mode, we need to wait until subBrandId is available before fetching products.
     */
    enabled: isFinishInitiated && !!locationId && !!id,
    staleTime: 300_000,
    cacheTime: 300_000,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
  };
};

export default useQueryProducts;

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getDeliveryGrabQuotation } from '@api/order';
import { GetDeliveryGrabQuotationRequest, GetDeliveryQuotationResponse } from '@api/order/types';
import { QUERY_KEY_DELIVERY_GRAB_QUOTATION } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface DeliveryGrabQuotationQueryKey {
  key: string;
  payload: GetDeliveryGrabQuotationRequest;
}

type UseQueryDeliveryQuotationOptions = UseQueryOptions<
  GetDeliveryQuotationResponse,
  APIDefaultErrors,
  GetDeliveryQuotationResponse,
  DeliveryGrabQuotationQueryKey[]
>;

const useQueryDeliveryGrabQuotation = (
  params: GetDeliveryGrabQuotationRequest,
  config?: UseQueryDeliveryQuotationOptions
) => {
  const { isLoggedIn } = getUserCookies();
  const queryKeys: DeliveryGrabQuotationQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_DELIVERY_GRAB_QUOTATION,
        payload: params,
      },
    ],
    [params]
  );

  const { data, isFetching, isError, isSuccess, refetch } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getDeliveryGrabQuotation(payload),
    {
      enabled: !!isLoggedIn,
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};

export default useQueryDeliveryGrabQuotation;

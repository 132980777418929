import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Image, Stack, Typography } from '@components/common';
import config from '@constants/config';
import { OrderPlacementFormShape } from '@containers/order/OrderPlacementContainer/forms/useFormOrderPlacement';
import { useCommonStore } from '@hooks/storage';
import { useQueryLocationDetail } from '@queries/locations';
import DeliveryOptionList from '../DeliveryOptionList';

const DeliveryVendorForm = () => {
  const { t } = useTranslation();

  const { control, watch } = useFormContext<OrderPlacementFormShape>();
  const deliveryMethod = watch('deliveryMethod');
  const { storageState } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const { data: locationDetailResponse } = useQueryLocationDetail({
    id: activeLocationId,
  });

  const {
    enableLalaMoveCar = false,
    enableLalaMoveMotorcycle = false,
    enableGrabExpressCar = false,
    enableGrabExpressMotorcycle = false,
  } = locationDetailResponse || {};

  const canDeliveryGrab = useMemo(() => {
    if (config.featureToggle.enableGrabExpress) {
      if (deliveryMethod === 'car') {
        return enableGrabExpressCar;
      }

      if (deliveryMethod === 'motorcycle') {
        return enableGrabExpressMotorcycle;
      }
    }

    return false;
  }, [enableGrabExpressCar, enableGrabExpressMotorcycle, deliveryMethod]);

  const canDeliveryLalamove = useMemo(() => {
    if (deliveryMethod === 'car') {
      return enableLalaMoveCar;
    }

    if (deliveryMethod === 'motorcycle') {
      return enableLalaMoveMotorcycle;
    }

    return false;
  }, [enableLalaMoveCar, enableLalaMoveMotorcycle, deliveryMethod]);

  const deliveryVendorOptions = useMemo(
    () => [
      ...(canDeliveryGrab
        ? [
            {
              value: 'grab',
              image: <Image height={31} src={'/images/grab-express-logo.svg'} />,
            },
          ]
        : []),
      ...(canDeliveryLalamove
        ? [
            {
              value: 'lalamove',
              image: <Image height={26} src={'/images/lalamove-logo.svg'} />,
            },
          ]
        : []),
    ],
    [canDeliveryGrab, canDeliveryLalamove]
  );

  return (
    <Stack spacing={'hs'}>
      {deliveryVendorOptions?.length > 0 ? (
        <Controller
          name={'deliveryVendor'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <DeliveryOptionList options={deliveryVendorOptions} value={value} onChange={onChange} />
          )}
        />
      ) : (
        <Typography variant={'bold'} color={'uiRedPrimary'} textAlign={'center'}>
          {t('order.no_delivery_vendor_available')}
        </Typography>
      )}
    </Stack>
  );
};

export default DeliveryVendorForm;

import Token from '@constants/token';
import { css } from '@emotion/react';

const color = (colorType: Lowercase<keyof typeof Token.color>) => css`
  color: ${Token.color[colorType.toUpperCase() as keyof typeof Token.color]};
`;

const styles = {
  color,
};

export default styles;

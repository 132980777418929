import { CSSProperties } from 'react';
import Token from '@constants/token';
import { css } from '@emotion/react';

const label = css`
  gap: ${Token.spacing.xs}px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  flex-grow: 1;
  border-radius: ${Token.borderRadius.default};
`;

const direction = (value: CSSProperties['flexDirection']) => css`
  flex-direction: ${value};
`;

const alignItems = (value: CSSProperties['alignItems']) => css`
  align-items: ${value};
`;

const disabled = css`
  &:hover {
    cursor: not-allowed;
  }
`;

const formControlLabelRoot = (variant?: 'outline') => css`
  padding: ${variant === 'outline' ? `${Token.spacing.m}px` : 'initial'};
`;

const formControlLabelLabel = css`
  .MuiFormControlLabel-label {
    display: flex;
  }
`;

const styles = {
  label,
  direction,
  alignItems,
  disabled,
  formControlLabelRoot,
  formControlLabelLabel,
};

export default styles;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const CloseOutlinedIcon: FC<Props> = ({ width = 16, height = 16, color = 'uiRedPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 16 16'} fill={'none'}>
        <path
          d={
            'M8 15.875C3.66875 15.875 0.125 12.3313 0.125 8C0.125 3.66875 3.66875 0.125 8 0.125C12.3313 0.125 15.875 3.66875 15.875 8C15.875 12.3313 12.3313 15.875 8 15.875ZM8 1.25C4.2875 1.25 1.25 4.2875 1.25 8C1.25 11.7125 4.2875 14.75 8 14.75C11.7125 14.75 14.75 11.7125 14.75 8C14.75 4.2875 11.7125 1.25 8 1.25Z'
          }
          fill={fillColor}
        />
        <path
          d={
            'M11.6561 10.8686L8.78733 7.99981L11.6561 5.13106C11.8811 4.90606 11.8811 4.56856 11.6561 4.34355C11.4311 4.11855 11.0936 4.11855 10.8686 4.34355L7.99984 7.21231L5.13108 4.34355C4.90608 4.11855 4.56859 4.11855 4.34359 4.34355C4.11859 4.56856 4.11859 4.90606 4.34359 5.13106L7.21234 7.99981L4.34359 10.8686C4.11859 11.0936 4.11859 11.4311 4.34359 11.6561C4.56859 11.8811 4.90608 11.8811 5.13108 11.6561L7.99984 8.78731L10.8686 11.6561C11.0936 11.8811 11.4311 11.8811 11.6561 11.6561C11.8811 11.4311 11.8811 11.0936 11.6561 10.8686Z'
          }
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default CloseOutlinedIcon;

import Token from '@constants/token';
import { CircularProgress } from '@mui/material';
import styles from './styles';

interface Props {
  size?: Lowercase<keyof typeof Token.fontSize>;
  color?: Lowercase<keyof typeof Token.color>;
}

const DefaultLoading = ({ size = 'm', color = 'uidarkprimary' }: Props) => {
  return <CircularProgress css={[Boolean(color) && styles.color(color)]} size={Token.spacing[size]} />;
};

export default DefaultLoading;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const MenuIcon: FC<Props> = ({ width = 20, height = 20, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 24 24'}>
        <path
          fill={fillColor}
          d={
            'M23 12.9795H1C0.447998 12.9795 0 12.5315 0 11.9795C0 11.4275 0.447998 10.9795 1 10.9795H23C23.552 10.9795 24 11.4275 24 11.9795C24 12.5315 23.552 12.9795 23 12.9795Z'
          }
        />
        <path
          fill={fillColor}
          d={
            'M23 5.3125H1C0.447998 5.3125 0 4.8645 0 4.3125C0 3.7605 0.447998 3.3125 1 3.3125H23C23.552 3.3125 24 3.7605 24 4.3125C24 4.8645 23.552 5.3125 23 5.3125Z'
          }
        />
        <path
          fill={fillColor}
          d={
            'M23 20.6455H1C0.447998 20.6455 0 20.1975 0 19.6455C0 19.0935 0.447998 18.6455 1 18.6455H23C23.552 18.6455 24 19.0935 24 19.6455C24 20.1975 23.552 20.6455 23 20.6455Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default MenuIcon;

import { CSSProperties, Ref, forwardRef } from 'react';
import Token from '@constants/token';
import { TokenType } from '@interfaces/token';
import { Stack as MUIStack, StackProps as MUIStackProps, useTheme } from '@mui/material';
import getTokenColorFromPaletteOptions from '@utils/theme/getTokenColorFromPaletteOptions';
import styles from './styles';

interface Props {
  spacing?: TokenType['spacing'];
  padding?: TokenType['spacing'];
  paddingX?: TokenType['spacing'];
  paddingLeft?: TokenType['spacing'];
  paddingRight?: TokenType['spacing'];
  paddingY?: TokenType['spacing'];
  paddingTop?: TokenType['spacing'];
  paddingBottom?: TokenType['spacing'];
  margin?: TokenType['spacing'];
  marginX?: TokenType['spacing'];
  marginLeft?: TokenType['spacing'];
  marginRight?: TokenType['spacing'];
  marginY?: TokenType['spacing'];
  marginTop?: TokenType['spacing'];
  marginBottom?: TokenType['spacing'];
  borderRadius?: TokenType['borderRadius'];
  borderTopLeftRadius?: TokenType['borderRadius'];
  borderTopRightRadius?: TokenType['borderRadius'];
  borderColor?: TokenType['color'];
  borderWidth?: TokenType['borderWidth'];
  borderStyle?: CSSProperties['borderStyle'];
  background?: TokenType['color'];
  columnGap?: TokenType['spacing'];
  rowGap?: TokenType['spacing'];
  overflowX?: CSSProperties['overflowX'];
  overflowY?: CSSProperties['overflowY'];
  disabled?: boolean;
}

type OmittedStackProps =
  | 'gap'
  | 'spacing'
  | 'padding'
  | 'paddingX'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingY'
  | 'paddingTop'
  | 'paddingBottom'
  | 'margin'
  | 'marginX'
  | 'marginLeft'
  | 'marginRight'
  | 'marginY'
  | 'marginTop'
  | 'marginBottom'
  | 'borderRadius'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderColor'
  | 'ref'
  | 'borderWidth'
  | 'columnGap'
  | 'rowGap';

export type StackProps = Props & Omit<MUIStackProps, OmittedStackProps>;

const Stack = forwardRef(function StackRef(props: StackProps, ref?: Ref<HTMLDivElement>) {
  const theme = useTheme();
  const colorPalette = getTokenColorFromPaletteOptions(theme.palette);

  const {
    children,
    // Overrided Stack Props
    spacing,
    padding,
    paddingX,
    paddingLeft,
    paddingRight,
    paddingY,
    paddingTop,
    paddingBottom,
    margin,
    marginX,
    marginLeft,
    marginRight,
    marginY,
    marginTop,
    marginBottom,
    borderRadius,
    borderColor,
    borderWidth,
    borderTopLeftRadius,
    borderTopRightRadius,
    columnGap,
    rowGap,
    overflowX,
    overflowY,

    // Inline Style Props
    background,
    borderStyle,

    // Other Stack Props
    disabled,
    ...otherProps
  } = props;

  const style = {
    spacing: spacing && Token.spacing[spacing],
    padding: padding && Token.spacing[padding],
    paddingX: paddingX && Token.spacing[paddingX],
    paddingLeft: paddingLeft && Token.spacing[paddingLeft],
    paddingRight: paddingRight && Token.spacing[paddingRight],
    paddingY: paddingY && Token.spacing[paddingY],
    paddingTop: paddingTop && Token.spacing[paddingTop],
    paddingBottom: paddingBottom && Token.spacing[paddingBottom],
    margin: margin && Token.spacing[margin],
    marginX: marginX && Token.spacing[marginX],
    marginLeft: marginLeft && Token.spacing[marginLeft],
    marginRight: marginRight && Token.spacing[marginRight],
    marginY: marginY && Token.spacing[marginY],
    marginTop: marginTop && Token.spacing[marginTop],
    marginBottom: marginBottom && Token.spacing[marginBottom],
    border: borderWidth && Token.borderWidth[borderWidth],
    borderRadius: borderRadius && Token.borderRadius[borderRadius],
    borderColor: borderColor && colorPalette[borderColor],
    columnGap: columnGap && Token.spacing[columnGap],
    rowGap: rowGap && Token.spacing[rowGap],
  };

  const inlineStyle: CSSProperties = {
    background: background && colorPalette[background],
    borderStyle,
    borderTopRightRadius: borderTopRightRadius && Token.borderRadius[borderTopRightRadius],
    borderTopLeftRadius: borderTopLeftRadius && Token.borderRadius[borderTopLeftRadius],
  };

  return (
    <MUIStack
      ref={ref}
      style={inlineStyle}
      {...style}
      {...otherProps}
      css={[
        ...(overflowX ? [styles.overflowX(overflowX)] : []),
        ...(overflowY ? [styles.overflowY(overflowY)] : []),
        ...(disabled ? [styles.disabled] : []),
      ]}
    >
      {children}
    </MUIStack>
  );
});

export default Stack;

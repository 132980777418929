import { useTranslation } from 'react-i18next';
import { CustomerOrderDetail } from '@api/order/types';
import { Typography } from '@components/common';
import { ORDER_DEFAULT_TIMER } from '@constants/order';
import { useOrderCountdownTimer } from '@hooks/orders';

interface Props {
  status: CustomerOrderDetail['status'];
  paidAt?: string;
}

const CountdownTimer = ({ status, paidAt }: Props) => {
  const { t } = useTranslation();
  const timeLeft = useOrderCountdownTimer(ORDER_DEFAULT_TIMER, status, paidAt);

  return (
    <Typography textAlign={'center'} color={'uiDarkSecondary'}>
      {t('order.time_remaining', { time: timeLeft })}
    </Typography>
  );
};

export default CountdownTimer;

import { useMemo } from 'react';
import { CustomerOrderPriceDetail, PaymentMethod } from '@api/order/types';
import { useQueryOpenBillCalculatePayment } from '@queries/dineIn';
import { getPaymentMethodAndTypeForCustomerOrderPrice } from '@utils/payment';

interface Props {
  paymentMethod?: PaymentMethod;
}

const useMergedOpenBillPriceDetail = ({ paymentMethod }: Props) => {
  const request = useMemo(
    () => ({
      ...(getPaymentMethodAndTypeForCustomerOrderPrice(paymentMethod) ?? {}),
    }),
    [paymentMethod]
  );

  const { data, isFetching, error, refetch } = useQueryOpenBillCalculatePayment(request, {
    enabled: true,
  });

  const isReady = !!data?.totalAmount;

  const priceDetail = {
    subTotal: data?.subTotal,
    subTotalBeforeTax: data?.subTotalBeforeTax,
    serviceCharge: data?.serviceCharge,
    serviceChargeAfterTax: data?.serviceChargeAfterTax,
    taxAmount: data?.taxAmount,
    deliveryFee: '0',
    totalAmount: data?.totalAmount,
    creditUsage: data?.creditUsage,
    totalAmountAfterCredit: data?.totalAmountAfterCredit,
    isTaxInclusive: data?.isTaxInclusive,
    onlinePlatformFee: data?.onlinePlatformFee,
    roundingAmount: data?.roundingAmount,
    totalAmountBeforeRounding: data?.totalAmountBeforeRounding,
    dineInFeeChargeToPurchaser: data?.dineInFeeChargeToPurchaser,
    dineInPlatformFee: data?.dineInPlatformFee,
    dineInPgFee: data?.dineInPgFee,
    totalAmountFinal: data?.totalAmountFinal,
    promoAmount: data?.promoAmount,
  } as CustomerOrderPriceDetail;

  return {
    priceDetail,
    error,
    refetch,
    isFetching,
    isReady,
  };
};

export default useMergedOpenBillPriceDetail;

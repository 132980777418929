import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DineInInvalid, NotFound } from '@components/common';
import { DINE_IN_QR_INVALID } from '@constants/errorType';
import { captureException } from '@utils/sentry';

interface Props {
  error?: Error;
  onRetry?: () => void;
  title?: string;
  description?: string;
  name?: string;
}

const Errors500Container = ({ error, onRetry, title, description = '', name }: Props) => {
  const { t } = useTranslation();
  const errorTitle = title || t('error.page_not_found_title');
  const errorDescription = description || t('error.page_server_error_description');

  useEffect(() => {
    if (error) {
      captureException(error);
    }
  }, [error]);

  if (name === DINE_IN_QR_INVALID) {
    return <DineInInvalid title={errorTitle} description={errorDescription} />;
  }
  return <NotFound onPressPrimary={onRetry} title={errorTitle} description={errorDescription} />;
};

export default Errors500Container;

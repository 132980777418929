import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { DecimalNumber } from '@api/baseAPI/types';
import { CreditCardPaymentMethod, OrderPaymentMethod, XenditPaymentMethodType } from '@api/order/types';
import { XenditCreateAuthenticationCallback, XenditCreateCCTokenCallback } from '@interfaces/xendit';
import { toNumber } from '@utils/number';
import { getXenditPaymentMethod, getXenditPaymentMethodType } from '@utils/payment';
import useXenditCc from './useXenditCc';

interface XenditCreateAuthenticationRequest {
  customerOrderId: string;
  totalAmount: DecimalNumber;
  paymentMethod: CreditCardPaymentMethod;
}

export interface OnSuccessXenditCcPaymentData {
  customerOrderId: string;
  paymentMethod: OrderPaymentMethod;
  paymentMethodType?: XenditPaymentMethodType;
  creditCardDetail: {
    tokenId: string;
    authenticationId: string;
    cardCvn: string;
  };
}

interface Props {
  onError: (message: string) => void;
  onSuccess: (data: OnSuccessXenditCcPaymentData) => void;
  onReview: (paymentUrl: string) => void;
}

const useXenditCcPayment = ({ onError, onSuccess, onReview }: Props) => {
  const xendit = useXenditCc();
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);

  const [isXenditLoading, setIsXenditLoading] = useState(false);

  const createAuthCallback = useCallback(
    (data: XenditCreateAuthenticationRequest): XenditCreateAuthenticationCallback => {
      return async (authErr, authResponse) => {
        setIsXenditLoading(false);
        if (authErr) {
          snackbarContext?.openSnackbar?.({
            message: authErr?.message,
            alertProps: {
              severity: 'error',
            },
          });
          return;
        }

        if (authResponse.status === 'FAILED') {
          const errorMessage = authResponse.failure_reason || t('error.xendit_cc_auth_error') || '';
          onError(errorMessage);
          snackbarContext?.openSnackbar?.({
            message: errorMessage,
            alertProps: {
              severity: 'error',
            },
          });
          return;
        }

        if (authResponse.status === 'VERIFIED') {
          if (authResponse.credit_card_token_id) {
            const { cvc = '' } = data.paymentMethod;
            const orderPaymentMethod = getXenditPaymentMethod(data.paymentMethod?.type);
            if (cvc && orderPaymentMethod) {
              onSuccess({
                customerOrderId: data.customerOrderId,
                paymentMethod: orderPaymentMethod,
                paymentMethodType: getXenditPaymentMethodType(data.paymentMethod),
                creditCardDetail: {
                  tokenId: authResponse.credit_card_token_id,
                  authenticationId: authResponse.id,
                  cardCvn: cvc,
                },
              });
            } else {
              snackbarContext?.openSnackbar?.({
                message: t('error.cvc_not_found_label'),
                alertProps: {
                  severity: 'error',
                },
              });
            }
          }
        }

        if (authResponse.status === 'IN_REVIEW') {
          if (authResponse.payer_authentication_url) {
            onReview(authResponse.payer_authentication_url);
          }
        }
      };
    },
    [onError, onReview, onSuccess, snackbarContext, t]
  );

  const createTokenCallback = useCallback(
    (data: XenditCreateAuthenticationRequest): XenditCreateCCTokenCallback => {
      return async (tokenErr, tokenResponse) => {
        setIsXenditLoading(false);
        if (tokenErr) {
          snackbarContext?.openSnackbar?.({
            message: tokenErr?.message,
            alertProps: {
              severity: 'error',
            },
          });
          return;
        }

        if (tokenResponse.status === 'FAILED') {
          const errorMessage = tokenResponse.failure_reason || t('error.xendit_cc_token_error') || '';
          onError(errorMessage);
          snackbarContext?.openSnackbar?.({
            message: tokenResponse.failure_reason,
            alertProps: {
              severity: 'error',
            },
          });
          return;
        }

        if (tokenResponse.status === 'VERIFIED') {
          // if verified but not authenticated yet
          if (!tokenResponse.authentication_id) {
            setIsXenditLoading(true);
            xendit.createAuthentication(
              {
                amount: data?.totalAmount,
                token_id: tokenResponse?.id,
              },
              createAuthCallback(data)
            );
            return;
          }

          const { cvc = '' } = data.paymentMethod as CreditCardPaymentMethod;
          const orderPaymentMethod = getXenditPaymentMethod(data.paymentMethod?.type);

          if (orderPaymentMethod) {
            onSuccess({
              customerOrderId: data.customerOrderId,
              paymentMethod: orderPaymentMethod,
              paymentMethodType: getXenditPaymentMethodType(data.paymentMethod),
              creditCardDetail: {
                tokenId: tokenResponse.id,
                authenticationId: tokenResponse.authentication_id,
                cardCvn: cvc,
              },
            });
          }
        }

        if (tokenResponse.status === 'IN_REVIEW') {
          if (tokenResponse.payer_authentication_url) {
            onReview(tokenResponse.payer_authentication_url);
          }
        }
      };
    },
    [createAuthCallback, onError, onReview, onSuccess, snackbarContext, t, xendit]
  );

  const handleOnSuccessCreditCard = useCallback(
    (data: XenditCreateAuthenticationRequest) => {
      const { totalAmount = '', paymentMethod } = data;
      const { expiredPeriod, cvc, accountNumber, shouldLinkToPersonalAccount } = paymentMethod;

      setIsXenditLoading(true);
      const { tokenId } = paymentMethod;
      if (tokenId) {
        xendit.createAuthentication(
          {
            amount: totalAmount,
            token_id: tokenId,
          },
          createAuthCallback(data)
        );
        return;
      }

      xendit.createToken(
        {
          amount: toNumber(totalAmount) ?? 0,
          cardNumber: accountNumber?.replace(/ /g, '') ?? '',
          cardExpMonth: expiredPeriod?.split('/')?.[0] ?? '',
          cardExpYear: `20${expiredPeriod?.split('/')?.[1]}`,
          cardCvn: cvc as string,
          isMultipleUse: shouldLinkToPersonalAccount ?? false,
        },
        createTokenCallback(data)
      );
    },
    [createAuthCallback, createTokenCallback, xendit]
  );

  return {
    isXenditLoading,
    handleOnSuccessCreditCard,
  };
};

export default useXenditCcPayment;

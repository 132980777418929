import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@api/order/types';
import { Hoverable, IconButton, Stack, Typography } from '@components/common';
import { ChevronIcon, IconPriceTag } from '@components/icons';
import routes from '@constants/routes';

interface Props {
  appliedPromos?: Promotion[];
}

const PromotionSelection = ({ appliedPromos }: Props) => {
  const { push, query } = useRouter();

  const onClickAvailablePromo = () => {
    push({
      pathname: routes.AVAILABLE_PROMO,
      query: {
        ...query,
        ...(appliedPromos &&
          appliedPromos.length > 0 && {
            promoId: appliedPromos?.flatMap((promo) => promo.id).join(','),
          }),
      },
    });
  };

  const { t } = useTranslation();

  if (appliedPromos && appliedPromos.length > 0) {
    return (
      <>
        <Hoverable display={'flex'} flexGrow={1} onClick={onClickAvailablePromo}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderRadius={'default'}
            spacing={'hs'}
            paddingY={'hs'}
            width={'100%'}
            overflow={'hidden'}
          >
            <Stack direction={'row'} columnGap={'xs'} alignItems={'center'}>
              <Stack padding={'xs'} background={'softGrey'} borderRadius={'default'}>
                <IconPriceTag width={20} height={20} />
              </Stack>
              <Typography size={'s'} variant={'bold'}>
                {t('promo.promo_applied', { count: appliedPromos.length })}
              </Typography>
            </Stack>
            <Stack padding={'xs'} borderColor={'uiLightStain'} borderWidth={'bolder'} borderRadius={'default'}>
              <ChevronIcon rotate={-90} width={12} height={12} />
            </Stack>
          </Stack>
        </Hoverable>
      </>
    );
  }

  return (
    <Hoverable display={'flex'} flexGrow={1} onClick={onClickAvailablePromo}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        borderRadius={'default'}
        spacing={'hs'}
        padding={'hs'}
        width={'100%'}
        overflow={'hidden'}
        boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.03)'}
      >
        <Typography size={'hs'} color={'uiDarkStain'}>
          {t('order.please_choose_promo')}
        </Typography>
        <IconButton padding={0}>
          <Stack
            borderRadius={'default'}
            background={'uiLightPrimary'}
            boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
            width={12}
            height={12}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ChevronIcon rotate={-90} />
          </Stack>
        </IconButton>
      </Stack>
    </Hoverable>
  );
};

export default PromotionSelection;

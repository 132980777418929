import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

interface Props {
  onEnter: () => void;
}

/**
 * Replaces react-waypoint due to the bugs introduced by that library.
 * Using react intersection observer will make sure the onEnter only called when the element is really in viewport.
 */
const Waypoint = ({ onEnter }: Props) => {
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && onEnter) {
      onEnter();
    }
  }, [inView, onEnter]);

  /**
   * NOTE: Need to add height 10 due to weird bug in some tablets that somehow make Waypoint not triggered.
   * Try to add text but make it transparent color so there's no weird text blinking when user with high speed internet scrolling.
   */
  return (
    <div style={{ height: 10, color: 'transparent' }} ref={ref}>
      {t('general.load_more')}
    </div>
  );
};

export default Waypoint;

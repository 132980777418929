import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Stack, Transition, Typography } from '@components/common';
import { ArrowIcon } from '@components/icons';

interface Props {
  open: boolean;
  pickupCode?: string;
  onClose: () => void;
}

const PickupCodeDialog: React.FC<Props> = (props) => {
  const { open, onClose, pickupCode } = props;
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <Stack padding={'m'} background={'uiLightPrimary'} flexDirection={'row'} alignItems={'center'} zIndex={1}>
        <Button onClick={onClose} variant={'text'} padding={0}>
          <ArrowIcon />
        </Button>
        <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
          <Typography variant={'medium'} size={'m'}>
            {t('order.pickup_code_title')}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={'xxl'} padding={'xl'} flexGrow={1} flexDirection={'column'} justifyContent={'center'}>
        <Typography align={'center'}>{t('order.pickup_description')}</Typography>
        <Stack flexDirection={'row'} justifyContent={'center'} columnGap={'xs'}>
          {pickupCode?.split('').map((code, index) => (
            <Stack key={index} background={'uiLightSecondary'} padding={'m'} borderRadius={'default'}>
              <Typography size={'xxxl'} variant={'medium'}>
                {code}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack flexDirection={'row'} justifyContent={'center'} columnGap={'xs'}>
          <Typography>{t('order.any_code_problem')}</Typography>
          <Typography color={'uiPrimaryMain'}>{t('order.contact_us')}</Typography>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PickupCodeDialog;

import { SubBrandDetail } from '@api/subBrands/types';
import { OptionCardListLoader, Stack } from '@components/common';
import { useCommonStore } from '@hooks/storage';
import { useQuerySubBrands } from '@queries/subBrands';
import NoSubBrandCard from './NoSubBrandCard';
import SubBrandCard from './SubBrandCard';

interface Props {
  open?: boolean;
  onClose: () => void;
}

const SubBrandLocations = ({ open = false, onClose }: Props) => {
  const { data, isFetching } = useQuerySubBrands(
    {},
    {
      enabled: open,
    }
  );
  const { updateStorage } = useCommonStore();

  const onPress = (subBrand: SubBrandDetail) => async () => {
    await updateStorage({ subBrand });
    onClose();
  };

  if (!open) {
    return null;
  }

  if (isFetching) {
    return <OptionCardListLoader />;
  }

  if (data?.subBrands?.length === 0) {
    return (
      <Stack width={'100%'} direction={'column'} spacing={'xxs'} borderRadius={'default'}>
        <NoSubBrandCard />
      </Stack>
    );
  }

  return (
    <Stack width={'100%'} direction={'column'} spacing={'xxs'} borderRadius={'default'}>
      {data?.subBrands.map((subBrand, index) => (
        <SubBrandCard key={index} onPress={onPress(subBrand)} subBrand={subBrand} />
      ))}
    </Stack>
  );
};

export default SubBrandLocations;

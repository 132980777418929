type LocaleEnFile = { en: Record<string, string> };
type LocaleIdFile = { id: Record<string, string> };

const localeGroups = [
  'actions',
  'balance',
  'dine_in',
  'error',
  'general',
  'locations',
  'products',
  'sessions',
  'menu',
  'order',
  'sub_brands',
  'promo',
];

const localeEnGroups = {};
localeGroups.map((group) => {
  const localeGroup = require(`../../locales/${group}/en.yml`)?.en as unknown as LocaleEnFile;
  Object.assign(localeEnGroups, localeGroup);
});

const localeIdGroups = {};
localeGroups.map((group) => {
  const localeGroup = require(`../../locales/${group}/id.yml`)?.id as unknown as LocaleIdFile;
  Object.assign(localeIdGroups, localeGroup);
});

const translations = {
  en: {
    ...localeEnGroups,
  },
  id: {
    ...localeIdGroups,
  },
};

export default translations;

import Token from '@constants/token';
import { css, keyframes } from '@emotion/react';
import { TokenColor } from '@interfaces/token';
import hexToRGBA from '@utils/theme/hexToRGBA';

const keyframePulse = (badgeColor: TokenColor) => {
  const color = Token.color?.[badgeColor];

  return keyframes`
    from, to {
      box-shadow: 0 0 0 0 ${hexToRGBA(color, 0.7)};
    }

    70% {
      box-shadow: 0 0 0 5px ${hexToRGBA(color, 0)};
    }

    100% {
      box-shadow: 0 0 0 0 ${hexToRGBA(color, 0)};
    }
  `;
};

const pulseAnimation = (badgeColor: TokenColor = 'uiRedPrimary') => css`
  .MuiBadge-dot {
    animation: ${keyframePulse(badgeColor)} 2s infinite;
  }
`;

const badgeColor = (badgeColor: TokenColor = 'uiRedPrimary', textColor: TokenColor = 'uiLightPrimary') => css`
  .MuiBadge-badge {
    background-color: ${Token.color?.[badgeColor]};
    color: ${Token.color?.[textColor]};
  }
`;

const styles = {
  pulseAnimation,
  badgeColor,
};

export default styles;

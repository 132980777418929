import { useRef, useState } from 'react';
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { Stack } from '@components/common';
import OrderTrackingDetailContent from './OrderTrackingDetailContent';

export const DRAWER_PINCH_HEIGHT = 40;

const OrderTrackingSwipeableEdge = () => {
  const drawerRef = useRef<BottomSheetRef>(null);

  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    height: 0,
  });

  const [mainVisibleContentHeight, setMainVisibleContentHeight] = useState(342);

  return (
    <Stack>
      <BottomSheet
        key={mainVisibleContentHeight}
        open
        skipInitialTransition
        ref={drawerRef}
        blocking={false}
        defaultSnap={({ maxHeight }) => maxHeight / 2}
        snapPoints={({ maxHeight }) => [DRAWER_PINCH_HEIGHT, mainVisibleContentHeight, maxHeight - 56]}
        onSpringEnd={(event) => {
          requestAnimationFrame(() => {
            const currentDrawerHeight = drawerRef?.current?.height || 0;
            const isOpen = drawerState.height < currentDrawerHeight;
            if (event.type !== 'OPEN') {
              setDrawerState({
                isOpen,
                height: drawerRef?.current?.height || 0,
              });
            }
          });
        }}
        expandOnContentDrag
      >
        <OrderTrackingDetailContent onChangeContentHeight={setMainVisibleContentHeight} />
      </BottomSheet>
    </Stack>
  );
};

export default OrderTrackingSwipeableEdge;

import { useRouter } from 'next/router';
import { Stack } from '@components/common';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import ProductDetailFormNavbar from '../ProductDetailFormNavbar';

const ProductSearchNavbar = () => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const goBack = () =>
    router.push({
      pathname: routes.ROOT,
      query,
    });

  return (
    <Stack position={'sticky'} top={0} background={'uiLightPrimary'} zIndex={1}>
      <ProductDetailFormNavbar onPressBack={goBack} />
    </Stack>
  );
};

export default ProductSearchNavbar;

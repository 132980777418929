import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getProducts } from '@api/products';
import { GetProductsErrorResponse, Product } from '@api/products/types';
import { useCommonStore } from '@hooks/storage';
import useCartStore from '@hooks/storage/useCartStore';
import { GetProductsQueryKeys } from '@queries/products/useQueryProducts';
import { getPricesFromCart } from '@utils/product';
import { GetProductDetailQueryKeys } from './useQueryProductDetail';

const useQueryProductCartList = () => {
  const { storageState } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;

  const { branchCart, updateProductCartItem } = useCartStore(activeLocationId);

  const queryKeys = Object.values(branchCart).reduce(
    (keys: GetProductsQueryKeys[] & GetProductDetailQueryKeys[], item) => {
      if (item.queryKeys) {
        item.queryKeys.forEach((key) => {
          if (!keys.some((existingKey) => existingKey.payload?.subBrandId === key.payload?.subBrandId)) {
            keys.push(key);
          }
        });
      }
      return keys;
    },
    []
  );

  const { data, isFetching, refetch } = useQuery(
    [queryKeys],
    async () => await Promise.all(queryKeys.map((queryKey) => getProducts(queryKey.payload))),
    {
      enabled: !!queryKeys?.length,
      staleTime: 300_000,
      cacheTime: 300_000,
      onError: async (error: GetProductsErrorResponse) => {
        if (error.errorType === 'sub_brand_error') {
          for (const productId of Object.keys(branchCart)) {
            const product = branchCart[Number(productId)];
            const payload = product?.queryKeys?.[0]?.payload;

            if (payload?.subBrandId === error.subBrandId && payload?.locationId === error.locationId) {
              await updateProductCartItem(Number(productId), {});
            }
          }
          refetch();
        }
      },
    }
  );

  const productCartList = useMemo(() => {
    const productCategories = data?.map((product) => product.productCategories).flat() || [];

    const products = productCategories.reduce((acc: Product[], productCategory) => {
      const currProducts = productCategory?.products || [];
      return [...acc, ...currProducts];
    }, []);

    if (isFetching) {
      return [];
    }
    const branchCartProductIds = Object.keys(branchCart) || [];

    const result = branchCartProductIds
      .map((productId) => products.find(({ id }) => Number(productId) === id))
      .filter((product) => product !== undefined) as Product[];

    return result;
  }, [data, isFetching, branchCart]);

  const prices = useMemo(() => getPricesFromCart(branchCart, productCartList), [branchCart, productCartList]);

  return {
    productCartList,
    prices,
    isFetching,
    refetchProductList: refetch,
  };
};

export default useQueryProductCartList;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Stack, Transition } from '@components/common';
import { DeliveryAddressAutoComplete } from '@components/order';
import DeliveryAddressNavbar from '@components/order/DeliveryAddressNavbar';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValue: string;
  placePredictions: google.maps.places.AutocompletePrediction[];
  getPlacePredictions: (opt: google.maps.places.AutocompletionRequest) => void;
  onSelectOption: (item: google.maps.places.AutocompletePrediction) => void;
  latitude: number;
  longitude: number;
}
const DeliveryAddressAutoCompleteDialog: React.FC<Props> = (props) => {
  const {
    latitude,
    longitude,
    open,
    onClose,
    defaultValue,
    placePredictions = [],
    onSelectOption,
    getPlacePredictions,
  } = props;
  const { t } = useTranslation();

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DeliveryAddressNavbar onPressBack={onClose} />
      <Stack paddingX={'m'}>
        <DeliveryAddressAutoComplete
          latitude={latitude}
          longitude={longitude}
          getPlacePredictions={getPlacePredictions}
          placePredictions={placePredictions}
          defaultValue={defaultValue}
          onSelectOption={onSelectOption}
          label={t('order.delivery_address_input_label')}
          onClose={onClose}
        />
      </Stack>
    </Dialog>
  );
};

export default DeliveryAddressAutoCompleteDialog;

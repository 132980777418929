import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const PhoneIcon: FC<Props> = ({ width = 22, height = 22, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 22 22'} fill={'none'}>
        <path
          d={
            'M21.4014 16.1458L18.3312 13.0756C17.2347 11.9791 15.3707 12.4178 14.9321 13.8432C14.6032 14.83 13.5067 15.3783 12.5198 15.1589C10.3268 14.6107 7.36633 11.7598 6.81809 9.45721C6.48914 8.47033 7.14703 7.37384 8.13387 7.04493C9.55931 6.60634 9.9979 4.74231 8.90141 3.64582L5.83125 0.575656C4.95406 -0.191885 3.63827 -0.191885 2.87073 0.575656L0.787404 2.65898C-1.29592 4.85196 1.0067 10.6633 6.16019 15.8168C11.3137 20.9703 17.1251 23.3826 19.318 21.1896L21.4014 19.1063C22.169 18.2291 22.169 16.9133 21.4014 16.1458Z'
          }
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default PhoneIcon;

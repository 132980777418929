import { initializeApp } from 'firebase/app';
import {
  GetTokenOptions,
  MessagePayload,
  NextFn,
  Observer,
  getMessaging as fbGetMessaging,
  getToken as fbGetToken,
  onMessage as fbOnMessage,
  isSupported,
} from 'firebase/messaging';
import { t } from 'i18next';
import config from '@constants/config';
import { FIREBASE_OPTIONS } from '@constants/firebase';

export type FirebaseMessageCallback = NextFn<MessagePayload> | Observer<MessagePayload>;

export const getFirebaseApp = () => initializeApp(FIREBASE_OPTIONS);

export const getMessaging = () => fbGetMessaging(getFirebaseApp());

export const onMessage = (callback: FirebaseMessageCallback) => fbOnMessage(getMessaging(), callback);

export const getToken = async (options?: GetTokenOptions) => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    const isSupport = await isSupported();
    if (isSupport) {
      return fbGetToken(getMessaging(), {
        vapidKey: config.firebase.firebaseVapidKey,
        ...options,
      });
    } else {
      throw new Error(t('general.notification_disabled'));
    }
  }
  throw new Error(t('general.notification_disabled'));
};

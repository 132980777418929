import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Button, Stack, SwipeableDrawer, Typography } from '@components/common';
import { useCommonStore } from '@hooks/storage';
import { useQueryProducts } from '@queries/products';
import { CartItem, CustomItem } from '@storage/types';
import { ProductDrawerLoader } from '../loaders';
import ProductOptionSet from './ProductOptionSet';

interface Props {
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  productId: number;
  onPressEditCustomItem: (customItem: CustomItem) => void;
  onPressSubmit: () => void;
  cartItem?: CartItem;
  onChangeCartItem?: (item: CartItem) => void;
  disabled?: boolean;
  productCategoryId?: number;
  productCategoryName?: string;
}
const ProductCustomOptionDrawer: FC<Props> = ({
  open = false,
  setOpen,
  onPressEditCustomItem,
  productId,
  cartItem,
  onChangeCartItem,
  onPressSubmit,
  disabled,
  productCategoryId,
  productCategoryName,
}) => {
  const { t } = useTranslation();
  const { isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const customItems = cartItem?.customItems || [];

  const handleOnChangeTotalCustomItem = (index: number) => (value: number) => {
    onChangeCartItem?.({
      ...cartItem,
      customItems: customItems?.map((item, idx) => (idx === index ? { ...item, qty: value } : item)),
      productCategoryId,
      productCategoryName,
    });
  };

  const { isFetching, data } = useQueryProducts();
  const productList = useMemo(() => {
    const productCategories = data?.productCategories || [];
    return productCategories.reduce((acc: Product[], productCategory) => {
      const currProducts = productCategory?.products || [];
      return [...acc, ...currProducts];
    }, []);
  }, [data]);

  const product = useMemo(() => productList?.find(({ id }) => id === productId), [productId, productList]);

  const renderContent = () => {
    if (isFetching || !isFinishLoadCommonStore) {
      return <ProductDrawerLoader />;
    }

    return (
      <Stack padding={'m'} spacing={'xxl'}>
        {customItems.map((item, index) => (
          <ProductOptionSet
            key={index}
            disabled={disabled}
            item={item}
            product={product}
            onPressEditCustomItem={onPressEditCustomItem}
            handleOnChangeTotalCustomItem={handleOnChangeTotalCustomItem(index)}
          />
        ))}

        <Button disabled={disabled} onClick={onPressSubmit} color={'primary'} variant={'contained'}>
          <Typography size={'hm'}>{t('products.action_submit_customize_label')}</Typography>
        </Button>
      </Stack>
    );
  };

  return (
    <SwipeableDrawer anchor={'bottom'} open={open} onClose={onClose} onOpen={onOpen} disableBackdropTransition>
      {renderContent()}
    </SwipeableDrawer>
  );
};

export default ProductCustomOptionDrawer;

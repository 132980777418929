import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { defaultQueryOption } from '@api/baseAPI';

const useBaseQueryConfig = () => {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);

  const onError = useCallback(
    ({ message = t('error.general_error_label') } = {}) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
    [snackbarContext, t]
  );

  return useMemo(
    () => ({
      ...defaultQueryOption,
      defaultOptions: {
        queries: {
          ...(defaultQueryOption?.defaultOptions?.queries || {}),
          onError,
        },
      },
    }),
    [onError]
  );
};

export default useBaseQueryConfig;

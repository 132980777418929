import { CSSProperties } from 'react';
import Token from '@constants/token';
import { css } from '@emotion/react';
import { TokenType } from '@interfaces/token';

const variant = (variantType: TokenType['fontWeight']) => css`
  font-weight: ${Token.fontWeight[variantType]};
`;

const size = (valueType: TokenType['fontSize']) => css`
  font-size: ${Token.fontSize[valueType]}px;
`;

const color = (colorType: TokenType['color'], colorPalette = Token.color) => css`
  color: ${colorPalette[colorType]};
`;

const transform = (transformValue: 'uppercase' | 'lowercase') => css`
  text-transform: ${transformValue};
`;

const wordSpacing = (valueType: TokenType['spacing']) => css`
  word-spacing: ${Token.spacing[valueType]}px;
`;

const lineClamp = (lineClamp: number) => css`
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp, ${lineClamp});
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
`;

const truncateWord = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const opacity = (value: number) => css`
  opacity: ${value};
`;

const align = (value: 'center' | 'left' | 'right') => css`
  text-align: ${value};
`;

const textDecoration = (value: CSSProperties['textDecoration']) => css`
  text-decoration: ${value};
`;

const textDecorationColor = (value: CSSProperties['textDecorationColor']) => css`
  text-decoration-color: ${value};
`;

const fontFamily = (value: TokenType['typography'] = 'DEFAULT') => css`
  font-family: ${Token.typography[value]};
`;

const flexShrink = (value: CSSProperties['flexShrink']) => css`
  flex-shrink: ${value};
`;

const fontStyle = (value: CSSProperties['fontStyle']) => css`
  font-style: ${value};
`;

const styles = {
  variant,
  size,
  color,
  fontFamily,
  transform,
  wordSpacing,
  lineClamp,
  truncateWord,
  opacity,
  align,
  textDecoration,
  textDecorationColor,
  flexShrink,
  fontStyle,
};

export default styles;

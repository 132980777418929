import localforage from 'localforage';
import {
  COMMON_DATABASE_DESCRIPTION,
  COMMON_DATABASE_NAME,
  COMMON_DATABASE_VERSION,
  COMMON_STORE_NAME,
  COMMON_STORE_STORAGE_KEY_NAME,
} from '@constants/storage';
import { getBrandUrl } from '@utils/navigator';
import { CommonStorage } from './types';

const getStorageKey = () => `${COMMON_STORE_STORAGE_KEY_NAME}_${getBrandUrl()}`;

const idbCommonStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: COMMON_DATABASE_NAME,
  version: COMMON_DATABASE_VERSION,
  storeName: COMMON_STORE_NAME,
  description: COMMON_DATABASE_DESCRIPTION,
});

const getStorage = async (): Promise<CommonStorage | undefined> => {
  return await idbCommonStore
    .getItem(getStorageKey())
    .then((response) => response as CommonStorage)
    .catch((err) => {
      console.error('commonStore getStorage', err);
      return undefined;
    });
};

const updateStorage = async (val: Partial<CommonStorage>) => {
  const currentStorage = await getStorage();
  if (currentStorage) {
    const updatedStorage = {
      ...currentStorage,
      ...val,
    };

    return await idbCommonStore.setItem(getStorageKey(), updatedStorage);
  }

  return await idbCommonStore.setItem(getStorageKey(), val);
};

const clear = async () => {
  const storage = await getStorage();
  if (storage) {
    return await idbCommonStore.removeItem(getStorageKey());
  }
};

const commonStore = {
  getStorage,
  updateStorage,
  clear,
};

export default commonStore;

import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS, TABLE_NO_QUERY_PARAMS } from '@constants/dineIn';

const usePersistentQueryParams = () => {
  const { query } = useRouter();
  const {
    [OPEN_BILL_QUERY_PARAMS]: openBillQueryParam,
    [CLOSED_BILL_QUERY_PARAMS]: closedBillQueryParam,
    [TABLE_NO_QUERY_PARAMS]: tableNoQueryParams,
  } = query;

  const persistentQuery = useMemo(
    () => ({
      query: {
        ...(openBillQueryParam
          ? {
              [OPEN_BILL_QUERY_PARAMS]: openBillQueryParam,
            }
          : {}),
        ...(closedBillQueryParam
          ? {
              [CLOSED_BILL_QUERY_PARAMS]: closedBillQueryParam,
            }
          : {}),
        ...(tableNoQueryParams
          ? {
              [TABLE_NO_QUERY_PARAMS]: tableNoQueryParams,
            }
          : {}),
      },
    }),
    [closedBillQueryParam, openBillQueryParam, tableNoQueryParams]
  );

  return persistentQuery;
};

export default usePersistentQueryParams;

import React from 'react';
import { Stack } from '@components/common';
import { Skeleton } from '@mui/material';

const OptionCardListLoader: React.FC = () => {
  return (
    <Stack height={'100%'}>
      <Stack background={'borderSubtle'} padding={'xxs'} borderRadius={'default'}>
        <Stack width={'100%'} direction={'column'} spacing={'xxs'}>
          {new Array(5).fill(null).map((_, idx) => (
            <Skeleton key={idx} variant={'rectangular'} width={'100%'} height={54} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OptionCardListLoader;

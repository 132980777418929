import { useTranslation } from 'react-i18next';
import { APP_MAX_WIDTH } from '@constants/common';
import { Image, Stack, Typography } from '..';

interface Props {
  title?: string;
  description: string;
}

const DineInInvalid: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { title = t('error.page_not_found_title'), description = t('error.page_not_found_description') } = props;

  const notFoundImage = '/images/brand-not-found.png';

  return (
    <Stack
      position={'absolute'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={'xl'}
      height={'100%'}
      width={'100%'}
      maxWidth={APP_MAX_WIDTH}
    >
      <Image src={notFoundImage} width={228} />
      <Stack alignItems={'center'} justifyContent={'center'} spacing={'m'}>
        <Typography textAlign={'center'} size={'m'} variant={'bold'}>
          {title}
        </Typography>
        <Typography color={'uiDarkSecondary'} textAlign={'center'} size={'hs'}>
          {description}
        </Typography>
        <Typography textAlign={'center'} size={'hs'}>
          {t('error.qr_dine_in_invalid')}
        </Typography>
      </Stack>

      <Stack direction={'row'} spacing={'xxs'} alignItems={'center'} paddingTop={'3xxl'}>
        <Typography size={'xs'}>{t('general.powered_by_label')}</Typography>
        <Image src={'/images/runchise-logo-horizontal.png'} height={16} />
      </Stack>
    </Stack>
  );
};

export default DineInInvalid;

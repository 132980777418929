import React, { CSSProperties, ReactNode } from 'react';
import { Radio, Stack, Typography } from '@components/common';
import { StackProps } from '@components/common/Stack';
import type { TokenFontSize } from '@interfaces/token';
import { FormControlLabel } from '@mui/material';
import styles from './styles';

interface Props<TValue> extends Pick<CSSProperties, 'alignItems'>, Pick<StackProps, 'flexBasis'> {
  variant?: 'outline';
  option: {
    value: TValue;
    label: string | ReactNode;
    tooltipText?: string;
    subLabel?: string;
    disabled?: boolean;
    icon?: ReactNode;
  };
  value?: TValue;
  disabled?: boolean;
  radioSize?: TokenFontSize;
  flexDirection?: CSSProperties['flexDirection'];
}

const GroupToggle = <TValue,>({
  variant,
  option,
  value,
  disabled,
  radioSize,
  alignItems,
  flexDirection,
}: Props<TValue>) => {
  return (
    <FormControlLabel
      value={option.value}
      checked={option.value === value}
      control={<Radio fontSize={radioSize} />}
      disabled={disabled || option.disabled}
      label={
        <Stack direction={'row'} spacing={'m'} alignItems={'center'}>
          {option.icon && option.icon}
          <Stack direction={'column'} textAlign={'left'}>
            <Typography variant={'bold'} size={'hs'}>
              {option.label}
            </Typography>
            <Typography color={'uiDarkStain'} size={'xs'}>
              {option.subLabel}
            </Typography>
          </Stack>
        </Stack>
      }
      css={[
        styles.label,
        styles.formControlLabelRoot(variant),
        styles.formControlLabelLabel,
        ...(alignItems ? [styles.alignItems(alignItems)] : []),
        ...(disabled || option.disabled ? [styles.disabled] : []),
        styles.direction(flexDirection),
      ]}
    />
  );
};

export default GroupToggle;

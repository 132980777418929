import Token from '@constants/token';
import { css } from '@emotion/react';

const popperWrapper = css`
  z-index: 1303;
  &[data-popper-placement^='top'] {
    margin-bottom: ${Token.spacing.xxs}px !important;
  }
  &[data-popper-placement^='bottom'] {
    margin-top: ${Token.spacing.xxs}px !important;
  }
  &[data-popper-placement^='left'] {
    margin-right: ${Token.spacing.xxs}px !important;
  }
  &[data-popper-placement^='right'] {
    margin-left: ${Token.spacing.xxs}px !important;
  }
`;

const modalWrapper = css`
  overflow: auto;
  position: relative;
`;

const customSelectedInput = css`
  & .MuiAutocomplete-popupIndicator {
    display: none;
  }
  & .MuiAutocomplete-clearIndicator {
    display: flex;
  }
`;

const container = css`
  height: 40px;
  display: inline-flex;
  width: 100%;
  position: relative;
`;

const styles = {
  popperWrapper,
  modalWrapper,
  customSelectedInput,
  container,
};

export default styles;

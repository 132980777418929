import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { GetOTPRequest } from '@api/auth/types';
import { getDineInCustomerCheck } from '@api/dineIn';
import { Button, PhoneInputField, Stack, Typography } from '@components/common';
import { PhoneNumber, defaultCountryCode } from '@components/common/PhoneInputField';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore, useGuestStore } from '@hooks/storage';

interface Props {
  isLoading?: boolean;
  onSubmit?: ({ phoneNumber, otpChannel }: GetOTPRequest) => void;
  isModalMode: boolean;
}

const LoginForm: React.FC<Props> = (props) => {
  const { isLoading, onSubmit, isModalMode } = props;
  const router = useRouter();
  const snackbarContext = useContext(SnackbarContext);
  const {
    storageState,
    isFinishInitiated: isFinishLoadCommonStore,
    isFetching: isFetchingCommonStore,
  } = useCommonStore();

  const { guestData } = useGuestStore();
  const { isOpenBill, openBillToken, isClosedBill, isDineIn, closedBillToken } = useCheckDineIn();

  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
    contactNumber: '',
    countryCode: defaultCountryCode,
  });

  const { setGuestInfo } = useGuestStore();

  const { mutate, isLoading: isLoadingCustomerCheck } = useMutation(getDineInCustomerCheck, {
    onSuccess: async ({ isRegistered, fullname }) => {
      if (isRegistered && isDineIn) {
        setGuestInfo({
          name: fullname,
          contactNumber: phoneNumber.contactNumber,
          countryCode: phoneNumber.countryCode,
        });
        router.replace({
          pathname: routes.PRODUCT_LIST,
          query: {
            ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
            ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          },
        });
        return;
      }

      router.replace({
        pathname: routes.GUEST,
        query: {
          ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
          ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          contactNumber: phoneNumber.contactNumber,
          countryCode: phoneNumber.countryCode,
        },
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const handleSubmit = () => {
    if (
      !isModalMode &&
      !isLoading &&
      !isFetchingCommonStore &&
      isFinishLoadCommonStore &&
      !guestData &&
      storageState.activeLocation?.guestModeDefault &&
      isDineIn
    ) {
      mutate({
        phoneNumber: phoneNumber.contactNumber,
        phoneNumberCountryCode: phoneNumber.countryCode,
      });
      return;
    }

    if (!isLoading) {
      onSubmit?.({
        phoneNumber,
        otpChannel: 'whatsapp',
      });
    }
  };

  const completePhoneNumber = `${phoneNumber.countryCode}${phoneNumber.contactNumber}`;

  const isValidContactNumber = new RegExp(/^[0-9]{6,14}$/).test(completePhoneNumber);

  return (
    <Stack direction={'column'} padding={'m'} spacing={'2xxl'}>
      <Typography align={'center'} size={'xxl'} variant={'bold'}>
        {t('sessions.login_page_title')}
      </Typography>
      <PhoneInputField value={phoneNumber} onChange={setPhoneNumber} />
      <Button
        isLoading={isLoading || isLoadingCustomerCheck}
        onClick={handleSubmit}
        disabled={!isValidContactNumber}
        variant={'contained'}
        padding={Token.spacing.m}
      >
        <Typography size={'hm'} variant={'medium'}>
          {t('sessions.login_submit_label')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default LoginForm;

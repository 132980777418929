import { ReactNode, useEffect, useState } from 'react';
import { GridItemProps } from 'react-virtuoso';
import styles from './styles';

interface Props extends GridItemProps {
  children?: ReactNode;
  /**
   * This is a auto added props from react-virtuoso Item
   */
  'data-known-size': number;
}

const ProductCardPlaceholder = ({ children, ...props }: Props) => {
  const [size, setSize] = useState(0);
  const knownSize = props['data-known-size'];

  useEffect(() => {
    setSize((prevSize) => (knownSize == 0 ? prevSize : knownSize));
  }, [knownSize]);

  return (
    <div
      {...props}
      className={'height-preserving-container'}
      // check styling in the style tag below
      css={styles.container(size)}
    >
      {children}
    </div>
  );
};

export default ProductCardPlaceholder;

import React from 'react';
import { Stack, Typography } from '@components/common';
import { Skeleton } from '@mui/material';

const ProductListLoader: React.FC = () => {
  return (
    <Stack direction={'column'} spacing={'xl'}>
      {new Array(5).fill(null).map((_, idx) => (
        <Stack key={idx} direction={'column'} spacing={'xs'}>
          <Stack direction={'row'} justifyContent={'space-between'} spacing={'m'}>
            <Stack direction={'column'} spacing={'xs'} paddingTop={'xs'} flex={1}>
              <Typography variant={'bold'}>
                <Skeleton variant={'text'} width={'75%'} />
              </Typography>
              <Typography variant={'bold'}>
                <Skeleton variant={'text'} width={'50%'} />
              </Typography>
              <Typography size={'xs'}>
                <Skeleton variant={'text'} width={'100%'} />
              </Typography>
            </Stack>
            <Stack direction={'row'} spacing={'xs'}>
              <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
                <Skeleton variant={'rectangular'} width={30} height={30} />
              </Stack>
              <Stack
                minWidth={92}
                direction={'column'}
                alignItems={'center'}
                justifyContent={'space-between'}
                spacing={'xs'}
              >
                <Skeleton variant={'rectangular'} width={78} height={78} />
                <Skeleton variant={'rectangular'} width={90} height={30} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default ProductListLoader;

import Token from '@constants/token';
import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material';

const getTokenColorFromPaletteOptions = (options: PaletteOptions) => {
  const optionsPrimary = options?.primary as SimplePaletteColorOptions;
  const optionsSecondary = options?.secondary as SimplePaletteColorOptions;
  const optionsSuccess = options?.success as SimplePaletteColorOptions;

  return {
    ...Token.color,
    uiPrimaryLight: optionsPrimary?.light as string,
    uiPrimaryMain: optionsPrimary?.main as string,
    uiPrimaryDark: optionsPrimary?.dark as string,
    uiPrimaryContrastText: optionsPrimary?.contrastText as string,
    uiSecondaryLight: optionsSecondary?.light as string,
    uiSecondaryMain: optionsSecondary?.main as string,
    uiSecondaryDark: optionsSecondary?.dark as string,
    uiSecondaryContrastText: optionsSecondary?.contrastText as string,
    uiSuccessLight: optionsSuccess?.light as string,
    uiSuccessMain: optionsSuccess?.main as string,
    uiSuccessDark: optionsSuccess?.dark as string,
  };
};

export default getTokenColorFromPaletteOptions;

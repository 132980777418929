import { MessagePayload, deleteToken } from 'firebase/messaging';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { deleteTokenFirebase, upsertTokenFirebase } from '@api/auth';
import { CustomerOrderDetail } from '@api/order/types';
import { atomServiceWorker } from '@atoms/serviceWorker';
import { SHOWN_NOTIFICATION_DENIED_ALERT_FLAG } from '@constants/common';
import { FIREBASE_INIT_QUERY_KEY } from '@constants/firebase';
import { useCommonStore } from '@hooks/storage';
import { getUserCookies } from '@utils/auth';
import { getMessaging, getToken, onMessage as onMessageFirebase } from '@utils/notification/firebase';
import { captureException } from '@utils/sentry';

interface Props {
  enabled?: boolean;
  onMessage?: (callback: MessagePayload) => void;
}

const useFirebase = ({ enabled = false, onMessage }: Props) => {
  const [{ serviceWorkerRegistration }] = useAtom(atomServiceWorker);
  const { t } = useTranslation();
  const { token } = getUserCookies();

  const { isFinishInitiated, storageState, updateStorage } = useCommonStore();
  const savedFcmToken = storageState.firebaseToken;
  const snackbarContext = useContext(SnackbarContext);

  const { mutate: onDeleteTokenFirebase } = useMutation(deleteTokenFirebase, {
    onSuccess: (_res, variables) => {
      if (variables.token) {
        deleteToken(getMessaging());
      }
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const initializeFirebase = useCallback(async () => {
    if (
      serviceWorkerRegistration &&
      'serviceWorker' in navigator &&
      'Notification' in window &&
      'PushManager' in window
    ) {
      try {
        const fetchedToken = await getToken({ serviceWorkerRegistration });
        if (savedFcmToken !== fetchedToken) {
          updateStorage({ firebaseToken: fetchedToken });
          if (savedFcmToken) {
            deleteToken(getMessaging());
            onDeleteTokenFirebase({ token: savedFcmToken });
          }
        }
        return fetchedToken;
      } catch (errors) {
        console.info('FIREBASE initializeFirebase errors:', errors);
      }
    }
  }, [onDeleteTokenFirebase, savedFcmToken, serviceWorkerRegistration, updateStorage]);

  const { mutate: onUpsertTokenFirebase } = useMutation(upsertTokenFirebase, {
    onError: (error) => {
      captureException(error);
      if (!window.sessionStorage.getItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG)) {
        console.info(t('general.notification_disabled'), error);
        window.sessionStorage.setItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG, '1');
      }
    },
  });
  const { data: firebaseToken } = useQuery([token, FIREBASE_INIT_QUERY_KEY], initializeFirebase, {
    enabled: !!token && isFinishInitiated && !!serviceWorkerRegistration && enabled,
    onSuccess: (res) => {
      if (res) {
        onUpsertTokenFirebase({ token: res });
        return;
      }
      if (!window.sessionStorage.getItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG)) {
        console.info(t('general.notification_disabled'));
        window.sessionStorage.setItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG, '1');
      }
    },
    onError: (error) => {
      captureException(error);

      if (!window.sessionStorage.getItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG)) {
        console.info(t('general.notification_disabled'), error);
        window.sessionStorage.setItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG, '1');
      }
    },
  });

  const { query } = useRouter();

  useEffect(() => {
    if (firebaseToken) {
      const unsubscribe = onMessageFirebase((message) => {
        console.log('FIREBASE foregroundMessage', { foregroundMessage: message });

        const orderStatus = message?.data?.customer_order_status as CustomerOrderDetail['status'];
        const alertVariant = orderStatus === 'expired' || orderStatus === 'cancelled' ? 'error' : 'success';
        const orderId = (message?.data?.customer_order_id || '').toString();
        const queryOrderId = (query?.orderId || '').toString();

        if (queryOrderId === orderId) {
          snackbarContext?.openSnackbar?.({
            title: message?.notification?.title,
            message: message?.notification?.body,
            alertProps: {
              severity: alertVariant,
            },
          });
        }
        if (onMessage) {
          onMessage(message);
        }
      });
      return unsubscribe;
    }
  }, [firebaseToken, onMessage, query?.orderId, snackbarContext]);
};

export default useFirebase;

export const QUERY_KEY_ORDER_DETAIL = 'orderDetail';
export const QUERY_KEY_ORDER_LIST = 'orderList';
export const QUERY_KEY_USER_BALANCE = 'userBalance';
export const QUERY_KEY_USER_ONGOING_ORDERS = 'userOngoingOrders';
export const QUERY_KEY_DELIVERY_LALAMOVE_QUOTATION = 'deliveryLalamoveQuotation';
export const QUERY_KEY_DELIVERY_GRAB_QUOTATION = 'deliveryGrabQuotation';
export const QUERY_KEY_CUSTOMER_ORDER_PRICE = 'customerOrderPrice';
export const QUERY_KEY_CREDIT_CARDS = 'creditCards';
export const QUERY_KEY_GET_LOCATIONS = 'locations';
export const QUERY_KEY_GET_LOCATION_DETAIL = 'locationDetail';
export const QUERY_KEY_GET_CUSTOMER_ADDRESSES = 'customerAddresses';
export const QUERY_KEY_GET_OPEN_BILL_ORDER = 'openBillOrder';
export const QUERY_KEY_GET_MERGED_OPEN_BILL_ORDER = 'mergedOpenBillOrder';
export const QUERY_KEY_GET_OPEN_BILL_CALCULATE_PAYMENT = 'openBillCalculatePayment';
export const QUERY_KEY_GET_CLOSED_BILL_ORDER = 'closedBillOrder';
export const QUERY_KEY_GET_BRAND_INFO = 'brandInfo';
export const QUERY_KEY_SERVER_GET_BRAND_INFO = 'serverBrandInfo';
export const QUERY_KEY_GET_BALANCE_HISTORY = 'balanceHistory';
export const QUERY_KEY_USER_PROFILE = 'profileDetail';
export const QUERY_KEY_PHONE_COUNTRIES = 'phoneCountries';
export const QUERY_KEY_PRODUCTS = 'products';
export const QUERY_KEY_SUB_BRANDS = 'subBrands';
export const QUERY_KEY_DINE_IN_FEE_SETTING = 'dineInFeeSetting';
export const QUERY_KEY_PROMOTIONS = 'promotions';

import { ReactNode } from 'react';
import { PaymentMethodType } from '@api/order/types';
import { useAllPaymentMethodOptions } from '@hooks/payments';

interface PaymentMethodOption {
  type: PaymentMethodType;
  icon: ReactNode;
  label: string;
}

const useOnlineDeliveryPaymentMethodOptions = (): PaymentMethodOption[] => {
  const allOptions = useAllPaymentMethodOptions({ allowCash: false });

  return allOptions;
};

export default useOnlineDeliveryPaymentMethodOptions;

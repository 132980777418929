import { addSeconds, format, startOfDay } from 'date-fns';
import { toLower, upperFirst } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OpeningHour, OpeningHourDay } from '@api/locations/types';
import { Stack, SwipeableDrawer, Typography } from '@components/common';
import { useDayOfWeekOptions } from '@hooks/common';

interface Props {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  address?: string;
  openingHour?: OpeningHour;
}
const LocationOpeningHourDrawer: React.FC<Props> = (props) => {
  const { address, openingHour, isOpen, onOpen, onClose } = props;
  const { t } = useTranslation();

  const todayLocalDay = toLower(format(new Date(), 'EEEE')) as OpeningHourDay;
  const dayOptions = useDayOfWeekOptions();

  return (
    <SwipeableDrawer anchor={'bottom'} open={isOpen} onClose={onClose} onOpen={onOpen} disableBackdropTransition>
      <Stack padding={'m'} spacing={'xxxl'} paddingTop={'2xxl'}>
        <Stack spacing={'m'}>
          <Typography size={'s'} variant={'medium'}>
            {t('products.location_address_label')}
          </Typography>
          <Typography color={'uiDarkSecondary'} size={'s'}>
            {address}
          </Typography>
        </Stack>

        <Stack spacing={'s'}>
          <Typography size={'s'} variant={'medium'}>
            {t('products.location_opening_hour_label')}
          </Typography>
          {dayOptions.map((day, index) => {
            const openDaySchedules = openingHour?.[day.dayOfWeek]?.schedules || [];
            const textVariant = todayLocalDay === day.dayOfWeek ? 'bold' : undefined;
            const isAlwaysOpen = openingHour?.[day.dayOfWeek]?.alwaysOpen;

            return (
              <Stack key={index} direction={'row'} spacing={'m'}>
                <Typography flex={2} variant={textVariant} color={'uiDarkSecondary'} size={'s'}>
                  {upperFirst(day.label)}
                </Typography>
                <Stack flex={3} spacing={'xxxs'}>
                  {isAlwaysOpen ? (
                    <Typography color={'uiDarkSecondary'} variant={textVariant}>
                      {'00:00 - 23:59'}
                    </Typography>
                  ) : (
                    openDaySchedules.map((openHour, index) => {
                      const openTimeInSeconds = openHour?.openTime || 0;
                      const closeTimeInInSeconds = openHour?.closeTime || 0;
                      const now = new Date();
                      const today = startOfDay(now);
                      const openTimeString = format(addSeconds(today, openTimeInSeconds), 'HH:mm');
                      const closeTimeString = format(addSeconds(today, closeTimeInInSeconds), 'HH:mm');

                      return (
                        <Typography key={index} color={'uiDarkSecondary'} variant={textVariant}>
                          {`${openTimeString} - ${closeTimeString}`}
                        </Typography>
                      );
                    })
                  )}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </SwipeableDrawer>
  );
};

export default LocationOpeningHourDrawer;

import localforage from 'localforage';
import {
  CART_DATABASE_DESCRIPTION,
  CART_DATABASE_NAME,
  CART_DATABASE_VERSION,
  CART_STORE_NAME,
} from '@constants/storage';
import { BranchCart, OrderType } from './types';

const getStorageKey = (branchId: number, orderType: OrderType) => `${CART_STORE_NAME}_${branchId}_${orderType}`;

const idbCartStore = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: CART_DATABASE_NAME,
  version: CART_DATABASE_VERSION,
  storeName: CART_STORE_NAME,
  description: CART_DATABASE_DESCRIPTION,
});

const getItem = async (branchId?: number, orderType?: OrderType): Promise<BranchCart | undefined> => {
  if (branchId && orderType) {
    return await idbCartStore
      .getItem(getStorageKey(branchId, orderType))
      .then((response) => response as BranchCart)
      .catch((err) => {
        console.error('cartStore getItem', err);
        return undefined;
      });
  }
};

const setItem = async (branchId: number, val: BranchCart, orderType: OrderType) => {
  if (orderType) {
    await idbCartStore.setItem(getStorageKey(branchId, orderType), val);
  }

  return;
};

const deleteItem = async (branchId: number, orderType: OrderType) => {
  const storage = await getItem(branchId, orderType);
  if (storage) {
    return await idbCartStore.removeItem(getStorageKey(branchId, orderType));
  }
};

const clear = async () => await idbCartStore.clear();
const keys = async () => await idbCartStore.keys();

const cartStore = {
  getItem,
  setItem,
  deleteItem,
  clear,
  keys,
};

export default cartStore;

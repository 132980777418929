import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Stack, Typography } from '@components/common';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { GetProductsQueryKeys } from '@queries/products/useQueryProducts';
import { CartItem } from '@storage/types';
import { PopularProductListLoader } from '../loaders';
import PopularProductCard from './PopularProductCard';

interface Props {
  popularProductList: Product[];
  disabled?: boolean;
  isLoading?: boolean;
  queryKeys?: GetProductsQueryKeys[];
}

const PopularProductList: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { popularProductList = [], disabled, isLoading, queryKeys } = props;

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const onSetItem = (productId: number) => (item: CartItem) => {
    updateProductCartItem(productId, { ...item, queryKeys });
  };

  if (isLoading || !isFinishLoadCommonStore || !isFinishLoadCartStore) {
    return <PopularProductListLoader />;
  }

  return (
    <Stack direction={'column'} spacing={'xs'} marginBottom={'m'}>
      <Stack padding={'xs'} background={'uiGreyLight'} borderRadius={'form'}>
        <Typography size={'hs'} variant={'medium'} color={'contentTertiary'} transform={'uppercase'}>
          {t('products.popular_label')}
        </Typography>
      </Stack>

      <Stack spacing={'xs'} overflow={'auto'} direction={'row'} padding={'xs'}>
        {popularProductList.map((product, index) => (
          <PopularProductCard
            disabled={disabled}
            product={product}
            key={index}
            cartItem={branchCart[product.id]}
            onChangeCartItem={onSetItem(product?.id)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default PopularProductList;

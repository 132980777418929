import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getOngoingOrders } from '@api/order';
import { GetOngoingOrdersResponse } from '@api/order/types';
import { QUERY_KEY_USER_ONGOING_ORDERS } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface UserOngoingOrdersQueryKey {
  key: string;
}

type UseQueryOngoingOrdersOptions = UseQueryOptions<
  GetOngoingOrdersResponse,
  unknown,
  GetOngoingOrdersResponse,
  UserOngoingOrdersQueryKey[]
>;

const useQueryOngoingOrders = (config?: UseQueryOngoingOrdersOptions) => {
  const { isLoggedIn } = getUserCookies();

  const queryKeys: UserOngoingOrdersQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_USER_ONGOING_ORDERS,
      },
    ],
    []
  );

  const { data, isFetching, isError, isSuccess, refetch } = useQuery(queryKeys, getOngoingOrders, {
    enabled: !!isLoggedIn,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    refetch,
  };
};

export default useQueryOngoingOrders;

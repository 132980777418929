import { FunctionComponent, ReactElement } from 'react';
import { InstallPrompt, Navbar, Stack } from '@components/common';

interface Props {
  children: ReactElement;
  hideNavbar?: boolean;
  NavbarComponent?: FunctionComponent;
}

const PublicLayout = ({ children, hideNavbar, NavbarComponent = Navbar }: Props) => {
  return (
    <>
      <Stack direction={'column'} flexGrow={1}>
        <InstallPrompt />
        {!hideNavbar && NavbarComponent && <NavbarComponent />}
        {children}
      </Stack>
    </>
  );
};

export default PublicLayout;

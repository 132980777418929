import { useContext, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions, useQuery } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getOpenBillOrder } from '@api/dineIn';
import { GetOpenBillOrderRequest, GetOpenBillOrderResponse } from '@api/dineIn/types';
import { DINE_IN_TYPE, GUEST_OPEN_BILL_UUID_KEY } from '@constants/auth';
import { DINE_IN_QR_INVALID } from '@constants/errorType';
import { QUERY_KEY_GET_OPEN_BILL_ORDER } from '@constants/queryKey';
import { useCheckDineIn } from '@hooks/dineIn';
import { setInternalCookie } from '@utils/auth';

export interface GetOpenBillOrderQueryKeys {
  key: string;
  payload: Partial<GetOpenBillOrderRequest>;
}

type UseQueryOpenBillOrderOptions = UseQueryOptions<
  GetOpenBillOrderResponse,
  APIDefaultErrors,
  GetOpenBillOrderResponse,
  GetOpenBillOrderQueryKeys[]
>;

const useQueryOpenBillOrder = (config?: UseQueryOpenBillOrderOptions) => {
  const { t } = useTranslation();
  const { enabled, ...otherConfig } = config ?? {};
  const { openBillToken, isOpenBill } = useCheckDineIn();
  const snackbarContext = useContext(SnackbarContext);
  const { showBoundary } = useErrorBoundary();

  const queryKeys: GetOpenBillOrderQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_OPEN_BILL_ORDER,
        payload: {
          uuid: openBillToken,
        },
      },
    ],
    [openBillToken]
  );

  const { data, isFetching, isError, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getOpenBillOrder(payload),
    {
      enabled: !!openBillToken && isOpenBill && enabled,
      onSuccess: (response) => {
        if (openBillToken) {
          setInternalCookie(null, GUEST_OPEN_BILL_UUID_KEY, openBillToken, {
            secure: true,
            sameSite: true,
            path: '/',
          });
          setInternalCookie(null, DINE_IN_TYPE, 'open_bill', {
            secure: true,
            sameSite: true,
            path: '/',
          });
        }
        return response;
      },
      onError: ({ message = t('error.general_error_label') }) => {
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
        const exception = {
          message,
          name: DINE_IN_QR_INVALID,
        };
        showBoundary(exception);
      },
      ...otherConfig,
    }
  );

  return useMemo(
    () => ({
      queryKeys,
      data,
      isFetching,
      isError,
      isSuccess,
    }),
    [data, isError, isFetching, isSuccess, queryKeys]
  );
};

export default useQueryOpenBillOrder;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DayOfWeek } from '@api/baseAPI/types';

export type DayOption = {
  dayOfWeek: DayOfWeek;
  label: string;
};

const useDayOfWeekOptions = () => {
  const { t } = useTranslation();

  const dayOptions: DayOption[] = useMemo(
    () => [
      { dayOfWeek: 'monday', label: t('general.monday') },
      { dayOfWeek: 'tuesday', label: t('general.tuesday') },
      { dayOfWeek: 'wednesday', label: t('general.wednesday') },
      { dayOfWeek: 'thursday', label: t('general.thursday') },
      { dayOfWeek: 'friday', label: t('general.friday') },
      { dayOfWeek: 'saturday', label: t('general.saturday') },
      { dayOfWeek: 'sunday', label: t('general.sunday') },
    ],
    [t]
  );

  return dayOptions;
};

export default useDayOfWeekOptions;

import { OpeningHourDay } from '@api/locations/types';

export const DEFAULT_LOCATION_SEARCH_SPEC = {
  latitude: '-6.175392',
  longitude: '106.827153',
};

export const OPEN_HOUR_MODAL_COOKIE_KEY = 'loc_open_hour_reminder';

export const DAY_OF_WEEKS: OpeningHourDay[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

import { addHours, isBefore } from 'date-fns';
import Link from 'next/link';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Stack, Typography } from '@components/common';
import { USER_LAST_SHOWN_ONGOING_ORDER_TIME_KEY } from '@constants/auth';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useCheckDineIn } from '@hooks/dineIn';
import { Divider } from '@mui/material';
import { useQueryProfileDetail } from '@queries/auth';
import { useQueryOngoingOrders } from '@queries/order';
import { setInternalCookie } from '@utils/auth';

/**
 * Show ongoing order popup, if there's only 1 order, on confirm, user will be redirected to order detail. Else user will be redirected to order list.
 * On popup close, we should show only once every hour.
 *
 * Figma: https://www.figma.com/file/9S8l2fJ3DsJZrRAY0WRcv9/Delivery-App-(Online-Ordering)?node-id=8152%3A7138
 */
const OngoingOrderDialog = () => {
  const { t } = useTranslation();

  const cookies = parseCookies();
  const lastShowOngoingOrderCookie = cookies[USER_LAST_SHOWN_ONGOING_ORDER_TIME_KEY];
  const lastShowOngoingOrderTime = lastShowOngoingOrderCookie
    ? parseInt(lastShowOngoingOrderCookie)
    : lastShowOngoingOrderCookie;

  const handleMarkToHidePopup = () => {
    const exp = addHours(new Date(), 1);

    setInternalCookie(null, USER_LAST_SHOWN_ONGOING_ORDER_TIME_KEY, String(exp.getTime()), {
      expires: new Date(exp),
      secure: true,
      sameSite: true,
      path: '/',
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const { isDineIn } = useCheckDineIn();

  const { data: profileData } = useQueryProfileDetail({
    enabled: false,
  });

  const { data } = useQueryOngoingOrders({
    enabled: !!profileData?.id && !isDineIn,
  });

  useEffect(() => {
    const isOngoingOrderTimeExpired =
      typeof lastShowOngoingOrderTime === 'number' && isBefore(new Date(lastShowOngoingOrderTime), new Date());

    if (
      !isOpen &&
      (!lastShowOngoingOrderTime || isOngoingOrderTimeExpired) &&
      data?.ongoingOrderIds &&
      data?.ongoingOrderIds?.length > 0
    ) {
      openModal();
    }
  }, [data?.ongoingOrderIds, isOpen, lastShowOngoingOrderTime]);

  const handleClose = () => {
    handleMarkToHidePopup();
    closeModal();
  };

  if (isOpen) {
    return (
      <Dialog
        fullWidth
        open={isOpen}
        PaperProps={{
          style: {
            maxWidth: '343px',
            borderRadius: Token.borderRadius.large,
          },
        }}
      >
        <Stack paddingY={'hxl'}>
          <Stack paddingX={'m'}>
            <Typography size={'m'} variant={'bolder'} color={'uiPrimaryDarker'}>
              {t('order.ongoing_orders_title')}
            </Typography>
          </Stack>

          <Divider />

          <Stack padding={'m'} marginBottom={'xxxl'}>
            <Typography size={'s'} color={'uiPrimaryDarker'}>
              {t('order.ongoing_orders_description')}
            </Typography>

            <Typography size={'s'} color={'uiPrimaryDarker'}>
              {t('order.ongoing_orders_subtitle')}
            </Typography>
          </Stack>

          <Stack paddingX={'m'} flexDirection={'row'} rowGap={'m'} alignItems={'center'}>
            <Button onClick={handleClose} variant={'text'} color={'secondary'} fullWidth>
              <Typography size={'s'} variant={'medium'} opacity={0.5}>
                {t('order.close_ongoing_orders')}
              </Typography>
            </Button>

            <Link
              href={
                data?.ongoingOrderIds && data?.ongoingOrderIds?.length > 0
                  ? data?.isMultipleOrders
                    ? routes.ORDER_LIST
                    : `${routes.ORDER_DETAIL}?orderId=${data?.ongoingOrderIds?.[0]}`
                  : routes.ORDER_LIST
              }
              passHref
            >
              <Button onClick={handleMarkToHidePopup} variant={'text'} color={'primary'} fullWidth>
                <Typography size={'s'} variant={'medium'}>
                  {t('order.see_ongoing_orders')}
                </Typography>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Dialog>
    );
  }

  return null;
};

export default OngoingOrderDialog;

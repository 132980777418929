import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import { Divider } from '@mui/material';
import PickupTimeInput from './PickupTimeInput';

interface Props {
  locationName: string;
  locationAddress: string;
}
const PickupDetailForm: React.FC<Props> = ({ locationName, locationAddress }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={'xl'}>
      <Stack spacing={'hs'}>
        <Typography size={'hs'} variant={'medium'}>
          {t('order.branch_select_label')}
        </Typography>
        <Stack direction={'column'} spacing={'xs'}>
          <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
            <LocationMarkerIcon />
            <Stack direction={'column'}>
              <Typography size={'hs'} variant={'bold'}>
                {locationName}
              </Typography>
              <Typography size={'xs'}>{locationAddress}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack spacing={'hs'}>
        <Typography size={'hs'} variant={'medium'}>
          {t('order.pickup_time_select_label')}
        </Typography>
        <PickupTimeInput />
      </Stack>
    </Stack>
  );
};

export default PickupDetailForm;

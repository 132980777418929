import { CSSProperties, MouseEventHandler, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Stack, Typography } from '@components/common';
import { StyleProps } from '@interfaces/common';
import { TokenBorderRadius } from '@interfaces/token';
import { toInitials } from '@utils/string';
import styles from './styles';

interface Props {
  minHeight?: CSSProperties['minHeight'];
  maxHeight?: CSSProperties['maxHeight'];
  height?: CSSProperties['height'];
  width?: number | string;
  alt?: string;
  src?: string;
  css?: StyleProps;
  rotate?: number;
  disabled?: boolean;
  opacity?: number;
  objectFit?: CSSProperties['objectFit'];
  borderRadius?: TokenBorderRadius;
  onClick?: MouseEventHandler<HTMLImageElement>;
  fallbackUrl?: string;
  flexShrink?: CSSProperties['flexShrink'];
}

const defaultFallbackUrl = '/images/no-photo.png';

const Image = ({ fallbackUrl = defaultFallbackUrl, ...props }: Props) => {
  const [src, setSrc] = useState(props.src || '');
  const [useAvatar, setUseAvatar] = useState(false);
  const [loading, setLoading] = useState(true);

  const fallbackHeight = props.height ? `${props.height}px` : '40px';
  const fallbackWidth = props.width ? `${props.width}px` : '40px';

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    setSrc(props.src || defaultFallbackUrl);
  }, [props.alt, props.src]);

  const onError = () => {
    setLoading(false);

    if (fallbackUrl) {
      setSrc(fallbackUrl);
      return;
    }

    if (props.alt) {
      setUseAvatar(true);
    }
  };

  if (useAvatar) {
    return (
      <Stack
        minHeight={fallbackHeight}
        minWidth={fallbackWidth}
        maxHeight={fallbackHeight}
        maxWidth={fallbackWidth}
        background={'uiLightNeutral'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Typography>{toInitials(props.alt)}</Typography>
      </Stack>
    );
  }

  const handleOnClick: MouseEventHandler<HTMLImageElement> = (e) => {
    !props.disabled && props?.onClick?.(e);
  };

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <img
      alt={loading ? undefined : props.alt}
      height={props.height}
      width={props.width}
      onClick={handleOnClick}
      ref={ref}
      src={inView || !loading ? src : undefined}
      css={[
        styles.container,
        props.objectFit && styles.objectFit(props.objectFit),
        props.css,
        props.rotate && styles.rotate(props.rotate),
        typeof props.disabled === 'boolean' && styles.disabled(props.disabled),
        props.borderRadius && styles.borderRadius(props?.borderRadius),
        props.minHeight && styles.minHeight(props?.minHeight),
        props.maxHeight && styles.maxHeight(props?.maxHeight),
        typeof props.flexShrink !== 'undefined' && styles.flexShrink(props.flexShrink),
      ]}
      loading={'lazy'}
      onError={onError}
      onLoad={onLoad}
    />
  );
};

export default Image;

import { useRouter } from 'next/router';
import { FC } from 'react';
import { Button, Stack, Typography } from '@components/common';
import { ArrowIcon, TrashIcon } from '@components/icons';

interface Props {
  onPressBack?: () => void;
  title?: string;
  isLoading?: boolean;
  id?: number;
  onDeleteAddress?: (id: number) => void;
}

const DeliveryAddressNavbar: FC<Props> = (props) => {
  const { onPressBack, title, isLoading, id, onDeleteAddress } = props;

  const router = useRouter();

  const handleOnPressBack = () => {
    onPressBack ? onPressBack() : router.back();
  };

  const handleDelete = () => {
    if (id && onDeleteAddress) {
      onDeleteAddress(id);
    }
  };

  return (
    <Stack padding={'m'} background={'uiLightPrimary'} flexDirection={'row'} alignItems={'center'} zIndex={1}>
      <Stack width={'32px'}>
        <Button onClick={handleOnPressBack} variant={'text'} padding={0}>
          <ArrowIcon />
        </Button>
      </Stack>

      <Stack flex={1} alignItems={'center'} justifyContent={'center'}>
        <Typography variant={'medium'} size={'m'}>
          {title}
        </Typography>
      </Stack>

      <Stack width={'32px'}>
        {id && (
          <Button onClick={handleDelete} variant={'text'} padding={0} isLoading={isLoading}>
            <TrashIcon />
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default DeliveryAddressNavbar;

import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { CustomTextField, Stack, Typography } from '@components/common';
import { MobileDatePicker } from '@mui/lab';
import { TextFieldProps } from '@mui/material';
import { StackProps } from '../Stack';
import { TypographyProps } from '../Typography';

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  labelProps?: TypographyProps;
  containerProps?: StackProps;
  inputProps?: TextFieldProps;
  control: Control<T>;
  inputFormat?: string;
}

const RHFDateInput = <T extends FieldValues>(props: Props<T>) => {
  const { name, label, labelProps, containerProps, inputProps, control, inputFormat = 'dd/MM/yyyy' } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onBlur, ref, value, onChange } = field;
        const { error } = fieldState;

        return (
          <MobileDatePicker
            inputFormat={inputFormat}
            value={value as Date}
            onChange={onChange}
            maxDate={new Date()}
            renderInput={(params) => (
              <Stack spacing={'hs'} {...containerProps}>
                {!!label && (
                  <Typography size={'hs'} {...labelProps}>
                    {label}
                  </Typography>
                )}
                <CustomTextField
                  inputRef={ref}
                  onBlur={onBlur}
                  error={!!error?.message}
                  helperText={error?.message}
                  {...params}
                  {...inputProps}
                />
              </Stack>
            )}
          />
        );
      }}
    />
  );
};

export default RHFDateInput;

import { ReactNode, Ref, forwardRef } from 'react';
import { SerializedStyles } from '@emotion/react';
import { TableRow as MuiTableRow } from '@mui/material';

export interface TableRowProps {
  children?: ReactNode | Element;
  customStyles?: SerializedStyles;
}

const TableRow = forwardRef(function TableRowWithRef(props: TableRowProps, ref?: Ref<HTMLTableRowElement>) {
  const { children, customStyles, ...otherProps } = props;

  return (
    <MuiTableRow ref={ref} css={customStyles} {...otherProps}>
      {children}
    </MuiTableRow>
  );
});

export default TableRow;

import { useContext, useMemo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions, useQuery } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getClosedBillOrder } from '@api/dineIn';
import { GetClosedBillOrderRequest, GetClosedBillOrderResponse } from '@api/dineIn/types';
import { DINE_IN_TYPE, GUEST_CLOSE_BILL_UUID_KEY } from '@constants/auth';
import { DINE_IN_QR_INVALID } from '@constants/errorType';
import { QUERY_KEY_GET_CLOSED_BILL_ORDER } from '@constants/queryKey';
import { useCheckDineIn } from '@hooks/dineIn';
import { setInternalCookie } from '@utils/auth';

export interface GetClosedBillOrderQueryKeys {
  key: string;
  payload: Partial<GetClosedBillOrderRequest>;
}

type UseQueryClosedBillOrderOptions = UseQueryOptions<
  GetClosedBillOrderResponse,
  APIDefaultErrors,
  GetClosedBillOrderResponse,
  GetClosedBillOrderQueryKeys[]
>;

const useQueryClosedBillOrder = (config?: UseQueryClosedBillOrderOptions) => {
  const { t } = useTranslation();
  const { enabled, ...otherConfig } = config ?? {};
  const { closedBillToken, isClosedBill } = useCheckDineIn();
  const snackbarContext = useContext(SnackbarContext);
  const { showBoundary } = useErrorBoundary();

  const queryKeys: GetClosedBillOrderQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_CLOSED_BILL_ORDER,
        payload: {
          closedBillToken,
        },
      },
    ],
    [closedBillToken]
  );

  const { data, isFetching, isError, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getClosedBillOrder(payload),
    {
      enabled: !!closedBillToken && isClosedBill && enabled,
      onSuccess: (response) => {
        if (closedBillToken) {
          setInternalCookie(null, GUEST_CLOSE_BILL_UUID_KEY, closedBillToken, {
            secure: true,
            sameSite: true,
            path: '/',
          });
          setInternalCookie(null, DINE_IN_TYPE, 'close_bill', {
            secure: true,
            sameSite: true,
            path: '/',
          });
        }
        return response;
      },
      onError: ({ message = t('error.general_error_label') }) => {
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
        const exception = {
          message,
          name: DINE_IN_QR_INVALID,
        };
        showBoundary(exception);
      },
      ...otherConfig,
    }
  );

  return useMemo(
    () => ({
      queryKeys,
      data,
      isFetching,
      isError,
      isSuccess,
    }),
    [data, isError, isFetching, isSuccess, queryKeys]
  );
};

export default useQueryClosedBillOrder;

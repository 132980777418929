import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Stack, TextField, Typography } from '@components/common';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useCheckDineIn } from '@hooks/dineIn';
import { useGuestStore } from '@hooks/storage';
import { getRhfError } from '@utils/form';
import useForm, { FormProps } from './forms/useForm';

interface Props {
  contactNumber?: string;
  countryCode?: string;
  closeModal?: () => void;
  setShowGuestNameInput?: Dispatch<SetStateAction<boolean>>;
  onSuccessLogin?: () => void;
  isModalMode?: boolean;
  skipPhoneNumber?: boolean;
}

const GuestNameForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const {
    contactNumber,
    countryCode,
    closeModal,
    isModalMode,
    setShowGuestNameInput,
    onSuccessLogin,
    skipPhoneNumber,
  } = props;
  const router = useRouter();
  const { setGuestInfo } = useGuestStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isOpenBill, openBillToken, isClosedBill, isDineIn, closedBillToken } = useCheckDineIn();

  useEffect(() => {
    if (!isDineIn) {
      router.replace({
        pathname: routes.PRODUCT_LIST,
        query: router.query,
      });
    }
  }, [isDineIn, isOpenBill, openBillToken, router]);

  const startGuestMode = useCallback(
    (name: string) => {
      if (isDineIn && skipPhoneNumber) {
        setGuestInfo({
          name: name,
          contactNumber: '',
          countryCode: '',
        });
        if (isModalMode) {
          setShowGuestNameInput?.(false);
          onSuccessLogin?.();
          closeModal?.();
          return;
        }
        router.push({
          pathname: routes.PRODUCT_LIST,
          query: {
            ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
            ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          },
        });
      }
      if (isDineIn && contactNumber && countryCode) {
        setGuestInfo({
          name: name,
          contactNumber: contactNumber,
          countryCode: countryCode,
        });
        if (isModalMode) {
          setShowGuestNameInput?.(false);
          onSuccessLogin?.();
          closeModal?.();
          return;
        }
        router.push({
          pathname: routes.PRODUCT_LIST,
          query: {
            ...(isOpenBill && { [OPEN_BILL_QUERY_PARAMS]: openBillToken }),
            ...(isClosedBill && { [CLOSED_BILL_QUERY_PARAMS]: closedBillToken }),
          },
        });
      }
    },
    [
      isDineIn,
      contactNumber,
      countryCode,
      setGuestInfo,
      isModalMode,
      router,
      isOpenBill,
      openBillToken,
      isClosedBill,
      closedBillToken,
      setShowGuestNameInput,
      onSuccessLogin,
      closeModal,
      skipPhoneNumber,
    ]
  );

  const onSubmit = (data: FormProps) => {
    if (data.name) {
      startGuestMode(data.name);
    }
  };

  return (
    <Stack direction={'column'} padding={'m'} spacing={'2xxl'}>
      <Typography align={'center'} size={'xxl'} variant={'bold'}>
        {t('sessions.input_name_label')}
      </Typography>
      <Stack direction={'column'} spacing={'xs'}>
        <Typography size={'hs'}>{t('sessions.profile_name_label')}</Typography>
        <Controller
          control={control}
          name={'name'}
          render={({ field }) => (
            <TextField
              placeholder={t('sessions.input_name_label')}
              defaultValue={field.value}
              onBlur={field.onChange}
              error={!!errors?.name}
              helperText={getRhfError(errors?.name)}
            />
          )}
        />
      </Stack>
      <Button onClick={handleSubmit(onSubmit)} variant={'contained'} padding={Token.spacing.m}>
        <Typography size={'hm'} variant={'medium'}>
          {t('sessions.login_submit_label')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default GuestNameForm;

import { Control, Controller, Path } from 'react-hook-form';
import { Stack, Typography } from '@components/common';
import { InputBaseComponentProps } from '@mui/material';
import Select, { SelectProps } from '../Select';
import { StackProps } from '../Stack';
import { TypographyProps } from '../Typography';

interface Props<T = string> {
  name: Path<T>;
  label?: string;
  labelProps?: TypographyProps;
  containerProps?: StackProps;
  inputProps?: InputBaseComponentProps;
  selectProps?: SelectProps;
  control: Control<T>;
}

const RHFSelect = <T,>(props: Props<T>) => {
  const { name, label, labelProps, containerProps, selectProps, control, inputProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onBlur, ref, value = '', onChange } = field;
        const { error } = fieldState;

        return (
          <Stack spacing={'hs'} {...containerProps}>
            {!!label && (
              <Typography size={'hs'} {...labelProps}>
                {label}
              </Typography>
            )}
            <Select
              inputRef={ref}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              error={!!error?.message}
              inputProps={{
                ...inputProps,
                helperText: error?.message,
              }}
              {...selectProps}
            />
          </Stack>
        );
      }}
    />
  );
};

export default RHFSelect;

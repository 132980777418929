import React, { CSSProperties, ReactNode, useMemo, useState } from 'react';
import { Card, Stack, Typography } from '@components/common';
import { StackProps } from '@components/common/Stack';
import { CardActionArea } from '@mui/material';

interface Props {
  onPress?: () => void;
  option: {
    name: string;
    description?: string;
  };
  isSelected?: boolean;
  imageComponent?: ReactNode;
  endAdornment?: ReactNode;
  minHeight?: CSSProperties['minHeight'];
}

const OptionCard: React.FC<Props> = ({ option, onPress, isSelected, imageComponent, endAdornment, minHeight }) => {
  const [isCardActive, setIsCardActive] = useState(false);

  const containerProps: Pick<StackProps, 'borderColor' | 'borderWidth'> = useMemo(() => {
    const borderColor = isCardActive || isSelected ? 'uiGreenPrimary' : 'uiLightPrimary';
    const borderWidth = isSelected ? 'bold' : 'thin';

    return {
      borderColor,
      borderWidth,
    };
  }, [isCardActive, isSelected]);

  const handleOnPointerDown = () => setIsCardActive(true);
  const handleOnPointerUp = () => setIsCardActive(false);

  return (
    <Card elevation={0} borderRadius={'default'} minHeight={minHeight}>
      <CardActionArea
        css={minHeight ? { minHeight } : null}
        onClick={onPress}
        onPointerDown={handleOnPointerDown}
        onPointerUp={handleOnPointerUp}
      >
        <Stack
          borderRadius={'default'}
          direction={'row'}
          alignItems={'center'}
          paddingX={'xs'}
          paddingY={'xs'}
          spacing={'xs'}
          justifyContent={'space-between'}
          {...containerProps}
        >
          <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
            {imageComponent}
            <Stack direction={'column'}>
              <Typography size={'hs'} variant={'bold'}>
                {option.name}
              </Typography>
              {option.description && (
                <Typography size={'xs'} fontStyle={'italic'}>
                  {option.description}
                </Typography>
              )}
            </Stack>
          </Stack>
          {endAdornment}
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default OptionCard;

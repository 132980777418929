import { TokenColor } from '@interfaces/token';
import BaseIcon from '../BaseIcon';
import { IconProps } from '../types';
import styles from './styles';

interface Props extends IconProps {
  color?: TokenColor;
}

const CheckmarkOutlinedLiveIcon = ({ width = 56, height = 56, color = 'uiPrimaryMain', ...baseIconProps }: Props) => {
  return (
    <BaseIcon {...baseIconProps}>
      <svg
        css={styles.checkmark(width, color)}
        width={width}
        height={height}
        xmlns={'http://www.w3.org/2000/svg'}
        viewBox={'0 0 52 52'}
      >
        <circle css={styles.checkmarkCircle(width, height, color)} cx={'26'} cy={'26'} r={'25'} fill={'none'} />
        <path css={styles.checkmarkCheck(width, height)} fill={'none'} d={'M14.1 27.2l7.1 7.2 16.7-16.8'} />
      </svg>
    </BaseIcon>
  );
};

export default CheckmarkOutlinedLiveIcon;

import { CSSProperties, Ref, forwardRef } from 'react';
import { TokenBorderRadius } from '@interfaces/token';
import { Card as MUICard, CardProps as MUICardProps } from '@mui/material';
import styles from './styles';

interface Props {
  borderRadius?: TokenBorderRadius;
  minHeight?: CSSProperties['minHeight'];
}

export type CardProps = Props & Pick<MUICardProps, 'children' | 'elevation'>;

const Card = forwardRef(function CardRef(props: CardProps, ref?: Ref<HTMLDivElement>) {
  const { children, borderRadius, minHeight, ...otherProps } = props;

  return (
    <MUICard
      ref={ref}
      {...otherProps}
      css={[...(borderRadius ? [styles.borderRadius(borderRadius)] : []), { minHeight }]}
    >
      {children}
    </MUICard>
  );
});

export default Card;

import { useTranslation } from 'react-i18next';
import { CustomerOrderDetail } from '@api/order/types';

const useOrderTypeLabel = (type?: CustomerOrderDetail['type']): string => {
  const { t } = useTranslation();

  if (type === 'closed_bill') {
    return t('dine_in.closed_bill_label');
  }

  if (type === 'merged_open_bill_order') {
    return t('dine_in.merged_open_bill_order_label');
  }

  if (type === 'self_pickup') {
    return t('order.pickup_tab_label');
  }

  if (type === 'delivery') {
    return t('order.delivery_tab_label');
  }

  return '';
};

export default useOrderTypeLabel;

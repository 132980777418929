import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const ClockIcon: FC<Props> = ({ width = 16, height = 16, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 16 16'} fill={'none'}>
        <path d={'M10.8501 9.41194L8.61926 7.73881V4.33062C8.61926 3.98794 8.34226 3.71094 7.99957 3.71094C7.65688 3.71094 7.37988 3.98794 7.37988 4.33062V8.04869C7.37988 8.24388 7.4716 8.42794 7.62776 8.54444L10.1064 10.4035C10.218 10.4871 10.3481 10.5274 10.4776 10.5274C10.6666 10.5274 10.8525 10.4425 10.974 10.2789C11.1798 10.0056 11.124 9.61706 10.8501 9.41194Z'} fill={fillColor} />
        <path d={'M8 0C3.58853 0 0 3.58853 0 8C0 12.4115 3.58853 16 8 16C12.4115 16 16 12.4115 16 8C16 3.58853 12.4115 0 8 0ZM8 14.7607C4.27266 14.7607 1.23934 11.7273 1.23934 8C1.23934 4.27266 4.27266 1.23934 8 1.23934C11.728 1.23934 14.7607 4.27266 14.7607 8C14.7607 11.7273 11.7273 14.7607 8 14.7607Z'} fill={fillColor} />
      </svg>
    </BaseIcon>
  );
};

export default ClockIcon;

import { CustomerOrderDetail, GetOrderListRequest, PaymentMethodType } from '@api/order/types';
import { UseQueryOrderDetailOptions } from '@queries/order/useQueryOrderDetail';

export const DEFAULT_ORDER_LIST_SEARCH_SPEC: GetOrderListRequest = {
  page: 1,
  perPage: 10,
};

export const ORDER_FINAL_STATUS_LIST: Array<CustomerOrderDetail['status']> = [
  'request_to_refund',
  'expired',
  'completed',
  'cancelled',
  'refunded',
];

export const ORDER_TIME_FORMAT = 'HH:mm aaa';
export const ORDER_GROUP_DATE_FORMAT = 'dd MMMM, EEEE';
export const ORDER_GROUP_ID_DATE_FORMAT = 'EEEE, dd MMMM';
export const ORDER_DEFAULT_TIMER = 900; //set to 15 minutes

export const ORDER_DETAIL_REFETCH_INTERVAL_OPTIONS: UseQueryOrderDetailOptions = {
  refetchOnWindowFocus: true,
  refetchInterval: 20000,
};

export const ALL_PAYMENT_METHOD_TYPES: PaymentMethodType[] = [
  'cash',
  'balance',
  'credit-card',
  'qris',
  'shopeepay',
  'gopay',
  'ovo',
  'dana',
  'va-transfer',
];

export const ONLINE_ORDER_PAYMENT_METHOD_TYPES: PaymentMethodType[] = [
  'credit-card',
  'qris',
  'shopeepay',
  'gopay',
  'ovo',
  'dana',
  'va-transfer',
];

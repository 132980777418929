import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getLocations } from '@api/locations';
import { GetLocationsRequest, GetLocationsResponse } from '@api/locations/types';
import { QUERY_KEY_GET_LOCATIONS } from '@constants/queryKey';
import { useSubBrandInfo } from '@hooks/subBrands';

interface GetLocationsQueryKeys {
  key: string;
  payload?: GetLocationsRequest;
}

type UseQueryLocationsOptions = UseQueryOptions<
  GetLocationsResponse,
  APIDefaultErrors,
  GetLocationsResponse,
  GetLocationsQueryKeys[]
>;

const useQueryLocations = (params?: GetLocationsRequest, config?: UseQueryLocationsOptions) => {
  const { id } = useSubBrandInfo();

  const queryKeys: GetLocationsQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_LOCATIONS,
        payload: {
          ...(id && { subBrandId: id }),
          ...params,
        },
      },
    ],
    [params, id]
  );

  const { data, isFetching, isError } = useQuery(queryKeys, ({ queryKey: [{ payload }] }) => getLocations(payload), {
    enabled: true,
    cacheTime: 60_000,
    staleTime: 60_000,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
  };
};

export default useQueryLocations;

import { useMemo } from 'react';
import { CustomerOrderPriceDetail } from '@api/order/types';
import { useQueryUserBalance } from '@queries/auth';
import { checkIfUserBalanceEnough } from '@utils/payment';

interface Props {
  priceDetail?: CustomerOrderPriceDetail;
  enabled?: boolean;
  isDineIn: boolean;
}

const useCheckBalanceEnoughForOrder = ({ priceDetail, enabled = false, isDineIn }: Props) => {
  const { data: userBalanceData, isLoading: isLoadingUserBalance } = useQueryUserBalance({
    enabled,
  });

  const isUserBalanceEnough = useMemo(() => {
    if (isLoadingUserBalance || !priceDetail) {
      return false;
    }

    if (typeof userBalanceData?.balance === 'string' || typeof userBalanceData?.balance === 'number') {
      return checkIfUserBalanceEnough(
        userBalanceData?.balance,
        isDineIn ? priceDetail?.totalAmountFinal : priceDetail?.totalAmount
      );
    }

    return false;
  }, [isDineIn, isLoadingUserBalance, priceDetail, userBalanceData?.balance]);

  return {
    isUserBalanceEnough,
    isLoadingUserBalance,
  };
};

export default useCheckBalanceEnoughForOrder;

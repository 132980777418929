import * as Sentry from '@sentry/browser';

interface SentryUser {
  id?: string;
  contactNumber?: string;
  username?: string;
  email?: string;
}

export const setUser = (sentryUser: SentryUser) => {
  if (sentryUser.contactNumber) {
    Sentry.setTag('contact_number', sentryUser.contactNumber);
  }

  Sentry.setUser({
    id: sentryUser.id,
    username: sentryUser.username,
    email: sentryUser.email,
  });
};

export const resetUser = () => {
  Sentry.configureScope((scope) => scope.setUser(null));
  Sentry.setTag('contact_number', undefined);
};

export const captureException = Sentry.captureException;
export const captureMessage = (key: string) => {
  Sentry.captureMessage(key, {
    level: 'info',
  });
};

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { calculateOpenBillPayment } from '@api/dineIn';
import { CalculateOpenBillPaymentRequest, CalculateOpenBillPaymentResponse } from '@api/dineIn/types';
import { QUERY_KEY_GET_OPEN_BILL_CALCULATE_PAYMENT } from '@constants/queryKey';
import { useCheckUserOrGuest } from '@hooks/auth';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';

export interface CalculateOpenBillPaymentQueryKeys {
  key: string;
  payload: Partial<CalculateOpenBillPaymentRequest>;
}

type UseQueryOpenBillCalculatePaymentOptions = UseQueryOptions<
  CalculateOpenBillPaymentResponse,
  APIDefaultErrors,
  CalculateOpenBillPaymentResponse,
  CalculateOpenBillPaymentQueryKeys[]
>;

const useQueryOpenBillCalculatePayment = (
  request: Partial<CalculateOpenBillPaymentRequest>,
  config?: UseQueryOpenBillCalculatePaymentOptions
) => {
  const { enabled, ...otherConfig } = config ?? {};
  const { openBillToken, isOpenBill, isReady } = useCheckDineIn();
  const { storageState, isFinishInitiated } = useCommonStore();
  const webPushToken = storageState?.firebaseToken;

  const { isUserOrGuest } = useCheckUserOrGuest();

  const queryKeys: CalculateOpenBillPaymentQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_OPEN_BILL_CALCULATE_PAYMENT,
        payload: {
          uuid: openBillToken,
          webPushToken,
          ...request,
        },
      },
    ],
    [openBillToken, request, webPushToken]
  );

  const { data, isFetching, isError, isSuccess, error, refetch } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => calculateOpenBillPayment(payload),
    {
      enabled: isOpenBill && isReady && !!openBillToken && enabled && isUserOrGuest && isFinishInitiated,
      ...otherConfig,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
    error,
    refetch,
  };
};

export default useQueryOpenBillCalculatePayment;

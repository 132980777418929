import { DecimalNumber } from '@api/baseAPI/types';
import { Stack, Typography } from '@components/common';
import { useProductPrice } from '@hooks/products';

interface Props {
  name: string;
  amount: DecimalNumber;
}

const PromoLine = ({ name, amount }: Props) => {
  const { label: amountLabel } = useProductPrice(amount);

  return (
    <Stack alignItems={'flex-start'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
      <Stack>
        <Stack spacing={'xxs'} direction={'row'} alignContent={'flex-end'}>
          <Typography size={'s'} variant={'medium'} color={'uiRedPrimary'}>
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Typography color={'uiRedPrimary'} size={'s'}>
        {`- ${amountLabel}`}
      </Typography>
    </Stack>
  );
};

export default PromoLine;

import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerAddress } from '@api/locations/types';
import { DeliveryQuotationVehicleType } from '@api/order/types';
import { IconButton, Image, Stack, Typography } from '@components/common';
import { ChevronIcon, LocationMarkerIcon, PlusIcon } from '@components/icons';
import { SavedLocationListDialog } from '@components/locations';
import { OrderPlacementFormShape } from '@containers/order/OrderPlacementContainer/forms/useFormOrderPlacement';
import { useCommonStore } from '@hooks/storage';
import { Divider, Skeleton } from '@mui/material';
import { useQueryCustomerAddresses, useQueryLocationDetail } from '@queries/locations';
import DeliveryOptionList from './DeliveryOptionList';
import DeliveryVendorForm from './DeliveryVendorForm';

interface Props {
  onOpenAddressFormDialog?: () => void;
}

const DeliveryMethodForm: React.FC<Props> = ({ onOpenAddressFormDialog }) => {
  const { t } = useTranslation();

  const { storageState } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const { data: locationDetailResponse } = useQueryLocationDetail({
    id: activeLocationId,
  });

  const {
    enableLalaMoveCar = false,
    enableLalaMoveMotorcycle = false,
    enableGrabExpressCar = false,
    enableGrabExpressMotorcycle = false,
  } = locationDetailResponse || {};

  const [openSavedLocationListDialog, setOpenSavedLocationListDialog] = useState(false);

  const { watch, control, getFieldState, setValue } = useFormContext<OrderPlacementFormShape>();

  const location = watch('location');

  const { isFetching } = useQueryCustomerAddresses({
    onSuccess: (res) => {
      if (res.length > 0 && !location?.id) {
        setValue('location', res[0]);
      }
    },
  });

  const handleOnChangeLocation = useCallback(
    (location: CustomerAddress) => {
      setValue('location', location);
    },
    [setValue]
  );

  const locationErrorMessage = getFieldState('location')?.error?.message;

  const handleOpenSavedLocationListDialog = () => {
    setOpenSavedLocationListDialog(true);
  };

  const handleCloseSavedLocationListDialog = () => {
    setOpenSavedLocationListDialog(false);
  };

  const deliveryOptions = useMemo(
    () => [
      ...(enableLalaMoveMotorcycle || enableGrabExpressMotorcycle
        ? [
            {
              label: t('order.delivery_option_motorcycle_label'),
              value: 'motorcycle' as const,
              image: <Image height={57} src={'/images/delivery-method-motorcycle.png'} />,
            },
          ]
        : []),
      ...(enableLalaMoveCar || enableGrabExpressCar
        ? [
            {
              label: t('order.delivery_option_car_label'),
              value: 'car' as const,
              image: <Image height={57} src={'/images/delivery-method-car.png'} />,
            },
          ]
        : []),
    ],
    [enableGrabExpressCar, enableGrabExpressMotorcycle, enableLalaMoveCar, enableLalaMoveMotorcycle, t]
  );

  const locationMarkerColor = locationErrorMessage ? 'uiRedPrimary' : 'uiDarkPrimary';

  const handleChangeDeliveryMethod = useCallback(
    (value?: DeliveryQuotationVehicleType) => {
      if (value === 'car') {
        if (enableGrabExpressCar || enableLalaMoveCar) {
          setValue('deliveryMethod', value);
          if (enableLalaMoveCar && !enableGrabExpressCar) {
            setValue('deliveryVendor', 'lalamove');
            return;
          }

          if (!enableLalaMoveCar && enableGrabExpressCar) {
            setValue('deliveryVendor', 'grab');
            return;
          }
          return;
        }
      }

      if (value === 'motorcycle') {
        if (enableLalaMoveMotorcycle || enableGrabExpressMotorcycle) {
          setValue('deliveryMethod', value);
          if (enableLalaMoveMotorcycle && !enableGrabExpressMotorcycle) {
            setValue('deliveryVendor', 'lalamove');
            return;
          }

          if (!enableLalaMoveMotorcycle && enableGrabExpressMotorcycle) {
            setValue('deliveryVendor', 'grab');
            return;
          }
        }
        return;
      }
    },
    [enableGrabExpressCar, enableGrabExpressMotorcycle, enableLalaMoveCar, enableLalaMoveMotorcycle, setValue]
  );

  const renderContent = useCallback(() => {
    if (location) {
      return (
        <Stack spacing={'xl'}>
          <Stack
            width={'100%'}
            alignItems={'center'}
            direction={'row'}
            spacing={'s'}
            onClick={handleOpenSavedLocationListDialog}
          >
            <LocationMarkerIcon color={locationMarkerColor} />
            <Stack spacing={'xxs'} flexGrow={1} overflow={'hidden'}>
              <Typography noWrap size={'hm'} variant={'medium'}>
                {location.addressName}
              </Typography>
              <Typography size={'hs'}>{location.address}</Typography>
              {!!locationErrorMessage && (
                <Typography color={locationMarkerColor} size={'xs'}>
                  {locationErrorMessage}
                </Typography>
              )}
            </Stack>
            <Stack>
              <ChevronIcon rotate={-90} color={'actionPrimary'} />
            </Stack>
          </Stack>
          <Divider />

          {deliveryOptions?.length > 0 ? (
            <Stack spacing={'m'}>
              <Controller
                name={'deliveryMethod'}
                control={control}
                render={({ field: { value } }) => (
                  <DeliveryOptionList options={deliveryOptions} value={value} onChange={handleChangeDeliveryMethod} />
                )}
              />
              <DeliveryVendorForm />
            </Stack>
          ) : (
            <Typography variant={'bold'} color={'uiRedPrimary'} textAlign={'center'}>
              {t('order.no_order_method_available')}
            </Typography>
          )}

          <SavedLocationListDialog
            open={openSavedLocationListDialog}
            onPressLocation={handleOnChangeLocation}
            onClose={handleCloseSavedLocationListDialog}
          />
        </Stack>
      );
    }

    if (isFetching) {
      return (
        <Stack spacing={'xl'}>
          <Stack width={'100%'} alignItems={'center'} direction={'row'} spacing={'s'}>
            <Skeleton variant={'rectangular'} width={14} height={19} />
            <Stack spacing={'xs'} flexGrow={1} overflow={'hidden'}>
              <Typography width={'30%'} noWrap size={'hm'} variant={'medium'}>
                <Skeleton variant={'text'} />
              </Typography>
              <Typography width={'80%'} noWrap textOverflow={'ellipsis'} size={'hs'}>
                <Skeleton variant={'text'} />
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction={'row'} spacing={'m'}>
            <Skeleton variant={'rectangular'} width={'48%'} height={102} />
            <Skeleton variant={'rectangular'} width={'48%'} height={102} />
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack borderRadius={'default'} borderWidth={'thick'} borderColor={'borderDivide'}>
        <IconButton onClick={onOpenAddressFormDialog} padding={0}>
          <Stack alignItems={'center'} justifyContent={'center'} paddingY={'xxl'} spacing={'s'}>
            <Stack
              width={78}
              height={78}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={'rounded'}
              borderStyle={'dashed'}
              borderWidth={'thin'}
              borderColor={'borderSubtle'}
              boxSizing={'border-box'}
            >
              <Stack
                boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15);`}
                width={32}
                height={32}
                borderRadius={'rounded'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <PlusIcon />
              </Stack>
            </Stack>
            <Typography color={'uiDarkSecondary'}>{t('order.add_your_address_label')}</Typography>
          </Stack>
        </IconButton>
      </Stack>
    );
  }, [
    control,
    deliveryOptions,
    handleChangeDeliveryMethod,
    handleOnChangeLocation,
    isFetching,
    location,
    locationErrorMessage,
    locationMarkerColor,
    onOpenAddressFormDialog,
    openSavedLocationListDialog,
    t,
  ]);

  return (
    <Stack spacing={'hs'}>
      <Typography size={'hs'} variant={'medium'} color={'uiDarkStain'}>
        {t('order.your_address_label')}
      </Typography>
      {renderContent()}
    </Stack>
  );
};

export default DeliveryMethodForm;

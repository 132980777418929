import { Fragment, ReactNode } from 'react';
import { Stack } from '@components/common';
import Token from '@constants/token';
import DeliveryOption from './DeliveryOption';

interface DeliveryOptionType<TValue> {
  label?: string;
  value: TValue;
  image: ReactNode;
}

interface DeliveryOptionListProps<TValue> {
  value?: TValue;
  onChange?: (value?: TValue) => void;
  options: DeliveryOptionType<TValue>[];
}

const DeliveryOptionList = <TValue extends string>(props: DeliveryOptionListProps<TValue>) => {
  const { value, onChange, options } = props;

  const handleOnPressOptions = (option: DeliveryOptionType<TValue>) => () => {
    onChange?.(option?.value);
  };

  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'}>
      {options?.map((option, index) => (
        <Fragment key={index}>
          <DeliveryOption
            label={option?.label}
            isSelected={option?.value === value}
            onPress={handleOnPressOptions(option)}
            image={option?.image}
          />
          {index !== options.length - 1 && <Stack width={Token.spacing.s} />}
        </Fragment>
      ))}
    </Stack>
  );
};

export default DeliveryOptionList;

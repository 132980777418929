import { FC } from 'react';
import { Stack, Typography } from '@components/common';
import { TokenColor } from '@interfaces/token';
import { StackProps } from '../Stack';
import { TypographyProps } from '../Typography';

export interface TagProps {
  text: string;
  backgroundColor?: TokenColor;
  textColor?: TokenColor;
  stackProps?: Partial<StackProps>;
  textProps?: Partial<TypographyProps>;
}
const Tag: FC<TagProps> = ({
  text,
  backgroundColor = 'uiSuccessMain',
  textColor = 'uiLightPrimary',
  stackProps,
  textProps,
}) => {
  return (
    <Stack
      background={backgroundColor}
      borderRadius={'form'}
      paddingX={'xl'}
      paddingY={'xxs'}
      width={'fit-content'}
      justifyContent={'center'}
      alignItems={'center'}
      {...stackProps}
    >
      <Typography size={'hs'} color={textColor} {...textProps}>
        {text}
      </Typography>
    </Stack>
  );
};

export default Tag;

import { APIDefaultErrors } from '@api/baseAPI/types';

interface PwaManifestIcon {
  src: string;
  type: 'image/svg+xml' | 'image/png';
  sizes: '512x512' | '384x384' | '192x192';
}

interface PwaRelatedApplication {
  /**
   * @param platform
   * If defined, we could check if user already installed the app with `navigator.getInstalledRelatedApps`.
   * But only supported in Windows Chrome & Windows Edge based on https://caniuse.com/?search=getInstalledRelatedApps.
   * https://web.dev/get-installed-related-apps/
   */
  platform: 'webapp' | 'play' | 'windows';
  url: string;
}

export interface PwaManifest {
  name: string;
  short_name: string;
  icons: PwaManifestIcon[];
  theme_color: string;
  background_color: string;
  start_url: string;
  display: 'fullscreen' | 'standalone' | 'minimal-ui' | 'browser';
  /**
   * @param {PwaRelatedApplication[]} related_applications
   *
   */
  related_applications: PwaRelatedApplication[];
  orientation:
    | 'any'
    | 'natural'
    | 'landscape'
    | 'landscape-primary'
    | 'landscape-secondary'
    | 'portrait'
    | 'portrait-primary'
    | 'portrait-secondary';
}

export const getDefaultManifestObject = (host: string): PwaManifest => ({
  name: 'Runchise Ordering',
  short_name: 'Runchise',
  icons: [
    {
      src: `${host}/logo/192x192.png`,
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: `${host}/logo/384x384.png`,
      sizes: '384x384',
      type: 'image/png',
    },
    {
      src: `${host}/logo/512x512.png`,
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  // NOTE: PWA Screenshots is hidden for time being
  // screenshots: [
  //   {
  //     src: `${host}/images/app-preview-1.png`,
  //     type: 'image/png',
  //     sizes: '590x776',
  //   },
  // ],
  theme_color: '#FDBD10',
  background_color: '#FDBD10',
  start_url: `${host}/`,
  display: 'standalone',
  orientation: 'portrait',
  related_applications: [
    {
      platform: 'webapp',
      url: `${host}/`,
    },
  ],
});

export const getErrorMessage = (errors?: APIDefaultErrors, fallback?: string) => {
  if (typeof errors !== 'undefined' && errors !== null) {
    return errors?.message || fallback;
  }
};

import { addSeconds } from 'date-fns';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { createSession, getOTP } from '@api/auth';
import { GetOTPRequest } from '@api/auth/types';
import { getDineInCustomerCheck } from '@api/dineIn';
import { Dialog, Transition } from '@components/common';
import { PhoneNumber, defaultCountryCode } from '@components/common/PhoneInputField';
import { RESEND_OTP_TIME, USER_OTP_REQUEST_TIME_KEY } from '@constants/auth';
import { useCheckDineIn } from '@hooks/dineIn';
import { useSetupSession } from '@hooks/sessions';
import { useCommonStore, useGuestStore } from '@hooks/storage';
import guestStore from '@storage/guestStore';
import { setInternalCookie } from '@utils/auth';
import { AuthNavbar, GuestNameForm, LoginForm, OTPForm } from '..';

interface Props {
  closeModal: () => void;
  onSuccessLogin?: () => void;
  isOpen?: boolean;
}
const AuthFormDialog: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { closeModal, isOpen = false, onSuccessLogin } = props;

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({
    contactNumber: '',
    countryCode: defaultCountryCode,
  });

  const {
    storageState,
    isFinishInitiated: isFinishLoadCommonStore,
    isFetching: isFetchingCommonStore,
  } = useCommonStore();

  const { guestData } = useGuestStore();

  const [showGuestNameInput, setShowGuestNameInput] = useState<boolean>(false);
  const { isDineIn } = useCheckDineIn();

  const snackbarContext = useContext(SnackbarContext);

  const setupSession = useSetupSession();
  const { mutateAsync: fetchCreateSession, isLoading: isCreatingSession } = useMutation(createSession, {
    onSuccess: (res) => {
      setupSession(res);
      onSuccessLogin?.();
      closeModal();
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const onSubmitSession = useCallback(
    (otpAttempt: string) => {
      fetchCreateSession({
        ...phoneNumber,
        otpAttempt,
      });
    },
    [fetchCreateSession, phoneNumber]
  );

  const {
    mutate,
    isLoading: isFetchingOTP,
    isSuccess: isSuccessFetchingOTP,
  } = useMutation(getOTP, {
    onSuccess: (_, request) => {
      const time = new Date().getTime();
      const reqCompletePhoneNumber = `${request?.phoneNumber.countryCode}${request?.phoneNumber.contactNumber}`;
      const cookieKey = `${USER_OTP_REQUEST_TIME_KEY}/${reqCompletePhoneNumber}`;
      const exp = addSeconds(new Date(), RESEND_OTP_TIME);
      setInternalCookie(null, cookieKey, String(time), {
        expires: new Date(exp),
        secure: true,
        sameSite: true,
        path: '/',
      });

      setPhoneNumber({
        contactNumber: request?.phoneNumber.contactNumber,
        countryCode: request?.phoneNumber.countryCode,
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const { mutate: mutateCustomerCheck } = useMutation(getDineInCustomerCheck, {
    onSuccess: async (response, request) => {
      setPhoneNumber({
        contactNumber: request?.phoneNumber,
        countryCode: request?.phoneNumberCountryCode,
      });
      if (response.isRegistered && isDineIn) {
        guestStore.setItem({
          name: response.fullname,
          contactNumber: request?.phoneNumber,
          countryCode: request?.phoneNumberCountryCode,
        });

        setShowGuestNameInput(false);
        onSuccessLogin?.();
        closeModal();
        return;
      }
      if (storageState.activeLocation?.requireGuestName) {
        setShowGuestNameInput(true);
        return;
      }

      guestStore.setItem({
        name: `${request?.phoneNumberCountryCode}${request?.phoneNumber}`,
        contactNumber: request?.phoneNumber,
        countryCode: request?.phoneNumberCountryCode,
      });

      setShowGuestNameInput(false);
      onSuccessLogin?.();
      closeModal();
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const onRequestingOTP = useCallback(
    ({ phoneNumber }: GetOTPRequest) => {
      if (
        storageState.activeLocation?.guestModeDefault &&
        storageState.activeLocation?.requireGuestPhoneNumber &&
        !isFetchingCommonStore &&
        isFinishLoadCommonStore &&
        !guestData &&
        isDineIn
      ) {
        mutateCustomerCheck({
          phoneNumber: phoneNumber.contactNumber,
          phoneNumberCountryCode: phoneNumber.countryCode,
        });
        return;
      }
      setShowGuestNameInput(false);
      mutate({
        phoneNumber,
        otpChannel: 'whatsapp',
      });
    },
    [
      guestData,
      isFetchingCommonStore,
      isFinishLoadCommonStore,
      isDineIn,
      mutate,
      mutateCustomerCheck,
      storageState.activeLocation?.guestModeDefault,
      storageState.activeLocation?.requireGuestPhoneNumber,
    ]
  );

  const resetPhoneNumber = () => {
    setPhoneNumber((prevState) => ({
      ...prevState,
      contactNumber: '',
    }));
  };

  const renderContent = useCallback(() => {
    if (isSuccessFetchingOTP && phoneNumber?.contactNumber && !showGuestNameInput) {
      return (
        <OTPForm
          onSubmit={onSubmitSession}
          isLoading={isCreatingSession}
          phoneNumber={phoneNumber}
          resetPhoneNumber={resetPhoneNumber}
          setShowGuestNameInput={setShowGuestNameInput}
          showGuestNameInput={showGuestNameInput}
          onSuccessLogin={onSuccessLogin}
          closeModal={closeModal}
        />
      );
    }

    if (
      (showGuestNameInput && isDineIn) ||
      (storageState.activeLocation?.guestModeDefault &&
        storageState.activeLocation?.requireGuestName &&
        !storageState.activeLocation?.requireGuestPhoneNumber &&
        isDineIn)
    ) {
      return (
        <GuestNameForm
          contactNumber={phoneNumber.contactNumber}
          countryCode={phoneNumber.countryCode}
          closeModal={closeModal}
          setShowGuestNameInput={setShowGuestNameInput}
          onSuccessLogin={onSuccessLogin}
          skipPhoneNumber={!storageState.activeLocation?.requireGuestPhoneNumber}
          isModalMode
        />
      );
    }

    return <LoginForm onSubmit={onRequestingOTP} isLoading={isFetchingOTP} isModalMode />;
  }, [
    isSuccessFetchingOTP,
    phoneNumber,
    showGuestNameInput,
    isDineIn,
    storageState.activeLocation?.guestModeDefault,
    storageState.activeLocation?.requireGuestName,
    storageState.activeLocation?.requireGuestPhoneNumber,
    onRequestingOTP,
    isFetchingOTP,
    onSubmitSession,
    isCreatingSession,
    onSuccessLogin,
    closeModal,
  ]);

  return (
    <Dialog fullScreen open={isOpen} onClose={closeModal} TransitionComponent={Transition}>
      <AuthNavbar onPressBack={closeModal} />
      {renderContent()}
    </Dialog>
  );
};

export default AuthFormDialog;

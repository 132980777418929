import { CSSProperties } from 'react';
import Token from '@constants/token';
import { css } from '@emotion/react';
import type { TokenColor, TokenSpacing } from '@interfaces/token';

const padding = (valueType?: TokenSpacing) => css`
  padding: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const width = (valueType: CSSProperties['width']) => css`
  width: ${valueType};
`;

const maxWidth = (valueType: CSSProperties['maxWidth']) => css`
  max-width: ${valueType};
`;

const minWidth = (valueType: CSSProperties['minWidth']) => css`
  min-width: ${valueType};
`;

const height = (valueType: CSSProperties['height']) => css`
  height: ${valueType};
`;

const margin = (valueType?: TokenSpacing) => css`
  margin: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const paddingLeft = (valueType?: TokenSpacing) => css`
  padding-left: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const paddingRight = (valueType?: TokenSpacing) => css`
  padding-right: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const paddingTop = (valueType?: TokenSpacing) => css`
  padding-top: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const paddingBottom = (valueType?: TokenSpacing) => css`
  padding-bottom: ${valueType ? Token.spacing[valueType] : 0}px;
`;

const backgroundColor = (valueType: TokenColor) => css`
  background-color: ${Token.color[valueType]};
`;

const borderBottomColor = (valueType?: TokenColor) => css`
  border-bottom-color: ${valueType ? Token.color[valueType] : 'transparent'};
`;

const borderBottomWidth = (valueType: number) => css`
  border-bottom-width: ${valueType}px;
`;

const valign = (valueType: CSSProperties['verticalAlign']) => css`
  vertical-align: ${valueType};
`;

const position = (valueType: CSSProperties['position']) => css`
  position: ${valueType};
`;

const left = (valueType: CSSProperties['left']) => css`
  left: ${valueType};
`;

const zIndex = (valueType: CSSProperties['zIndex']) => css`
  z-index: ${valueType};
`;

const color = (valueType: CSSProperties['color']) => css`
  color: ${valueType};
`;

const styles = {
  padding,
  width,
  maxWidth,
  minWidth,
  height,
  margin,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  backgroundColor,
  color,
  borderBottomColor,
  borderBottomWidth,
  valign,
  position,
  left,
  zIndex,
};

export default styles;

import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';

interface Props {
  label?: string;
  enable: boolean;
  isNegative: boolean;
}

const AdjustmentDetailLine = ({ label, enable, isNegative }: Props) => {
  const { t } = useTranslation();

  if (enable) {
    return (
      <Stack alignItems={'flex-start'} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'space-between'}>
        <Stack>
          <Stack spacing={'xxs'} direction={'row'} alignContent={'flex-end'}>
            <Typography size={'s'} variant={'medium'} color={'uiRedPrimary'}>
              {isNegative ? t('order.discount_label') : t('order.surcharge_label')}
            </Typography>
          </Stack>
        </Stack>
        <Typography color={'uiRedPrimary'} size={'s'}>
          {label}
        </Typography>
      </Stack>
    );
  }

  return null;
};

export default AdjustmentDetailLine;

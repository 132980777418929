import { useMemo } from 'react';
import { GetClosedBillOrderResponse, GetOpenBillOrderResponse } from '@api/dineIn/types';
import { useQueryClosedBillOrder, useQueryOpenBillOrder } from '@queries/dineIn';
import useCheckDineIn from './useCheckDineIn';

interface Props {
  /**
   * @param {boolean} enabled
   * Whether we need to call the API or not.
   * If you need to call this custom hook more than once, make sure to pass enabled as true in the highest level order of call and others must be set as false.
   */
  enabled?: boolean;
}

interface BaseReturnProps {
  isDineIn: boolean;
  isOpenBill: boolean;
  isClosedBill: boolean;
  isFetching: boolean;
  dineInDetail?: GetOpenBillOrderResponse | GetClosedBillOrderResponse;
}

interface OpenBillReturnProps extends BaseReturnProps {
  isOpenBill: true;
  isClosedBill: false;
  dineInDetail?: GetOpenBillOrderResponse;
}

interface ClosedBillReturnProps extends BaseReturnProps {
  isOpenBill: false;
  isClosedBill: true;
  dineInDetail?: GetClosedBillOrderResponse;
}

type ReturnProps = BaseReturnProps | OpenBillReturnProps | ClosedBillReturnProps;

/**
 * Get dine in detail based on the available params.
 * Make sure to use call hook only once in each container level, if you call this both in container and components level, it can cause infinite rerenders and API fetching.
 *
 * If you need to call this hook more than once, make sure to pass enabled as false.
 */
const useDineInOrderDetail = ({ enabled = true }: Props = {}): ReturnProps => {
  const { isOpenBill, isClosedBill } = useCheckDineIn();
  const { data: openBillDetail, isFetching: isFetchingOpenBillOrder } = useQueryOpenBillOrder({
    enabled,
  });

  const { data: closedBillDetail, isFetching: isFetchingClosedBillOrder } = useQueryClosedBillOrder({
    enabled,
  });

  return useMemo(
    () => ({
      isDineIn: isOpenBill || isClosedBill,
      isOpenBill,
      isClosedBill,
      isFetching: isOpenBill ? isFetchingOpenBillOrder : isFetchingClosedBillOrder,
      dineInDetail: isOpenBill ? openBillDetail : closedBillDetail,
    }),
    [closedBillDetail, isClosedBill, isFetchingClosedBillOrder, isFetchingOpenBillOrder, isOpenBill, openBillDetail]
  );
};

export default useDineInOrderDetail;

import { ReactNode } from 'react';
import { IconProps } from '../types';
import styles from './styles';

interface Props extends Pick<IconProps, 'rotate' | 'opacity'> {
  children: ReactNode;
}

const BaseIcon = ({ rotate, children, opacity }: Props) => {
  return <div css={[styles.container, rotate && styles.rotate(rotate), styles.opacity(opacity)]}>{children}</div>;
};

export default BaseIcon;

import React from 'react';
import { Box, Button, Stack, Typography } from '@components/common';

const BUTTON_SIZE = 30;

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
  disabledIncrement?: boolean;
  disabledDecrement?: boolean;
}
const Stepper: React.FC<Props> = (props) => {
  const { value = 0, onChange, minValue, maxValue, disabled, disabledDecrement, disabledIncrement } = props;
  const incrementTotal = () => {
    onChange?.(value + 1);
  };
  const decrementTotal = () => {
    onChange?.(value - 1);
  };

  const disableDecrementButton = typeof minValue !== undefined ? minValue === value : false;
  const disableIncrementButton = typeof maxValue !== undefined ? maxValue === value : false;

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={'s'}>
      <Button
        minHeight={0}
        padding={0}
        variant={'contained'}
        onClick={decrementTotal}
        disabled={disableDecrementButton || disabled || disabledDecrement}
        stopPropagation
      >
        <Box width={BUTTON_SIZE} height={BUTTON_SIZE} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          <Typography lineHeight={0} size={'xxxl'}>
            {'-'}
          </Typography>
        </Box>
      </Button>
      <Typography>{String(value)}</Typography>
      <Button
        minHeight={0}
        padding={0}
        paddingRight={0}
        variant={'contained'}
        onClick={incrementTotal}
        disabled={disableIncrementButton || disabled || disabledIncrement}
        stopPropagation
      >
        <Box width={BUTTON_SIZE} height={BUTTON_SIZE} alignItems={'center'} justifyContent={'center'} display={'flex'}>
          <Typography lineHeight={0} size={'m'}>
            {'+'}
          </Typography>
        </Box>
      </Button>
    </Stack>
  );
};

export default Stepper;

import { atom } from 'jotai';
import { BeforeInstallPromptEvent } from '@interfaces/common';

interface Props {
  deferredPromptPWA?: BeforeInstallPromptEvent;
  serviceWorkerRegistration?: ServiceWorkerRegistration;
}

export const atomServiceWorker = atom<Props>({
  deferredPromptPWA: undefined,
  serviceWorkerRegistration: undefined,
});

import Decimal from 'decimal.js';
import { toLower, upperCase } from 'lodash';
import { DecimalNumber } from '@api/baseAPI/types';
import {
  BalanceCustomerOrderPayment,
  BaseCustomerOrderPayment,
  CustomerOrderPaymentCash,
  CustomerOrderPaymentResponse,
  GopayCustomerOrderPayment,
  OrderPaymentMethod,
  PaymentMethod,
  PaymentMethodBankId,
  PaymentMethodType,
  VirtualAccountPaymentMethod,
  XenditCustomerOrderPayment,
  XenditPaymentMethodType,
} from '@api/order/types';
import { Image } from '@components/common';
import { calcDecimal } from '@utils/number';

// https://stackoverflow.com/questions/72768/how-do-you-detect-credit-card-type-based-on-number/72801#72801
const regexTester = [
  {
    key: 'visa',
    label: 'Visa',
    regex: new RegExp('^4[0-9]{6,}$'),
    image: '/payment-method-logo/visa.png',
  },
  {
    key: 'master-card',
    label: 'Master Card',
    regex: new RegExp(
      '^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$'
    ),
    image: '/payment-method-logo/master-card.png',
  },
  {
    key: 'american-express',
    label: 'American Express',
    regex: new RegExp('^3[47][0-9]{5,}$'),
    image: '/payment-method-logo/american-express.png',
  },
  {
    key: 'diners-club',
    label: 'Diners Club',
    regex: new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{4,}$'),
    image: '/payment-method-logo/diners-club.png',
  },
  {
    key: 'discover',
    label: 'Discover',
    regex: new RegExp('^6(?:011|5[0-9]{2})[0-9]{3,}$'),
    image: '/payment-method-logo/discover.png',
  },
  {
    key: 'jcb',
    label: 'JCB',
    regex: new RegExp('^(?:2131|1800|35[0-9]{3})[0-9]{3,}$'),
    image: '/payment-method-logo/jcb.png',
  },
];

export const getCardDataFromAccountNumber = (accountNumber = '') => {
  const cleanString = accountNumber?.replace(/ /g, '');

  const matchedCardType = regexTester.find((card) => card.regex.test(cleanString));

  return matchedCardType || regexTester[0];
};

export const getBankLogoFromBankId = (bankId?: PaymentMethodBankId) => {
  switch (bankId) {
    case 'BCA':
      return '/payment-method-logo/bca.png';
    case 'BNI':
      return '/payment-method-logo/bni.png';
    case 'BRI':
      return '/payment-method-logo/bri.png';
    default:
      return '/payment-method-logo/va-transfer.png';
  }
};

export const getXenditPaymentMethodIcon = (paymentMethod?: PaymentMethod, alt?: string) => {
  const imageProps = {
    height: 24,
    alt,
  };

  switch (paymentMethod?.type) {
    case 'credit-card':
      return <Image {...imageProps} src={getCardDataFromAccountNumber(paymentMethod?.accountNumber)?.image} />;
    case 'dana':
      return <Image {...imageProps} src={'/payment-method-logo/dana.png'} />;
    case 'ovo':
      return <Image {...imageProps} src={'/payment-method-logo/ovo.png'} />;
    case 'gopay':
      return <Image {...imageProps} src={'/payment-method-logo/gopay.png'} />;
    case 'shopeepay':
      return <Image {...imageProps} src={'/payment-method-logo/shopeepay.png'} />;
    case 'va-transfer': {
      const { bankId } = paymentMethod as VirtualAccountPaymentMethod;
      return <Image {...imageProps} src={getBankLogoFromBankId(bankId)} />;
    }
    case 'qris':
      return <Image {...imageProps} src={'/payment-method-logo/qris.png'} />;
    default:
      return null;
  }
};

export const getXenditPaymentMethod = (type?: PaymentMethodType): OrderPaymentMethod | undefined => {
  if (type === 'va-transfer') {
    return 'virtual_account';
  }
  if (type === 'qris') {
    return 'qris';
  }
  if (type === 'credit-card') {
    return 'credit_card';
  }
  if (type === 'dana' || type === 'ovo' || type === 'shopeepay') {
    return 'e_wallet';
  }
  if (type === 'gopay') {
    return 'gopay';
  }
  if (type === 'balance') {
    return 'balance';
  }
};

export const getXenditPaymentMethodType = (paymentMethod?: PaymentMethod): XenditPaymentMethodType | undefined => {
  if (paymentMethod?.type === 'va-transfer') {
    const { bankId = 'bca' } = paymentMethod as VirtualAccountPaymentMethod;
    return toLower(bankId) as XenditPaymentMethodType;
  }
  if (paymentMethod?.type === 'dana' || paymentMethod?.type === 'ovo' || paymentMethod?.type === 'shopeepay') {
    return paymentMethod?.type;
  }
};

export const getPaymentMethodCRKey = (type?: PaymentMethodType) => {
  if (type === 'credit-card') {
    return 'order.credit_card_label';
  }

  if (type === 'dana') {
    return 'order.dana_label';
  }

  if (type === 'gopay') {
    return 'order.gopay_label';
  }

  if (type === 'ovo') {
    return 'order.ovo_label';
  }

  if (type === 'shopeepay') {
    return 'order.shopeepay_label';
  }

  if (type === 'qris') {
    return 'order.qris_label';
  }

  if (type === 'va-transfer') {
    return 'order.va_transfer_label';
  }

  if (type === 'balance') {
    return 'order.balance_label';
  }

  if (type === 'cash') {
    return 'dine_in.cashier_label';
  }

  return '';
};

export const getPaymentMethod = (method?: CustomerOrderPaymentResponse): PaymentMethod | undefined => {
  if (method?.type === 'virtual_account') {
    return {
      type: 'va-transfer',
      bankId: upperCase(method?.paymentMethodType) as PaymentMethodBankId,
    };
  }
  if (method?.type === 'qris' || method?.type === 'gopay') {
    return {
      type: method?.type,
    };
  }

  if (method?.type === 'e_wallet') {
    return {
      type: method?.paymentMethodType as PaymentMethodType,
      accountNumber: method?.metadata?.mobileNumber,
    };
  }

  if (method?.type === 'credit_card') {
    return {
      type: 'credit-card',
      accountNumber: method?.metadata?.maskedCardNumber,
    };
  }

  if (method?.type === 'balance') {
    return {
      type: 'balance',
    };
  }

  if (method?.type === 'cash') {
    return {
      type: 'cash',
    };
  }
};

export const getPaymentMethodAndTypeForCustomerOrderPrice = (
  paymentMethod?: PaymentMethod
): BaseCustomerOrderPayment | undefined => {
  const paymentMethodType = paymentMethod?.type;
  if (paymentMethodType === 'gopay') {
    return {
      paymentMethod: 'gopay',
    } as GopayCustomerOrderPayment;
  }

  if (paymentMethodType === 'balance') {
    return {
      paymentMethod: 'balance',
    } as BalanceCustomerOrderPayment;
  }

  if (paymentMethodType === 'cash') {
    return {
      paymentMethod: 'cash',
    } as CustomerOrderPaymentCash;
  }

  const xenditPaymentMethod = getXenditPaymentMethod(paymentMethodType);
  const xenditPaymentMethodType = getXenditPaymentMethodType(paymentMethod);

  if (xenditPaymentMethod === 'credit_card' || xenditPaymentMethod === 'qris') {
    return {
      paymentMethod: xenditPaymentMethod,
      paymentMethodType: '',
    } as XenditCustomerOrderPayment;
  }

  if (
    xenditPaymentMethod === 'e_wallet' &&
    (xenditPaymentMethodType === 'shopeepay' || xenditPaymentMethodType === 'ovo' || xenditPaymentMethodType === 'dana')
  ) {
    return {
      paymentMethod: xenditPaymentMethod,
      paymentMethodType: xenditPaymentMethodType,
    };
  }

  if (
    xenditPaymentMethod === 'virtual_account' &&
    (xenditPaymentMethodType === 'bca' || xenditPaymentMethodType === 'bni' || xenditPaymentMethodType === 'bri')
  ) {
    return {
      paymentMethod: xenditPaymentMethod,
      paymentMethodType: xenditPaymentMethodType,
    };
  }
};

export const checkIfUserBalanceEnough = (balance: DecimalNumber = '', totalAmount: DecimalNumber = '') => {
  if (
    (typeof balance === 'string' || typeof balance === 'number') &&
    (typeof totalAmount === 'string' || typeof totalAmount === 'number')
  ) {
    const parsedBalance = calcDecimal(balance);
    const parsedTotalAmountCredit = calcDecimal(totalAmount);

    if (parsedBalance instanceof Decimal && parsedTotalAmountCredit instanceof Decimal) {
      return parsedBalance?.greaterThanOrEqualTo(parsedTotalAmountCredit);
    }
  }

  return false;
};

export const isMinimumAmountForPaymentMethod = (
  amount: DecimalNumber,
  paymentMethodType: PaymentMethodType,
  bankId?: PaymentMethodBankId
) => {
  const calcAmount = calcDecimal(amount);
  const isMinimumAmount = calcAmount instanceof Decimal && calcAmount?.lessThanOrEqualTo(10000);

  if (paymentMethodType === 'credit-card') {
    return isMinimumAmount;
  }
  if (paymentMethodType === 'va-transfer') {
    return isMinimumAmount && bankId === 'BCA';
  }

  return false;
};

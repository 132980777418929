import { useRouter } from 'next/router';
import { useEffect } from 'react';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useCartStore, useCommonStore } from '@hooks/storage';

interface Props {
  enabled?: boolean;
}

const useRedirectEmptyProductCart = ({ enabled = true }: Props = {}) => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const { storageState, isFinishInitiated, isFetching: isFetchingCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
    isLoading: isFetchingCartStore,
  } = useCartStore(activeLocationId);

  useEffect(() => {
    if (
      enabled &&
      !isFetchingCommonStore &&
      !isFetchingCartStore &&
      isFinishInitiated &&
      isFinishLoadCartStore &&
      router.isReady
    ) {
      const productIds = Object.keys(branchCart);
      if (productIds.length === 0) {
        router.push({
          pathname: routes?.ROOT,
          query,
        });
      }
    }
  }, [
    branchCart,
    enabled,
    isFetchingCartStore,
    isFetchingCommonStore,
    isFinishInitiated,
    isFinishLoadCartStore,
    query,
    router,
  ]);
};

export default useRedirectEmptyProductCart;

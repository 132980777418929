import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const ChevronIcon: FC<Props> = ({ width = 12, height = 12, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 12 12'}>
        <path
          fill={fillColor}
          d={
            'M6.00082 9.42042C5.78576 9.42042 5.57072 9.3383 5.40675 9.17441L0.247024 4.01463C-0.0812021 3.6864 -0.0812021 3.15424 0.247024 2.82615C0.575117 2.49805 1.10717 2.49805 1.43543 2.82615L6.00082 7.39181L10.5662 2.82631C10.8945 2.49821 11.4265 2.49821 11.7545 2.82631C12.0829 3.1544 12.0829 3.68656 11.7545 4.01479L6.59489 9.17457C6.43084 9.33849 6.21581 9.42042 6.00082 9.42042Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default ChevronIcon;

import Decimal from 'decimal.js';

/**
 * calcDecimal used to convert string/number to decimal, it will also remove trailing zeros.
 * For example:
 * calcDecimal('10.0') -> 10 (but in Decimal class type)
 * calcDecimal('10.0')?.toString() -> '10'
 */
export const calcDecimal = (value?: string | number | null) => {
  if (typeof value !== 'undefined' && value !== null) {
    try {
      return new Decimal(value);
    } catch (e) {
      if (e instanceof Error && /DecimalError/.test(e.message)) {
        if (value) {
          console.info('You can safely ignore this. This is just to know what value FE try to calcDecimal: ', value);
        }
        return undefined;
      }
    }
  }

  return value;
};

export const toNumber = (str = '0') => {
  const parsedStr = calcDecimal(str);
  if (parsedStr instanceof Decimal) {
    return parsedStr?.toNumber();
  }
};

export const toLocaleStringNumber = (amount = '0', locale = 'id-ID') => toNumber(amount)?.toLocaleString(locale);

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { CustomerAddress } from '@api/locations/types';
import { IconButton, Stack, Typography } from '@components/common';
import { ChevronIcon, LocationMarkerIcon, PlusIcon } from '@components/icons';
import DeliveryAddressFormDialog from '@components/order/DeliveryAddressFormDialog';
import { Divider } from '@mui/material';
import { useQueryCustomerAddresses } from '@queries/locations';
import SavedLocationListLoader from '../SavedLocationListLoader';

interface Props {
  onPressLocation?: (location: CustomerAddress) => void;
  isContentEditable?: boolean;
}

const SavedLocationList: React.FC<Props> = (props) => {
  const { onPressLocation, isContentEditable } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isOpenAddressFormDialog, setIsOpenAddressFormDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>();

  const { isFetching, queryKeys, data } = useQueryCustomerAddresses();

  const onSuccessUpdateCustomerAddress = () => queryClient.invalidateQueries(queryKeys);

  const onOpenAddressFormDialog = () => {
    setSelectedAddress(undefined);
    setIsOpenAddressFormDialog(true);
  };

  const onCloseAddressFormDialog = () => {
    setIsOpenAddressFormDialog(false);
  };

  const addressList: CustomerAddress[] = data || [];

  const handleOnPressAddress = (location: CustomerAddress) => () => {
    onPressLocation?.(location);
  };

  const onEditAddress = (address: CustomerAddress) => () => {
    setSelectedAddress(address);
    setIsOpenAddressFormDialog(true);
  };

  const renderLocationList = () => {
    if (isFetching) {
      return <SavedLocationListLoader />;
    }
    return addressList.map((address, index) => (
      <Stack key={index} spacing={'m'}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          {isContentEditable ? (
            <>
              <Stack
                flexGrow={1}
                overflow={'auto'}
                onClick={onEditAddress(address)}
                direction={'row'}
                spacing={'s'}
                alignItems={'center'}
                overflowX={'hidden'}
              >
                <LocationMarkerIcon />
                <Stack spacing={'xs'} width={'85%'}>
                  <Typography noWrap lineHeight={0.9} size={'hm'} variant={'bold'}>
                    {address?.addressName}
                  </Typography>
                  <Typography noWrap size={'hs'}>
                    {address.address}
                  </Typography>
                </Stack>
                <ChevronIcon rotate={270} />
              </Stack>
            </>
          ) : (
            <Stack
              flexGrow={1}
              overflow={'auto'}
              onClick={handleOnPressAddress(address)}
              direction={'row'}
              spacing={'s'}
              alignItems={'center'}
            >
              <LocationMarkerIcon />
              <Stack spacing={'xs'} width={'85%'}>
                <Typography noWrap lineHeight={0.9} size={'hm'} variant={'bold'}>
                  {address?.addressName}
                </Typography>
                <Typography noWrap size={'hs'}>
                  {address.address}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Stack>
        <Divider />
      </Stack>
    ));
  };

  return (
    <Stack spacing={'xxl'}>
      {renderLocationList()}
      <IconButton onClick={onOpenAddressFormDialog} padding={0}>
        <Stack alignItems={'center'} justifyContent={'center'} paddingY={'xxl'} spacing={'s'}>
          <Stack
            width={78}
            height={78}
            alignItems={'center'}
            justifyContent={'center'}
            borderRadius={'rounded'}
            borderStyle={'dashed'}
            borderWidth={'thin'}
            borderColor={'borderSubtle'}
            boxSizing={'border-box'}
          >
            <Stack
              boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15);`}
              width={32}
              height={32}
              borderRadius={'rounded'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <PlusIcon />
            </Stack>
          </Stack>
          <Typography color={'uiDarkSecondary'}>{t('locations.add_new_address_label')}</Typography>
        </Stack>
      </IconButton>
      <DeliveryAddressFormDialog
        open={isOpenAddressFormDialog}
        onClose={onCloseAddressFormDialog}
        onSuccessSubmitLocation={onSuccessUpdateCustomerAddress}
        address={selectedAddress}
        queryKeys={queryKeys}
      />
    </Stack>
  );
};

export default SavedLocationList;

import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@components/common';
import { CardActionArea } from '@mui/material';

interface DeliveryOptionProps {
  label?: string;
  image?: ReactNode;
  isSelected?: boolean;
  disabled?: boolean;
  onPress?: () => void;
}

const DeliveryOption: FC<DeliveryOptionProps> = (props) => {
  const { label, isSelected, onPress, disabled, image } = props;

  const borderColor = disabled ? 'uiDarkSecondary' : isSelected ? 'uiPrimaryMain' : 'borderSubtle';
  const fontColor = disabled ? 'uiDarkSecondary' : isSelected ? 'uiPrimaryMain' : 'uiDarkPrimary';
  const borderWidth = disabled ? 'thin' : 'bold';
  const backgroundColor = disabled ? 'borderSubtle' : 'uiLightPrimary';
  const typographyVariant = isSelected ? 'medium' : 'regular';

  return (
    <Stack minWidth={'max-content'} flex={1}>
      <CardActionArea onClick={onPress} disabled={disabled}>
        <Stack
          borderWidth={borderWidth}
          borderColor={borderColor}
          padding={'xs'}
          borderRadius={'default'}
          alignItems={'center'}
          boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
          background={backgroundColor}
          spacing={'xxs'}
          minHeight={'47px'}
          justifyContent={'center'}
        >
          {image}
          {label && (
            <Typography variant={typographyVariant} color={fontColor}>
              {label}
            </Typography>
          )}
        </Stack>
      </CardActionArea>
    </Stack>
  );
};

export default DeliveryOption;

import { anchorRef, bindPopover, bindTrigger } from 'material-ui-popup-state';
import { PopupState, usePopupState as usePopupStateMui } from 'material-ui-popup-state/hooks';
import { useMemo } from 'react';

type Props = Pick<PopupState, 'popupId'>;

const usePopupState = ({ popupId }: Props) => {
  const popupState = usePopupStateMui({ variant: 'popover', popupId });
  const triggerProps = bindTrigger(popupState);
  const popoverProps = bindPopover(popupState);

  return useMemo(
    () => ({
      popupState,
      anchorProps: anchorRef(popupState),
      triggerProps,
      popoverProps,
    }),
    [popoverProps, popupState, triggerProps]
  );
};

export default usePopupState;

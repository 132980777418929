import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { COMMON_STORAGE_INITIAL_VALUE } from '@constants/storage';
import commonStore from '@storage/commonStore';
import { CommonStorage } from '@storage/types';

const useCommonStore = () => {
  const {
    refetch,
    isFetching,
    isSuccess: isFinishInitiated,
    data: commonStoreData,
  } = useQuery(['getCommonStorage'], commonStore.getStorage, {
    cacheTime: 0,
  });

  const storageState = useMemo(() => commonStoreData || COMMON_STORAGE_INITIAL_VALUE, [commonStoreData]);

  const updateStorage = async (storage: Partial<CommonStorage>) =>
    await commonStore
      .updateStorage({
        ...storageState,
        ...storage,
      })
      .then(() => {
        refetch();
      });

  return {
    isFetching,
    isFinishInitiated,
    storageState,
    updateStorage,
  };
};

export default useCommonStore;

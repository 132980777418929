import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { CustomTextField, Stack, Typography } from '@components/common';
import { TextFieldProps } from '@mui/material';
import { StackProps } from '../Stack';
import { TypographyProps } from '../Typography';

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  subLabel?: string;
  labelProps?: TypographyProps;
  subLabelProps?: TypographyProps;
  containerProps?: StackProps;
  inputProps?: TextFieldProps;
  control: Control<T>;
  inputMaskProps?: InputMaskProps;
  mask: InputMaskProps['mask'];
  customValueFormatter?: (value: string) => string;
}

const RHFTextFieldMask = <T extends FieldValues>(props: Props<T>) => {
  const {
    name,
    label,
    labelProps,
    subLabel,
    subLabelProps,
    containerProps,
    inputProps,
    inputMaskProps,
    control,
    mask,
    customValueFormatter,
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onBlur, ref, value, onChange } = field;
        const { error } = fieldState;

        const onChangeTextField: TextFieldProps['onChange'] = (e) => {
          if (customValueFormatter) {
            return onChange(customValueFormatter(e?.target.value));
          }
          return onChange(e?.target.value);
        };

        return (
          <Stack spacing={'hs'} {...containerProps}>
            {!!label && (
              <Typography size={'hs'} {...labelProps}>
                {label}
              </Typography>
            )}
            {!!subLabel && (
              <Typography size={'xs'} opacity={0.5} {...subLabelProps}>
                {subLabel}
              </Typography>
            )}
            <InputMask
              ref={ref}
              onChange={onChangeTextField}
              value={value as InputMaskProps['value']}
              onBlur={onBlur}
              mask={mask}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore InputMask PropTypes is incorrect from the package.
              maskChar={null}
              {...inputMaskProps}
            >
              {() => (
                <CustomTextField inputRef={ref} error={!!error?.message} helperText={error?.message} {...inputProps} />
              )}
            </InputMask>
          </Stack>
        );
      }}
    />
  );
};

export default RHFTextFieldMask;

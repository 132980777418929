import { format, parse } from 'date-fns';
import { useCallback, useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { object, string } from 'yup';
import { updateProfile } from '@api/auth';
import { UserProfile } from '@api/auth/types';
import { Nullable } from '@api/baseAPI/types';
import {
  Button,
  DefaultLoading,
  PhoneInputField,
  RHFDateInput,
  RHFSelect,
  RHFTextField,
  Stack,
  Typography,
} from '@components/common';
import { BIRTHDAY_FORMAT, GENDER_OPTIONS } from '@constants/auth';
import Token from '@constants/token';
import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import { useQueryProfileDetail } from '@queries/auth';

type FormShape = Omit<UserProfile, 'birthday'> & { birthday: Nullable<Date> };

interface Props {
  isLoading?: boolean;
}

const ProfileForm: React.FC<Props> = (props) => {
  const { isLoading } = props;
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit: handleFormSubmit,
    reset,
  } = useForm<FormShape>({
    resolver: yupResolver(
      object({
        email: string().nullable().notRequired().email(),
      })
    ),
  });

  const {
    data,
    isFetching: isFetchingProfile,
    queryKeys: profileQueryKeys,
  } = useQueryProfileDetail({
    enabled: false,
  });

  const { isLoading: isUpdatingProfile, mutate } = useMutation(updateProfile, {
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
    onSuccess: () => {
      snackbarContext?.openSnackbar?.({
        message: t('sessions.success_update_profile_label'),
        alertProps: {
          severity: 'success',
        },
      });
      queryClient.invalidateQueries(profileQueryKeys);
    },
  });

  const handleSubmit = useCallback(
    (value: FormShape) => {
      mutate?.({
        ...value,
        birthday: value?.birthday ? format(value?.birthday, BIRTHDAY_FORMAT) : '',
      });
    },
    [mutate]
  );

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        birthday: data?.birthday ? parse(data.birthday, BIRTHDAY_FORMAT, new Date()) : null,
      });
    }
  }, [data, reset]);

  if (isLoading || isFetchingProfile) {
    return <DefaultLoading />;
  }

  return (
    <Stack direction={'column'} padding={'m'} spacing={'xxxl'}>
      <Stack direction={'column'}>
        <Stack spacing={'hs'}>
          <RHFTextField
            inputProps={{
              placeholder: t('sessions.name_placeholder'),
              disabled: isUpdatingProfile,
            }}
            control={control}
            name={'name'}
            label={t('sessions.profile_name_label')}
          />
        </Stack>
      </Stack>
      <Stack direction={'column'}>
        <Stack spacing={'hs'}>
          <RHFTextField
            inputProps={{
              placeholder: t('sessions.email_placeholder'),
              disabled: !!data?.email || data?.isConfirmed || isUpdatingProfile,
            }}
            control={control}
            name={'email'}
            label={t('sessions.profile_email_label')}
          />
        </Stack>
      </Stack>
      <Stack direction={'column'}>
        <Stack spacing={'hs'}>
          <Controller
            name={'contactNumber'}
            control={control}
            render={({ field: { value, ref } }) => (
              <PhoneInputField phoneInputRef={ref} valueText={value as string} disabled />
            )}
          />
        </Stack>
      </Stack>
      <Stack direction={'row'} spacing={'m'}>
        <Stack flex={2}>
          <RHFDateInput
            control={control}
            name={'birthday'}
            inputProps={{
              placeholder: t('sessions.birthday_placeholder'),
              disabled: isUpdatingProfile,
            }}
            label={t('sessions.profile_date_of_birth_label')}
          />
        </Stack>
        <Stack flex={2}>
          <RHFSelect
            selectProps={{
              options: GENDER_OPTIONS,
              disabled: isUpdatingProfile,
            }}
            control={control}
            name={'gender'}
            label={t('sessions.profile_gender_label')}
          />
        </Stack>
      </Stack>

      <Button onClick={handleFormSubmit(handleSubmit)} variant={'contained'} padding={Token.spacing.xs}>
        {isUpdatingProfile ? (
          <CircularProgress color={'secondary'} size={24} />
        ) : (
          <Typography size={'hs'} variant={'medium'}>
            {t('sessions.profile_submit_label')}
          </Typography>
        )}
      </Button>
    </Stack>
  );
};

export default ProfileForm;

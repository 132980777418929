import { useAtom } from 'jotai';
import { some, sumBy } from 'lodash';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { atomCustomItem } from '@atoms/products';
import { Box, Button, Image, LineSeparator, Stack, TextField, Typography } from '@components/common';
import { ChatFilledIcon, FavoriteIcon } from '@components/icons';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useCommonStore } from '@hooks/storage';
import { InputProps } from '@mui/material';
import { useQueryBrandInfo } from '@queries/commons';
import { CartItem, CustomItem } from '@storage/types';
import { OutOfStockTag, ProductCustomOptionDrawer, ProductPrice, ProductTotalInput } from '..';

interface Props {
  product: Product;
  disabled?: boolean;
  cartItem?: CartItem;
  onChangeCartItem?: (item: CartItem) => void;
  productCategoryId?: number;
  productCategoryName?: string;
  isFavorite?: boolean;
  showBorder?: boolean;
}

const ProductCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();

  const { isFavorite, product, cartItem, onChangeCartItem, productCategoryId, productCategoryName, showBorder } = props;

  const outOfStock = !product || product.outOfStockFlag;
  const disabled = props.disabled;

  const disabledOrOutOfStock = disabled || outOfStock;

  const [showProductNotesInput, setShowProductNotesInput] = useState(false);
  const [openCustomOptionDrawer, setOpenCustomOptionDrawer] = useState(false);
  const [_, setCustomItem] = useAtom(atomCustomItem);
  const [notes, setNotes] = useState(cartItem?.notes);
  const router = useRouter();
  const { storageState } = useCommonStore();

  const { name, price, description, imageUrl, promoAmount } = product;

  const hasProductOptionSetOptions = useMemo(
    () => product.productOptionSets?.filter((optionSet) => optionSet.optionSetOptions?.length !== 0).length ?? 0,
    [product.productOptionSets]
  );

  const hasProductOptionSet = product?.type === 'option_set' && hasProductOptionSetOptions > 0;

  const isCustomProduct = hasProductOptionSet;

  const totalInputProduct = useMemo(() => {
    if (hasProductOptionSet) {
      return sumBy(cartItem?.customItems, (customItem) => customItem?.qty || 0);
    }

    return cartItem?.qty || 0;
  }, [cartItem, hasProductOptionSet]);

  const hasNotes = useMemo(() => {
    if (hasProductOptionSet) {
      return some(cartItem?.customItems, (customItem) => !!customItem.notes);
    }
    return !!cartItem?.notes;
  }, [cartItem, hasProductOptionSet]);

  const showNotesButton = totalInputProduct > 0;
  const notesButtonColor = !hasNotes || outOfStock ? 'uiDarkSecondary' : 'uiPrimaryMain';
  const notesIconColor = !hasNotes || outOfStock ? 'uiDarkSecondary' : 'uiDarkPrimary';

  /**
   * This Effect is to update notes into the latest notes when we change it from detail dialog modal
   */
  useEffect(() => {
    setNotes(cartItem?.notes);
  }, [cartItem?.notes]);

  /**
   * THis effect is to auto Close drawer if all custom product removed
   */
  useEffect(() => {
    if (openCustomOptionDrawer && totalInputProduct === 0) {
      setOpenCustomOptionDrawer(false);
    }
  }, [openCustomOptionDrawer, totalInputProduct]);

  /**
   * Method to open Notes Input
   * This method will show notes input if the product is not custom options
   * When the product have custom options, it will open custom otpion drawer
   */
  const openNotesInput = () => {
    if (isCustomProduct) {
      setOpenCustomOptionDrawer(true);
    } else {
      setShowProductNotesInput(true);
    }
  };

  const closeNotesInput = () => {
    setShowProductNotesInput(false);
  };

  const toggleNotesInput = () => {
    if (showProductNotesInput) {
      closeNotesInput();
    } else {
      openNotesInput();
    }
  };

  const onChangeNotes: InputProps['onChange'] = (e) => {
    setNotes(e?.target.value);
  };

  const saveNotes = () => {
    onChangeCartItem?.({
      ...cartItem,
      productCategoryId,
      productCategoryName,
      notes,
    });
    closeNotesInput();
  };

  const onOpenProductDetailPage = useCallback(() => {
    router.push({
      pathname: `${routes.PRODUCT_LIST}/${product.id}`,
      query: {
        ...router.query,
        locationId: storageState.activeLocation?.id,
        subBrandId: storageState.subBrand?.id,
      },
    });
  }, [product.id, router, storageState.activeLocation?.id, storageState.subBrand?.id]);

  /**
   * This method is to handle total Input Change
   * For Product with custom option, it will open Product detail form the first increment
   * It will open the custom option drawer on next increment
   * For Product without custom Options, it will increment the qty of the product
   * it will remove the notes on cart item when the value become 0
   * It will also set the notes state into empty string when the value become 0
   * @param {number} value
   * @returns
   */
  const onChangeProductTotalInput = useCallback(
    (value: number) => {
      if (isCustomProduct) {
        const isAddingFirstItem = totalInputProduct === 0 && value === 1;
        if (isAddingFirstItem) {
          onOpenProductDetailPage();
        }
        return setOpenCustomOptionDrawer(true);
      } else {
        onChangeCartItem?.({
          ...cartItem,
          productCategoryId,
          productCategoryName,
          id: product?.id,
          qty: value,
          ...(value === 0 && { notes: '' }),
        });

        if (value === 0) {
          setNotes('');
          closeNotesInput();
        }
      }
    },
    [
      cartItem,
      isCustomProduct,
      onChangeCartItem,
      onOpenProductDetailPage,
      product?.id,
      productCategoryId,
      productCategoryName,
      totalInputProduct,
    ]
  );

  const onPressEditCustomItem = (customItem: CustomItem) => {
    setCustomItem(customItem);
    onOpenProductDetailPage();
  };

  const onPressCard = () => {
    if (disabledOrOutOfStock) {
      return;
    }

    onOpenProductDetailPage();
  };

  return (
    <React.Fragment>
      <Stack direction={'column'} spacing={'xs'} padding={'hs'} rowGap={'xs'}>
        <Stack direction={'row'} justifyContent={'space-between'} spacing={'m'} onClick={onPressCard}>
          <Stack direction={'column'} spacing={'xs'} paddingTop={'xs'}>
            <Stack direction={'row'} spacing={'xxs'} alignItems={'center'}>
              <Typography variant={'bold'}>{name}</Typography>
              {isFavorite && <FavoriteIcon width={16} height={16} />}
            </Stack>
            <ProductPrice price={price} discount={promoAmount} />
            {!!description && (
              <Typography size={'xs'} lineClamp={3}>
                {description}
              </Typography>
            )}
          </Stack>
          <Stack direction={'row'} spacing={'xs'}>
            {showNotesButton && (
              <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
                <Button
                  minHeight={0}
                  padding={0}
                  size={'small'}
                  onClick={toggleNotesInput}
                  disabled={disabledOrOutOfStock}
                  stopPropagation
                >
                  <Box
                    borderColor={notesButtonColor}
                    borderWidth={'thin'}
                    borderRadius={'default'}
                    width={28}
                    height={28}
                    alignItems={'center'}
                    justifyContent={'center'}
                    display={'flex'}
                  >
                    <ChatFilledIcon color={notesIconColor} />
                  </Box>
                </Button>
              </Stack>
            )}
            <Stack
              minWidth={92}
              direction={'column'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={'xs'}
              position={'relative'}
            >
              <Image
                alt={t('general.alt_product_image', { productName: name, brandName: data?.brand?.name })}
                borderRadius={'default'}
                disabled={disabledOrOutOfStock}
                width={78}
                height={78}
                src={imageUrl}
                objectFit={'cover'}
              />
              {outOfStock && (
                <Stack position={'absolute'} right={-Token.spacing.xs} top={-Token.spacing.m}>
                  <OutOfStockTag />
                </Stack>
              )}
              <ProductTotalInput
                disabledIncrement={outOfStock}
                disabled={disabled}
                onChange={onChangeProductTotalInput}
                value={totalInputProduct}
              />
            </Stack>
          </Stack>
        </Stack>
        {showBorder && <LineSeparator opacity={0.5} color={'lineLight'} />}
        {showProductNotesInput && (
          <Stack direction={'column'} spacing={'xs'}>
            <TextField
              placeholder={t('products.product_comment_placeholder')}
              rows={3}
              multiline
              defaultValue={notes}
              onBlur={onChangeNotes}
            />
            <Button
              stopPropagation
              disabled={disabledOrOutOfStock}
              onClick={saveNotes}
              variant={'contained'}
              color={'primary'}
            >
              {t('products.action_save_label')}
            </Button>
          </Stack>
        )}
      </Stack>

      {openCustomOptionDrawer && (
        <ProductCustomOptionDrawer
          open={openCustomOptionDrawer}
          setOpen={setOpenCustomOptionDrawer}
          productId={product?.id}
          onPressEditCustomItem={onPressEditCustomItem}
          onPressSubmit={onOpenProductDetailPage}
          cartItem={cartItem}
          onChangeCartItem={onChangeCartItem}
          disabled={disabledOrOutOfStock}
          productCategoryId={productCategoryId}
          productCategoryName={productCategoryName}
        />
      )}
    </React.Fragment>
  );
};

export default ProductCard;

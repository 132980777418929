import nookies from 'nookies';
import { PREVIEW_DEV_DOMAIN, SUBDOMAIN_DEFAULT } from '@constants/auth';

const getBrandUrl = () => {
  const host = window.location.host;
  if (process.env.NEXT_PUBLIC_ENABLE_PREVIEW_DEV_DOMAIN === 'true') {
    const cookies = nookies.get();
    const previewDevDomain = cookies[PREVIEW_DEV_DOMAIN];
    const fallBackUrl = process.env.NEXT_PUBLIC_FALLBACK_PREVIEW_DEV_DOMAIN;

    if (previewDevDomain) {
      return previewDevDomain;
    }
    if (fallBackUrl) {
      return fallBackUrl;
    }
  }

  /**
   * If localhost -> expect brand-url.localhost -> FE will replace to brand-url.runchise.id
   * If custom domain -> brand-url.com
   * If subdomain -> brand-url.runchise.id
   */
  const brandUrl =
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
      ? host
      : host.split(':')?.[0].replace('localhost', SUBDOMAIN_DEFAULT);
  return brandUrl;
};

export default getBrandUrl;

import { useTranslation } from 'react-i18next';
import { Tag } from '@components/common';

const OutOfStockTag = () => {
  const { t } = useTranslation();

  return (
    <Tag
      backgroundColor={'uiRedTertiary'}
      textColor={'uiLightPrimary'}
      text={t('products.out_of_stock_label')}
      stackProps={{
        borderRadius: 'form',
        paddingX: 'xs',
        minWidth: 'max-content',
        alignSelf: 'flex-start',
      }}
      textProps={{
        size: 'xs',
      }}
    />
  );
};

export default OutOfStockTag;

import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import { PaymentMethodBankId } from '@api/order/types';
import { IconButton, Image, Popover, Stack, Typography } from '@components/common';
import { CheckmarkIcon, ChevronIcon } from '@components/icons';
import Token from '@constants/token';
import { isMinimumAmountForPaymentMethod } from '@utils/payment';

const POPOVER_ID = 'virtual-account-select';

interface BankOption {
  label: string;
  value: string;
  icon?: string;
}

interface Props {
  value?: string;
  onChange: (value: string) => void;
  options: BankOption[];
  label?: string;
  totalAmount: DecimalNumber;
}

const VirtualAccountSelect: React.FC<Props> = (props) => {
  const { label, value, onChange, options, totalAmount } = props;

  const popupState = usePopupState({ variant: 'popover', popupId: POPOVER_ID, disableAutoFocus: true });
  const { onClick, ...otherPopupState } = bindTrigger(popupState);

  const { onClose } = bindPopover(popupState);

  const selectedVirtualAccount = options?.find((va) => va.value === value);
  const selectedVirtualAccountLabel = selectedVirtualAccount && selectedVirtualAccount?.label;
  const selectedVirtualAccountIcon = selectedVirtualAccount && selectedVirtualAccount?.icon;

  const { t } = useTranslation();

  const renderSelectedVirtualAccount = () => {
    if (!selectedVirtualAccount) {
      return <Typography size={'s'}>{t('order.select_virtual_account_placeholder')}</Typography>;
    }

    return (
      <Stack direction={'row'} alignItems={'center'} maxHeight={60} spacing={'m'}>
        <Image width={50} src={selectedVirtualAccountIcon} />
        <Typography variant={'medium'}>{selectedVirtualAccountLabel}</Typography>
      </Stack>
    );
  };

  const handleOnChangeVirtualAccount = (va: BankOption, isMinimumAmountForPayment: boolean) => () => {
    if (isMinimumAmountForPayment) {
      return;
    }
    onChange?.(va.value);
    onClose();
  };

  const renderOptions = () => {
    return (
      <Stack width={'100%'} spacing={'xxs'} direction={'column'}>
        {options.map((va, index) => {
          const selectedBankValue = va.value as PaymentMethodBankId;
          const isMinimumAmountForPayment = isMinimumAmountForPaymentMethod(
            totalAmount,
            'va-transfer',
            selectedBankValue
          );
          return (
            <Stack
              onClick={handleOnChangeVirtualAccount(va, isMinimumAmountForPayment)}
              background={'uiLightPrimary'}
              paddingX={'hm'}
              paddingY={'m'}
              key={index}
              borderRadius={'default'}
              justifyContent={'space-between'}
              flexDirection={'row'}
              alignItems={'center'}
              borderWidth={'bold'}
              borderColor={va?.value === value ? 'uiSuccessMain' : 'uiLightPrimary'}
              disabled={isMinimumAmountForPayment}
            >
              <Stack direction={'row'} alignItems={'center'} maxHeight={60} spacing={'m'}>
                <Image alt={va.label} width={50} src={va.icon} disabled={isMinimumAmountForPayment} />
                <Typography
                  size={'s'}
                  key={index}
                  color={isMinimumAmountForPayment ? 'uiDarkSecondary' : 'uiDarkPrimary'}
                >
                  {va.label}
                </Typography>
              </Stack>
              {va?.value === value && <CheckmarkIcon />}
            </Stack>
          );
        })}
      </Stack>
    );
  };

  const borderColor = popupState.isOpen ? 'uiPrimaryMain' : 'borderSubtle';
  const backgroundColor = popupState.isOpen ? 'uiLightPrimary' : 'uiLightSecondary';

  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Typography size={'hs'}>{label || t('sessions.input_number_label')}</Typography>
      <Stack direction={'row'} width={'100%'} spacing={'xs'} {...otherPopupState}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderColor={borderColor}
          background={backgroundColor}
          borderWidth={'bold'}
          borderRadius={'default'}
          onClick={onClick}
          paddingX={'xs'}
          maxHeight={56}
          paddingY={'s'}
          width={'100%'}
        >
          {renderSelectedVirtualAccount()}
          <IconButton padding={0} paddingLeft={Token.spacing.xs}>
            <Stack
              borderWidth={'thin'}
              borderColor={'borderSubtle'}
              borderRadius={'default'}
              background={'uiLightPrimary'}
              boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
              width={30}
              height={30}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <ChevronIcon rotate={-90} />
            </Stack>
          </IconButton>
        </Stack>
      </Stack>
      <Popover {...bindPopover(popupState)}>
        <Stack spacing={'xs'}>
          <Stack maxHeight={250} overflow={'auto'} background={'uiLightStain'} padding={'xxs'} borderRadius={'default'}>
            {renderOptions()}
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default VirtualAccountSelect;

import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Image, MenuDialog, Stack } from '@components/common';
import { ArrowIcon, MenuIcon } from '@components/icons';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useQueryBrandInfo } from '@queries/commons';

interface Props {
  onPressBack?: () => void;
}

const OrderDetailNavbar: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onPressBack } = props;
  const [openMenu, setOpenMenu] = useState(false);

  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const { data } = useQueryBrandInfo();

  const handleOnPressBack = () => {
    onPressBack
      ? onPressBack()
      : router.push({
          pathname: routes.ORDER_LIST,
          query,
        });
  };

  const onOpen = () => {
    setOpenMenu(true);
  };

  const onClose = () => {
    setOpenMenu(false);
  };

  const brandName = data?.brand?.name;
  const brandLogo = data?.brand?.logoUrl;

  return (
    <Stack padding={'m'} direction={'row'} justifyContent={'space-between'} background={'uiLightPrimary'} zIndex={1}>
      <IconButton onClick={handleOnPressBack} padding={0}>
        <ArrowIcon />
      </IconButton>

      <Image
        width={30}
        height={30}
        src={brandLogo}
        alt={t('general.alt_logo_image', { brandName })}
        objectFit={'contain'}
      />

      <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
        <IconButton onClick={onOpen} padding={0}>
          <MenuIcon />
        </IconButton>
      </Stack>
      <MenuDialog open={openMenu} onClose={onClose} />
    </Stack>
  );
};

export default OrderDetailNavbar;

import axios, { AxiosRequestConfig } from 'axios';
import applyConverters from 'axios-case-converter';
import { isPast } from 'date-fns';
import { API_SESSION_REFRESH, refreshSession } from '@api/auth';
import config from '@constants/config';
import routes from '@constants/routes';
import { setupSession } from '@hooks/sessions/useSetupSession';
import { getGuestCookies, getUserCookies, handleDestroySession } from '@utils/auth';
import { getBrandUrl } from '@utils/navigator';
import { DefaultError, DefaultErrorOption, DefaultSuccess } from './types';

const converterOptions = {
  preservedKeys: [
    '_destroy',
    'success_action_status',
    'x-amz-credential',
    'x-amz-algorithm',
    'x-amz-date',
    'x-amz-signature',
  ],
};

const baseAPI = axios.create({
  baseURL: config.apiUrl.runchise,
});

const createServerBaseAPI = (axiosOptions: AxiosRequestConfig) =>
  axios.create({
    baseURL: config.apiUrl.runchise,
    ...axiosOptions,
  });

export const defaultQueryOption = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
};

baseAPI.interceptors.request.use(async (config) => {
  const { token: currentToken, expires, refreshToken, isLoggedIn } = getUserCookies();
  const { uuid, param } = getGuestCookies();
  let token = currentToken;
  const brandUrl = getBrandUrl();

  const isExpired = expires && isPast(new Date(expires));

  if (token && isExpired && !config.url?.includes(API_SESSION_REFRESH)) {
    const resRefreshSession = await refreshSession({ accessToken: token, refreshToken });
    setupSession(resRefreshSession);
    if (resRefreshSession?.auth?.accessToken) {
      token = resRefreshSession?.auth?.accessToken;
    }
  }

  config.headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(brandUrl && { 'Brand-URL': brandUrl }),
    ...(!isLoggedIn && uuid && { [param]: uuid }),
    ...config.headers,
  };
  return config;
});

export const handleDefaultSuccess = <T>({ data }: DefaultSuccess<T>) => data;
export const handleDefaultError = <T>(error: DefaultError<T>, options?: DefaultErrorOption) => {
  const responseError = error?.response?.data?.errors || error?.response?.data || error;

  if (error.response?.status === 401 && !options?.disableRedirect) {
    handleDestroySession();
    window.location.href = routes.PROFILE_LOGIN;
    throw responseError;
  }

  throw responseError;
};

export const handleRefreshTokenError = <T>(error: DefaultError<T>) => {
  const responseError = error?.response?.data?.errors || error?.response?.data || error;
  handleDestroySession();
  window.location.href = routes.PROFILE_LOGIN;
  throw responseError;
};

export const serverBaseAPI = (axiosOptions: AxiosRequestConfig) =>
  applyConverters(createServerBaseAPI(axiosOptions), converterOptions);

export default applyConverters(baseAPI, converterOptions);

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
  contentColor?: TokenColor;
}

const ChatFilledIcon: FC<Props> = ({
  width = 14,
  height = 14,
  color = 'uiDarkPrimary',
  contentColor = 'uiLightPrimary',
  ...baseIconProps
}) => {
  const fillColor = Token.color[color];
  const fillContentColor = Token.color[contentColor];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 14 14'}>
        <path
          fill={fillColor}
          d={
            'M13.8564 6.40813C13.7285 4.4742 12.7695 2.66813 11.2512 1.46941C9.73278 0.27069 7.7509 -0.22478 5.83295 0.094878C2.41261 0.686246 -0.0967096 3.85086 0.143034 7.33514C0.254914 9.01334 0.958162 10.5637 2.1409 11.7464C1.83722 12.1939 1.56551 12.4816 0.846282 13.057C0.702436 13.1689 0.638504 13.3447 0.686453 13.5205C0.718419 13.6963 0.846282 13.8402 1.00611 13.8881C1.24585 13.968 1.59748 14 1.99705 14C2.84415 14 3.915 13.8082 4.74611 13.3767C5.84893 13.7603 7.03167 13.8562 8.19842 13.6484C11.6188 13.041 14.0961 9.86044 13.8564 6.40813ZM3.80312 8.1183C3.17979 8.1183 2.68432 7.62283 2.68432 6.9995C2.68432 6.37616 3.17979 5.88069 3.80312 5.88069C4.42645 5.88069 4.92192 6.37616 4.92192 6.9995C4.92192 7.62283 4.42645 8.1183 3.80312 8.1183ZM6.9997 8.1183C6.37637 8.1183 5.8809 7.62283 5.8809 6.9995C5.8809 6.37616 6.37637 5.88069 6.9997 5.88069C7.62304 5.88069 8.11851 6.37616 8.11851 6.9995C8.11851 7.62283 7.62304 8.1183 6.9997 8.1183ZM10.1963 8.1183C9.57295 8.1183 9.07748 7.62283 9.07748 6.9995C9.07748 6.37616 9.57295 5.88069 10.1963 5.88069C10.8196 5.88069 11.3151 6.37616 11.3151 6.9995C11.3151 7.62283 10.8196 8.1183 10.1963 8.1183Z'
          }
        />
        <defs>
          <rect width={width} height={height} fill={fillContentColor} />
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default ChatFilledIcon;

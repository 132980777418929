import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useCheckDineIn } from '@hooks/dineIn';
import cartStore from '@storage/cartStore';
import { BranchCart, CartItem } from '@storage/types';

const useCartStore = (branchId?: number, initialValue?: BranchCart) => {
  const { isOpenBill, isClosedBill } = useCheckDineIn();

  const orderType = useMemo(() => {
    if (isOpenBill && !isClosedBill) {
      return 'open_bill';
    }

    if (!isOpenBill && isClosedBill) {
      return 'close_bill';
    }

    return 'online_ordering';
  }, [isClosedBill, isOpenBill]);

  const queryKeys = useMemo(
    () => [
      {
        key: 'getCartStore',
        payload: {
          branchId,
        },
      },
    ],
    [branchId]
  );

  const {
    refetch,
    isLoading,
    isSuccess: isFinishInitiated,
    data: cartStoreData,
  } = useQuery(queryKeys, ({ queryKey: [{ payload }] }) => cartStore.getItem(payload.branchId, orderType), {
    enabled: !!branchId,
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  const branchCart = useMemo(() => cartStoreData ?? initialValue ?? {}, [cartStoreData, initialValue]);

  /**
   * Method to auto add/remove/update cartItem with productId.
   * This method will automatically add/remove/update product
   * It will remove the product when we put the qty as zero
   * @constructor
   * @param {number} productId - The Id of product that we want to add/remove/update
   * @param {CartItem} item - The item that we want to add/remove/update from product based on productId.
   */
  const updateProductCartItem = useCallback(
    async (productId: number, item: CartItem) => {
      if (!branchId) {
        return;
      }

      const highestSequence = Object.keys(branchCart).reduce((curHighestSeq, pId) => {
        const curProduct = branchCart[Number(pId)] as CartItem;
        const curSequence = curProduct?.sequence || 0;
        if (curHighestSeq < curSequence) {
          return curSequence;
        }
        return curHighestSeq;
      }, 0);

      const sequence = item?.sequence ?? highestSequence + 1;

      const filteredItem: CartItem = {
        ...item,
        sequence,
        customItems: item?.customItems?.filter((item) => !!item.qty),
      };

      const hasCustomOptionCartItem = filteredItem?.customItems?.length;

      let newBranchCart = {
        ...branchCart,
        [productId]: filteredItem,
      };

      if (!item?.qty && !hasCustomOptionCartItem) {
        newBranchCart = omit(branchCart, productId);
      }

      await cartStore.setItem(branchId, newBranchCart, orderType);

      refetch();
    },
    [branchCart, branchId, orderType, refetch]
  );

  const clearCart = useCallback(
    async (branchId?: number) => {
      if (branchId && orderType) {
        await cartStore.deleteItem(branchId, orderType);
        return;
      }

      await cartStore.clear();
    },
    [orderType]
  );

  return {
    isLoading,
    isFinishInitiated,
    branchCart,
    updateProductCartItem,
    clearCart,
  };
};

export default useCartStore;

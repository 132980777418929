import { CSSProperties, ReactNode } from 'react';
import { TokenType } from '@interfaces/token';
import { Typography as MDTypography, TypographyProps as MUITypographyProps, useTheme } from '@mui/material';
import getTokenColorFromPaletteOptions from '@utils/theme/getTokenColorFromPaletteOptions';
import styles from './styles';

interface BaseProps {
  variant?: TokenType['fontWeight'];
  size?: TokenType['fontSize'];
  color?: TokenType['color'];
  fontFamily?: TokenType['typography'];
  transform?: 'uppercase' | 'lowercase';
  wordSpacing?: TokenType['spacing'];
  /**
   * @param {number} lineClamp
   * Used to truncate lines of words.
   * If you want to truncate 1 long word, you need to use `truncateWord` props
   */
  lineClamp?: number;
  /**
   * @param {boolean} truncateWord
   * Used to truncateWord 1 long word.
   */
  truncateWord?: boolean;
  /**
   * @param {boolean} showMouseOverText
   * Used to show children value as title when user mouse over the component
   */
  showMouseOverText?: boolean;
  lineHeight?: CSSProperties['lineHeight'];
  opacity?: number;
  align?: 'center' | 'left' | 'right';
  textDecoration?: CSSProperties['textDecoration'];
  textDecorationColor?: CSSProperties['textDecorationColor'];
  flexShrink?: CSSProperties['flexShrink'];
  isHTML?: boolean;
  children?: ReactNode | string;
  component?: 'div' | 'span' | 'p';
  fontStyle?: CSSProperties['fontStyle'];
}

export type TypographyProps = Omit<MUITypographyProps, 'variant' | 'color' | 'fontWeight' | 'fontSize'> & BaseProps;

const Typography = (props: TypographyProps) => {
  const theme = useTheme();
  const colorPalette = getTokenColorFromPaletteOptions(theme.palette);

  const {
    variant = 'regular',
    size = 's',
    color,
    transform,
    fontFamily = 'DEFAULT',
    wordSpacing,
    lineClamp,
    truncateWord,
    opacity,
    align,
    textDecoration,
    textDecorationColor,
    flexShrink,
    isHTML,
    children,
    fontStyle,
    ...otherProps
  } = props;

  const css = [
    styles.fontFamily(fontFamily),
    styles.variant(variant),
    styles.size(size),
    color && styles.color(color, colorPalette),
    opacity && styles.opacity(opacity),
    transform && styles.transform(transform),
    wordSpacing && styles.wordSpacing(wordSpacing),
    lineClamp && styles.lineClamp(lineClamp),
    align && styles.align(align),
    truncateWord && styles.truncateWord,
    textDecoration && styles.textDecoration(textDecoration),
    textDecorationColor && styles.textDecorationColor(textDecorationColor),
    typeof flexShrink !== 'undefined' && styles.flexShrink(flexShrink),
    styles.fontStyle(fontStyle),
  ];

  if (isHTML && typeof children === 'string') {
    return <MDTypography css={css} dangerouslySetInnerHTML={{ __html: children }} {...otherProps} />;
  }

  return (
    <MDTypography css={css} {...otherProps}>
      {children}
    </MDTypography>
  );
};

export default Typography;

import { CSSProperties, ReactNode } from 'react';
import { ClickAwayListener, Popper as MuiPopper, PopperProps as MuiPopperProps, Paper } from '@mui/material';
import styles from './styles';

export interface PopperProps {
  anchorRef?: HTMLInputElement | null;
  children?: ReactNode;
  width?: string;
  maxHeight?: CSSProperties['maxHeight'];
  minWidth?: CSSProperties['minWidth'];
  maxWidth?: CSSProperties['maxHeight'];
  handleClickAway: (event: MouseEvent | TouchEvent) => void;
  placement?: MuiPopperProps['placement'];
}

const Popper = (props: PopperProps) => {
  if (props.anchorRef) {
    return (
      <MuiPopper
        anchorEl={props.anchorRef}
        open={Boolean(props.anchorRef)}
        placement={props?.placement ?? 'bottom-start'}
        css={styles.popperWrapper}
      >
        <ClickAwayListener onClickAway={props.handleClickAway}>
          <Paper
            elevation={2}
            css={[
              {
                width: props.width || props.anchorRef?.offsetWidth || 'auto',
                ...(props.maxHeight ? { maxHeight: props.maxHeight } : {}),
                ...(props.minWidth ? { minWidth: props.minWidth } : {}),
                ...(props.maxWidth ? { maxWidth: props.maxWidth } : {}),
              },
              styles.modalWrapper,
            ]}
          >
            {props.children}
          </Paper>
        </ClickAwayListener>
      </MuiPopper>
    );
  }

  return null;
};

export default Popper;

import { useMemo } from 'react';
import { useGuestStore } from '@hooks/storage';
import { getUserCookies } from '@utils/auth';

const useCheckUserOrGuest = () => {
  const { isLoggedIn } = getUserCookies();
  const { guestData } = useGuestStore();

  const isUserOrGuest = useMemo(() => {
    if (isLoggedIn) {
      return isLoggedIn;
    }

    return !!guestData;
  }, [guestData, isLoggedIn]);

  return {
    isUserOrGuest,
  };
};

export default useCheckUserOrGuest;

import { Ref, forwardRef } from 'react';
import { TokenSpacing } from '@interfaces/token';
import { CheckboxProps } from '@mui/material';
import { Checkbox as MuiCheckbox } from '@mui/material';
import styles from './styles';

interface Props extends Pick<CheckboxProps, 'checked' | 'onChange' | 'disabled'> {
  paddingRight?: TokenSpacing;
}

const Checkbox = forwardRef(function StackRef(
  { checked, onChange, paddingRight, disabled }: Props,
  ref?: Ref<HTMLButtonElement>
) {
  return (
    <MuiCheckbox
      ref={ref}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      css={[styles.container, ...(paddingRight ? [styles.paddingRight(paddingRight)] : [])]}
    />
  );
});

export default Checkbox;

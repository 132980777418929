import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { CLOSED_BILL_QUERY_PARAMS, OPEN_BILL_QUERY_PARAMS } from '@constants/dineIn';

const useCheckDineIn = () => {
  const { query, isReady } = useRouter();
  const openBillToken = useMemo(() => {
    const param = query?.[OPEN_BILL_QUERY_PARAMS];
    return Array.isArray(param) ? param[0] : param;
  }, [query]);

  const closedBillToken = useMemo(() => {
    const param = query?.[CLOSED_BILL_QUERY_PARAMS];
    return Array.isArray(param) ? param[0] : param;
  }, [query]);

  return useMemo(() => {
    const isOpenBill = !!openBillToken;
    const isClosedBill = !!closedBillToken;

    return {
      isReady,
      isDineIn: isOpenBill || isClosedBill,
      isOpenBill,
      isClosedBill,
      openBillToken,
      closedBillToken,
    };
  }, [closedBillToken, isReady, openBillToken]);
};

export default useCheckDineIn;

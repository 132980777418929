import { pickBy } from 'lodash';
import { CSSProperties, ReactNode, Ref, forwardRef } from 'react';
import Token from '@constants/token';
import { IconButtonProps, default as MUIIconButton } from '@mui/material/IconButton';

interface Props {
  children: ReactNode;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];
  borderRadius?: CSSProperties['borderRadius'];
  padding?: CSSProperties['padding'];
  paddingRight?: CSSProperties['paddingRight'];
  paddingLeft?: CSSProperties['paddingLeft'];
}

const IconButton = forwardRef(function ButtonRef(
  { children, ...props }: Props & IconButtonProps,
  ref?: Ref<HTMLButtonElement>
) {
  const {
    width,
    height,
    minHeight,
    minWidth,
    borderRadius = Token.borderRadius.default,
    padding,
    paddingRight,
    paddingLeft,
    onClick,
  } = props;

  const styles = pickBy(
    {
      width,
      height,
      minHeight,
      minWidth,
      borderRadius,
      padding,
      paddingRight,
      paddingLeft,
    },
    (el: unknown) => el !== undefined
  );

  return (
    <MUIIconButton style={styles} ref={ref} onClick={onClick} {...props}>
      {children}
    </MUIIconButton>
  );
});

export default IconButton;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const LanguageIcon: FC<Props> = ({ width = 22, height = 24, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 24 24'} fill={'none'}>
        <path
          d={
            'M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
          }
          stroke={fillColor}
          strokeWidth={'2'}
          strokeLinecap={'square'}
        />
        <path
          d={
            'M10.7518 20.6802C11.4002 21.4817 12.5998 21.4817 13.2482 20.6802C15.0827 18.4122 16 15.5188 16 12C16 8.4812 15.0827 5.58781 13.2482 3.31984C12.5998 2.51829 11.4002 2.51829 10.7518 3.31984C8.91728 5.58781 8 8.4812 8 12C8 15.5188 8.91728 18.4122 10.7518 20.6802Z'
          }
          stroke={fillColor}
          strokeWidth={'2'}
          strokeLinecap={'round'}
        />
        <path d={'M2.5 9H21.5M2.5 15H21.5'} stroke={fillColor} strokeWidth={'2'} strokeLinecap={'round'} />
      </svg>
    </BaseIcon>
  );
};

export default LanguageIcon;

import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Stack, Typography } from '@components/common';
import { CardActionArea } from '@mui/material';
import { toNumber } from '@utils/number';

interface OptionButtonProps {
  label?: string;
  description?: string;
  isSelected?: boolean;
  onPress?: () => void;
  outOfStockFlag?: boolean;
}

const checkboxHeight = 24;
const minimumPriceWidth = 53;

const OptionButtonListItem: FC<OptionButtonProps> = (props) => {
  const { label, description, isSelected, onPress, outOfStockFlag } = props;
  const { t } = useTranslation();
  const fontColor = useMemo(() => {
    if (outOfStockFlag) {
      return 'textMuted' as const;
    }

    return isSelected ? 'uiPrimaryMain' : ('uiDarkPrimary' as const);
  }, [isSelected, outOfStockFlag]);

  const showDescription = description && !!toNumber(description);

  const renderDescription = () => {
    if (outOfStockFlag) {
      return (
        <Typography color={'uiRedTertiary'} size={'xs'} textAlign={'right'}>
          {t('products.out_of_stock_label')}
        </Typography>
      );
    }

    if (showDescription && !outOfStockFlag) {
      return (
        <Typography color={'uiDarkPrimary'} size={'xs'} textAlign={'right'}>
          {description}
        </Typography>
      );
    }
  };

  return (
    <Stack columnGap={'xs'} flexDirection={'row'} alignItems={'center'}>
      <CardActionArea style={{ height: '100%' }} onClick={onPress} disabled={outOfStockFlag}>
        <Stack
          alignItems={'flex-start'}
          paddingX={'xxs'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          columnGap={'xs'}
        >
          <Stack alignItems={'flex-start'} flexDirection={'row'} columnGap={'xs'} overflow={'hidden'}>
            <Checkbox checked={isSelected} disabled={outOfStockFlag} />
            <Stack overflow={'hidden'} flexWrap={'wrap'} minHeight={checkboxHeight} justifyContent={'center'}>
              <Typography size={'hs'} color={fontColor} variant={isSelected ? 'bold' : 'regular'}>
                {label}
              </Typography>
            </Stack>
          </Stack>

          <Stack height={checkboxHeight} justifyContent={'center'} minWidth={minimumPriceWidth}>
            {renderDescription()}
          </Stack>
        </Stack>
      </CardActionArea>
    </Stack>
  );
};

export default OptionButtonListItem;

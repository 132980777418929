import { CookieSerializeOptions } from 'cookie';
import { NextApiResponse, NextPageContext } from 'next';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import {
  DINE_IN_TYPE,
  GUEST_CLOSE_BILL_UUID_KEY,
  GUEST_OPEN_BILL_UUID_KEY,
  TOKEN_COOKIE_KEY,
  TOKEN_EXPIRES_COOKIE_KEY,
  TOKEN_REFRESH_COOKIE_KEY,
} from '@constants/auth';
import { SHOWN_NOTIFICATION_DENIED_ALERT_FLAG } from '@constants/common';
import cartStore from '@storage/cartStore';
import commonStore from '@storage/commonStore';
import { resetUser } from './sentry';

export const handleDestroySession = () => {
  destroyCookie({}, TOKEN_COOKIE_KEY, { path: '/' });
  destroyCookie({}, TOKEN_REFRESH_COOKIE_KEY, { path: '/' });
  destroyCookie({}, TOKEN_EXPIRES_COOKIE_KEY, { path: '/' });
  window.sessionStorage.removeItem(SHOWN_NOTIFICATION_DENIED_ALERT_FLAG);
  cartStore.clear();
  commonStore.clear();
  resetUser();
};

/**
 * This is internal setCookie, the reason why we extends it to custom util so we can test cookie in localhost Safari.
 * By default in Safari, if the cookie is set as secure but it's not https, the cookie will be gone, making it harder to develop on Safari.
 */
export const setInternalCookie = (
  _ctx:
    | Pick<NextPageContext, 'res'>
    | {
        res: NextApiResponse;
      }
    | null
    | undefined,
  name: string,
  value: string,
  options?: CookieSerializeOptions
) => {
  const isSecureProtocol = window.location.protocol?.includes('https');

  return setCookie(null, name, value, {
    ...options,
    secure: isSecureProtocol,
  });
};

export const getUserCookies = () => {
  const cookies = parseCookies();
  const token = cookies[TOKEN_COOKIE_KEY];
  const expires = cookies[TOKEN_EXPIRES_COOKIE_KEY];
  const refreshToken = cookies[TOKEN_REFRESH_COOKIE_KEY];

  return {
    isLoggedIn: !!token,
    token,
    expires,
    refreshToken,
  };
};

export const getGuestCookies = () => {
  const cookies = parseCookies();
  const dineInType = cookies[DINE_IN_TYPE];
  if (dineInType === 'open_bill') {
    const uuid = cookies[GUEST_OPEN_BILL_UUID_KEY];

    return {
      uuid,
      param: 'open-bill-uuid',
    };
  }

  const uuid = cookies[GUEST_CLOSE_BILL_UUID_KEY];
  return {
    uuid,
    param: 'closed-bill-token',
  };
};

import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodType } from '@api/order/types';
import { DollarIcon } from '@components/icons';
import { ONLINE_ORDER_PAYMENT_METHOD_TYPES } from '@constants/order';
import { useQueryDineInSetting } from '@queries/dineIn';
import { getPaymentMethodCRKey, getXenditPaymentMethodIcon } from '@utils/payment';

interface PaymentMethodOption {
  type: PaymentMethodType;
  icon: ReactNode;
  label: string;
}

const useAllPaymentMethodOptions = ({ allowCash = false, isDineIn = false }): PaymentMethodOption[] => {
  const { t } = useTranslation();
  const { data } = useQueryDineInSetting({
    enabled: isDineIn,
  });

  const disabledCC = useMemo(() => {
    if (isDineIn) {
      return data?.paymentMethods.creditCard.enable;
    }

    return true;
  }, [data?.paymentMethods.creditCard.enable, isDineIn]);

  return useMemo(() => {
    const paymentMethods = ONLINE_ORDER_PAYMENT_METHOD_TYPES.map((type) => {
      if (type === 'va-transfer') {
        return {
          enable: isDineIn ? data?.paymentMethods.virtualAccount.enable : true,
          type: 'va-transfer' as PaymentMethodType,
        };
      }

      if (type === 'credit-card') {
        return {
          enable: disabledCC,
          type: 'credit-card' as PaymentMethodType,
        };
      }

      if (type === 'gopay') {
        return {
          enable: isDineIn ? data?.paymentMethods.gopay.enable : true,
          type: 'gopay' as PaymentMethodType,
        };
      }

      if (type === 'ovo') {
        return {
          enable: isDineIn ? data?.paymentMethods.ovo.enable : true,
          type: 'ovo' as PaymentMethodType,
        };
      }

      if (type === 'dana') {
        return {
          enable: isDineIn ? data?.paymentMethods.dana.enable : true,
          type: 'dana' as PaymentMethodType,
        };
      }

      if (type === 'shopeepay') {
        return {
          enable: isDineIn ? data?.paymentMethods.shopeepay.enable : true,
          type: 'shopeepay' as PaymentMethodType,
        };
      }

      if (type === 'qris') {
        return {
          enable: isDineIn ? data?.paymentMethods.qris.enable : true,
          type: 'qris' as PaymentMethodType,
        };
      }

      return {
        enable: true,
        type,
      };
    });

    const onlineDeliveryOptions = paymentMethods
      .filter((paymentMethod) => paymentMethod.enable)
      .map((paymentMethod) => ({
        type: paymentMethod.type,
        icon: getXenditPaymentMethodIcon({ type: paymentMethod.type }),
        label: t(getPaymentMethodCRKey(paymentMethod.type)),
      }));

    if ((isDineIn && data?.paymentMethods.viaCashier.enable) || allowCash) {
      const cashierOption = {
        type: 'cash' as const,
        icon: <DollarIcon height={24} />,
        label: t('dine_in.cashier_label'),
      };

      return [cashierOption, ...onlineDeliveryOptions];
    }

    return onlineDeliveryOptions;
  }, [
    isDineIn,
    data?.paymentMethods.viaCashier.enable,
    data?.paymentMethods.virtualAccount.enable,
    data?.paymentMethods.gopay.enable,
    data?.paymentMethods.ovo.enable,
    data?.paymentMethods.dana.enable,
    data?.paymentMethods.shopeepay.enable,
    data?.paymentMethods.qris.enable,
    allowCash,
    disabledCC,
    t,
  ]);
};

export default useAllPaymentMethodOptions;

import { anchorRef, bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { LocationDetail } from '@api/locations/types';
import { Button, IconButton, OptionCardListLoader, Popover, Stack, Typography } from '@components/common';
import { ChevronIcon, LocationMarkerIcon } from '@components/icons';
import { LocationCard, LocationStatusBadge, NoLocationCard } from '@components/locations';
import routes from '@constants/routes';
import { useCurrentPosition } from '@hooks/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useCommonStore } from '@hooks/storage';
import { Divider } from '@mui/material';
import { useQueryLocations } from '@queries/locations';
import LocationOpeningHourDrawer from '../LocationOpeningHourDrawer';
import { ProductSelectLocationLoader } from '../loaders';

const POPOVER_ID = 'location-select-popup';

interface Props {
  hideLocationStatus?: boolean;
  markerIcon?: React.ReactElement;
  hideMarkerIcon?: boolean;
  hideLocationAddress?: boolean;
  disabled?: boolean;
  /**
   * If set to false, the location permission disabled snackbar will not shown
   */
  enableLocationWarning?: boolean;
}

const ProductSelectLocation: React.FC<Props> = ({
  hideLocationStatus,
  markerIcon = <LocationMarkerIcon />,
  hideMarkerIcon,
  hideLocationAddress,
  disabled = false,
  enableLocationWarning = true,
}) => {
  const router = useRouter();
  const { storageState, updateStorage, isFinishInitiated } = useCommonStore();
  const popupState = usePopupState({ variant: 'popover', popupId: POPOVER_ID });
  const { onClose } = bindPopover(popupState);
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);
  const [isNeedValidateLocation, setIsNeedValidateLocation] = useState(true);
  const { isDineIn } = useCheckDineIn();

  const [showLocationOpenHourDrawer, setShowLocationOpenHourDrawer] = useState(false);

  const {
    isAlreadyRequestLocation,
    currentPosition,
    isLoading: isRequestingPosition,
  } = useCurrentPosition({
    enabled: !disabled && isFinishInitiated,
    onError: ({ message = t('error.general_error_label') }) => {
      console.error('ProductSelectLocation useCurrentPosition', message);

      if (enableLocationWarning) {
        snackbarContext?.openSnackbar?.({
          message: t('general.location_permission_disabled'),
          alertProps: {
            severity: 'info',
          },
        });
      }
    },
  });

  const activeLocation = storageState.activeLocation;
  const activeLocationId = activeLocation?.id;

  const { isOpen, locationDetail: { openingHour, address } = {} } = useRedirectByLocationSchedule({
    locationId: activeLocationId,
    skipRedirect: true,
  });

  const branchStatus = isOpen ? 'open' : 'closed';

  const locationsRequest = useMemo(() => {
    const parsedLat = Number(currentPosition?.coords?.latitude || 0);
    const parsedLng = Number(currentPosition?.coords?.longitude || 0);
    if (typeof parsedLat === 'number' && typeof parsedLng === 'number' && parsedLat && parsedLng) {
      return {
        latitude: parsedLat?.toString(),
        longitude: parsedLng?.toString(),
      };
    }

    return {
      latitude: undefined,
      longitude: undefined,
    };
  }, [currentPosition?.coords?.latitude, currentPosition?.coords?.longitude]);

  const isReadyToFetchLocation = isFinishInitiated && isAlreadyRequestLocation;
  const { data, isFetching } = useQueryLocations(locationsRequest, {
    enabled: (!disabled && isReadyToFetchLocation && !activeLocationId) || popupState.isOpen || isNeedValidateLocation,
  });

  useEffect(() => {
    const firstLocationResponse = data?.locations?.[0];
    if (firstLocationResponse && !activeLocationId) {
      updateStorage({ activeLocation: firstLocationResponse });
      return;
    } else if (isNeedValidateLocation && activeLocationId && data?.locations.length && !disabled && !isDineIn) {
      const validatedLocation = data.locations.some((location) => location.id === activeLocationId);

      if (!validatedLocation) {
        updateStorage({ activeLocation: undefined });
        setIsNeedValidateLocation(false);
        router.push(routes.LOCATIONS);
        return;
      }
    }

    if (router.pathname !== routes.LOCATIONS && data?.locations?.length === 0 && !isDineIn) {
      router.push(routes.LOCATIONS);
    }
  }, [activeLocationId, data?.locations, disabled, isDineIn, isNeedValidateLocation, router, updateStorage]);

  const locationList = useMemo(() => data?.locations || [], [data?.locations]);

  const onPress = useCallback(
    (location: LocationDetail) => () => {
      updateStorage({ activeLocation: location });
      onClose();
    },
    [onClose, updateStorage]
  );

  const handleShowOpenHourDrawer = () => setShowLocationOpenHourDrawer(true);
  const handleHideOpenHourDrawer = () => setShowLocationOpenHourDrawer(false);

  useEffect(() => {
    if (isFinishInitiated && !storageState.activeLocation?.id && router?.pathname !== routes.LOCATIONS && !isDineIn) {
      router.push(routes.LOCATIONS);
    }
  }, [isDineIn, isFinishInitiated, router, storageState.activeLocation?.id]);

  const locationNameSize = hideLocationAddress ? 's' : 'hs';
  const popperTriggerState = useMemo(() => {
    const triggerState = bindTrigger(popupState);

    return {
      ...triggerState,
      onClick: disabled ? undefined : triggerState.onClick,
    };
  }, [disabled, popupState]);

  const renderOptions = useCallback(() => {
    if (isFetching) {
      return <OptionCardListLoader />;
    }

    if (locationList?.length === 0) {
      return (
        <Stack width={'100%'} direction={'column'} spacing={'xxs'} borderRadius={'default'}>
          <NoLocationCard />
        </Stack>
      );
    }

    return (
      <Stack width={'100%'} direction={'column'} spacing={'xxs'} borderRadius={'default'}>
        {locationList.map((location, index) => (
          <LocationCard key={index} onPress={onPress(location)} location={location} />
        ))}
      </Stack>
    );
  }, [isFetching, locationList, onPress]);

  if (!isFinishInitiated || isRequestingPosition) {
    return <ProductSelectLocationLoader hideLocationStatus={hideLocationStatus} />;
  }

  return (
    <Stack direction={'column'} spacing={'xs'} {...anchorRef(popupState)} disabled={disabled}>
      <React.Fragment>
        <Stack direction={'column'} spacing={'xs'} {...popperTriggerState}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Stack direction={'row'} alignItems={'center'} spacing={'xs'} flexGrow={1} overflow={'hidden'}>
              {hideMarkerIcon ? null : markerIcon}
              <Stack direction={'column'} flexGrow={1} overflow={'hidden'}>
                <Typography noWrap textOverflow={'ellipsis'} size={locationNameSize} variant={'bold'}>
                  {activeLocation?.name}
                </Typography>
                {!hideLocationAddress && (
                  <Typography noWrap textOverflow={'ellipsis'} size={'xs'}>
                    {activeLocation?.address}
                  </Typography>
                )}
              </Stack>
            </Stack>
            {data?.locations.length !== 0 && (
              <IconButton padding={0} disabled={disabled}>
                <Stack borderWidth={'bolder'} padding={'xxs'} borderColor={'borderSubtle'} borderRadius={'default'}>
                  <ChevronIcon rotate={popupState.isOpen ? -180 : 0} />
                </Stack>
              </IconButton>
            )}
          </Stack>
          <Divider />
        </Stack>
        <Popover {...bindPopover(popupState)}>
          <Stack background={'borderSubtle'} padding={'xxs'} borderRadius={'default'} maxHeight={300} overflow={'auto'}>
            {renderOptions()}
          </Stack>
        </Popover>
      </React.Fragment>

      {!hideLocationStatus && (
        <Stack direction={'row'} spacing={'hs'} alignItems={'center'}>
          <LocationStatusBadge status={branchStatus} />
          <Button onClick={handleShowOpenHourDrawer} padding={0} variant={'text'} color={'primary'}>
            <Typography size={'hs'}>{t('products.see_location_detail_label')}</Typography>
          </Button>
        </Stack>
      )}

      {showLocationOpenHourDrawer && (
        <LocationOpeningHourDrawer
          address={address}
          isOpen={showLocationOpenHourDrawer}
          openingHour={openingHour}
          onClose={handleHideOpenHourDrawer}
          onOpen={handleShowOpenHourDrawer}
        />
      )}
    </Stack>
  );
};

export default ProductSelectLocation;

import { useCallback, useEffect, useState } from 'react';
import { REQUEST_LOCATION_TIMEOUT } from '@constants/common';

interface Props {
  enabled?: boolean;
  onSuccess?: PositionCallback;
  onError?: PositionErrorCallback;
}

const useCurrentPosition = ({ enabled, onSuccess, onError }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAlreadyRequestLocation, setIsAlreadyRequestLocation] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();

  const handleSuccess = useCallback(
    (position: GeolocationPosition) => {
      setCurrentPosition(position);
      onSuccess && onSuccess(position);
      setIsError(false);
      setIsLoading(false);
      setIsAlreadyRequestLocation(true);
    },
    [onSuccess]
  );

  const handleError = useCallback(
    (positionError: GeolocationPositionError) => {
      setIsError(true);
      onError && onError(positionError);
      setIsLoading(false);
      setIsAlreadyRequestLocation(true);
    },
    [onError]
  );

  useEffect(() => {
    if (enabled && navigator?.geolocation && !currentPosition && !isError) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
        timeout: REQUEST_LOCATION_TIMEOUT,
      });
    }
  }, [enabled, currentPosition, handleSuccess, handleError, isError]);

  return {
    currentPosition,
    isLoading,
    isError,
    isAlreadyRequestLocation,
  };
};

export default useCurrentPosition;

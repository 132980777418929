import { useEffect, useState } from 'react';
import { CustomerOrderDetail } from '@api/order/types';

const useOrderCountdownTimer = (timerInSeconds: number, status?: CustomerOrderDetail['status'], startTime = '') => {
  const [timeLeft, setTimeLeft] = useState('');

  useEffect(() => {
    if (status === 'waiting_restaurant_confirmation') {
      const countdown = setInterval(() => {
        const now = new Date();
        const baseTime = new Date(startTime);

        const difference = now.getTime() - baseTime.getTime();

        const totalSecondsLeft = Math.max(timerInSeconds - Math.floor(difference / 1000), 0);
        const minutesLeft = Math.floor(totalSecondsLeft / 60);
        const secondsLeft = totalSecondsLeft % 60;

        const formattedTime = `${String(minutesLeft).padStart(2, '0')}:${String(secondsLeft).padStart(2, '0')}`;

        setTimeLeft(formattedTime);

        if (totalSecondsLeft <= 0) {
          clearInterval(countdown);
        }
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    }
  }, [status, startTime, timerInSeconds]);

  return timeLeft;
};

export default useOrderCountdownTimer;

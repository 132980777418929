import Token from '@constants/token';
import { css } from '@emotion/react';

const container = (size: number) => css`
  --child-height: ${size}px;
  margin-bottom: ${Token.spacing.xl}px;
`;

const styles = {
  container,
};

export default styles;

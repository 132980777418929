import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const ChatIcon: FC<Props> = ({ width = 24, height = 24, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 24 24'}>
        <path
          color={fillColor}
          d={
            'M12 0C5.36792 0 0 5.367 0 12C0 14.1048 0.54895 16.1605 1.59137 17.9736L0.0452271 22.775C-0.0626221 23.1097 0.026001 23.4767 0.274658 23.7253C0.520935 23.9716 0.886963 24.0635 1.22498 23.9548L6.02637 22.4086C7.83948 23.451 9.8952 24 12 24C18.6321 24 24 18.633 24 12C24 5.36792 18.633 0 12 0ZM12 22.125C10.0988 22.125 8.24561 21.5945 6.64069 20.5909C6.40979 20.4467 6.12305 20.4077 5.85626 20.4935L2.39026 21.6097L3.50647 18.1437C3.59106 17.8808 3.55536 17.5937 3.40887 17.3593C2.40546 15.7544 1.875 13.9012 1.875 12C1.875 6.41711 6.41711 1.875 12 1.875C17.5829 1.875 22.125 6.41711 22.125 12C22.125 17.5829 17.5829 22.125 12 22.125ZM13.1719 12C13.1719 12.6471 12.6473 13.1719 12 13.1719C11.3527 13.1719 10.8281 12.6471 10.8281 12C10.8281 11.3527 11.3527 10.8281 12 10.8281C12.6473 10.8281 13.1719 11.3527 13.1719 12ZM17.8594 12C17.8594 12.6471 17.3348 13.1719 16.6875 13.1719C16.0402 13.1719 15.5156 12.6471 15.5156 12C15.5156 11.3527 16.0402 10.8281 16.6875 10.8281C17.3348 10.8281 17.8594 11.3527 17.8594 12ZM8.48438 12C8.48438 12.6471 7.95978 13.1719 7.3125 13.1719C6.66541 13.1719 6.14062 12.6471 6.14062 12C6.14062 11.3527 6.66541 10.8281 7.3125 10.8281C7.95978 10.8281 8.48438 11.3527 8.48438 12Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default ChatIcon;

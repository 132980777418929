import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const SwipeUpIcon: FC<Props> = ({ width = 15, height = 22, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 15 22'} fill={'none'}>
        <path
          d={
            'M14.8036 10.9564C14.5329 11.2372 14.08 11.2476 13.7943 10.9836L7.49914 5.15246L1.20471 10.9836C0.918986 11.2483 0.466829 11.2372 0.1954 10.9564C-0.0760292 10.6763 -0.0639006 10.2342 0.222558 9.9688L7.00843 3.68293C7.14557 3.55581 7.32272 3.49156 7.49914 3.49156C7.67557 3.49156 7.85342 3.55581 7.99059 3.68293L14.7765 9.9688C15.0629 10.2342 15.0751 10.6763 14.8036 10.9564Z'
          }
          fill={fillColor}
        />
        <g opacity={'0.8'} clipPath={'url(#clip1_1176_8691)'}>
          <path
            d={
              'M12.7165 15.1604C12.5231 15.361 12.1996 15.3684 11.9955 15.1799L7.49897 11.0147L3.00295 15.1798C2.79886 15.3689 2.47589 15.3609 2.28201 15.1604C2.08813 14.9603 2.0968 14.6445 2.30141 14.455L7.14846 9.96507C7.24642 9.87426 7.37295 9.82837 7.49897 9.82837C7.62499 9.82837 7.75203 9.87426 7.85 9.96507L12.6971 14.455C12.9017 14.6446 12.9103 14.9603 12.7165 15.1604Z'
            }
            fill={fillColor}
          />
        </g>
        <g opacity={'0.6'} clipPath={'url(#clip2_1176_8691)'}>
          <path
            d={
              'M11.6729 19.8801C11.5182 20.0405 11.2594 20.0465 11.0961 19.8956L7.49888 16.5635L3.90206 19.8956C3.73879 20.0469 3.48042 20.0405 3.32532 19.8801C3.17021 19.72 3.17714 19.4674 3.34083 19.3157L7.21848 15.7238C7.29684 15.6512 7.39807 15.6145 7.49888 15.6145C7.5997 15.6145 7.70133 15.6512 7.77971 15.7238L11.6574 19.3157C11.821 19.4674 11.828 19.72 11.6729 19.8801Z'
            }
            fill={fillColor}
          />
        </g>
        <defs>
          <rect width={'15'} height={'14.6667'} fill={'white'} transform={'matrix(1 0 0 -1 0 14.667)'} />
          <rect width={'10.7143'} height={'10.4762'} fill={'white'} transform={'matrix(1 0 0 -1 2.14258 17.8105)'} />
          <rect width={'8.57143'} height={'8.38095'} fill={'white'} transform={'matrix(1 0 0 -1 3.21387 22)'} />
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default SwipeUpIcon;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomerAddress } from '@api/locations/types';
import { Dialog, Stack, Transition, Typography } from '@components/common';
import { DeliveryAddressNavbar } from '@components/order';
import { SavedLocationList } from '..';

interface Props {
  open?: boolean;
  onClose: () => void;
  onPressLocation?: (location: CustomerAddress) => void;
}

const SavedLocationListDialog: React.FC<Props> = (props) => {
  const { open = false, onClose, onPressLocation } = props;

  const { t } = useTranslation();

  const handleOnPressLocation = (location: CustomerAddress) => {
    onPressLocation?.(location);
    onClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DeliveryAddressNavbar onPressBack={onClose} />
      <Stack>
        <Stack
          height={'100%'}
          minHeight={'max-content'}
          direction={'column'}
          padding={'m'}
          paddingTop={'xl'}
          spacing={'2xxl'}
          overflow={'hidden'}
        >
          <Typography size={'xxl'} variant={'bold'}>
            {t('locations.delivery_address_list_title')}
          </Typography>
          <SavedLocationList onPressLocation={handleOnPressLocation} />
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SavedLocationListDialog;

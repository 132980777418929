import { TextField } from '@components/common';
import Token from '@constants/token';
import styled from '@emotion/styled';

const CustomTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: Token.color.uiLightSecondary,
    border: `${Token.borderWidth.thick}px solid ${Token.color.borderSubtle}`,
    borderRadius: Token.borderRadius.default,
    paddingLeft: Token.spacing.xs,

    '& fieldset': {
      border: `${Token.borderWidth.thick}px solid ${Token.color.borderSubtle}`,
      borderRadius: Token.borderRadius.default,
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: Token.color.uiLightPrimary,
    },
  },
  '& .MuiFormHelperText-root': {
    marginLeft: 0,
  },
});

export default CustomTextField;

import { css } from '@emotion/react';

const container = css`
  display: inline-flex;
`;

const rotate = (value: number) => css`
  -webkit-transition: -webkit-transform 225ms ease-in-out;
  -ms-transition: -ms-transform 225ms ease-in-out;
  transition: transform 225ms ease-in-out;
  transform: rotate(${value}deg);
`;

const opacity = (value?: number) => css`
  opacity: ${value ?? 1};
`;

const styles = {
  container,
  rotate,
  opacity,
};

export default styles;

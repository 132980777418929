import React, { CSSProperties } from 'react';
import Token from '@constants/token';
import { TokenType } from '@interfaces/token';
import { BoxProps, Box as MUIBox } from '@mui/material';

interface Props {
  padding?: TokenType['spacing'];
  paddingX?: TokenType['spacing'];
  paddingLeft?: TokenType['spacing'];
  paddingRight?: TokenType['spacing'];
  paddingY?: TokenType['spacing'];
  paddingTop?: TokenType['spacing'];
  paddingBottom?: TokenType['spacing'];
  margin?: TokenType['spacing'];
  marginX?: TokenType['spacing'];
  marginLeft?: TokenType['spacing'];
  marginRight?: TokenType['spacing'];
  marginY?: TokenType['spacing'];
  marginTop?: TokenType['spacing'];
  marginBottom?: TokenType['spacing'];
  bottom?: TokenType['spacing'];
  top?: TokenType['spacing'];
  left?: TokenType['spacing'];
  right?: TokenType['spacing'];
  borderRadius?: TokenType['borderRadius'];
  borderColor?: TokenType['color'];
  borderWidth?: TokenType['borderWidth'];
  background?: TokenType['color'];
}

type OmmitedBoxProps =
  | 'spacing'
  | 'padding'
  | 'paddingX'
  | 'paddingLeft'
  | 'paddingRight'
  | 'paddingY'
  | 'paddingTop'
  | 'paddingBottom'
  | 'margin'
  | 'marginX'
  | 'marginLeft'
  | 'marginRight'
  | 'marginY'
  | 'marginTop'
  | 'marginBottom'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'borderRadius'
  | 'borderColor'
  | 'borderWidth';

const Box: React.FC<Props & Omit<BoxProps, OmmitedBoxProps>> = ({ children, ...props }) => {
  const {
    // Overrided Box Props
    padding,
    paddingX,
    paddingLeft,
    paddingRight,
    paddingY,
    paddingTop,
    paddingBottom,
    margin,
    marginX,
    marginLeft,
    marginRight,
    marginY,
    marginTop,
    marginBottom,
    top,
    bottom,
    left,
    right,
    borderRadius,
    borderColor,
    borderWidth,

    // inline Style Props
    background,

    // other Box Props
    ...otherProps
  } = props;

  const style = {
    padding: padding && Token.spacing[padding],
    paddingX: paddingX && Token.spacing[paddingX],
    paddingLeft: paddingLeft && Token.spacing[paddingLeft],
    paddingRight: paddingRight && Token.spacing[paddingRight],
    paddingY: paddingY && Token.spacing[paddingY],
    paddingTop: paddingTop && Token.spacing[paddingTop],
    paddingBottom: paddingBottom && Token.spacing[paddingBottom],
    margin: margin && Token.spacing[margin],
    marginX: marginX && Token.spacing[marginX],
    marginLeft: marginLeft && Token.spacing[marginLeft],
    marginRight: marginRight && Token.spacing[marginRight],
    marginY: marginY && Token.spacing[marginY],
    marginTop: marginTop && Token.spacing[marginTop],
    marginBottom: marginBottom && Token.spacing[marginBottom],
    top: top && Token.spacing[top],
    bottom: bottom && Token.spacing[bottom],
    left: left && Token.spacing[left],
    right: right && Token.spacing[right],
    border: borderWidth && Token.borderWidth[borderWidth],
    borderRadius: borderRadius && Token.borderRadius[borderRadius],
    borderColor: borderColor && Token.color[borderColor],
  };

  const inlineStyle: CSSProperties = {
    background: background && Token.color[background],
  };

  return (
    <MUIBox style={inlineStyle} {...style} {...otherProps}>
      {children}
    </MUIBox>
  );
};
export default Box;

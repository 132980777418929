import { SetStateAction, atom, useAtom } from 'jotai';
import { UserProfile } from '@api/auth/types';

export interface CurrentProfileProps {
  detail?: UserProfile;
}

const atomCurrentProfile = atom<CurrentProfileProps>({});

type ReturnProps = [CurrentProfileProps, (update: SetStateAction<CurrentProfileProps>) => void];

const useCurrentProfile = (): ReturnProps => useAtom(atomCurrentProfile);

export default useCurrentProfile;

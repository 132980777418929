import { useTranslation } from 'react-i18next';
import { CustomerOrderDetail } from '@api/order/types';
import { TagProps } from '@components/common/Tag';

const useOrderStatusTag = (status?: CustomerOrderDetail['status']): TagProps => {
  const { t } = useTranslation();

  if (status === 'expired' || status === 'refunded') {
    return {
      textColor: 'uiRedDark',
      backgroundColor: 'uiRedLight',
      text: status === 'expired' ? t('order.expired_badge_label') : t('order.refunded_badge_label'),
    };
  }

  if (status === 'completed') {
    return {
      textColor: 'uiDarkSecondary',
      backgroundColor: 'uiLightStain',
      text: t('order.order_completed_badge_label'),
    };
  }

  if (status === 'waiting_restaurant_confirmation') {
    return {
      text: t('order.waiting_confirmation_badge_label'),
      textColor: 'uiPrimaryDark',
      backgroundColor: 'uiLightStain',
    };
  }

  return {
    text: t('order.on_going_badge_label'),
    textColor: 'uiGreenPrimary',
    backgroundColor: 'uiGreenSecondary',
  };
};

export default useOrderStatusTag;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const PencilIcon: FC<Props> = ({ width = 16, height = 16, color = 'uiSecondaryMain', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 16 16'} fill={'none'}>
        <path
          d={
            'M3.56917 10.3919C3.5225 10.4385 3.49117 10.4979 3.47784 10.5619L3.0065 12.9192C2.9845 13.0285 3.01917 13.1412 3.09784 13.2205C3.16117 13.2839 3.2465 13.3179 3.33384 13.3179C3.35517 13.3179 3.37717 13.3159 3.39917 13.3112L5.75584 12.8399C5.82117 12.8265 5.8805 12.7952 5.9265 12.7485L11.2012 7.47385L8.8445 5.11719L3.56917 10.3919Z'
          }
          fill={fillColor}
        />
        <path
          d={
            'M12.8311 3.4875C12.1811 2.8375 11.1238 2.8375 10.4744 3.4875L9.55176 4.41017L11.9084 6.76683L12.8311 5.84417C13.1458 5.53017 13.3191 5.1115 13.3191 4.66617C13.3191 4.22083 13.1458 3.80217 12.8311 3.4875Z'
          }
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default PencilIcon;

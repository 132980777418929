import { TokenColor } from '@interfaces/token';
import MUIBadge, { BadgeProps as MUIBadgeProps } from '@mui/material/Badge';
import styles from './styles';

type Props = Pick<MUIBadgeProps, 'badgeContent' | 'children' | 'variant'> & {
  showAnimation?: boolean;
  badgeColor?: TokenColor;
  textColor?: TokenColor;
};

const Badge = (props: Props) => {
  return (
    <MUIBadge
      {...props}
      css={[
        styles.badgeColor(props.badgeColor, props.textColor),
        ...(props.showAnimation ? [styles.pulseAnimation(props.badgeColor)] : []),
      ]}
    />
  );
};

export default Badge;

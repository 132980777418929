import Token from '@constants/token';
import { PaletteOptions, createTheme as createThemeMUI } from '@mui/material/styles';

const DEFAULT_PALLETE: PaletteOptions = {
  primary: {
    light: Token.color.uiPrimaryLight,
    main: Token.color.uiPrimaryMain,
    dark: Token.color.uiPrimaryDark,
    contrastText: Token.color.uiPrimaryContrastText,
  },
  secondary: {
    light: Token.color.uiSecondaryLight,
    main: Token.color.uiSecondaryMain,
    dark: Token.color.uiSecondaryDark,
    contrastText: Token.color.uiSecondaryContrastText,
  },
  success: {
    light: Token.color.uiSuccessLight,
    main: Token.color.uiSuccessMain,
    dark: Token.color.uiSuccessDark,
  },
};

const createTheme = (palette?: PaletteOptions) =>
  createThemeMUI({
    palette: {
      ...DEFAULT_PALLETE,
      ...palette,
      primary: {
        ...DEFAULT_PALLETE.primary,
        ...palette?.primary,
      },
      secondary: {
        ...DEFAULT_PALLETE.secondary,
        ...palette?.secondary,
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    spacing: (multiplier: number) => multiplier,
  });

export default createTheme;

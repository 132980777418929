import React from 'react';
import { Stack, Typography } from '@components/common';
import { Skeleton } from '@mui/material';

const FavoriteProductListLoader: React.FC = () => {
  return (
    <Stack direction={'column'} spacing={'xs'} marginBottom={'m'}>
      <Typography variant={'medium'} size={'hs'} color={'uiDarkPrimary'}>
        <Skeleton variant={'text'} width={100} />
      </Typography>
      <Stack spacing={'xs'} overflow={'auto'} direction={'row'}>
        {new Array(5).fill(null).map((_, idx) => (
          <Stack minWidth={279} maxWidth={279} minHeight={136} maxHeight={136} key={idx}>
            <Stack borderWidth={'bolder'} borderColor={'borderSubtle'} borderRadius={'default'} flexGrow={1}>
              <Stack direction={'column'} spacing={'xs'} padding={'m'}>
                <Stack direction={'row'} justifyContent={'space-between'} spacing={'m'} alignItems={'center'}>
                  <Stack direction={'column'} spacing={'xs'} paddingTop={'xs'} flex={1}>
                    <Typography variant={'bold'}>
                      <Skeleton variant={'text'} width={'75%'} />
                    </Typography>
                    <Typography variant={'bold'}>
                      <Skeleton variant={'text'} width={'100%'} />
                    </Typography>
                    <Typography size={'xs'}>
                      <Skeleton variant={'text'} width={'75%'} />
                    </Typography>
                  </Stack>

                  <Skeleton variant={'rectangular'} width={78} height={78} />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default FavoriteProductListLoader;

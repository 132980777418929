import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';
import Token from '@constants/token';
import { OrderPlacementFormShape } from '@containers/order/OrderPlacementContainer/forms/useFormOrderPlacement';
import { useCommonStore } from '@hooks/storage';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import { styled } from '@mui/system';
import { useQueryLocationDetail } from '@queries/locations';
import DeliveryMethodForm from './DeliveryMethodForm';
import PickupDetailForm from './PickupDetailForm';

const Tab = styled(TabUnstyled)`
  color: ${Token.color.uiDarkSecondary};
  cursor: pointer;
  font-size: ${Token.fontSize.hs}px;
  font-weight: ${Token.fontWeight.bold};
  background-color: transparent;
  flex: 1;
  padding: ${Token.spacing.hs}px;
  margin: 6px 6px;
  border: none;
  border-radius: ${Token.borderRadius.default};
  display: flex;
  justify-content: center;
  overflow: hidden;

  &.${buttonUnstyledClasses.focusVisible} {
    background-color: ${Token.color.uiLightPrimary};
    outline: none;
  }
  &.${buttonUnstyledClasses.active} {
    background-color: ${Token.color.uiLightPrimary};
    outline: none;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${Token.color.uiLightPrimary};
    color: ${Token.color.uiDarkPrimary};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: ${Token.fontSize.hs}px;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: ${Token.color.uiLightSecondary};
  border-radius: ${Token.borderRadius.default};
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

interface Props {
  onOpenAddressFormDialog?: () => void;
}

const OrderPlacementTab: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onOpenAddressFormDialog } = props;

  const { watch, setValue, clearErrors } = useFormContext<OrderPlacementFormShape>();

  const { storageState } = useCommonStore();
  const activeLocation = storageState.activeLocation;
  const activeLocationId = activeLocation?.id;
  const { data: locationDetailResponse } = useQueryLocationDetail({
    id: activeLocationId,
  });

  const {
    enableLalaMoveCar = false,
    enableLalaMoveMotorcycle = false,
    enableGrabExpressCar = false,
    enableGrabExpressMotorcycle = false,
    pickup: canPickup = false,
  } = locationDetailResponse || {};

  const handleChange = useCallback(
    (_, newValue) => {
      setValue('orderMethod', newValue);
      clearErrors();
    },
    [clearErrors, setValue]
  );

  const canDeliveryCar = enableGrabExpressCar || enableLalaMoveCar;
  const canDeliveryMotorcycle = enableGrabExpressMotorcycle || enableLalaMoveMotorcycle;

  const orderMethod = watch('orderMethod');

  return (
    <Stack width={'100%'}>
      <TabsUnstyled value={orderMethod} onChange={handleChange}>
        <TabsList>
          {(canDeliveryCar || canDeliveryMotorcycle) && (
            <Tab value={'delivery'} disabled={!canDeliveryCar && !canDeliveryMotorcycle}>
              <Typography size={'hs'} variant={'bold'}>
                {t('order.delivery_tab_label')}
              </Typography>
            </Tab>
          )}
          {canPickup && (
            <Tab value={'self_pickup'} disabled={!canPickup}>
              <Typography size={'hs'} variant={'bold'}>
                {t('order.pickup_tab_label')}
              </Typography>
            </Tab>
          )}
        </TabsList>

        {(canDeliveryCar || canDeliveryMotorcycle) && (
          <TabPanel value={'delivery'}>
            <DeliveryMethodForm onOpenAddressFormDialog={onOpenAddressFormDialog} />
          </TabPanel>
        )}
        <TabPanel value={'self_pickup'}>
          <PickupDetailForm locationName={activeLocation?.name ?? ''} locationAddress={activeLocation?.address ?? ''} />
        </TabPanel>
      </TabsUnstyled>
    </Stack>
  );
};

export default OrderPlacementTab;

import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { LocationDetailShow } from '@api/locations/types';
import routes from '@constants/routes';
import { useQueryLocationDetail } from '@queries/locations';
import { isBranchOpen } from '@utils/product';

interface Props {
  locationId?: number;
  onSuccess?: (location: LocationDetailShow) => void;
  skipRedirect?: boolean;
}

const useRedirectByLocationSchedule = ({ locationId, onSuccess, skipRedirect = false }: Props) => {
  const router = useRouter();
  const { pathname } = router;
  const { data: locationDetailResponse, isFetching: isFetchingLocationDetail } = useQueryLocationDetail(
    {
      id: locationId,
    },
    {
      enabled: !!locationId,
      onSuccess,
    }
  );

  const isOpen = useMemo(() => {
    if (locationDetailResponse?.isOpen) {
      return isBranchOpen(locationDetailResponse.openingHour);
    }

    return false;
  }, [locationDetailResponse]);

  useEffect(() => {
    if (!skipRedirect && !isOpen && locationDetailResponse && !isFetchingLocationDetail && pathname !== routes.ROOT) {
      router.push(routes.ROOT);
    }
  }, [isFetchingLocationDetail, isOpen, locationDetailResponse, pathname, router, skipRedirect]);

  return useMemo(
    () => ({
      isOpen,
      locationDetail: locationDetailResponse,
      isFetchingLocationDetail,
    }),
    [isFetchingLocationDetail, isOpen, locationDetailResponse]
  );
};

export default useRedirectByLocationSchedule;

import React from 'react';
import { Stack, Typography } from '@components/common';
import { Divider, Skeleton } from '@mui/material';

interface Props {
  hideLocationStatus?: boolean;
}
const ProductSelectLocationLoader: React.FC<Props> = ({ hideLocationStatus }) => {
  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Stack direction={'column'} spacing={'xs'} height={55}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Stack direction={'row'} alignItems={'center'} spacing={'xs'} flex={1}>
            <Skeleton variant={'rectangular'} width={14} height={19} />
            <Stack direction={'column'} flex={1}>
              <Typography size={'hs'} variant={'bold'}>
                <Skeleton variant={'text'} width={'100%'} />
              </Typography>
              <Typography size={'xs'}>
                <Skeleton variant={'text'} width={'100%'} />
              </Typography>
            </Stack>
          </Stack>
          <Stack margin={'xxs'} borderRadius={'default'}>
            <Skeleton variant={'rectangular'} width={30} height={30} />
          </Stack>
        </Stack>
        <Divider />
      </Stack>
      {!hideLocationStatus && <Skeleton variant={'rectangular'} width={58} height={18} />}
    </Stack>
  );
};

export default ProductSelectLocationLoader;

import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import { Button, IconButton, MenuDialog, Stack } from '@components/common';
import { ArrowIcon, MenuIcon } from '@components/icons';

interface Props {
  onPressBack?: () => void;
}

const ProductDetailFormNavbar: FC<Props> = (props) => {
  const { onPressBack } = props;

  const [openMenu, setOpenMenu] = useState(false);

  const router = useRouter();

  const handleOnPressBack = () => {
    onPressBack ? onPressBack() : router.back();
  };

  const onOpenMenu = () => {
    setOpenMenu(true);
  };

  const onCloseMenu = () => {
    setOpenMenu(false);
  };

  return (
    <Stack padding={'m'} direction={'row'} justifyContent={'space-between'} background={'uiLightPrimary'} zIndex={1}>
      <Button onClick={handleOnPressBack} variant={'text'} padding={0}>
        <ArrowIcon />
      </Button>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <IconButton padding={0} onClick={onOpenMenu}>
          <MenuIcon />
        </IconButton>
      </Stack>
      <MenuDialog open={openMenu} onClose={onCloseMenu} />
    </Stack>
  );
};

export default ProductDetailFormNavbar;

import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';
import SnackbarProvider from 'src/context/SnackbarProvider';
import { atomServiceWorker } from '@atoms/serviceWorker';
import { FIREBASE_OPTIONS } from '@constants/firebase';
import { AppContainer } from '@containers/common';
import { CacheProvider } from '@emotion/react';
import { useDetectRouteChanges } from '@hooks/track';
import { AppPropsWithLayout } from '@interfaces/common';
import { ResizeObserver as ResizeObserverPoly } from '@juggle/resize-observer';
import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { createEmotionCache } from '@utils/emotion';
import '@utils/i18n';
import createTheme from '@utils/theme/createTheme';

if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPoly;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const theme = createTheme();

function RunchiseApp({ Component, pageProps, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
  const [_, setAtomServiceWorker] = useAtom(atomServiceWorker);
  useDetectRouteChanges();

  const registerServiceWorker = useCallback(async () => {
    if ('serviceWorker' in navigator) {
      const firebaseConfig = encodeURIComponent(JSON.stringify(FIREBASE_OPTIONS));
      const registration = await navigator.serviceWorker.register(
        `/firebase-messaging-and-pwa-sw.js?config=${firebaseConfig}`
      );
      setAtomServiceWorker((prevState) => ({
        ...prevState,
        serviceWorkerRegistration: registration,
      }));
    }
  }, [setAtomServiceWorker]);

  useEffect(() => {
    registerServiceWorker();
  }, [registerServiceWorker]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <SnackbarProvider>
            <AppContainer Component={Component} pageProps={pageProps} />
          </SnackbarProvider>
        </StylesProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default RunchiseApp;

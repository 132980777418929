import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
  contentColor?: TokenColor;
  borderColor?: TokenColor;
}

const ClearIcon: FC<Props> = ({
  width = 24,
  height = 24,
  borderColor = 'uiDarkNeutral',
  color = 'uiDarkSecondary',
  contentColor = 'uiLightPrimary',
  ...baseIconProps
}) => {
  const fillColor = Token.color[color];
  const fillBorderColor = Token.color[borderColor];
  const fillContentColor = Token.color[contentColor];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 24 24'} fill={'none'}>
        <circle opacity={'0.4'} cx={'12'} cy={'12'} r={'11.5'} fill={fillColor} stroke={fillBorderColor} />
        <path
          d={
            'M13.1014 12.0007L16.8402 8.26163C16.9431 8.1587 16.9998 8.02138 17 7.87496C17 7.72846 16.9433 7.59098 16.8402 7.48821L16.5126 7.16065C16.4096 7.05748 16.2723 7.00098 16.1257 7.00098C15.9793 7.00098 15.842 7.05748 15.739 7.16065L12.0002 10.8994L8.26114 7.16065C8.15829 7.05748 8.02089 7.00098 7.87439 7.00098C7.72805 7.00098 7.59065 7.05748 7.4878 7.16065L7.16 7.48821C6.94667 7.70155 6.94667 8.04854 7.16 8.26163L10.8989 12.0007L7.16 15.7395C7.05707 15.8426 7.00041 15.9799 7.00041 16.1263C7.00041 16.2728 7.05707 16.4101 7.16 16.5131L7.48772 16.8407C7.59057 16.9437 7.72805 17.0003 7.87431 17.0003C8.02081 17.0003 8.15821 16.9437 8.26106 16.8407L12.0001 13.1018L15.7389 16.8407C15.842 16.9437 15.9793 17.0003 16.1256 17.0003H16.1258C16.2722 17.0003 16.4095 16.9437 16.5125 16.8407L16.8402 16.5131C16.943 16.4102 16.9998 16.2728 16.9998 16.1263C16.9998 15.9799 16.943 15.8426 16.8402 15.7396L13.1014 12.0007Z'
          }
          fill={fillContentColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default ClearIcon;

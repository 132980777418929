import NotFoundPage from 'pages/404';
import React, { ReactNode, useMemo } from 'react';
import { DefaultLoading, Stack } from '@components/common';
import { APP_MAX_WIDTH } from '@constants/common';
import Token from '@constants/token';
import { useSetupPWA } from '@hooks/common';
import { ThemeProvider } from '@mui/material';
import { useQueryServerBrandInfo } from '@queries/commons';
import createTheme from '@utils/theme/createTheme';
import hexToRGBA from '@utils/theme/hexToRGBA';
import ProfileLayout from './ProfileLayout';

interface Props {
  children: ReactNode;
  brandUrl: string;
}

const SubdomainLayout = ({ children, brandUrl = '' }: Props) => {
  useSetupPWA();

  const { isSuccess, isError, isFetching, data } = useQueryServerBrandInfo(brandUrl);

  const primary = data?.brand?.mainColourTheme || Token.color.uiPrimaryMain;
  const secondary = data?.brand?.secondaryColourTheme || Token.color.uiSecondaryMain;

  const mainContrastText = data?.brand?.mainTextColour;
  const secondaryContrastText = data?.brand?.secondaryTextColour;

  const theme = useMemo(() => {
    return createTheme({
      primary: {
        main: primary,
        light: hexToRGBA(primary, 0.8),
        ...(mainContrastText && { contrastText: mainContrastText }),
      },
      secondary: {
        main: secondary,
        light: hexToRGBA(secondary, 0.9),
        ...(secondaryContrastText && { contrastText: secondaryContrastText }),
      },
    });
  }, [mainContrastText, primary, secondary, secondaryContrastText]);

  if (isSuccess) {
    return (
      <ProfileLayout>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ProfileLayout>
    );
  }

  if (isError) {
    return <NotFoundPage />;
  }

  if (isFetching) {
    return (
      <Stack
        position={'absolute'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        height={'100%'}
        maxWidth={`${APP_MAX_WIDTH}px`}
      >
        <DefaultLoading />
      </Stack>
    );
  }

  return <>{children}</>;
};

export default SubdomainLayout;

import { CommonStorage } from '@storage/types';

export const CART_DATABASE_NAME = 'runchise-cart-db';
export const CART_STORE_NAME = 'runchise-user-cart';
export const CART_DATABASE_VERSION = 1.0;
export const CART_DATABASE_DESCRIPTION = 'Store your selected products to order, powered by Runchise.';

export const COMMON_DATABASE_NAME = 'runchise-common-db';
export const COMMON_STORE_NAME = 'common-store';
export const COMMON_STORE_STORAGE_KEY_NAME = 'common-store-key';
export const COMMON_DATABASE_VERSION = 1.0;
export const COMMON_DATABASE_DESCRIPTION = 'Store general information, powered by Runchise.';

export const COMMON_STORAGE_INITIAL_VALUE: CommonStorage = {
  firebaseToken: '',
};

export const GUEST_DATABASE_NAME = 'runchise-guest-db';
export const GUEST_STORE_NAME = 'runchise-guest';
export const GUEST_DATABASE_VERSION = 1.0;
export const GUEST_DATABASE_DESCRIPTION = 'Store your guest information, powered by Runchise.';

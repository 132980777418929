import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { getServerBrandInfo } from '@api/auth';
import { QUERY_KEY_SERVER_GET_BRAND_INFO } from '@constants/queryKey';

export interface GetBrandInfoQueryServerKey {
  key: string;
  payload: {
    brandUrl: string;
  };
}

const useQueryServerBrandInfo = (brandUrl: string) => {
  const queryKeys: GetBrandInfoQueryServerKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_SERVER_GET_BRAND_INFO,
        payload: {
          brandUrl,
        },
      },
    ],
    [brandUrl]
  );

  const { isError, data, isFetching, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getServerBrandInfo(payload)(),
    {
      enabled: !!brandUrl,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
  };
};

export default useQueryServerBrandInfo;

import { APP_MAX_WIDTH } from '@constants/common';
import { TokenBorderRadius } from '@interfaces/token';
import { DialogProps, Dialog as MUIDialog } from '@mui/material';
import styles from './styles';

interface Props extends DialogProps {
  borderRadius?: TokenBorderRadius;
}

const Dialog = ({ borderRadius, ...otherProps }: Props) => {
  return (
    <MUIDialog
      PaperProps={{
        style: {
          maxWidth: APP_MAX_WIDTH,
          margin: 'auto',
          ...(borderRadius && styles.borderRadius(borderRadius)),
        },
      }}
      {...otherProps}
    />
  );
};

export default Dialog;

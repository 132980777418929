import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import {
  CreditCardPaymentMethod,
  PaymentMethod,
  PaymentMethodType,
  SavedCreditCard,
  VirtualAccountPaymentMethod,
} from '@api/order/types';
import { Hoverable, IconButton, Popover, Stack, Typography } from '@components/common';
import { ChevronIcon } from '@components/icons';
import config from '@constants/config';
import { useGuestStore } from '@hooks/storage';
import { useQueryCreditCards } from '@queries/order';
import { getXenditPaymentMethodIcon, isMinimumAmountForPaymentMethod } from '@utils/payment';
import { maskString } from '@utils/string';
import AddPaymentMethodDrawer from '../AddPaymentMethodDrawer';
import PaymentMethodOption from './PaymentMethodOption';

const POPOVER_ID = 'payment-method-popover';

interface PaymentMethodOption {
  type: PaymentMethodType;
  icon: ReactNode;
  label: string;
}

interface Props {
  onChange: (paymentMethod: PaymentMethod) => void;
  value?: PaymentMethod;
  error?: string;
  disabled?: boolean;
  paymentMethodOptions: PaymentMethodOption[];
  totalAmount: DecimalNumber;
}
const PaymentMethodInput: React.FC<Props> = ({
  onChange,
  value,
  error,
  disabled,
  paymentMethodOptions = [],
  totalAmount,
}) => {
  const { t } = useTranslation();

  const [activeFormType, setActiveFormType] = useState<PaymentMethodType>();
  const [openPaymentMethodForm, setOpenPaymentMethodForm] = useState(false);

  const popupState = usePopupState({ variant: 'popover', popupId: POPOVER_ID });
  const { onClick, ...otherPopupState } = bindTrigger(popupState);
  const { onClose } = bindPopover(popupState);

  const { guestData } = useGuestStore();

  const { data } = useQueryCreditCards({ enabled: popupState.isOpen && !guestData });

  const savedCreditCardOptions = data || [];

  const canAddNewCard = useMemo(
    () => !!paymentMethodOptions.find((option) => option.type === 'credit-card'),
    [paymentMethodOptions]
  );

  const paymentNumber = useMemo(() => {
    if (value?.type === 'va-transfer') {
      const { bankId } = value as VirtualAccountPaymentMethod;
      return bankId;
    }
    if (value?.type === 'credit-card') {
      return maskString(value?.accountNumber);
    }

    return value?.accountNumber;
  }, [value]);

  const selectedPaymentMethodOption = useMemo(
    () => paymentMethodOptions.find((option) => option.type === value?.type),
    [paymentMethodOptions, value?.type]
  );

  const paymentMethodStringDisplay = useMemo(() => {
    if (selectedPaymentMethodOption) {
      return `${selectedPaymentMethodOption.label} ${paymentNumber ? `(${paymentNumber})` : ''}`;
    }

    return '';
  }, [paymentNumber, selectedPaymentMethodOption]);

  const renderSelectedPaymentMethod = () => {
    if (!value?.type || error) {
      return (
        <Typography color={'uiRedPrimary'} size={'hm'} variant={'bold'}>
          {error || t(`order.payment_method_error_label`)}
        </Typography>
      );
    }

    return (
      <Stack spacing={'s'} flexGrow={1} alignItems={'center'} overflow={'auto'} direction={'row'}>
        {selectedPaymentMethodOption?.icon}
        <Typography textOverflow={'ellipsis'} size={'hm'} variant={'bold'}>
          {paymentMethodStringDisplay}
        </Typography>
      </Stack>
    );
  };

  const onChangePaymentMethod = (type: PaymentMethodType) => () => {
    setActiveFormType(type);
    onClose();
    if (type === 'qris' || type === 'shopeepay' || type === 'dana' || type === 'gopay' || type === 'cash') {
      onChange({
        type,
      });
      return;
    }
    onOpenPaymentMethodForm();
  };

  const handleOnPressSavedCreditCards = (creditCard: SavedCreditCard) => () => {
    onClose();
    onChange({
      type: 'credit-card',
      accountNumber: creditCard.maskedCardNumber,
      tokenId: creditCard.tokenId,
    });
  };

  const onOpenPaymentMethodForm = () => {
    setOpenPaymentMethodForm(true);
  };

  const onClosePaymentMethodForm = () => {
    setOpenPaymentMethodForm(false);
  };

  const handleOnAddNewCreditCard = () => {
    setActiveFormType('credit-card');
    onOpenPaymentMethodForm();
    onClose();
  };

  const handleSubmitPaymentMethod = (paymentMethod: PaymentMethod) => {
    onChange(paymentMethod);
  };

  const { tokenId = '' } = value && 'tokenId' in value ? (value as CreditCardPaymentMethod) : {};
  const isSavedCreditCardOptionSelected = savedCreditCardOptions?.some((option) => option?.tokenId === tokenId);

  return (
    <Stack direction={'column'} spacing={'xs'} width={'100%'}>
      <Typography size={'hs'}>{t('order.payment_method_select_label')}</Typography>
      <Stack direction={'row'} width={'100%'} spacing={'xs'} {...otherPopupState}>
        <Hoverable disabled={disabled} onClick={onClick} display={'flex'} flexGrow={1}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            borderRadius={'default'}
            spacing={'xs'}
            width={'100%'}
            overflow={'hidden'}
          >
            {renderSelectedPaymentMethod()}
            <IconButton padding={0}>
              <Stack
                borderWidth={'thin'}
                borderColor={'borderSubtle'}
                borderRadius={'default'}
                background={'uiLightPrimary'}
                boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
                width={30}
                height={30}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <ChevronIcon rotate={popupState?.isOpen ? 0 : -90} />
              </Stack>
            </IconButton>
          </Stack>
        </Hoverable>
      </Stack>
      <Popover {...bindPopover(popupState)}>
        <Stack maxHeight={250} overflow={'auto'} background={'uiLightStain'} padding={'xxs'} borderRadius={'default'}>
          <Stack width={'100%'} spacing={'xxs'} direction={'column'}>
            {paymentMethodOptions?.map((option, index) => {
              const isMinimumAmountForPayment = isMinimumAmountForPaymentMethod(totalAmount, option.type);
              return (
                <React.Fragment key={index}>
                  <PaymentMethodOption
                    icon={option.icon}
                    label={option.label}
                    isSelected={option.type === value?.type && !isSavedCreditCardOptionSelected}
                    onClick={onChangePaymentMethod(option.type)}
                    disabled={isMinimumAmountForPayment}
                  />
                  {config.featureToggle.enableXenditSavedCc &&
                    option.type === 'credit-card' &&
                    savedCreditCardOptions.map((option, creditCardIndex) => (
                      <PaymentMethodOption
                        key={creditCardIndex}
                        icon={getXenditPaymentMethodIcon({
                          type: 'credit-card',
                          accountNumber: option?.maskedCardNumber,
                        })}
                        label={option?.maskedCardNumber}
                        isSelected={option?.tokenId === tokenId}
                        onClick={handleOnPressSavedCreditCards(option)}
                        disabled={isMinimumAmountForPayment}
                      />
                    ))}
                </React.Fragment>
              );
            })}
            {canAddNewCard && !isMinimumAmountForPaymentMethod(totalAmount, 'credit-card') && (
              <Stack
                onClick={handleOnAddNewCreditCard}
                background={'uiLightPrimary'}
                paddingX={'hm'}
                paddingY={'m'}
                borderRadius={'default'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'row'}
                borderWidth={'bold'}
                borderColor={'uiLightPrimary'}
              >
                <Typography size={'s'} color={'uiPrimaryMain'}>
                  {t('order.add_new_credit_card_label')}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Popover>
      {value && (
        <AddPaymentMethodDrawer
          value={value}
          open={openPaymentMethodForm}
          activeFormType={activeFormType}
          onSubmit={handleSubmitPaymentMethod}
          onOpen={onOpenPaymentMethodForm}
          onClose={onClosePaymentMethodForm}
          totalAmount={totalAmount}
        />
      )}
    </Stack>
  );
};

export default PaymentMethodInput;

import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import { Card } from '@mui/material';

const NoSubBrandCard = () => {
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <Stack
        borderWidth={'thin'}
        borderRadius={'default'}
        direction={'row'}
        alignItems={'center'}
        paddingX={'xs'}
        paddingY={'xs'}
        spacing={'xs'}
      >
        <LocationMarkerIcon color={'uiDarkSecondary'} />
        <Stack width={'100%'} direction={'column'} flexGrow={1} overflow={'hidden'}>
          <Typography noWrap textOverflow={'ellipsis'} variant={'medium'} size={'hs'}>
            {t('sub_brands.no_sub_brands')}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default NoSubBrandCard;

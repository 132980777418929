import { CSSProperties } from 'react';
import Token from '@constants/token';
import { css } from '@emotion/react';

const display = (value: CSSProperties['display']) => css`
  display: ${value};
`;

const flexGrow = (value: CSSProperties['flexGrow']) => css`
  flex-grow: ${value};
`;

const disabled = css`
  &:hover {
    cursor: not-allowed;
  }
`;

const link = css`
  text-decoration: none;
  color: inherit;

  &:hover {
    cursor: pointer;
    background-color: ${Token.color.backgroundNeutralSecondary};
  }
`;

const styles = {
  link,
  display,
  flexGrow,
  disabled,
};

export default styles;

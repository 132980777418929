import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Image, Stack } from '@components/common';
import { ArrowIcon } from '@components/icons';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useQueryBrandInfo } from '@queries/commons';

interface Props {
  onPressBack?: () => void;
  redirectHome?: boolean;
}

const OrderPlacementNavbar: FC<Props> = ({ onPressBack, redirectHome }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const { data } = useQueryBrandInfo();

  const handlePressBack = useCallback(() => {
    if (onPressBack) {
      onPressBack();
      return;
    }

    if (redirectHome) {
      router.push({
        pathname: routes.ROOT,
        query,
      });
      return;
    }

    router.back();
  }, [onPressBack, query, redirectHome, router]);

  const brandLogo = data?.brand?.logoUrl;
  const brandName = data?.brand?.name;

  return (
    <Stack padding={'m'} direction={'row'} justifyContent={'space-between'} background={'uiLightPrimary'} zIndex={1}>
      <IconButton onClick={handlePressBack} padding={0}>
        <ArrowIcon />
      </IconButton>

      <Image
        width={30}
        height={30}
        src={brandLogo}
        alt={t('general.alt_logo_image', { brandName })}
        objectFit={'contain'}
      />
    </Stack>
  );
};

export default OrderPlacementNavbar;

import React, { useMemo } from 'react';
import { LocationDetail } from '@api/locations/types';
import { OptionCard, Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import { useCommonStore } from '@hooks/storage';
import { LocationStatusBadge } from '..';

interface Props {
  onPress?: () => void;
  location: LocationDetail;
}

const LocationCard: React.FC<Props> = ({ location, onPress }) => {
  const status = location?.isOpen ? 'open' : 'closed';

  const typographyColor = status === 'open' ? undefined : 'uiDarkSecondary';

  const { storageState } = useCommonStore();
  const isSelected = storageState?.activeLocation?.id === location?.id;

  const iconColor = useMemo(() => {
    if (status === 'open') {
      if (isSelected) {
        return 'uiGreenPrimary';
      }
      return 'uiDarkPrimary';
    }

    return 'uiDarkSecondary';
  }, [isSelected, status]);

  return (
    <OptionCard
      onPress={onPress}
      option={{
        name: location.name,
        description: location.address,
      }}
      isSelected={isSelected}
      imageComponent={<LocationMarkerIcon color={iconColor} />}
      minHeight={'max-content'}
      endAdornment={
        <Stack alignItems={'center'} direction={'row'} spacing={'m'}>
          {location?.distance !== 'N/A' && (
            <Typography noWrap textOverflow={'ellipsis'} color={typographyColor} variant={'medium'} size={'hs'}>
              {location.distance}
            </Typography>
          )}
          <LocationStatusBadge status={status} />
        </Stack>
      }
    />
  );
};

export default LocationCard;

import { useAtom } from 'jotai';
import { sumBy } from 'lodash';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { atomCustomItem } from '@atoms/products';
import { Image, Stack, Typography } from '@components/common';
import { OutOfStockTag, ProductPrice } from '@components/products';
import routes from '@constants/routes';
import Token from '@constants/token';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';
import { CartItem, CustomItem } from '@storage/types';
import { ProductCustomOptionDrawer } from '../..';

interface Props {
  product: Product;
  disabled?: boolean;
  cartItem?: CartItem;
  onChangeCartItem?: (item: CartItem) => void;
}

const PopularProductCard: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();
  const { product, cartItem, onChangeCartItem } = props;

  const outOfStock = !product || product.outOfStockFlag;
  const disabled = props.disabled || outOfStock;
  const { storageState } = useCommonStore();

  const [openCustomOptionDrawer, setOpenCustomOptionDrawer] = useState(false);
  const [_, setEditedCustomItem] = useAtom(atomCustomItem);

  const { name, price, imageUrl, promoAmount } = product;

  const hasProductOptionSet = product?.type === 'option_set';

  const isCustomProduct = hasProductOptionSet;

  const router = useRouter();

  const totalInputProduct = useMemo(() => {
    if (hasProductOptionSet) {
      return sumBy(cartItem?.customItems, (customItem) => customItem?.qty || 0);
    }

    return cartItem?.qty || 0;
  }, [cartItem, hasProductOptionSet]);

  const onOpenProductDetailPage = () => {
    return router.push({
      pathname: `${routes.PRODUCT_LIST}/${product.id}`,
      query: {
        ...router.query,
        locationId: storageState.activeLocation?.id,
        subBrandId: storageState.subBrand?.id,
      },
    });
  };

  /**
   * This method is to handle onClose Product Detail Modal
   * It will remove active edited item when the modal is closed
   */

  const onPressEditCustomItem = (customItem: CustomItem) => {
    setEditedCustomItem(customItem);
    onOpenProductDetailPage();
  };

  const onPressCard = () => {
    if (disabled) {
      return;
    }

    if (isCustomProduct && totalInputProduct > 0) {
      setOpenCustomOptionDrawer(true);
      return;
    }

    onOpenProductDetailPage();
  };

  const borderColor = totalInputProduct > 0 ? 'uiPrimaryMain' : 'borderSubtle';
  const productName = totalInputProduct > 0 ? `${totalInputProduct} x ${name}` : name;
  const productNameColor = useMemo(() => {
    if (disabled) {
      return 'uiDarkSecondary';
    }
    if (totalInputProduct > 0) {
      return 'uiPrimaryMain';
    }
    return 'uiDarkPrimary';
  }, [disabled, totalInputProduct]);

  return (
    <React.Fragment>
      <Stack
        borderWidth={'bolder'}
        borderColor={borderColor}
        borderRadius={'default'}
        paddingTop={'xs'}
        paddingBottom={'s'}
        onClick={onPressCard}
        maxWidth={154}
        minWidth={154}
        height={202}
        position={'relative'}
      >
        <Stack direction={'column'} alignItems={'center'} flexGrow={1} spacing={'xs'}>
          <Image
            alt={t('general.alt_product_image', { productName, brandName: data?.brand?.name })}
            disabled={disabled}
            width={125}
            height={125}
            src={imageUrl}
            objectFit={'cover'}
          />
          <Stack maxWidth={'90%'} direction={'column'} overflow={'hidden'}>
            <Typography color={productNameColor} variant={'bold'} size={'s'} textAlign={'center'} lineClamp={2}>
              {productName}
            </Typography>
            <ProductPrice price={price} discount={promoAmount} justifyContent={'center'} disabled={disabled} />
          </Stack>
        </Stack>
        {outOfStock && (
          <Stack position={'absolute'} top={Token.spacing.xs} right={0}>
            <OutOfStockTag />
          </Stack>
        )}
      </Stack>
      {openCustomOptionDrawer && (
        <ProductCustomOptionDrawer
          open={openCustomOptionDrawer}
          setOpen={setOpenCustomOptionDrawer}
          productId={product?.id}
          onPressEditCustomItem={onPressEditCustomItem}
          onPressSubmit={onOpenProductDetailPage}
          cartItem={cartItem}
          onChangeCartItem={onChangeCartItem}
          disabled={disabled}
          productCategoryId={product?.productCategoryId}
          productCategoryName={product?.productCategoryName}
        />
      )}
    </React.Fragment>
  );
};

export default PopularProductCard;

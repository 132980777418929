export const APP_MAX_WIDTH = 600;

export const NAVBAR_HEIGHT = 56;
export const PRODUCT_INPUT_SEARCH_HEIGHT = 66;
export const PRODUCT_TAG_FILTER_HEIGHT = 52.5;

export const PWA_IGNORE_BANNER_FLAG = 'whenUserChooseToIgnorePWABanner';
export const SHOWN_NOTIFICATION_DENIED_ALERT_FLAG = 'shownNotificationDeniedAlert';
export const PWA_BANNER_ZINDEX = 1;

export const REQUEST_LOCATION_TIMEOUT = 3000;

export const commonStyle = `
  // TODO temporary use font regular for weight 300 since I can't find the files
  @font-face {
    font-family: 'SfPro';
    src: local(''), url('/fonts/SfPro/SFProDisplay-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
  }
  @font-face {
    font-family: 'SfPro';
    src: local(''), url('/fonts/SfPro/SFProDisplay-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'SfPro';
    src: local(''), url('/fonts/SfPro/SFProDisplay-Medium.woff') format('woff');
    font-style: medium;
    font-weight: 500;
    font-display: swap;
  }
  @font-face {
    font-family: 'SfPro';
    src: local(''), url('/fonts/SfPro/SFProDisplay-Bold.woff') format('woff');
    font-style: bold;
    font-weight: 600;
    font-display: swap;
  }
  // TODO temporary use font regular for weight 700 since I can't find the files
  @font-face {
    font-family: 'SfPro';
    src: local(''), url('/fonts/SfPro/SFProDisplay-Bold.woff') format('woff');
    font-style: bold;
    font-weight: 700;
    font-display: swap;
  }

  html, body {
    padding: 0;
    margin: 0;
    font-family: 'SfPro';
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
    display: flex;
    overflow: auto;
  }
  :root {
    --rsbs-max-w: ${APP_MAX_WIDTH}px;
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }
  div#__next {
    width: 100%;
    height: auto;
    min-height: 100%;
    flex-grow: 1;
    max-width: ${APP_MAX_WIDTH}px;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const WHATSAPP_NUMBER = '6282211113780';

import Decimal from 'decimal.js';
import { difference, differenceWith, some, sumBy } from 'lodash';
import { Product } from '@api/products/types';
import { BranchCart, CartItem, CustomItem, ProductOptionSet } from '@storage/types';
import { calcDecimal, toNumber } from '@utils/number';

export const isEqualOptionSet = (firstOptionSet: ProductOptionSet, secondOptionSet: ProductOptionSet) => {
  const hasEqualOptionSetId = firstOptionSet.optionSetId === secondOptionSet.optionSetId;

  const intersectionOptionSetOptionIds = difference(
    firstOptionSet.optionSetOptionIds,
    secondOptionSet.optionSetOptionIds
  );
  const hasEqualOptionSetOptionIdsLength =
    firstOptionSet.optionSetOptionIds.length === secondOptionSet.optionSetOptionIds.length;
  const hasEqualOptionSetOptionIds = hasEqualOptionSetOptionIdsLength && intersectionOptionSetOptionIds.length === 0;

  return hasEqualOptionSetId && hasEqualOptionSetOptionIds;
};

const isEqualProductOptionSets = (
  firstCustomOptionSets: ProductOptionSet[] = [],
  secondCustomOptionSets: ProductOptionSet[] = []
) => {
  const isEqualLength = firstCustomOptionSets?.length === secondCustomOptionSets?.length;
  const hasDifferentOptionSets = differenceWith(firstCustomOptionSets, secondCustomOptionSets, isEqualOptionSet);

  return isEqualLength && hasDifferentOptionSets?.length === 0;
};

const isProductWithCustomItem = (productCart: CartItem, customItem: CustomItem) =>
  some(productCart?.customItems, (item) =>
    isEqualProductOptionSets(item.productOptionSets, customItem.productOptionSets)
  );

export const getAddedCustomItemOnProduct = (productCart: CartItem, customItem: CustomItem): CartItem => {
  if (isProductWithCustomItem(productCart, customItem)) {
    return {
      ...productCart,
      customItems: productCart?.customItems?.map((item) => {
        const currItemProductOptionSets = item?.productOptionSets;
        const comparedProductOptionSets = customItem?.productOptionSets;
        if (isEqualProductOptionSets(currItemProductOptionSets, comparedProductOptionSets)) {
          return {
            ...item,
            notes: customItem?.notes,
            qty: (item.qty || 0) + 1,
          };
        }
        return item;
      }),
    };
  }

  return {
    ...productCart,
    customItems: [...(productCart?.customItems || []), customItem],
  };
};

export const getProductOptionSetPriceOnProduct = (productOptionSet?: ProductOptionSet, product?: Product) => {
  const productOptionSetOnProduct = product?.productOptionSets || [];
  const fallbackDecimal = calcDecimal(0) as Decimal;

  const matchOptionSetOption =
    productOptionSetOnProduct.find(({ optionSetId }) => optionSetId === productOptionSet?.optionSetId)
      ?.optionSetOptions || [];

  const optionSetOptionIds = productOptionSet?.optionSetOptionIds || [];

  const optionSetOptionPrice = optionSetOptionIds.reduce((accumulator, optionSetOptionId) => {
    const price = matchOptionSetOption?.find((optionSetOption) => optionSetOption?.id === optionSetOptionId)?.price;
    return accumulator.add(toNumber(price) ?? 0);
  }, fallbackDecimal);

  return optionSetOptionPrice?.toNumber();
};

export const getTotalPriceFromCustomItem = (customItem: CustomItem, product?: Product) => {
  const price = toNumber(product?.price);
  const fallbackDecimal = calcDecimal(0) as Decimal;

  const parsedCustomItemQty = calcDecimal(customItem?.qty || 0);
  const customItemQty = parsedCustomItemQty instanceof Decimal ? parsedCustomItemQty : fallbackDecimal;
  const productOptionSets = customItem?.productOptionSets || [];
  const productOptionSetsTotalPrice = productOptionSets.reduce((optionSetAccumulator, productOptionSet) => {
    return optionSetAccumulator.add(getProductOptionSetPriceOnProduct(productOptionSet, product));
  }, fallbackDecimal);

  const productDiscountPrice = product?.promoAmount || '0';
  const parsedCustomItemTotalDiscount = calcDecimal(toNumber(productDiscountPrice));
  const customItemTotalDiscount =
    parsedCustomItemTotalDiscount instanceof Decimal ? parsedCustomItemTotalDiscount : fallbackDecimal;
  const customitemTotalPrice = productOptionSetsTotalPrice
    .add(price ?? 0)
    .minus(customItemTotalDiscount)
    .mul(customItemQty);

  return customitemTotalPrice.toNumber();
};

export const getTotalDiscountromCustomItem = (customItem: CustomItem, product?: Product) => {
  const fallbackDecimal = calcDecimal(0) as Decimal;
  const parsedCustomItemQty = calcDecimal(customItem?.qty || 0);
  const customItemQty = parsedCustomItemQty instanceof Decimal ? parsedCustomItemQty : fallbackDecimal;
  const productDiscountPrice = product?.promoAmount || '0';
  const parsedCustomItemTotalDiscount = calcDecimal(toNumber(productDiscountPrice));
  const customItemTotalDiscount =
    parsedCustomItemTotalDiscount instanceof Decimal ? parsedCustomItemTotalDiscount : fallbackDecimal;
  const customitemTotalPrice = customItemTotalDiscount.mul(customItemQty);

  return customitemTotalPrice.toNumber();
};

export const getTotalPriceFromProductCart = (productCart?: CartItem, product?: Product) => {
  const fallbackDecimal = calcDecimal(0) as Decimal;
  const parsedPrice = calcDecimal(toNumber(product?.price));
  const price = parsedPrice instanceof Decimal ? parsedPrice : fallbackDecimal;
  const parsedDiscount = calcDecimal(toNumber(product?.promoAmount || '0'));
  const discount = parsedDiscount instanceof Decimal ? parsedDiscount : fallbackDecimal;
  const parsedQty = calcDecimal(productCart?.qty || 0);
  const qty = parsedQty instanceof Decimal ? parsedQty : fallbackDecimal;

  const customItems = productCart?.customItems || [];
  if (customItems.length) {
    return customItems
      .reduce(
        (accumulator, customItem) => accumulator.add(getTotalPriceFromCustomItem(customItem, product)),
        fallbackDecimal
      )
      .toNumber();
  }
  return price.minus(discount).mul(qty).toNumber();
};

export const getTotalDiscountFromProductCart = (productCart?: CartItem, product?: Product) => {
  const fallbackDecimal = calcDecimal(0) as Decimal;
  const parsedDiscount = calcDecimal(toNumber(product?.promoAmount || '0'));
  const discount = parsedDiscount instanceof Decimal ? parsedDiscount : fallbackDecimal;
  const parsedQty = calcDecimal(productCart?.qty || 0);
  const qty = parsedQty instanceof Decimal ? parsedQty : fallbackDecimal;

  const customItems = productCart?.customItems || [];
  if (customItems.length) {
    return customItems
      .reduce(
        (accumulator, customItem) => accumulator.add(getTotalDiscountromCustomItem(customItem, product)),
        fallbackDecimal
      )
      .toNumber();
  }
  return discount.mul(qty).toNumber();
};

export const getTotalProduct = (branchCart: BranchCart) => {
  const branchCartProductIds = Object.keys(branchCart) || [];

  const total = branchCartProductIds.reduce((accumulator, productId) => {
    const customItems = branchCart[Number(productId)]?.customItems || [];
    const qty = branchCart[Number(productId)]?.qty || 0;
    if (customItems.length) {
      return sumBy(customItems, 'qty') + accumulator;
    }

    return accumulator + qty;
  }, 0);

  return total;
};

/**
 * This method is to get new cart item
 * it will check both prev and new custom item whether they are already inside product cart customItems or not.
 * If PrevCustomItem and NewCustomItem have a same index, it will replace the previous item with the new item inside product cart customItems.
 * If PrevCustom Item is not in the cart customItems, it will replace the item that have same index with new item index.
 * If we cannot find the new item on customItems, won't do anything.
 * @param {CustomItem} prevCustomItemValue previous custom item value
 * @param {CartItem} productCart current product cart value
 * @param {CustomItem} newCustomItemValue new custom item value
 * @returns
 */
export const getUpdatedCustomItemOnCartItem = (
  prevCustomItemValue?: CustomItem,
  productCart?: CartItem,
  newCustomItemValue?: CustomItem
): CartItem => {
  const customItems = productCart?.customItems || [];
  const prevCustomItemIndex = customItems.findIndex((item) =>
    isEqualProductOptionSets(item.productOptionSets, prevCustomItemValue?.productOptionSets)
  );
  const newCustomItemIndex = customItems.findIndex((item) =>
    isEqualProductOptionSets(item.productOptionSets, newCustomItemValue?.productOptionSets)
  );

  if (prevCustomItemIndex > -1) {
    if (newCustomItemIndex === -1 || prevCustomItemIndex === newCustomItemIndex) {
      return {
        ...productCart,
        productCategoryId: productCart?.productCategoryId ?? 0,
        productCategoryName: productCart?.productCategoryName ?? '',
        customItems: customItems.map((item, index) => {
          if (index === prevCustomItemIndex) {
            return newCustomItemValue;
          }
          return item;
        }) as CustomItem[],
      };
    } else {
      return {
        ...productCart,
        productCategoryId: productCart?.productCategoryId ?? 0,
        productCategoryName: productCart?.productCategoryName ?? '',
        customItems: customItems.map((item, index) => {
          if (index === newCustomItemIndex) {
            return newCustomItemValue;
          }
          return item;
        }) as CustomItem[],
      };
    }
  }

  return productCart as CartItem;
};

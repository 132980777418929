import { useTranslation } from 'react-i18next';
import { LocationOpenStatus } from '@api/locations/types';

const useLocationStatusBadge = (status: LocationOpenStatus) => {
  const { t } = useTranslation();

  if (status === 'open') {
    return {
      label: t('locations.open'),
    };
  }

  return {
    label: t('locations.close'),
  };
};

export default useLocationStatusBadge;

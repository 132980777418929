import { format } from 'date-fns';
import { parseCookies } from 'nookies';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { OpeningHour } from '@api/locations/types';
import { Dialog, IconButton, Stack, Typography } from '@components/common';
import { CloseIcon } from '@components/icons';
import { OPEN_HOUR_MODAL_COOKIE_KEY } from '@constants/location';
import Token from '@constants/token';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';
import { useQueryLocationDetail, useQueryLocations } from '@queries/locations';
import { setInternalCookie } from '@utils/auth';
import { getBranchNearCloseTime } from '@utils/product';

const OpenHourReminderLayout: React.FC = ({ children }) => {
  const cookies = parseCookies();
  const queryClient = useQueryClient();

  const [showModal, setShowModal] = useState(false);
  const [closeHourIntervalId, setCloseHourIntervalId] = useState<NodeJS.Timer>();

  const { t } = useTranslation();

  const isClosedOpenHourReminder = cookies[OPEN_HOUR_MODAL_COOKIE_KEY];

  const { data } = useQueryBrandInfo({
    enabled: !isClosedOpenHourReminder,
  });
  const { queryKeys } = useQueryLocations({}, { enabled: false });

  const { storageState } = useCommonStore();

  const activeLocation = storageState.activeLocation;
  const activeLocationId = activeLocation?.id;

  const { data: locationDetailResponse } = useQueryLocationDetail(
    {
      id: activeLocationId,
    },
    {
      enabled: !!activeLocationId,
    }
  );

  const nearestCloseTime = useMemo(() => {
    if (locationDetailResponse && data) {
      const activeLocationOpenHours = locationDetailResponse?.openingHour as OpeningHour;
      return getBranchNearCloseTime(activeLocationOpenHours, data?.brand?.processTime);
    }
  }, [data, locationDetailResponse]);

  const createTimer = useCallback(() => {
    const intervalId = setInterval(() => {
      if (nearestCloseTime) {
        setShowModal(true);
      }
    }, 1000);

    return intervalId;
  }, [nearestCloseTime]);

  useEffect(() => {
    const intervalId = createTimer();
    setCloseHourIntervalId(intervalId);

    return () => clearInterval(intervalId);
  }, [createTimer]);

  const handleCloseModal = useCallback(() => {
    if (nearestCloseTime) {
      setInternalCookie(null, OPEN_HOUR_MODAL_COOKIE_KEY, `${nearestCloseTime.getTime()}`, {
        secure: true,
        expires: nearestCloseTime,
        sameSite: true,
        path: '/',
      });

      if (closeHourIntervalId) {
        queryClient.refetchQueries(queryKeys);
        clearInterval(closeHourIntervalId);
      }
    }

    setShowModal(false);
  }, [closeHourIntervalId, nearestCloseTime, queryClient, queryKeys]);

  return (
    <React.Fragment>
      {children}
      <Dialog
        open={showModal}
        fullWidth
        maxWidth={'lg'}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            padding: 0,
            borderRadius: Token.borderRadius.default,
          },
        }}
      >
        <Stack padding={'xxl'}>
          <Stack alignItems={'flex-end'} marginBottom={'xs'}>
            <IconButton onClick={handleCloseModal} padding={0}>
              <CloseIcon width={10} height={10} />
            </IconButton>
          </Stack>
          <Stack spacing={'m'}>
            <Typography size={'s'} color={'uiRedPrimary'}>
              {t('products.location_about_to_close_label')}
            </Typography>
            <Typography size={'s'} color={'uiDarkSecondary'}>
              {t('products.location_about_to_close_description', { closeTime: format(nearestCloseTime || 0, 'HH:mm') })}
            </Typography>
          </Stack>
        </Stack>
      </Dialog>
    </React.Fragment>
  );
};

export default OpenHourReminderLayout;

import { useForm as useFormReactHook } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormProps {
  name: string;
}

const useForm = () => {
  const schema = yup.object().shape({
    name: yup.string().required(),
  });

  return useFormReactHook<FormProps>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });
};

export default useForm;

import React from 'react';
import { APP_MAX_WIDTH } from '@constants/common';
import Token from '@constants/token';
import { SwipeableDrawer as MUISwipeableDrawer, SwipeableDrawerProps } from '@mui/material';

const SwipeableDrawer: React.FC<SwipeableDrawerProps> = ({ children, ...props }) => {
  return (
    <MUISwipeableDrawer
      PaperProps={{
        style: {
          maxWidth: APP_MAX_WIDTH,
          margin: 'auto',
          borderTopLeftRadius: Token.borderRadius.default,
          borderTopRightRadius: Token.borderRadius.default,
        },
      }}
      {...props}
    >
      {children}
    </MUISwipeableDrawer>
  );
};

export default SwipeableDrawer;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const LocationMarkerIcon: FC<Props> = ({ width = 14, height = 19, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 14 19'}>
        <path
          fill={fillColor}
          d={
            'M7.00084 1.52979C7.94426 1.52979 8.88858 1.77448 9.73472 2.26573C11.4286 3.2454 12.4686 5.0559 12.4686 7.01807C12.4686 8.3345 11.609 10.3054 10.3932 12.1811C9.35723 13.7825 8.08625 15.333 7.00084 16.4921C5.91424 15.333 4.64471 13.7825 3.60708 12.1811C2.39169 10.3054 1.53165 8.3345 1.53165 7.01807C1.53165 5.0559 2.57301 3.2454 4.26552 2.26573C5.11311 1.77448 6.05652 1.52979 7.00084 1.52979ZM7.55638 18.1244C8.81842 16.8562 10.3934 14.9991 11.679 13.0143C12.9596 11.0383 13.981 8.96351 13.9996 7.06071C14.001 7.02018 13.9984 6.97874 13.9984 6.94298C13.9716 4.46625 12.6443 2.18215 10.5015 0.941096C9.41965 0.314154 8.21035 0 7.00084 0C5.79159 0 4.58204 0.314154 3.49924 0.941055C1.33432 2.1939 0 4.51262 0 7.01807C0 8.93147 1.03266 11.0229 2.32294 13.0136C3.61205 15.0056 5.19412 16.8689 6.45897 18.137C6.75611 18.4324 7.20662 18.4543 7.55638 18.1244Z'
          }
        />
        <path
          fill={fillColor}
          d={
            'M7.0009 5.10621C7.89766 5.10621 8.61029 5.82141 8.61029 6.72525C8.61029 7.62813 7.89766 8.34147 7.0009 8.34147C6.10294 8.34147 5.39056 7.62813 5.39056 6.72525C5.39056 5.82141 6.10319 5.10621 7.0009 5.10621ZM7.0009 3.57617C5.27399 3.57617 3.85986 4.99692 3.85986 6.725C3.85986 8.45353 5.27403 9.87337 7.0009 9.87337C8.72802 9.87337 10.1422 8.45357 10.1422 6.725C10.1422 4.99717 8.72802 3.57617 7.0009 3.57617Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default LocationMarkerIcon;

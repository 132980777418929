import { useTranslation } from 'react-i18next';
import { object, setLocale, string } from 'yup';
import { useXenditCc } from '@hooks/xendit';

const useCreditCardFormSchema = () => {
  const { t } = useTranslation();

  const xendit = useXenditCc();

  const requiredErrorMessage = t('error.required_label');
  const cardExpiredPeriodErrorMessage = t('error.credit_card_expired_label');
  const cardAccountNumberErrorMessage = t('error.credit_card_account_number_label');
  const cardCvnErrorMessage = t('error.credit_card_cvn_label');

  const isValidCardPeriodTest = (value = '') => {
    const month = value?.split('/')?.[0];
    const years = `20${value?.split('/')?.[1]}`;
    return !!xendit.validateExpiry(month, years);
  };

  const isValidAccountNumberTest = (value = '') => {
    const cleanAccountNUmber = value?.replace(/ /g, '');
    return !!xendit.validateCardNumber(cleanAccountNUmber);
  };
  const isValidCvcTest = (value = '') => !!xendit.validateCvnNumber(value);

  setLocale({
    mixed: {
      notType: requiredErrorMessage,
      default: requiredErrorMessage,
      required: requiredErrorMessage,
    },
    string: {
      min: requiredErrorMessage,
    },
    number: {
      min: requiredErrorMessage,
    },
  });

  return object().shape({
    accountNumber: string()
      .required()
      .min(1)
      .test('isValidAccountNumberTest', cardAccountNumberErrorMessage, isValidAccountNumberTest),
    expiredPeriod: string()
      .required()
      .min(5)
      .test('isValidCardPeriodTest', cardExpiredPeriodErrorMessage, isValidCardPeriodTest),
    cvc: string().required().min(3).test('isValidCvcTest', cardCvnErrorMessage, isValidCvcTest),
  });
};

export default useCreditCardFormSchema;

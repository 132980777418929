import { ReactNode } from 'react';
import { Hoverable, Stack, Typography } from '@components/common';
import { CheckmarkIcon } from '@components/icons';

interface Props {
  isSelected: boolean;
  onClick: () => void;
  icon: ReactNode;
  label: string;
  disabled: boolean;
}

const PaymentMethodOption = ({ isSelected, onClick, icon, label, disabled }: Props) => {
  return (
    <Hoverable onClick={onClick} disabled={disabled}>
      <Stack
        background={'uiLightPrimary'}
        paddingX={'hm'}
        paddingY={'m'}
        borderRadius={'default'}
        justifyContent={'space-between'}
        flexDirection={'row'}
        alignItems={'center'}
        borderWidth={'bold'}
        borderColor={isSelected ? 'uiSuccessMain' : 'uiLightPrimary'}
      >
        <Stack direction={'row'} spacing={'xs'}>
          {icon}
          <Typography size={'s'} color={disabled ? 'uiDarkSecondary' : 'uiDarkPrimary'}>
            {label}
          </Typography>
        </Stack>
        {isSelected && <CheckmarkIcon />}
      </Stack>
    </Hoverable>
  );
};

export default PaymentMethodOption;

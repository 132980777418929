import { CSSProperties, ReactNode, Ref, forwardRef } from 'react';
import { SerializedStyles } from '@emotion/react';
import type { TokenBorderRadius, TokenSpacing } from '@interfaces/token';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';
import styles from './styles';

export interface TableProps
  extends Pick<CSSProperties, 'whiteSpace' | 'tableLayout' | 'overflow'>,
    Pick<MuiTableProps, 'stickyHeader'> {
  children?: ReactNode;
  borderSpacing?: {
    horizontal?: TokenSpacing;
    vertical?: TokenSpacing;
  };
  width?: string;
  height?: string;
  customStyles?: SerializedStyles;
  borderRadius?: TokenBorderRadius;
  borderTopRadius?: TokenBorderRadius;
  borderCollapse?: CSSProperties['borderCollapse'];
  grow?: number;
}

const Table = forwardRef(function TableWithRef(props: TableProps, ref?: Ref<HTMLTableElement>) {
  const {
    children,
    borderSpacing = {},
    width = '100%',
    tableLayout,
    height,
    customStyles,
    borderRadius,
    borderTopRadius,
    overflow,
    borderCollapse,
    grow,
    whiteSpace,
    ...tableProps
  } = props;
  return (
    <MuiTable
      ref={ref}
      {...tableProps}
      css={[
        tableLayout && styles.tableLayout(tableLayout),
        width && styles.width(width),
        height && styles.height(height),
        borderRadius && styles.borderRadius(borderRadius),
        borderTopRadius && styles.borderTopRadius(borderTopRadius),
        overflow && styles.overflow(overflow),
        borderCollapse && styles.borderCollapse(borderCollapse),
        grow && styles.grow(grow),
        whiteSpace && styles.whiteSpace(whiteSpace),
        styles.borderSpacing(borderSpacing),
        customStyles,
      ]}
    >
      {children}
    </MuiTable>
  );
});

export default Table;

import { CSSProperties } from 'react';
import { css } from '@emotion/react';

const overflowX = (value: CSSProperties['overflowX']) => css`
  overflow-x: ${value};
`;

const overflowY = (value: CSSProperties['overflowY']) => css`
  overflow-y: ${value};
`;

const disabled = css`
  &:hover {
    cursor: not-allowed;
  }
`;

const styles = {
  overflowX,
  overflowY,
  disabled,
};

export default styles;

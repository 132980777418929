import { useRouter } from 'next/router';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import AuthNavbar from '../AuthNavbar';

const OTPAuthNavbar = () => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const onPressBack = () =>
    router.replace({
      pathname: routes.PROFILE_LOGIN,
      query,
    });

  return <AuthNavbar onPressBack={onPressBack} />;
};

export default OTPAuthNavbar;

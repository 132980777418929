import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getSubBrands } from '@api/subBrands';
import { GetSubBrandsRequest, GetSubBrandsResponse } from '@api/subBrands/types';
import { QUERY_KEY_SUB_BRANDS } from '@constants/queryKey';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';

export interface GetSubBrandsQueryKeys {
  key: string;
  payload?: GetSubBrandsRequest;
}

type UseQuerySubBrandsOptions = UseQueryOptions<
  GetSubBrandsResponse,
  APIDefaultErrors,
  GetSubBrandsResponse,
  GetSubBrandsQueryKeys[]
>;

const useQuerySubBrands = (params?: Partial<GetSubBrandsRequest>, config?: UseQuerySubBrandsOptions) => {
  const { storageState } = useCommonStore();
  const { closedBillToken, openBillToken } = useCheckDineIn();
  const locationId = storageState.activeLocation?.id;

  const queryKeys: GetSubBrandsQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_SUB_BRANDS,
        payload: {
          locationId,
          ...(openBillToken
            ? {
                uuid: openBillToken,
              }
            : {}),
          ...(closedBillToken
            ? {
                closedBillToken,
              }
            : {}),
          ...params,
        },
      },
    ],
    [locationId, openBillToken, closedBillToken, params]
  );

  const { data, isFetching } = useQuery(queryKeys, ({ queryKey: [{ payload }] }) => getSubBrands(payload), {
    enabled: !!locationId,
    cacheTime: 300_000, // 5 minutes
    staleTime: 300_000, // 5 minutes
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
  };
};

export default useQuerySubBrands;

import { differenceInMilliseconds } from 'date-fns';
import React, { Dispatch, MutableRefObject, SetStateAction, useCallback, useMemo, useState } from 'react';
import InView from 'react-intersection-observer';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';
import { GetProductsResponse } from '@api/products/types';
import { Stack, Typography } from '@components/common';
import { NAVBAR_HEIGHT, PRODUCT_INPUT_SEARCH_HEIGHT, PRODUCT_TAG_FILTER_HEIGHT } from '@constants/common';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { GetProductsQueryKeys } from '@queries/products/useQueryProducts';
import { CartItem } from '@storage/types';
import { ProductCard } from '..';
import { ProductListLoader } from '../loaders';
import ProductCardPlaceholder from './ProductCardPlaceholder';

interface Props {
  disabled?: boolean;
  setActiveCategoryList?: Dispatch<SetStateAction<string[] | undefined>>;
  isLoading?: boolean;
  scrollDelay?: Date;
  virtuosoRef: MutableRefObject<VirtuosoHandle | null>;
  setScrolling: Dispatch<SetStateAction<boolean>>;
  products?: GetProductsResponse;
  queryKeys?: GetProductsQueryKeys[];
}

const ProductList: React.FC<Props> = (props) => {
  const { disabled, setActiveCategoryList, isLoading, products, queryKeys } = props;

  const [visibleRange, setVisibleRange] = useState({
    startIndex: 0,
    endIndex: 0,
  });

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const onSetItem = (productId: number, productCategoryId: number, productCategoryName: string) => (item: CartItem) => {
    updateProductCartItem(productId, {
      ...item,
      productCategoryId,
      productCategoryName,
      queryKeys,
    });
  };

  const productCategories = useMemo(() => products?.productCategories || [], [products?.productCategories]);
  const favoriteProductIds = useMemo(
    () => products?.favoriteMenu?.productIds || [],
    [products?.favoriteMenu?.productIds]
  );

  const onChangeIntersection = useCallback(
    (category: string) => (isVisible: boolean) => {
      const now = new Date();

      if (props?.scrollDelay && differenceInMilliseconds(now, props.scrollDelay) < 500) {
        return;
      }

      if (isVisible) {
        return setActiveCategoryList?.((prevState = []) => {
          if (prevState.includes(category)) {
            return prevState;
          }

          // NOTE: Since we virtualized product list, in some cases when user scroll too fast, the component may not be rendered out from view yet
          // and just got removed immediately from React Node due to how virtualization work.
          // This logic is to filter out categories that are not visible anymore and removed from virtualized area.
          const visibleCategoryNames = prevState?.flatMap((prevCategoryName) => {
            const index = productCategories?.findIndex(
              (currCategory) => currCategory?.productCategoryName === prevCategoryName
            );
            if (index >= visibleRange?.startIndex && index <= visibleRange?.endIndex) {
              return prevCategoryName;
            }

            return [];
          });

          const filteredCategories = prevState?.filter((prevCategoryName) =>
            visibleCategoryNames?.includes(prevCategoryName)
          );
          return [...filteredCategories, category];
        });
      }
      return setActiveCategoryList?.((prevState = []) => prevState?.filter((cat) => cat !== category));
    },
    [productCategories, props.scrollDelay, setActiveCategoryList, visibleRange?.endIndex, visibleRange?.startIndex]
  );

  const rootMargin = NAVBAR_HEIGHT + PRODUCT_INPUT_SEARCH_HEIGHT + PRODUCT_TAG_FILTER_HEIGHT;

  if (!isFinishLoadCommonStore || !isFinishLoadCartStore || isLoading) {
    return <ProductListLoader />;
  }

  return (
    <Stack direction={'column'} spacing={'xl'} position={'sticky'} top={rootMargin}>
      <Virtuoso
        useWindowScroll
        isScrolling={props.setScrolling}
        style={{
          display: 'flex',
          flexGrow: 1,
        }}
        components={{
          Item: ProductCardPlaceholder,
        }}
        data={productCategories}
        rangeChanged={setVisibleRange}
        ref={props.virtuosoRef}
        itemContent={(index, data) => (
          <Stack spacing={'m'}>
            <Stack padding={'xs'} background={'uiGreyLight'} borderRadius={'form'}>
              <Typography size={'hs'} variant={'medium'} color={'contentTertiary'} transform={'uppercase'}>
                {data.productCategoryName}
              </Typography>
            </Stack>
            <InView
              trackVisibility={true}
              rootMargin={`-${rootMargin}px 0px 0px 0px`}
              delay={100}
              onChange={onChangeIntersection(data.productCategoryName)}
            >
              <Stack direction={'column'} spacing={'xl'}>
                {data.products.map((product, pIdx) => (
                  <ProductCard
                    cartItem={branchCart[product.id]}
                    onChangeCartItem={onSetItem(product?.id, data.productCategoryId, data.productCategoryName)}
                    productCategoryId={data.productCategoryId}
                    productCategoryName={data.productCategoryName}
                    disabled={disabled}
                    product={product}
                    isFavorite={favoriteProductIds.includes(product.id)}
                    key={`c${index}-p${pIdx}`}
                    showBorder={data.products.length - 1 !== pIdx}
                  />
                ))}
              </Stack>
            </InView>
          </Stack>
        )}
      />
    </Stack>
  );
};

export default ProductList;

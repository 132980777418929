import { useQueryBrandInfo } from '@queries/commons';
import { PwaManifest, getDefaultManifestObject } from '@utils/common';

/**
 * useSetupPWA will fetch brand info and generate PWA manifest file.
 * Must be called first before any other component that call useQueryBrandInfo so the onSuccess will be triggered.
 */
const useSetupPWA = () => {
  const handleGenerateManifest = (defaultManifestObject: PwaManifest) => {
    const manifestElement = document.getElementById('manifest');

    if (manifestElement) {
      const manifestString = JSON.stringify(defaultManifestObject);
      const blob = new Blob([manifestString], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);

      manifestElement.setAttribute('href', manifestURL);

      // TODO-PWA: Need to check why we need this, somehow without this the manifest.json will not use the blob one
      const link = document.createElement('link');
      link.rel = 'manifest';
      link.setAttribute('href', 'data:application/json;charset=8' + manifestString);
    }
  };

  useQueryBrandInfo({
    onSuccess: ({ brand }) => {
      const defaultManifestObject = getDefaultManifestObject(window.location.origin);

      const primaryColor = brand?.mainColourTheme;
      const secondaryColor = brand?.secondaryColourTheme;
      const brandName = brand?.name;

      const shortName = brandName?.split(' ')?.[0] || '';
      const brandIcon = brand?.logoUrl;

      defaultManifestObject.name = brandName;
      defaultManifestObject.short_name = shortName;
      const newIcons = defaultManifestObject.icons?.map((icon) => ({
        ...icon,
        ...(brandIcon && { src: brandIcon }),
      }));
      defaultManifestObject.icons = newIcons;
      defaultManifestObject.theme_color = primaryColor;
      defaultManifestObject.background_color = secondaryColor;

      handleGenerateManifest(defaultManifestObject);
    },
    onError: () => {
      const defaultManifestObject = getDefaultManifestObject(window.location.origin);
      handleGenerateManifest(defaultManifestObject);
    },
  });
};

export default useSetupPWA;

import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { atomServiceWorker } from '@atoms/serviceWorker';
import { BeforeInstallPromptEvent } from '@interfaces/common';
import { isIOS, isPWAStandalone } from '@utils/navigator';

interface Props {
  onBeforeInstallPrompt?: () => void;
}

const useInstallPWA = ({ onBeforeInstallPrompt }: Props = {}) => {
  const [{ deferredPromptPWA }, setAtomServiceWorker] = useAtom(atomServiceWorker);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      onBeforeInstallPrompt?.();
      setAtomServiceWorker((prevState) => ({
        ...prevState,
        deferredPromptPWA: event,
      }));
    };

    window.addEventListener('beforeinstallprompt', (event) => {
      handleBeforeInstallPrompt(event as unknown as BeforeInstallPromptEvent);
    });

    return () => {
      // NOTE: Can't pass function with type when cleaning event listener
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt as any);
    };
  }, [onBeforeInstallPrompt, setAtomServiceWorker]);

  const handleInstall = useCallback(() => {
    if (deferredPromptPWA) {
      deferredPromptPWA.prompt();
      deferredPromptPWA.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('user accept A2HS prompt');
        }

        setAtomServiceWorker((prevState) => ({
          ...prevState,
          deferredPromptPWA: undefined,
        }));
      });
    }
  }, [deferredPromptPWA, setAtomServiceWorker]);

  const shouldShowPwaIos = isIOS && !isPWAStandalone;

  return useMemo(
    () => ({
      deferredPromptPWA,
      handleInstall,
      shouldShowPwaIos,
      isIOS,
      isPWAStandalone,
    }),
    [deferredPromptPWA, handleInstall, shouldShowPwaIos]
  );
};

export default useInstallPWA;

import React, { Dispatch, Fragment, MutableRefObject, SetStateAction, useState } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { VirtuosoHandle } from 'react-virtuoso';
import { Button, Dialog, LineSeparator, Stack, Transition, Typography } from '@components/common';
import { TypographyProps } from '@components/common/Typography';
import { NAVBAR_HEIGHT, PRODUCT_INPUT_SEARCH_HEIGHT } from '@constants/common';
import Token from '@constants/token';
import EndIcon from './EndIcon';

interface CategoryProps {
  name: string;
  total: number;
}

interface Props {
  categories: CategoryProps[];
  value?: string[];
  disabled?: boolean;
  isLoading?: boolean;
  onPress?: (category: string) => void;
  setScrollDelay: Dispatch<SetStateAction<Date | undefined>>;
  virtuosoRef: MutableRefObject<VirtuosoHandle | null>;
}

const ProductCategoryDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { categories = [], value = [], disabled, onPress, isLoading } = props;
  const activeButton = useMemo(
    () => categories?.find((category) => value?.includes(category.name)),
    [categories, value]
  );
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const getTagColor = useCallback(
    (tag: string) => {
      const isSelected = activeButton?.name === tag;
      const color = isSelected ? 'uiPrimaryMain' : ('uiDarkPrimary' as TypographyProps['color']);

      return {
        color,
      };
    },
    [activeButton]
  );

  const onPressTag = useCallback(
    (category: string) => () => {
      const offset = NAVBAR_HEIGHT + PRODUCT_INPUT_SEARCH_HEIGHT + Token.spacing.xl;
      const groupIndex = categories?.findIndex((group) => group.name === category);
      props.virtuosoRef?.current?.scrollToIndex?.({
        index: groupIndex,
        align: 'start',
        offset: -offset,
      });
      onPress?.(category);
      setOpen(false);
    },
    [categories, onPress, props.virtuosoRef]
  );

  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog} TransitionComponent={Transition}>
        <Stack paddingX={'m'} paddingY={'s'} width={240} borderRadius={'default'}>
          {categories.map((category, index) => (
            <Fragment key={index}>
              <Stack paddingY={'xxs'}>
                <Button onClick={onPressTag(category.name)} disabled={disabled} variant={'text'}>
                  <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Typography {...getTagColor(category.name)} textAlign={'start'}>
                      {category.name}
                    </Typography>
                    <Typography {...getTagColor(category.name)}>{category.total}</Typography>
                  </Stack>
                </Button>
              </Stack>
              <LineSeparator opacity={1} color={'borderDivide'} />
            </Fragment>
          ))}
        </Stack>
      </Dialog>
      <Button
        disabled={disabled || isLoading}
        borderRadius={Token.borderRadius.rounded}
        variant={'contained'}
        color={'secondary'}
        onClick={handleOpenDialog}
        startIcon={<EndIcon />}
        width={'max-content'}
      >
        <Typography variant={'bold'} transform={'uppercase'}>
          {t('products.menu')}
        </Typography>
      </Button>
    </>
  );
};

export default ProductCategoryDialog;

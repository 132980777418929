import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getOrderDetail } from '@api/order';
import { GetOrderDetailResponse } from '@api/order/types';
import { QUERY_KEY_ORDER_DETAIL } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface OrderDetailQueryKey {
  key: string;
  payload: {
    id: string;
  };
}

export const generateQueryKey = (id: string) => ({
  key: QUERY_KEY_ORDER_DETAIL,
  payload: { id },
});

export type UseQueryOrderDetailOptions = UseQueryOptions<
  GetOrderDetailResponse,
  APIDefaultErrors,
  GetOrderDetailResponse,
  OrderDetailQueryKey[]
>;

const useQueryOrderDetail = (id: string, config?: UseQueryOrderDetailOptions) => {
  const { isLoggedIn } = getUserCookies();
  const queryKeys: OrderDetailQueryKey[] = useMemo(() => [generateQueryKey(id)], [id]);

  const { data, isFetching, isLoading, isError, isSuccess } = useQuery(
    queryKeys,
    ({ queryKey: [{ payload }] }) => getOrderDetail(payload.id),
    {
      enabled: isLoggedIn && !!id,
      ...config,
    }
  );

  return {
    queryKeys,
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useQueryOrderDetail;

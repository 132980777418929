import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Stack, Typography } from '@components/common';
import { FavoriteIcon } from '@components/icons';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { GetProductsQueryKeys } from '@queries/products/useQueryProducts';
import { CartItem } from '@storage/types';
import { FavoriteProductListLoader } from '../loaders';
import FavoriteProductCard from './FavoriteProductCard';

interface Props {
  favoriteProductList: Product[];
  disabled?: boolean;
  isLoading?: boolean;
  queryKeys?: GetProductsQueryKeys[];
}

const FavoriteProductList: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { favoriteProductList = [], disabled, isLoading, queryKeys } = props;

  const { storageState, isFinishInitiated: isFinishLoadCommonStore } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const {
    updateProductCartItem,
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
  } = useCartStore(activeLocationId);

  const onSetItem = (productId: number) => (item: CartItem) => {
    updateProductCartItem(productId, { ...item, queryKeys });
  };

  if (isLoading || !isFinishLoadCommonStore || !isFinishLoadCartStore) {
    return <FavoriteProductListLoader />;
  }

  return (
    <Stack direction={'column'} spacing={'xs'} marginBottom={'m'}>
      <Stack
        padding={'xs'}
        direction={'row'}
        spacing={'xs'}
        alignItems={'center'}
        background={'uiGreyLight'}
        borderRadius={'form'}
      >
        <FavoriteIcon width={16} height={16} />
        <Typography variant={'medium'} size={'hs'} color={'contentTertiary'} transform={'uppercase'}>
          {t('products.favorite_label')}
        </Typography>
      </Stack>
      <Stack spacing={'xs'} overflow={'auto'} direction={'row'}>
        {favoriteProductList.map((product, index) => (
          <FavoriteProductCard
            disabled={disabled}
            product={product}
            key={index}
            cartItem={branchCart[product.id]}
            onChangeCartItem={onSetItem(product?.id)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default FavoriteProductList;

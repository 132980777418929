import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubBrandDetail } from '@api/subBrands/types';
import { Image, OptionCard } from '@components/common';
import { useCommonStore } from '@hooks/storage';

interface Props {
  onPress?: () => void;
  subBrand: SubBrandDetail;
}

const SubBrandCard: React.FC<Props> = ({ subBrand, onPress }) => {
  const { t } = useTranslation();
  const { storageState } = useCommonStore();
  const isSelected = storageState?.subBrand?.id === subBrand?.id;

  return (
    <OptionCard
      onPress={onPress}
      option={{
        name: subBrand.name,
        description: [subBrand.location.name, subBrand.location.address].join(', '),
      }}
      isSelected={isSelected}
      imageComponent={
        <Image
          width={40}
          height={40}
          src={subBrand.imageUrl}
          alt={t('general.alt_logo_image', { brandName: subBrand.name })}
          objectFit={'contain'}
        />
      }
    />
  );
};

export default SubBrandCard;

import { CSSProperties, MouseEvent, ReactNode, Ref, forwardRef } from 'react';
import styles from './styles';

interface Props extends Partial<Pick<HTMLAnchorElement, 'target'>>, Pick<CSSProperties, 'display' | 'flexGrow'> {
  href?: string;
  disabled?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  children: ReactNode;
}
const HoverableWithRef = forwardRef(function Hoverable(
  { href, disabled, onClick, target, children, display, flexGrow }: Props,
  ref?: Ref<HTMLAnchorElement>
) {
  return (
    <a
      ref={ref}
      href={href}
      onClick={disabled ? undefined : onClick}
      target={target}
      css={[
        styles.link,
        ...(display ? [styles.display(display)] : []),
        ...(flexGrow ? [styles.flexGrow(flexGrow)] : []),
        ...(disabled ? [styles.disabled] : []),
      ]}
    >
      {children}
    </a>
  );
});

export default HoverableWithRef;

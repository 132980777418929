import { ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonStore } from '@hooks/storage';
import { useQueryProfileDetail } from '@queries/auth';
import { getUserCookies } from '@utils/auth';
import { setUser } from '@utils/sentry';

interface Props {
  children?: ReactNode;
}

const ProfileLayout = ({ children }: Props) => {
  const { isLoggedIn } = getUserCookies();
  const { updateStorage, storageState, isFinishInitiated } = useCommonStore();
  const { i18n } = useTranslation();

  const { data, isFetching: isFetchingProfile } = useQueryProfileDetail({
    enabled: isLoggedIn,
  });

  const defaultLocale = useMemo(
    () => storageState.language || data?.activeLanguage || i18n?.language || 'en',
    [storageState.language, data?.activeLanguage, i18n?.language]
  );

  useEffect(() => {
    i18n.changeLanguage(defaultLocale);
  }, [defaultLocale, i18n]);

  useEffect(() => {
    if (isFinishInitiated && !storageState.language) {
      if (isLoggedIn && data) {
        updateStorage({ language: data.activeLanguage });
        return;
      }
      updateStorage({ language: 'en' });
    }
  }, [data, isFinishInitiated, isLoggedIn, storageState.activeLocation, storageState.language, updateStorage]);

  useEffect(() => {
    if (!isFetchingProfile && data?.isConfirmed) {
      setUser({
        id: data.id ? `${data.id}` : '',
        email: data.email || '',
        username: data.name || '',
        contactNumber: data.contactNumber || '',
      });
    }
  }, [data?.contactNumber, data?.email, data?.id, data?.isConfirmed, data?.name, isFetchingProfile]);

  return <>{children}</>;
};

export default ProfileLayout;

import Token from '@constants/token';
import { css } from '@emotion/react';
import { TokenBorderRadius } from '@interfaces/token';

const borderRadius = (value: TokenBorderRadius) => css`
  border-radius: ${Token.borderRadius[value]};
`;

const styles = {
  borderRadius,
};

export default styles;

import { FC } from 'react';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

const IconDiscount: FC<IconProps> = ({ width = 22, height = 22, ...baseIconProps }) => {
  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 16 16'} fill={'none'}>
        <g clipPath={'url(#clip0_12532_282)'}>
          <path
            d={
              'M14.9361 8.20726C14.8717 8.07554 14.8717 7.92448 14.9361 7.79279L15.5329 6.57205C15.8651 5.89234 15.6019 5.08219 14.9336 4.72759L13.7332 4.09072C13.6037 4.02204 13.515 3.89979 13.4896 3.75544L13.2549 2.41704C13.1242 1.67186 12.4348 1.17111 11.6859 1.27708L10.3405 1.46739C10.1953 1.48789 10.0517 1.44121 9.94633 1.3393L8.96974 0.394556C8.42596 -0.131503 7.57409 -0.131534 7.03035 0.394556L6.05373 1.33936C5.94835 1.4413 5.80476 1.48789 5.65957 1.46746L4.31418 1.27714C3.56496 1.17111 2.8759 1.67192 2.74519 2.41711L2.51044 3.75544C2.48509 3.89982 2.39631 4.02204 2.26684 4.09075L1.06654 4.72762C0.39823 5.08218 0.134982 5.8924 0.46723 6.57211L1.06395 7.79283C1.12832 7.92454 1.12832 8.07561 1.06395 8.20729L0.467199 9.42803C0.13495 10.1077 0.398199 10.9179 1.06651 11.2725L2.26681 11.9094C2.39631 11.9781 2.48509 12.1003 2.51044 12.2446L2.74519 13.583C2.86418 14.2614 3.44587 14.7372 4.1144 14.7371C4.18024 14.7371 4.24705 14.7325 4.31421 14.723L5.65961 14.5327C5.8047 14.5121 5.94839 14.5588 6.05376 14.6608L7.03035 15.6055C7.30228 15.8686 7.65109 16.0001 8.00003 16C8.34887 16 8.6979 15.8685 8.96971 15.6055L9.94633 14.6608C10.0517 14.5588 10.1953 14.5123 10.3405 14.5327L11.6859 14.723C12.4352 14.829 13.1242 14.3282 13.2549 13.583L13.4896 12.2447C13.515 12.1003 13.6037 11.9781 13.7332 11.9094L14.9335 11.2725C15.6018 10.9179 15.8651 10.1077 15.5328 9.428L14.9361 8.20726Z'
            }
            fill={'#12A56F'}
          />
          <path
            d={
              'M15.5328 6.57202C15.8651 5.89231 15.6018 5.08216 14.9335 4.72757L13.7332 4.09069C13.6037 4.02201 13.5149 3.89976 13.4896 3.75542L13.2548 2.41702C13.1241 1.67183 12.4348 1.17109 11.6859 1.27706L10.3405 1.46737C10.1952 1.48787 10.0516 1.44118 9.9463 1.33927L8.96971 0.394529C8.69781 0.13153 8.3489 0 8 0V15.9999C8.34884 15.9999 8.69787 15.8684 8.96968 15.6054L9.9463 14.6606C10.0517 14.5587 10.1953 14.5122 10.3405 14.5325L11.6859 14.7229C12.4352 14.8289 13.1241 14.3281 13.2548 13.5829L13.4896 12.2446C13.5149 12.1002 13.6037 11.978 13.7332 11.9092L14.9335 11.2724C15.6018 10.9178 15.865 10.1076 15.5328 9.42788L14.9361 8.20717C14.8717 8.07545 14.8717 7.92439 14.9361 7.79271L15.5328 6.57202Z'
            }
            fill={'#12A56F'}
          />
          <path
            d={
              'M11.3713 4.62855C11.1912 4.44836 10.899 4.44836 10.7188 4.62855L4.62855 10.7188C4.44836 10.899 4.44836 11.1912 4.62855 11.3714C4.71864 11.4614 4.83673 11.5065 4.9548 11.5065C5.07286 11.5065 5.19098 11.4615 5.28104 11.3714L11.3713 5.28111C11.5515 5.10089 11.5515 4.80877 11.3713 4.62855Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M10.7189 4.62855L8 7.34744V8.65249L11.3714 5.28108C11.5516 5.10089 11.5516 4.80873 11.3714 4.62855C11.1912 4.44836 10.899 4.44836 10.7189 4.62855Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M6.15448 7.23108C7.08735 7.23108 7.84631 6.47212 7.84631 5.53925C7.84631 4.60638 7.08735 3.84741 6.15448 3.84741C5.22161 3.84741 4.46265 4.60638 4.46265 5.53925C4.46265 6.47212 5.22161 7.23108 6.15448 7.23108Z'
            }
            fill={'white'}
          />
          <path
            d={
              'M9.84564 8.76904C8.91277 8.76904 8.15381 9.52801 8.15381 10.4609C8.15381 11.3937 8.91277 12.1527 9.84564 12.1527C10.7785 12.1527 11.5375 11.3937 11.5375 10.4609C11.5375 9.52801 10.7785 8.76904 9.84564 8.76904Z'
            }
            fill={'white'}
          />
        </g>
        <defs>
          <clipPath id={'clip0_12532_282'}>
            <rect width={'16'} height={'16'} fill={'white'} />
          </clipPath>
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default IconDiscount;

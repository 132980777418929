import { merge, omit } from 'lodash';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { Typography } from '@components/common';
import { Alert, AlertProps, Snackbar, SnackbarProps } from '@mui/material';

type SnackbarConfig = Omit<SnackbarProps, 'open' | 'onClose'> & {
  alertProps?: AlertProps;
};
interface SnackbarAction {
  openSnackbar: (config: SnackbarConfig) => void;
  closeSnackbar?: VoidFunction;
}

export const SnackbarContext = createContext<SnackbarAction>({
  openSnackbar: () => null,
});

const SNACKBAR_DEFAULT_CONFIG: SnackbarConfig = {
  autoHideDuration: 5000,
  alertProps: {
    severity: 'info',
    variant: 'filled',
  },
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

const SnackbarProvider: React.FC = ({ children }) => {
  const [snackbarConfig, setSnackbarConfig] = useState<SnackbarConfig>();
  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  const openSnackbar = useCallback((config: SnackbarConfig) => {
    setSnackbarConfig({
      ...SNACKBAR_DEFAULT_CONFIG,
      ...config,
    });
    onOpen();
  }, []);

  const providerValue = {
    openSnackbar,
    closeSnackbar: onClose,
  };

  const snackbarProps = useMemo(
    () => merge(omit(SNACKBAR_DEFAULT_CONFIG, 'alertProps'), omit(snackbarConfig, 'alertProps')),
    [snackbarConfig]
  );

  const alertProps = useMemo(
    () => ({
      ...SNACKBAR_DEFAULT_CONFIG.alertProps,
      ...snackbarConfig?.alertProps,
    }),
    [snackbarConfig?.alertProps]
  );

  const renderSnackbarContent = useCallback(() => {
    if (snackbarConfig?.children) {
      return snackbarConfig?.children;
    }

    return (
      <Alert style={{ width: '100%' }} {...alertProps}>
        <Typography variant={'bold'}>{snackbarConfig?.title}</Typography>
        <Typography>{snackbarConfig?.message}</Typography>
      </Alert>
    );
  }, [alertProps, snackbarConfig?.children, snackbarConfig?.message, snackbarConfig?.title]);

  return (
    <SnackbarContext.Provider value={providerValue}>
      {children}
      <Snackbar open={open} onClose={onClose} {...snackbarProps}>
        {renderSnackbarContent()}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

import { useForm as useFormReactHook } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export interface FormProps {
  name?: string;
}

const useForm = () => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    name: yup.string().required(t('error.required_label')),
  });

  return useFormReactHook<FormProps>({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
  });
};

export default useForm;

import React from 'react';
import Token from '@constants/token';
import styled from '@emotion/styled';
import { Select as MUISelect, SelectProps as MUISelectProps, MenuItem, menuItemClasses } from '@mui/material';

const CustomSelect = styled(MUISelect)({
  background: Token.color.uiLightSecondary,
  border: `${Token.borderWidth.thick}px solid ${Token.color.borderSubtle}`,
  borderRadius: Token.borderRadius.default,
  '& fieldset': {
    border: `${Token.borderWidth.thick}px solid ${Token.color.borderSubtle}`,
    borderRadius: Token.borderRadius.default,
  },
});

const CustomMenuItem = styled(MenuItem)({
  background: Token.color.uiLightPrimary,
  borderRadius: Token.borderRadius.default,
  marginBottom: Token.spacing.xxs,
  [`&.${menuItemClasses.selected}`]: {
    background: Token.color.uiLightPrimary,
    border: `${Token.borderWidth.bold}px solid ${Token.color.uiSuccessMain}`,
  },
});

export interface SelectOption {
  label: string;
  key?: string;
  value: string | number;
}
export interface SelectProps extends MUISelectProps {
  options?: SelectOption[];
}

const Select: React.FC<SelectProps> = ({ options = [], children, ...props }) => (
  <CustomSelect
    MenuProps={{
      MenuListProps: {
        sx: {
          backgroundColor: Token.color.uiLightStain,
          padding: `${Token.spacing.xxs}px`,
        },
      },
    }}
    {...props}
  >
    {options.map((option, index) => (
      <CustomMenuItem key={option?.key || index} value={option.value}>
        {option.label}
      </CustomMenuItem>
    ))}
    {children}
  </CustomSelect>
);

export default Select;

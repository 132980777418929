import React from 'react';
import { Stack, Typography } from '@components/common';
import { Divider, Skeleton } from '@mui/material';

const SavedLocationListLoader: React.FC = () => {
  return (
    <Stack spacing={'xxl'}>
      {new Array(3).fill(null).map((_, index) => (
        <Stack key={index} spacing={'m'}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack flexGrow={1} overflow={'hidden'} direction={'row'} spacing={'s'} alignItems={'center'}>
              <Skeleton variant={'rectangular'} width={14} height={19} />
              <Stack spacing={'xs'} width={'90%'}>
                <Typography width={'40'} noWrap lineHeight={0.9} size={'hm'} variant={'bold'}>
                  <Skeleton variant={'text'} />
                </Typography>
                <Typography width={'90'} noWrap size={'hs'}>
                  <Skeleton variant={'text'} />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};

export default SavedLocationListLoader;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, LanguageDialog, MenuDialog, Stack, Typography } from '@components/common';
import { ChatIcon, LanguageIcon, MenuIcon } from '@components/icons';
import { SubBrandLocationPicker } from '@components/subBrands';
import { NAVBAR_HEIGHT } from '@constants/common';
import { useCheckDineIn } from '@hooks/dineIn';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';
import IconButton from '../IconButton';

const Navbar = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [openLanguageMenu, setOpenLanguageMenu] = useState(false);
  const { storageState } = useCommonStore();
  const { isDineIn, isReady } = useCheckDineIn();
  const locationContactNumberChat = storageState?.activeLocation?.contactNumberChat;

  const onGoToChatPage = () => {
    if (locationContactNumberChat) {
      window.open(`https://wa.me/${locationContactNumberChat}`, '_blank');
    }
  };

  const { data } = useQueryBrandInfo();

  const brandName = data?.brand?.name;
  const brandLogo = data?.brand?.logoUrl;
  const allowMultiBrand = data?.brand?.allowMultiBrand;

  const onOpen = () => {
    setOpenMenu(true);
  };

  const onClose = () => {
    setOpenMenu(false);
  };

  const onOpenLanguage = () => {
    setOpenLanguageMenu(true);
  };

  const onCloseLanguage = () => {
    setOpenLanguageMenu(false);
  };

  return (
    <Stack
      position={'sticky'}
      top={0}
      padding={'m'}
      direction={'row'}
      justifyContent={'space-between'}
      background={'uiLightPrimary'}
      zIndex={1}
      height={NAVBAR_HEIGHT}
      spacing={'s'}
      columnGap={'m'}
    >
      {allowMultiBrand ? (
        <SubBrandLocationPicker />
      ) : (
        <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
          <Image
            width={60}
            height={60}
            src={brandLogo}
            alt={t('general.alt_logo_image', { brandName })}
            objectFit={'contain'}
          />
          <Typography size={'m'} variant={'bold'}>
            {brandName}
          </Typography>
        </Stack>
      )}

      {/**
       * NOTE: In Dine in mode, we don't show chat restaurant icon.
       * And we move the menu icon to DineInHeader instead of global navbar.
       */}
      {isReady && !isDineIn && (
        <Stack direction={'row'} alignItems={'center'} spacing={'s'}>
          <IconButton padding={0} onClick={onOpenLanguage}>
            <LanguageIcon />
          </IconButton>
          <LanguageDialog onClose={onCloseLanguage} open={openLanguageMenu} />
          {locationContactNumberChat && (
            <IconButton onClick={onGoToChatPage} padding={0}>
              <ChatIcon />
            </IconButton>
          )}
          <IconButton onClick={onOpen} padding={0}>
            <MenuIcon />
          </IconButton>
          <MenuDialog open={openMenu} onClose={onClose} />
        </Stack>
      )}
    </Stack>
  );
};

export default Navbar;

import { ReactNode, Ref, forwardRef } from 'react';
import { TableBody as MuiTableBody } from '@mui/material';

interface Props {
  children?: ReactNode | ReactNode[];
  ref?: Ref<HTMLTableSectionElement>;
}

const TableBody = forwardRef(function TableBodyWithRef(
  { children, ...otherProps }: Props,
  ref?: Ref<HTMLTableSectionElement>
) {
  return (
    <MuiTableBody ref={ref} {...otherProps}>
      {children}
    </MuiTableBody>
  );
});

export default TableBody;

import { useRouter } from 'next/router';
import { useEffect } from 'react';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useQueryOpenBillOrder } from '@queries/dineIn';

/**
 * Redirect user based on the dine in order status
 */
const useDineInRedirectByStatusByStatus = () => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const { data: openBillDetail } = useQueryOpenBillOrder({
    enabled: true,
  });

  useEffect(() => {
    if (openBillDetail?.aasmState === 'processing_payment' && router.isReady) {
      router.push({
        pathname: routes.OPEN_BILL_DETAIL,
        query,
      });
    }
  }, [openBillDetail?.aasmState, query, router]);
};

export default useDineInRedirectByStatusByStatus;

import Token from '@constants/token';
import { css, keyframes } from '@emotion/react';
import { TokenColor } from '@interfaces/token';

const keyframesBlinking = (color: string) => keyframes`
  from, 20%, to {
    color: transparent;
    text-shadow:
      .25em 0 0 transparent,
      .5em 0 0 transparent;
  }

  40% {
    color: ${color};
    text-shadow:
      .25em 0 0 transparent,
      .5em 0 0 transparent;
  }

  60% {
    text-shadow:
      .25em 0 0 ${color},
      .5em 0 0 transparent;
  }

  80%, 100% {
    text-shadow:
      .25em 0 0 ${color},
      .5em 0 0 ${color};
  }
`;

const addLoadingText = (loadingColor: TokenColor) => {
  const color = Token.color?.[loadingColor];

  return css`
    &:after {
      content: ' .';
      animation: ${keyframesBlinking(color)} 1s steps(5, end) infinite;
    }
  `;
};

export default addLoadingText;

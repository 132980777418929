import React, { FocusEventHandler, InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { RefCallBack } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { CustomTextField, Hoverable, IconButton, Popper, Stack, Typography } from '@components/common';
import { CheckmarkIcon, ChevronIcon, ClearIcon, SearchIcon } from '@components/icons';
import { Skeleton } from '@mui/material';
import { useQueryPhoneCountries } from '@queries/auth';

export const defaultCountryCode = '62';
const defaultPhoneInputOptions = [{ key: '🇮🇩 +62', value: '62' }];

export interface PhoneNumber {
  contactNumber: string;
  countryCode: string;
}
interface Props {
  value?: PhoneNumber;
  onChange?: (value: PhoneNumber) => void;
  onChangeText?: (value: string) => void;
  valueText?: string;
  phoneInputRef?: RefCallBack;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  error?: string;
  label?: string;
  disabled?: boolean;
}

const PhoneInputField: React.FC<Props> = (props) => {
  const { onChangeText, valueText = '', label, value, onChange, phoneInputRef, onBlur, error, disabled } = props;
  const [anchorRef, setAnchorRef] = useState<HTMLInputElement>();
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLInputElement>(null);

  const contactNumber = value?.contactNumber || '';
  const countryCode = value?.countryCode || defaultCountryCode;

  const { t } = useTranslation();

  const [prefix, setPrefix] = useState(() => countryCode);
  const [suffix, setSuffix] = useState(() => contactNumber);
  const [prefixSearchKeyword, setPrefixSearchKeyword] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (wrapperRef?.current && event?.target && !wrapperRef.current.contains(event.target as Node)) {
      setAnchorRef(undefined);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (typeof open === 'boolean' && open && inputRef?.current) {
      setAnchorRef(inputRef.current);
    }
  }, [open]);

  const { data, isFetching: isFetchingPhoneCountries } = useQueryPhoneCountries({
    enabled: true,
  });

  useEffect(() => {
    const phoneCountries = data || defaultPhoneInputOptions;
    if (value) {
      const valuePrefix = phoneCountries.find((phone) => phone.value === prefix) || defaultPhoneInputOptions[0];
      setPrefix(valuePrefix.value);
      setSuffix(contactNumber);
    }

    if (valueText) {
      const valuePrefix =
        phoneCountries.find((phone) => new RegExp('^' + phone.value)?.test(valueText)) || defaultPhoneInputOptions[0];
      const valueSuffix = valueText.replace(valuePrefix.value, '');
      setPrefix(valuePrefix.value);
      setSuffix(valueSuffix);
    }
  }, [contactNumber, data, prefix, value, valueText]);

  const countryOptions = useMemo(() => data || defaultPhoneInputOptions, [data]);
  const selectedCountry = countryOptions?.find((country) => country.value === prefix);
  const selectedCountryLabel = selectedCountry && selectedCountry?.key;

  const handleOnChangePhoneNumber: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    const newSuffix = e?.target?.value.replace(/^0|\s/g, '');
    setSuffix(newSuffix);
    onChange?.({
      countryCode: prefix,
      contactNumber: newSuffix,
    });
    onChangeText?.(`${prefix}${newSuffix}`);
  };

  const handleOnChangePrefixPhoneNumber = (newPrefix: string) => () => {
    setPrefix(newPrefix);
    setOpen(false);
    setAnchorRef(undefined);
    onChangeText?.(`${newPrefix}${suffix}`);
    onChange?.({
      countryCode: newPrefix,
      contactNumber: suffix,
    });
  };

  const handleOnChangePrefixSearchKeyword: InputHTMLAttributes<HTMLInputElement>['onChange'] = (e) => {
    setPrefixSearchKeyword(e?.target?.value);
  };

  const handleOnClickSelectCountry: InputHTMLAttributes<HTMLInputElement>['onClick'] = () => {
    if (!disabled) {
      setOpen((prevState) => !prevState);
    }
  };

  const borderColor = open ? 'uiPrimaryMain' : 'borderSubtle';
  const backgroundColor = open ? 'uiLightPrimary' : 'uiLightSecondary';

  const renderCountrySelectPlaceholder = () => {
    if (isFetchingPhoneCountries) {
      return <Skeleton variant={'text'} width={51} />;
    }
    return <Typography color={disabled ? 'textMuted' : undefined}>{selectedCountryLabel}</Typography>;
  };

  const filteredCountryOptions = useMemo(
    () => countryOptions?.filter((option) => new RegExp(`^${prefixSearchKeyword}`)?.test(option.value)),
    [countryOptions, prefixSearchKeyword]
  );

  const isValidPhoneNumber = new RegExp(/^[0-9]{6,14}$/).test(`${prefix}${suffix}`);

  const renderCountrySelectOptions = () => {
    return (
      <Stack width={'100%'} direction={'column'} rowGap={'xs'}>
        {filteredCountryOptions.length ? (
          filteredCountryOptions.map((country, index) => (
            <Hoverable key={index} onClick={handleOnChangePrefixPhoneNumber(country?.value)}>
              <Stack
                paddingX={'s'}
                key={index}
                borderRadius={'default'}
                justifyContent={'space-between'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                <Typography size={'s'} key={index}>
                  {country.key}
                </Typography>
                {country?.value === prefix && <CheckmarkIcon width={16} height={16} />}
              </Stack>
            </Hoverable>
          ))
        ) : (
          <Stack
            paddingX={'hm'}
            paddingY={'m'}
            borderRadius={'default'}
            justifyContent={'center'}
            flexDirection={'row'}
            alignItems={'center'}
            borderWidth={'bold'}
            borderColor={'uiLightPrimary'}
          >
            <Typography size={'s'}>{t('sessions.no_data')}</Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  const InputEndIcon = () => {
    if (!suffix?.trim()) {
      return null;
    }

    return (
      <Stack marginLeft={'xs'}>{isValidPhoneNumber ? <CheckmarkIcon color={'uiPrimaryMain'} /> : <ClearIcon />}</Stack>
    );
  };

  return (
    <Stack direction={'column'} spacing={'xs'}>
      <Typography size={'hs'}>{label || t('sessions.input_number_label')}</Typography>
      <Stack direction={'row'} width={'100%'} spacing={'xs'} ref={wrapperRef}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          borderColor={borderColor}
          background={backgroundColor}
          borderWidth={'bold'}
          borderRadius={'default'}
          onClick={handleOnClickSelectCountry}
          paddingX={'xs'}
          spacing={'xs'}
          maxHeight={56}
          ref={inputRef}
        >
          {renderCountrySelectPlaceholder()}
          {!disabled && (
            <IconButton padding={0}>
              <Stack
                borderWidth={'thin'}
                borderColor={'borderSubtle'}
                borderRadius={'default'}
                background={'uiLightPrimary'}
                boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
                width={30}
                height={30}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <ChevronIcon rotate={-90} />
              </Stack>
            </IconButton>
          )}
        </Stack>
        <Stack flex={1}>
          <InputMask
            ref={phoneInputRef}
            onChange={handleOnChangePhoneNumber}
            mask={'999 999 999 999'}
            onBlur={onBlur}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore InputMask PropTypes is incorrect from the package.
            maskChar={null}
            value={suffix}
          >
            {() => (
              <CustomTextField
                error={!!error}
                helperText={error}
                placeholder={t('sessions.phone_number_placeholder')}
                inputProps={{
                  inputMode: 'numeric',
                }}
                InputProps={{
                  endAdornment: <InputEndIcon />,
                  disabled,
                }}
              />
            )}
          </InputMask>
        </Stack>
      </Stack>
      {open && (
        <Popper anchorRef={anchorRef} handleClickAway={handleClickAway} width={'fit-content'}>
          <Stack spacing={'xs'}>
            <Stack paddingTop={'s'} paddingLeft={'s'} paddingRight={'s'} spacing={'xs'}>
              <InputMask
                onChange={handleOnChangePrefixSearchKeyword}
                mask={'99999'}
                value={prefixSearchKeyword}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore InputMask PropTypes is incorrect from the package.
                maskChar={null}
              >
                {() => (
                  <CustomTextField
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    InputProps={{
                      autoFocus: true,
                      disabled,
                      startAdornment: <SearchIcon width={25} />,
                    }}
                    placeholder={t('sessions.search_country_label')}
                    size={'small'}
                  />
                )}
              </InputMask>
            </Stack>
            <Stack
              maxHeight={250}
              overflow={'auto'}
              background={'uiLightPrimary'}
              borderRadius={'default'}
              padding={'s'}
            >
              {renderCountrySelectOptions()}
            </Stack>
          </Stack>
        </Popper>
      )}
    </Stack>
  );
};

export default PhoneInputField;

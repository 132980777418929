import {
  XenditAuthenticationRequest,
  XenditCreateAuthenticationCallback,
  XenditCreateCCTokenCallback,
} from '@interfaces/xendit';

export interface CreateCCTokenPayload {
  amount: number;
  cardNumber: string;
  cardExpMonth: string;
  cardExpYear: string;
  cardCvn: string;
  isMultipleUse?: boolean;
}

const useXenditCc = () => {
  const validateCardNumber = (cardNumber: string) => window?.Xendit?.card.validateCardNumber(cardNumber);

  const validateExpiry = (month: string, year: string) => window?.Xendit?.card.validateExpiry(month, year);

  const validateCvnNumber = (cvn: string) => window?.Xendit?.card.validateCvn(cvn);

  const createToken = (payload: CreateCCTokenPayload, callback?: XenditCreateCCTokenCallback) => {
    const constructedPayload = {
      amount: payload?.amount,
      card_number: payload?.cardNumber,
      card_exp_month: payload?.cardExpMonth,
      card_exp_year: payload?.cardExpYear,
      card_cvn: payload?.cardCvn,
      is_multiple_use: payload?.isMultipleUse,
    };
    window?.Xendit?.card.createToken(constructedPayload, callback);
  };

  const createAuthentication = (request: XenditAuthenticationRequest, callback?: XenditCreateAuthenticationCallback) =>
    window?.Xendit?.card?.createAuthentication(request, callback);

  return {
    validateCardNumber,
    validateExpiry,
    validateCvnNumber,
    createToken,
    createAuthentication,
  };
};

export default useXenditCc;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '@api/products/types';
import { Button, Stack, Stepper, Tag, Typography } from '@components/common';
import { PencilIcon } from '@components/icons';
import Token from '@constants/token';
import { useProductPrice } from '@hooks/products';
import { Divider } from '@mui/material';
import { CustomItem } from '@storage/types';
import { getItemTagList } from '@utils/product';
import { getTotalPriceFromCustomItem } from '@utils/storage/cartStore';

interface Props {
  disabled?: boolean;
  item: CustomItem;
  product?: Product;
  onPressEditCustomItem: (item: CustomItem) => void;
  handleOnChangeTotalCustomItem?: (value: number) => void;
}

const ProductOptionSet = ({ disabled, item, product, onPressEditCustomItem, handleOnChangeTotalCustomItem }: Props) => {
  const { t } = useTranslation();
  const generateItemTags = useCallback(
    (item: CustomItem) => {
      if (product && item) {
        return getItemTagList(product, item);
      }

      return [];
    },
    [product]
  );

  const handleOnPressEditCustomItem = () => {
    onPressEditCustomItem(item);
  };

  const price = String(getTotalPriceFromCustomItem(item, product));
  const { label: priceLabel } = useProductPrice(price);

  return (
    <Stack direction={'column'} spacing={'s'}>
      <Stack direction={'column'}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} marginBottom={'xxs'}>
          <Stack direction={'column'}>
            <Typography size={'xl'} variant={'bolder'}>
              {product?.name || ''}
            </Typography>
            <Typography size={'hs'} color={'uiPrimaryMain'}>
              {item?.notes || ''}
            </Typography>
          </Stack>
          <Typography size={'xxl'} variant={'regular'}>
            {priceLabel}
          </Typography>
        </Stack>
        <Divider />
      </Stack>
      <Stack direction={'row'} alignItems={'center'} flexWrap={'wrap'}>
        {generateItemTags(item)?.map((tagProps, index) => (
          <Tag
            stackProps={{
              marginRight: 'xs',
              marginBottom: 'xs',
            }}
            key={index}
            text={tagProps?.text}
          />
        ))}
      </Stack>

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack borderWidth={'bolder'} padding={'xxs'} borderColor={'borderSubtle'} borderRadius={'default'}>
          <Button
            disabled={disabled}
            paddingRight={Token.spacing.s}
            paddingLeft={Token.spacing.s}
            startIcon={<PencilIcon />}
            variant={'text'}
            color={'secondary'}
            size={'small'}
            onClick={handleOnPressEditCustomItem}
          >
            <Typography size={'s'} variant={'bolder'}>
              {t('products.action_edit_customize_label')}
            </Typography>
          </Button>
        </Stack>

        <Stepper disabled={disabled} value={item?.qty} onChange={handleOnChangeTotalCustomItem} />
      </Stack>
    </Stack>
  );
};

export default ProductOptionSet;

import baseAPI, { handleDefaultError, handleDefaultSuccess } from '@api/baseAPI';
import { GetProductDetailAPI, GetProductDetailResponse, GetProductsAPI, GetProductsResponse } from './types';

const API_PRODUCTS = '/api/delivery/products';
const API_PRODUCT_DETAIL = (id: number) => `/api/delivery/products/${id}`;

export const getProducts: GetProductsAPI = async (request) => {
  if (typeof request?.locationId === 'undefined' || !request?.locationId) {
    return Promise.reject(new Error('Invalid request getProducts'));
  }

  return await baseAPI
    .get<GetProductsResponse>(API_PRODUCTS, { params: request })
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const getProductDetail: GetProductDetailAPI = async (request) => {
  if (typeof request?.id === 'undefined') {
    return Promise.reject(new Error('Invalid request getProductDetail'));
  }

  return await baseAPI
    .get<GetProductDetailResponse>(API_PRODUCT_DETAIL(request.id), { params: request })
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCommonPaymentFormSchema } from 'src/formSchemas';
import { PaymentMethod } from '@api/order/types';
import { Button, IconButton, Image, RHFTextField, Stack, Typography } from '@components/common';
import { CloseIcon } from '@components/icons';
import { yupResolver } from '@hookform/resolvers/yup';

interface FormProps {
  value: PaymentMethod;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  onClose: () => void;
}

const DanaForm: React.FC<FormProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onClose, value } = props;

  const { control, handleSubmit: handleFormSubmit } = useForm<PaymentMethod>({
    resolver: yupResolver(useCommonPaymentFormSchema()),
    defaultValues: {
      accountNumber: value?.type === 'dana' ? value.accountNumber : '',
    },
  });

  const handleSubmitPaymentMethod = useCallback(
    (value: PaymentMethod) => {
      onSubmit?.({
        ...value,
        type: 'dana',
      });
    },
    [onSubmit]
  );

  return (
    <Stack padding={'m'} spacing={'xxl'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack alignItems={'center'} direction={'row'} spacing={'xs'}>
          <Image width={24} src={'/payment-method-logo/dana.png'} />
          <Typography variant={'bold'} size={'xxl'}>
            {t('order.dana_form_title')}
          </Typography>
        </Stack>

        <IconButton onClick={onClose} padding={0}>
          <CloseIcon width={15} height={15} />
        </IconButton>
      </Stack>

      <RHFTextField
        name={'accountNumber'}
        control={control}
        label={t('order.dana_form_number_label')}
        inputProps={{
          inputProps: {
            inputMode: 'numeric',
          },
        }}
      />

      <Typography size={'xs'} color={'uiDarkSecondary'} textAlign={'center'}>
        {t('order.dana_form_description')}
      </Typography>

      <Button variant={'contained'} onClick={handleFormSubmit(handleSubmitPaymentMethod)}>
        <Typography size={'hm'} variant={'medium'}>
          {t('order.dana_submit_button_label')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default DanaForm;

import { FC } from 'react';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

const IndonesiaFlagIcon: FC<IconProps> = ({ width = 22, height = 24, ...baseIconProps }) => {
  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 23 16'} fill={'none'}>
        <path
          d={
            'M19.9111 3.05176e-05H2.48889C1.1144 3.05176e-05 0 1.10218 0 2.46157V8.00003H22.4V2.46157C22.4 1.10218 21.2856 3.05176e-05 19.9111 3.05176e-05Z'
          }
          fill={'#DC1F26'}
        />
        <path
          d={
            'M22.4 13.5385C22.4 14.8979 21.2856 16 19.9111 16H2.48889C1.1144 16 0 14.8979 0 13.5385V8.00003H22.4V13.5385Z'
          }
          fill={'#EEEEEE'}
        />
      </svg>
    </BaseIcon>
  );
};

export default IndonesiaFlagIcon;

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { CustomerAddress } from '@api/locations/types';
import { Button, Stack, Typography } from '@components/common';
import { LocationMarkerIcon } from '@components/icons';
import { DeliveryAddressAutoComplete } from '@components/order';
import config from '@constants/config';
import { DEFAULT_LOCATION_SEARCH_SPEC } from '@constants/location';
import { Divider } from '@mui/material';
import { GoogleMap, GoogleMapProps, Marker, MarkerProps } from '@react-google-maps/api';

const GOOGLE_MAPS_ZOOM_LEVEL = 17;

const containerStyle = {
  width: '100%',
  height: '400px',
};

interface Props {
  open?: boolean;
  initialValue: Pick<CustomerAddress, 'address' | 'latitude' | 'longitude'>;
  onSubmit: (value: Pick<CustomerAddress, 'address' | 'latitude' | 'longitude'>) => void;
}
const GoogleMapsForm: React.FC<Props> = ({ open, onSubmit, initialValue }) => {
  const initialMarkerPosition = useMemo(() => {
    const latitude = Number(initialValue.latitude);
    const longitude = Number(initialValue.longitude);

    if (isNaN(latitude) || isNaN(longitude)) {
      return {
        latitude: Number(DEFAULT_LOCATION_SEARCH_SPEC.latitude),
        longitude: Number(DEFAULT_LOCATION_SEARCH_SPEC.longitude),
      };
    }

    return { latitude, longitude };
  }, [initialValue.latitude, initialValue.longitude]);

  const { latitude, longitude } = initialMarkerPosition;

  const snackbarContext = useContext(SnackbarContext);

  const [markerPosition, setMarkerPosition] = useState(() => initialMarkerPosition);
  const [selectedAddress, setSelectedAddress] = useState(() => initialValue.address);

  const { t } = useTranslation();

  const getGeoLocation = useCallback(
    async (lng: string, lat: string) => {
      const geocoder = new window.google.maps.Geocoder();
      try {
        const res = await geocoder.geocode({ location: { lat: Number(lat), lng: Number(lng) } });
        setSelectedAddress(res.results?.[0]?.formatted_address);
      } catch (error) {
        const { message = t('error.general_error_label') } = error as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      }
    },
    [snackbarContext, t]
  );

  const onChangeMarkerPosition: MarkerProps['onDragEnd'] = (e) => {
    setMarkerPosition({
      latitude: Number(e.latLng?.lat()),
      longitude: Number(e.latLng?.lng()),
    });
  };

  const onClickGoogleMaps: GoogleMapProps['onClick'] = (e) => {
    setMarkerPosition({
      latitude: Number(e.latLng?.lat()),
      longitude: Number(e.latLng?.lng()),
    });
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      address: selectedAddress,
      latitude: String(markerPosition.latitude),
      longitude: String(markerPosition.longitude),
    });
  }, [markerPosition, onSubmit, selectedAddress]);

  useEffect(() => {
    if (open && markerPosition.longitude && markerPosition.latitude) {
      getGeoLocation(markerPosition.longitude?.toString(), markerPosition.latitude?.toString());
    }
  }, [getGeoLocation, markerPosition.latitude, markerPosition.longitude, open]);

  const position = useMemo(
    () => ({
      lat: markerPosition.latitude,
      lng: markerPosition.longitude,
    }),
    [markerPosition.latitude, markerPosition.longitude]
  );

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: config.apiKey.googleMapApiKey,
    language: 'id-ID',
  });

  const onSelectOption = useCallback(
    (item: google.maps.places.AutocompletePrediction) => {
      placesService?.getDetails({ placeId: item?.place_id }, (res) => {
        const resLat = String(res?.geometry?.location?.lat());
        const resLng = String(res?.geometry?.location?.lng());

        setSelectedAddress(item?.description);
        setMarkerPosition({
          latitude: Number(resLat),
          longitude: Number(resLng),
        });
      });
    },
    [placesService]
  );

  return (
    <Stack spacing={'xxl'} paddingBottom={'m'}>
      <Stack alignItems={'center'} justifyContent={'center'}>
        <GoogleMap
          onClick={onClickGoogleMaps}
          mapContainerStyle={containerStyle}
          zoom={GOOGLE_MAPS_ZOOM_LEVEL}
          center={position}
        >
          <Stack width={30} height={30} borderRadius={'rounded'} borderWidth={'bold'}>
            <Marker
              position={position}
              onClick={onChangeMarkerPosition}
              draggable
              onDragEnd={onChangeMarkerPosition}
              icon={'/images/user-location-marker.png'}
            />
          </Stack>
        </GoogleMap>
      </Stack>

      <Stack spacing={'s'} flexGrow={1} flexDirection={'column'} alignContent={'center'}>
        <Stack direction={'row'} spacing={'xxs'} alignItems={'flex-start'}>
          <LocationMarkerIcon />
          <Typography size={'s'}>{selectedAddress}</Typography>
        </Stack>
        <DeliveryAddressAutoComplete
          latitude={latitude}
          longitude={longitude}
          getPlacePredictions={getPlacePredictions}
          placePredictions={placePredictions}
          defaultValue={selectedAddress}
          onSelectOption={onSelectOption}
        />
        <Divider />
      </Stack>
      <Button onClick={handleSubmit} variant={'contained'}>
        <Typography>{t('order.google_maps_submit_label')}</Typography>
      </Button>
    </Stack>
  );
};

export default GoogleMapsForm;

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getUserBalance } from '@api/order';
import { GetUserBalanceResponse } from '@api/order/types';
import { QUERY_KEY_USER_BALANCE } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

interface UserBalanceQueryKey {
  key: string;
}

type useQueryUserBalanceOptions = UseQueryOptions<
  GetUserBalanceResponse,
  APIDefaultErrors,
  GetUserBalanceResponse,
  UserBalanceQueryKey[]
>;

const useQueryUserBalance = (config?: useQueryUserBalanceOptions) => {
  const { enabled, ...otherConfig } = config ?? {};
  const { isLoggedIn } = getUserCookies();
  const queryKeys: UserBalanceQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_USER_BALANCE,
      },
    ],
    []
  );

  const { data, isFetching, isLoading, isError, isSuccess } = useQuery(queryKeys, getUserBalance, {
    enabled: enabled && isLoggedIn,
    ...otherConfig,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useQueryUserBalance;

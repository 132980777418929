import Token from '@constants/token';
import { css } from '@emotion/react';
import type { TokenBorderRadius, TokenSpacing } from '@interfaces/token';
import { TableProps } from '.';

const borderSpacing = (value: { horizontal?: TokenSpacing; vertical?: TokenSpacing }) => css`
  border-spacing: ${value.horizontal ? Token.spacing[value.horizontal] : 0}px
    ${value.vertical ? Token.spacing[value.vertical] : 0}px;
`;

const width = (value: string) => css`
  width: ${value};
`;

const height = (value: string) => css`
  height: ${value};
`;

const tableLayout = (value: TableProps['tableLayout']) => css`
  table-layout: ${value};
`;

const borderRadius = (value: TokenBorderRadius) => css`
  border-radius: ${Token.borderRadius[value]};
`;

const borderTopRadius = (value: TokenBorderRadius) => css`
  border-top-left-radius: ${Token.borderRadius[value]};
  border-top-right-radius: ${Token.borderRadius[value]};
`;

const overflow = (value: TableProps['overflow']) => css`
  overflow: ${value};
`;

const whiteSpace = (value: TableProps['whiteSpace']) => css`
  white-space: ${value};
`;

const grow = (value: TableProps['grow']) => css`
  flex-grow: ${value};
`;

const borderCollapse = (value: TableProps['borderCollapse']) => css`
  border-collapse: ${value};
`;

const styles = {
  width,
  height,
  tableLayout,
  whiteSpace,
  borderSpacing,
  borderRadius,
  borderTopRadius,
  overflow,
  borderCollapse,
  grow,
};

export default styles;

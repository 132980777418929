import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { Product } from '@api/products/types';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { calcDecimal } from '@utils/number';
import { getTotalPriceFromProductCart } from '@utils/storage/cartStore';

interface Props {
  products: Product[];
}

const useProductCartTotalPrices = ({ products = [] }: Props) => {
  const { storageState } = useCommonStore();
  const activeLocationId = storageState.activeLocation?.id;
  const { branchCart } = useCartStore(activeLocationId);

  const fallbackDecimal = calcDecimal(0) as Decimal;

  const totalPrice = useMemo(() => {
    const branchCartProductIds = Object.keys(branchCart) || [];

    return branchCartProductIds
      ?.reduce((accumulator, productId) => {
        const cartItem = branchCart[Number(productId)];
        const product = products.find(({ id }) => Number(productId) === id);

        return accumulator.add(getTotalPriceFromProductCart(cartItem, product));
      }, fallbackDecimal)
      .toNumber();
  }, [branchCart, fallbackDecimal, products]);

  return totalPrice;
};

export default useProductCartTotalPrices;

import { isSameDay } from 'date-fns';
import { useRouter } from 'next/router';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Stack, Typography } from '@components/common';
import { CloseIcon } from '@components/icons';
import { APP_MAX_WIDTH, PWA_BANNER_ZINDEX, PWA_IGNORE_BANNER_FLAG } from '@constants/common';
import { routeRegexDineInPaymentSuccess } from '@constants/routes';
import { useInstallPWA } from '@hooks/common';
import { useQueryBrandInfo } from '@queries/commons';
import { isMatchArrayByRegex } from '@utils/string';
import Button from '../Button';

const InstallPrompt = () => {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const [hideBanner, setHideBanner] = useState(false);

  const checkIfHasBeenIgnoredToday = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    const lastPWAIgnoredFlag = window.sessionStorage.getItem(PWA_IGNORE_BANNER_FLAG);
    if (lastPWAIgnoredFlag) {
      const parsedDatetime = parseInt(lastPWAIgnoredFlag);
      if (typeof parsedDatetime === 'number' && !isNaN(parsedDatetime)) {
        return isSameDay(new Date(parsedDatetime), new Date());
      }
    }
    return false;
  };

  const hasBeenIgnoredToday = checkIfHasBeenIgnoredToday();

  const onBeforeInstallPrompt = useCallback(() => {
    setHideBanner(hasBeenIgnoredToday);
  }, [hasBeenIgnoredToday]);

  const { deferredPromptPWA, handleInstall, shouldShowPwaIos, isIOS, isPWAStandalone } = useInstallPWA({
    onBeforeInstallPrompt,
  });

  const { isFetching, data } = useQueryBrandInfo();

  const handleCloseBanner = () => {
    setHideBanner(true);
    window.sessionStorage.setItem(PWA_IGNORE_BANNER_FLAG, `${new Date().getTime()}`);
  };

  const pageDontHaveInstallPrompt = useMemo(
    () => !isMatchArrayByRegex(pathname, routeRegexDineInPaymentSuccess),
    [pathname]
  );

  if (
    !hideBanner &&
    !isFetching &&
    data?.brand &&
    (deferredPromptPWA || shouldShowPwaIos) &&
    pageDontHaveInstallPrompt &&
    !hasBeenIgnoredToday
  ) {
    return (
      <Stack background={'uiLightSoftPrimary'} width={'100%'} maxWidth={APP_MAX_WIDTH} zIndex={PWA_BANNER_ZINDEX}>
        <Stack
          padding={'s'}
          spacing={'s'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          position={'relative'}
        >
          <Stack spacing={'s'} direction={'row'} alignItems={'center'}>
            <IconButton onClick={handleCloseBanner} padding={0}>
              <CloseIcon width={15} height={15} />
            </IconButton>
            {/** TODO-PWA: Wait designer to provide svg image here and figure out if there's better way to render image between long texts */}
            <Stack spacing={'s'} direction={'column'} alignContent={'center'}>
              <Typography>{t('actions.install_description')}</Typography>
              {shouldShowPwaIos && (
                <Typography isHTML>
                  {t('general.install_pwa_in_ios_install_step', {
                    image: "<img src='/images/ios-share-icon.png' width='16' height='16' />",
                  })}
                </Typography>
              )}
            </Stack>
          </Stack>

          {!isPWAStandalone && !isIOS && (
            <Stack direction={'row'} spacing={'xs'}>
              <Button onClick={handleInstall} variant={'contained'}>
                <Typography>{t('actions.install')}</Typography>
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }

  return null;
};

export default InstallPrompt;

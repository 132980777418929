import { useEffect, useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getProfile } from '@api/auth';
import { GetProfileResponse } from '@api/auth/types';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { QUERY_KEY_USER_PROFILE } from '@constants/queryKey';
import { useCurrentProfile } from '@hooks/auth';

export interface GetProfileDetailQueryKeys {
  key: string;
}

type UseQueryProfileDetailOptions = UseQueryOptions<
  GetProfileResponse,
  APIDefaultErrors,
  GetProfileResponse,
  GetProfileDetailQueryKeys[]
>;

const useQueryProfileDetail = (config?: UseQueryProfileDetailOptions) => {
  const [_, setCurrentProfile] = useCurrentProfile();
  const queryKeys: GetProfileDetailQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_USER_PROFILE,
      },
    ],
    []
  );

  const { isError, data, isFetching, isSuccess } = useQuery(queryKeys, getProfile, {
    ...config,
  });

  useEffect(() => {
    setCurrentProfile((prevState) => ({
      ...prevState,
      detail: data,
    }));
  }, [data, setCurrentProfile]);

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
  };
};

export default useQueryProfileDetail;

import { OpenHourReminderLayout, SubdomainLayout } from '@layouts';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Hydrate, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import AuthDialogProvider from 'src/context/AuthDialogProvider';
import { commonStyle } from '@constants/common';
import { Errors500Container } from '@containers/errors';
import { Global } from '@emotion/react';
import { useBaseQueryConfig } from '@hooks/common';
import { AppPropsWithLayout } from '@interfaces/common';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import '@utils/i18n';

function AppContainer({ Component, pageProps }: Pick<AppPropsWithLayout, 'Component' | 'pageProps'>) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const defaultQueryConfig = useBaseQueryConfig();
  const [queryClient] = useState(() => new QueryClient(defaultQueryConfig));

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ReactQueryDevtools />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Global styles={commonStyle} />
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                fallbackRender={({ error, resetErrorBoundary }) => (
                  <Errors500Container onRetry={resetErrorBoundary} description={error?.message} name={error?.name} />
                )}
                onReset={reset}
              >
                <SubdomainLayout brandUrl={pageProps?.brandUrl}>
                  <OpenHourReminderLayout>
                    <AuthDialogProvider>{getLayout(<Component {...pageProps} />)}</AuthDialogProvider>
                  </OpenHourReminderLayout>
                </SubdomainLayout>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </LocalizationProvider>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default AppContainer;

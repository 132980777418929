import { CSSProperties, ReactNode, Ref, forwardRef } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import { CircularProgress, useTheme } from '@mui/material';
import { ButtonProps as MUIButtonProps, default as MuiButton } from '@mui/material/Button';
import { pickBy } from '@utils/lodash';
import getTokenColorFromPaletteOptions from '@utils/theme/getTokenColorFromPaletteOptions';

interface Props {
  children: ReactNode;
  onClick?: MUIButtonProps['onClick'];
  onMouseDown?: MUIButtonProps['onMouseDown'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  minHeight?: CSSProperties['minHeight'];
  minWidth?: CSSProperties['minWidth'];
  borderRadius?: CSSProperties['borderRadius'];
  /**
   * @deprecated
   * Should refactor this to accept string of Token.spacing
   */
  padding?: CSSProperties['padding'];
  /**
   * @deprecated
   * Should refactor this to accept string of Token.spacing
   */
  paddingLeft?: CSSProperties['paddingLeft'];
  /**
   * @deprecated
   * Should refactor this to accept string of Token.spacing
   */
  paddingRight?: CSSProperties['paddingRight'];
  zIndex?: CSSProperties['zIndex'];
  borderColor?: TokenColor;
  background?: TokenColor;
  isLoading?: boolean;
  stopPropagation?: boolean;
}

export type ButtonProps = Props & Omit<MUIButtonProps, 'borderColor'>;

const Button = forwardRef(function ButtonRef(
  { children, variant, onClick, onMouseDown, isLoading, stopPropagation, ...props }: ButtonProps,
  ref?: Ref<HTMLButtonElement>
) {
  const {
    width,
    height,
    minHeight,
    minWidth = 'auto',
    borderRadius = Token.borderRadius.default,
    padding,
    paddingLeft,
    paddingRight,
    zIndex,
    ...otherProps
  } = props;

  const theme = useTheme();
  const colorPalette = getTokenColorFromPaletteOptions(theme.palette);
  const borderColor = props.borderColor ? colorPalette[props.borderColor] : undefined;
  const background = props.background ? colorPalette[props.background] : undefined;

  const styles = pickBy(
    {
      width,
      height,
      minHeight,
      minWidth,
      borderRadius,
      padding,
      paddingLeft,
      paddingRight,
      zIndex,
      background,
      borderColor,
    },
    (el: unknown) => el !== undefined
  );

  const handleOnClick: ButtonProps['onClick'] = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    onClick?.(e);
  };

  const handleOnMouseDown: ButtonProps['onMouseDown'] = (e) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    onMouseDown?.(e);
  };

  return (
    <MuiButton
      style={styles}
      ref={ref}
      variant={variant}
      onMouseDown={handleOnMouseDown}
      onClick={otherProps?.disabled || isLoading ? undefined : handleOnClick}
      {...otherProps}
    >
      {isLoading ? <CircularProgress color={'secondary'} size={24} /> : children}
    </MuiButton>
  );
});

export default Button;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const CloseIcon: FC<Props> = ({ width = 20, height = 20, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 20 20'}>
        <path
          fill={fillColor}
          d={
            'M11.1723 10.0052L19.7455 1.43202C20.0764 1.11246 20.0856 0.585184 19.766 0.254296C19.4464 -0.0765923 18.9192 -0.0857674 18.5883 0.233798C18.5814 0.240485 18.5745 0.247317 18.5678 0.254296L9.99455 8.82747L1.42137 0.254247C1.09048 -0.0653187 0.563206 -0.0561436 0.24364 0.274745C-0.0681178 0.597531 -0.0681178 1.10924 0.24364 1.43202L8.81682 10.0052L0.24364 18.5784C-0.0815388 18.9036 -0.0815388 19.4309 0.24364 19.7561C0.568867 20.0812 1.09614 20.0812 1.42137 19.7561L9.99455 11.1829L18.5677 19.7561C18.8986 20.0757 19.4259 20.0665 19.7455 19.7356C20.0572 19.4128 20.0572 18.9011 19.7455 18.5784L11.1723 10.0052Z'
          }
        />
      </svg>
    </BaseIcon>
  );
};

export default CloseIcon;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const FlagIcon: FC<Props> = ({ width = 22, height = 22, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 22 22'} fill={'none'}>
        <path
          d={
            'M18.8833 2.65835C18.6849 2.54298 18.4399 2.54298 18.2416 2.65835C17.4735 3.08451 16.6084 3.3055 15.73 3.3C14.4456 3.3284 13.2037 2.83959 12.2833 1.94335C11.2208 0.903289 9.78416 0.334984 8.29765 0.366695C7.1005 0.35634 5.93003 0.720414 4.95 1.40804V1.1C4.95 0.492508 4.45754 0 3.85 0C3.24246 0 2.75 0.492465 2.75 1.1V20.9C2.75 21.5075 3.24246 22 3.85 22C4.45749 22 4.95 21.5075 4.95 20.9V11.3667C5.07152 11.3553 5.18727 11.3095 5.28365 11.2347C6.156 10.5922 7.21437 10.2523 8.29765 10.2667C9.58439 10.2371 10.829 10.7259 11.7517 11.6233C12.8122 12.6619 14.2459 13.2301 15.73 13.2C16.8673 13.2132 17.9829 12.8877 18.9347 12.265C19.1383 12.1238 19.257 11.8893 19.25 11.6416V3.3C19.2523 3.03566 19.1122 2.79052 18.8833 2.65835Z'
          }
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default FlagIcon;

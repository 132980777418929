import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const PlusCircleIcon: FC<Props> = ({ width = 28, height = 28, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 28 28'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <g filter={'url(#filter0_d_8321_7413)'}>
          <circle cx={'14'} cy={'13'} r={'12'} fill={'white'} />
        </g>
        <path d={'M14 8V18'} stroke={fillColor} strokeWidth={'0.8'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M19 13H9'} stroke={fillColor} strokeWidth={'0.8'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <defs>
          <filter
            id={'filter0_d_8321_7413'}
            x={'0.5'}
            y={'0.25'}
            width={'27'}
            height={'27'}
            filterUnits={'userSpaceOnUse'}
            colorInterpolationFilters={'sRGB'}
          >
            <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
            <feColorMatrix
              in={'SourceAlpha'}
              type={'matrix'}
              values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
              result={'hardAlpha'}
            />
            <feOffset dy={'0.75'} />
            <feGaussianBlur stdDeviation={'0.75'} />
            <feColorMatrix
              type={'matrix'}
              values={'0 0 0 0 0.00302082 0 0 0 0 0.0984791 0 0 0 0 0.241667 0 0 0 0.15 0'}
            />
            <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow_8321_7413'} />
            <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow_8321_7413'} result={'shape'} />
          </filter>
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default PlusCircleIcon;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const PickupManIcon: FC<Props> = ({ width = 25, height = 25, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 25 25'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <g clipPath={'url(#clip0_1199_7243)'}>
          <path
            fillRule={'evenodd'}
            clipRule={'evenodd'}
            d={
              'M19.738 0H15.7828C14.5716 0 13.5855 0.986099 13.5855 2.19727V3.51524H12.4625C12.0579 3.51524 11.73 3.84331 11.73 4.24767C11.73 4.65221 12.0579 4.98009 12.4625 4.98009H13.6013C13.7877 7.12299 15.5869 8.78887 17.7603 8.78887C20.0659 8.78887 21.9353 6.91948 21.9353 4.61388C21.9353 3.80917 21.9353 3.00274 21.9353 2.19727C21.9353 0.986099 20.9492 0 19.738 0Z'
            }
            fill={fillColor}
          />
          <path
            fillRule={'evenodd'}
            clipRule={'evenodd'}
            d={
              'M16.0536 10.6218C15.5192 10.8631 15.0444 11.2127 14.6584 11.6421L10.7209 15.6738H5.22464C4.01462 15.6738 3.02737 16.6613 3.02737 17.8713C3.02737 19.0811 4.01481 20.0686 5.22464 20.0686H11.3772C12.0522 20.0686 12.7213 19.899 13.2239 19.3928L13.8787 18.7336V24.2674C13.8787 24.6222 14.1306 24.9327 14.4654 25.0002L21.2038 24.9998C21.6078 24.9994 21.9353 24.6716 21.9353 24.2674V14.4287C21.9353 11.421 18.8111 9.37729 16.0536 10.6218Z'
            }
            fill={fillColor}
          />
          <path
            fillRule={'evenodd'}
            clipRule={'evenodd'}
            d={
              'M5.04039 14.209H10.314C10.7186 14.209 11.0464 13.8811 11.0464 13.4766V8.25195C11.0464 7.8474 10.7186 7.51953 10.314 7.51953H8.40972V8.77762C8.40972 9.18217 8.08166 9.51004 7.6773 9.51004C7.27275 9.51004 6.94488 9.18217 6.94488 8.77762V7.51953H5.04039C4.63603 7.51953 4.30797 7.8474 4.30797 8.25195V13.4766C4.30797 13.8811 4.63603 14.209 5.04039 14.209Z'
            }
            fill={fillColor}
          />
        </g>
        <defs>
          <clipPath id={'clip0_1199_7243'}>
            <rect width={width} height={height} fill={'white'} />
          </clipPath>
        </defs>
      </svg>
    </BaseIcon>
  );
};

export default PickupManIcon;

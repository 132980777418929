import baseAPI, { handleDefaultError, handleDefaultSuccess, handleRefreshTokenError, serverBaseAPI } from '../baseAPI';
import {
  CreateSessionRequest,
  CreateSessionResponse,
  GetBrandInfoResponse,
  GetOTPRequest,
  GetPhoneCountriesResponse,
  GetProfileResponse,
  RefreshSessionRequest,
  RefreshSessionResponse,
  UpdateProfileRequest,
  UpsertTokenFirebaseRequest,
} from './types';

const API_SESSION = '/api/delivery/auth';
const API_PROFILE = 'api/delivery/profiles';
const API_REQUEST_OTP = '/api/delivery/auth/otp';
export const API_SESSION_REFRESH = '/api/delivery/auth/refresh';
const API_GET_COUNTRY = '/api/delivery/timezones/phone_countries';
const API_GET_BRAND_INFO = '/api/delivery/brands/info';
const API_UPSERT_TOKEN = '/api/delivery/web_push_tokens/';

export const createSession = async (request: CreateSessionRequest) => {
  return await baseAPI
    .post<CreateSessionResponse>(API_SESSION, {
      ...request,
      contactNumber: `${request.countryCode}${request.contactNumber}`,
    })
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const getOTP = async (request: GetOTPRequest) => {
  return await baseAPI
    .post(API_REQUEST_OTP, {
      contactNumber: `${request.phoneNumber.countryCode}${request.phoneNumber.contactNumber}`,
      countryCode: request.phoneNumber.countryCode,
      otpChannel: request.otpChannel,
    })
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const refreshSession = async (request: RefreshSessionRequest) => {
  return await baseAPI
    .post<RefreshSessionResponse>(API_SESSION_REFRESH, request)
    .then(handleDefaultSuccess)
    .catch((error) => handleRefreshTokenError(error));
};

export const getPhoneCountries = async () => {
  return await baseAPI
    .get<GetPhoneCountriesResponse>(API_GET_COUNTRY)
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const getBrandInfo = async () => {
  return await baseAPI
    .get<GetBrandInfoResponse>(API_GET_BRAND_INFO)
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const getServerBrandInfo =
  ({ brandUrl = '' }) =>
  async () => {
    return await serverBaseAPI({
      headers: {
        ...(brandUrl && { 'Brand-URL': brandUrl }),
      },
    })
      .get<GetBrandInfoResponse>(API_GET_BRAND_INFO)
      .then(handleDefaultSuccess)
      .catch((error) => handleDefaultError(error, { disableRedirect: true }));
  };

export const getProfile = async () => {
  return await baseAPI
    .get<GetProfileResponse>(API_PROFILE)
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const updateProfile = async (request: UpdateProfileRequest) => {
  return await baseAPI
    .patch(API_PROFILE, request)
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const upsertTokenFirebase = async (request: UpsertTokenFirebaseRequest) => {
  return await baseAPI
    .post(API_UPSERT_TOKEN, request)
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

export const deleteTokenFirebase = async (request: UpsertTokenFirebaseRequest) => {
  return await baseAPI
    .delete(API_UPSERT_TOKEN, {
      data: request,
    })
    .then(handleDefaultSuccess)
    .catch((error) => handleDefaultError(error, { disableRedirect: true }));
};

import Token from '@constants/token';
import { css, keyframes } from '@emotion/react';
import { TokenColor } from '@interfaces/token';

const keyframeStroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const keyframeScale = keyframes`
  0%, 100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const keyframeFill = (width: number, color: TokenColor) => keyframes`
  100% {
    box-shadow: inset 0px 0px 0px ${width / 2}px ${Token.color[color]};
  }
`;

const checkmark = (width: number, color: TokenColor) => css`
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${Token.color.uiLightPrimary};
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px ${Token.color[color]};
  animation: ${keyframeFill(width, color)} 0.4s ease-in-out 0.4s forwards, ${keyframeScale} 0.3s ease-in-out 0.9s both;
`;

const checkmarkCheck = (width: number, height: number) => css`
  transform-origin: 50% 50%;
  stroke-dasharray: ${width - 10};
  stroke-dashoffset: ${height - 10};
  animation: ${keyframeStroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`;

const checkmarkCircle = (width: number, height: number, color: TokenColor) => css`
  stroke-dasharray: ${width + 110};
  stroke-dashoffset: ${height + 110};
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${Token.color[color]};
  fill: none;
  animation: ${keyframeStroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`;

const styles = {
  checkmark,
  checkmarkCheck,
  checkmarkCircle,
};

export default styles;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const CurrentLocationIcon: FC<Props> = ({ width = 16, height = 16, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={'0 0 16 16'} fill={'none'}>
        <path d={'M8.00002 5.0909C6.39273 5.0909 5.09094 6.39273 5.09094 7.99998C5.09094 9.60723 6.39273 10.9091 8.00002 10.9091C9.6073 10.9091 10.9091 9.60727 10.9091 8.00002C10.9091 6.39277 9.60727 5.0909 8.00002 5.0909ZM14.5018 7.27272C14.1673 4.23999 11.76 1.83273 8.72728 1.49819V0H7.27272V1.49819C4.23999 1.83273 1.83273 4.23999 1.49819 7.27272H0V8.72728H1.49819C1.83273 11.76 4.24003 14.1673 7.27276 14.5018V16H8.72728V14.5018C11.76 14.1673 14.1673 11.76 14.5018 8.72728H16V7.27272H14.5018ZM8.00002 13.0909C5.18912 13.0909 2.90912 10.8109 2.90912 8.00002C2.90912 5.18912 5.18912 2.90912 8.00002 2.90912C10.8109 2.90912 13.0909 5.18908 13.0909 8.00002C13.0909 10.811 10.8109 13.0909 8.00002 13.0909Z'} fill={fillColor} />
      </svg>
    </BaseIcon>
  );
};

export default CurrentLocationIcon;

export const toInitials = (text = '', max = 2) => {
  return text
    .split(' ')
    .slice(0, max)
    .map((str) => str.charAt(0).toUpperCase())
    .join('');
};

interface MaskStringConfig {
  startIndex?: number;
  endIndex?: number;
  maskCharacter?: string;
}

export const maskString = (text = '', config?: MaskStringConfig) => {
  const cleanText = text?.replace(/ /g, '') ?? '';
  const defaultConfig = {
    maskCharacter: 'X',
    startIndex: Math.floor(cleanText.length / 3),
    endIndex: Math.floor((2 * cleanText.length) / 3) + 1,
  };
  const activeConfig = {
    ...defaultConfig,
    ...config,
  };
  const isIndexBetween = (index: number) => index >= activeConfig?.startIndex && index <= activeConfig?.endIndex;
  return cleanText
    .split('')
    .map((char, index) => (isIndexBetween(index) ? activeConfig?.maskCharacter : char))
    .join('');
};

export const isMatchArrayByRegex = (currentValue: string, stringValues: string[]) =>
  stringValues.filter((data) => {
    const regexUrl = new RegExp(data);

    return regexUrl.test(currentValue);
  }).length > 0;

import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import guestStore from '@storage/guestStore';
import { GuestInfo } from '@storage/types';

const useGuestStore = () => {
  const queryKeys = useMemo(
    () => [
      {
        key: 'getGuestStore',
      },
    ],
    []
  );

  const getGuestStoreItem = () => guestStore.getItem();

  const {
    isLoading,
    isSuccess: isFinishInitiated,
    data: guestStoreData,
    refetch,
  } = useQuery(queryKeys, getGuestStoreItem, {
    enabled: true,
  });

  const guestData = useMemo(() => guestStoreData, [guestStoreData]);

  const setGuestInfo = async (storage: GuestInfo) =>
    await guestStore.setItem(storage).then(() => {
      refetch();
    });

  const clearGuestData = useCallback(async () => {
    await guestStore.clear().then(() => {
      refetch();
    });
  }, [refetch]);

  return {
    isLoading,
    isFinishInitiated,
    guestData,
    clearGuestData,
    setGuestInfo,
    refetch,
  };
};

export default useGuestStore;

import React from 'react';
import { Stack, Typography } from '@components/common';
import { Divider, Skeleton } from '@mui/material';

const ProductDetailLoader: React.FC = () => {
  return (
    <Stack direction={'column'} paddingX={'m'} paddingBottom={'m'} spacing={'xl'}>
      <Skeleton variant={'rectangular'} width={'100%'} height={'25%'} />
      <Stack direction={'column'} spacing={'xxs'}>
        <Typography size={'hs'}>
          <Skeleton variant={'text'} width={110} />
        </Typography>
      </Stack>

      {new Array(2).fill(null).map((_, idx) => (
        <Stack key={idx} direction={'column'} spacing={'s'}>
          <Stack direction={'column'} spacing={'xxs'}>
            <Typography size={'hs'} variant={'medium'}>
              <Skeleton variant={'text'} width={80} />
            </Typography>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack direction={'row'} alignItems={'center'} spacing={'xxs'}>
                <Typography size={'hm'} variant={'bold'}>
                  <Skeleton variant={'text'} width={120} />
                </Typography>
                <Typography size={'s'} variant={'light'}>
                  <Skeleton variant={'text'} width={32} />
                </Typography>
              </Stack>
              <Skeleton variant={'rectangular'} width={30} height={30} />
            </Stack>
          </Stack>
          <Divider />
        </Stack>
      ))}
      <Skeleton variant={'rectangular'} width={'100%'} height={192} />
      <Skeleton variant={'rectangular'} width={'100%'} height={40} />
    </Stack>
  );
};

export default ProductDetailLoader;

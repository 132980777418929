import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const BillIcon: FC<Props> = ({ width = 22, height = 24, color = 'uiDarkSecondary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 22 26'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <path
          fillRule={'evenodd'}
          clipRule={'evenodd'}
          d={
            'M4.33333 2C3.8913 2 3.46738 2.17559 3.15482 2.48816C2.84226 2.80072 2.66666 3.22464 2.66666 3.66667V23.1315L5.11196 21.5013C5.50859 21.2369 6.03671 21.2892 6.37377 21.6262L8.33333 23.5858L10.2929 21.6262C10.6834 21.2357 11.3166 21.2357 11.7071 21.6262L13.6667 23.5858L15.6262 21.6262C15.9633 21.2892 16.4914 21.2369 16.888 21.5013L19.3333 23.1315V3.66667C19.3333 3.22464 19.1577 2.80072 18.8452 2.48816C18.5326 2.17559 18.1087 2 17.6667 2H4.33333ZM1.74061 1.07394C2.42824 0.386308 3.36087 0 4.33333 0H17.6667C18.6391 0 19.5718 0.386308 20.2594 1.07394C20.947 1.76157 21.3333 2.69421 21.3333 3.66667V25C21.3333 25.3688 21.1303 25.7077 20.8052 25.8817C20.48 26.0557 20.0855 26.0366 19.7786 25.8321L16.4607 23.6201L14.3738 25.7071C13.9832 26.0976 13.3501 26.0976 12.9596 25.7071L11 23.7475L9.04044 25.7071C8.64991 26.0976 8.01675 26.0976 7.62622 25.7071L5.53925 23.6201L2.22136 25.8321C1.91451 26.0366 1.51996 26.0557 1.19481 25.8817C0.869649 25.7077 0.666664 25.3688 0.666664 25V3.66667C0.666664 2.69421 1.05297 1.76158 1.74061 1.07394ZM6 6.33333C6 5.78105 6.44771 5.33333 7 5.33333H15C15.5523 5.33333 16 5.78105 16 6.33333C16 6.88562 15.5523 7.33333 15 7.33333H7C6.44771 7.33333 6 6.88562 6 6.33333ZM6 11.6667C6 11.1144 6.44771 10.6667 7 10.6667H15C15.5523 10.6667 16 11.1144 16 11.6667C16 12.219 15.5523 12.6667 15 12.6667H7C6.44771 12.6667 6 12.219 6 11.6667ZM11.3333 17C11.3333 16.4477 11.781 16 12.3333 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H12.3333C11.781 18 11.3333 17.5523 11.3333 17Z'
          }
          fill={fillColor}
        />
      </svg>
    </BaseIcon>
  );
};

export default BillIcon;

import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { getCustomerAddresses } from '@api/locations';
import { GetCustomerAddressesResponse } from '@api/locations/types';
import { QUERY_KEY_GET_CUSTOMER_ADDRESSES } from '@constants/queryKey';
import { getUserCookies } from '@utils/auth';

export interface GetCustomerAddressesQueryKey {
  key: string;
}

type UseQueryCustomerAddressesOptions = UseQueryOptions<
  GetCustomerAddressesResponse,
  APIDefaultErrors,
  GetCustomerAddressesResponse,
  GetCustomerAddressesQueryKey[]
>;

const useQueryCustomerAddresses = (config?: UseQueryCustomerAddressesOptions) => {
  const { isLoggedIn } = getUserCookies();

  const queryKeys: GetCustomerAddressesQueryKey[] = useMemo(
    () => [
      {
        key: QUERY_KEY_GET_CUSTOMER_ADDRESSES,
      },
    ],
    []
  );

  const { data, isFetching, isError } = useQuery(queryKeys, getCustomerAddresses, {
    enabled: !!isLoggedIn,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
  };
};

export default useQueryCustomerAddresses;

import { addSeconds, format, isAfter, isBefore, isFuture, isWithinInterval, startOfDay, subMinutes } from 'date-fns';
import Decimal from 'decimal.js';
import { omit, sumBy, toLower } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { GetMergedOpenBillOrderResponse } from '@api/dineIn/types';
import { OpenCloseTime, OpeningHour, OpeningHourDay } from '@api/locations/types';
import { GetOrderDetailResponse, ProductOrder } from '@api/order/types';
import { GetProductsResponse, Product } from '@api/products/types';
import { TagProps } from '@components/common/Tag';
import { GetProductsQueryKeys } from '@queries/products/useQueryProducts';
import cartStore from '@storage/cartStore';
import { BranchCart, CustomItem, OrderType } from '@storage/types';
import { calcDecimal, toNumber } from '@utils/number';
import {
  getTotalDiscountFromProductCart,
  getTotalDiscountromCustomItem,
  getTotalPriceFromCustomItem,
  getTotalPriceFromProductCart,
} from '@utils/storage/cartStore';

/**
 * This product is all product on cart
 * It will also filter the product, only product that have a product definition will be included.
 * Have product definition means the product id is included inside fetched product list
 */
export const getOrderRequestProducts = (branchCart: BranchCart, productList: Product[]) => {
  const branchCartProductIds = Object.keys(branchCart) || [];

  return branchCartProductIds.reduce((accumulator: ProductOrder[], productId) => {
    const cartItem = branchCart[Number(productId)];
    const product = productList.find(({ id }) => Number(productId) === id);
    if (product) {
      const customItems = cartItem.customItems || [];
      if (customItems.length) {
        return [
          ...accumulator,
          ...customItems.map((customItem) => ({
            id: Number(productId),
            name: product?.name,
            description: product?.description,
            qty: calcDecimal(customItem.qty)?.toString() || '0',
            remarks: customItem.notes,
            imageUrl: product.imageUrl,
            price: product.price,
            productCategoryId: cartItem.productCategoryId,
            productCategoryName: cartItem.productCategoryName,
            priceTotal: String(getTotalPriceFromCustomItem(customItem, product)),
            promoAmount: String(getTotalDiscountromCustomItem(customItem, product)),
            promoName: product.promoName,
            subBrandId: cartItem.queryKeys?.[0]?.payload?.subBrandId,
            subBrandName: cartItem.queryKeys?.[0]?.payload?.subBrandName,
            optionSets: customItem.productOptionSets?.map((optionSet) => {
              const currentOptionSet = product.productOptionSets?.find(
                ({ optionSetId }) => optionSetId === optionSet.optionSetId
              );
              const currentOptionSetOptions = currentOptionSet?.optionSetOptions || [];
              return {
                id: optionSet.optionSetId,
                optionSetName: currentOptionSet?.optionSetName,
                optionSetOptions: optionSet.optionSetOptionIds.map((optionSetOptionId) => {
                  const currentOptionSetOption = currentOptionSetOptions.find(({ id }) => id === optionSetOptionId);
                  return {
                    id: optionSetOptionId,
                    price: currentOptionSetOption?.price,
                    productId: currentOptionSetOption?.productId,
                    productName: currentOptionSetOption?.productName,
                    productCategoryId: currentOptionSetOption?.productCategoryId,
                    printCategoryId: currentOptionSetOption?.printCategoryId,
                    optionSetQuantity: currentOptionSetOption?.quantity,
                  };
                }),
              };
            }),
          })),
        ];
      }
      return [
        ...accumulator,
        {
          id: Number(productId),
          name: product?.name,
          description: product?.description,
          price: product?.price,
          priceTotal: String(getTotalPriceFromProductCart(cartItem, product)),
          qty: calcDecimal(cartItem.qty)?.toString() || '0',
          remarks: cartItem.notes,
          promoAmount: product?.promoAmount,
          promoName: product.promoName,
          imageUrl: product.imageUrl,
          productCategoryId: cartItem.productCategoryId,
          productCategoryName: cartItem.productCategoryName,
          subBrandId: cartItem.queryKeys?.[0]?.payload?.subBrandId,
          subBrandName: cartItem.queryKeys?.[0]?.payload?.subBrandName,
        },
      ];
    }
    return accumulator;
  }, []);
};

/**
 * This method to return flattern product list from response
 * @param {GetProductsResponse} res get product response
 * @returns {Array of Product}
 */
export const getProductListFromResponse = (res?: GetProductsResponse) => {
  const productCategories = res?.productCategories || [];
  return productCategories.reduce((acc: Product[], productCategory) => {
    const currProducts = productCategory?.products || [];
    return [...acc, ...currProducts];
  }, []);
};

/**
 * returning totalPrice and totalDiscount from Branch Cart
 * @param {BranchCart} branchCart
 * @param {Array of Product} productList
 * @returns prices: { totalPrice, totalDiscount }
 */
export const getPricesFromCart = (branchCart: BranchCart, productList: Product[]) => {
  const branchCartProductIds = Object.keys(branchCart) || [];
  const fallbackDecimal = calcDecimal(0) as Decimal;

  return branchCartProductIds.reduce(
    (accumulator, productId) => {
      const cartItem = branchCart[Number(productId)];
      const product = productList.find(({ id }) => Number(productId) === id);

      const productGrossPrice = getTotalPriceFromProductCart(cartItem, product);
      const productDiscount = getTotalDiscountFromProductCart(cartItem, product);

      return {
        totalPrice: accumulator.totalPrice.add(productGrossPrice),
        totalDiscount: accumulator.totalDiscount.add(productDiscount),
      };
    },
    { totalPrice: fallbackDecimal, totalDiscount: fallbackDecimal }
  );
};

/**
 * To map products from order detail to have priceTotal like create order request
 * @param {GetOrderDetailResponse} res
 * @returns {Array of ProductOrder}
 */
export const getProductListFromOrderDetailResponse = (res?: GetOrderDetailResponse): ProductOrder[] => {
  const products = res?.products || [];
  const fallbackDecimal = calcDecimal(0) as Decimal;

  return products.map((product) => {
    const optionSets = product?.optionSets || [];
    const parsedProductPrice = calcDecimal(product.price || '0');
    const productPrice = parsedProductPrice instanceof Decimal ? parsedProductPrice : fallbackDecimal;
    const productQty = product.qty || '0';
    const parsedProductQty = calcDecimal(productQty);
    const prettyProductQty = parsedProductQty instanceof Decimal ? parsedProductQty?.toString() : productQty;

    if (optionSets.length > 0) {
      const optionSetsPrice = sumBy(optionSets, (optionSet) =>
        sumBy(optionSet.optionSetOptions, (optionSetOption) => toNumber(optionSetOption.price) ?? 0)
      );

      return {
        ...product,
        priceTotal: productPrice.add(optionSetsPrice).mul(productQty).toString(),
        qty: prettyProductQty,
      };
    }

    return {
      ...product,
      priceTotal: productPrice.mul(productQty).toString(),
      qty: prettyProductQty,
    };
  });
};

/**
 * To create new Branch Cart object from product List
 * @param {Array of ProductOrder} productList
 * @returns BranchCart
 */
export const getCartFromOrderDetail = (productList: ProductOrder[], queryKeys: GetProductsQueryKeys[]) => {
  const newCart: BranchCart = {};
  productList.forEach((product) => {
    const cartItemId = product.id;
    const cartItem = newCart[cartItemId];
    if (cartItem) {
      if (product.optionSets?.length) {
        const customItems = cartItem.customItems || [];
        const customItem: CustomItem = {
          productOptionSets: product.optionSets.map((optionSet) => ({
            optionSetId: optionSet.id,
            optionSetOptionIds: optionSet.optionSetOptions?.map(({ id }) => id),
          })),
          qty: parseInt(product.qty),
          notes: product.remarks,
        };
        newCart[cartItemId] = {
          ...cartItem,
          customItems: [...customItems, customItem],
        };
      }
    } else {
      if (product.optionSets?.length) {
        const customItem: CustomItem = {
          productOptionSets: product.optionSets.map((optionSet) => ({
            optionSetId: optionSet.id,
            optionSetOptionIds: optionSet.optionSetOptions?.map(({ id }) => id),
          })),
          qty: parseInt(product.qty),
          notes: product.remarks,
        };
        newCart[cartItemId] = {
          id: cartItemId,
          productCategoryId: product.productCategoryId,
          productCategoryName: product.productCategoryName,
          customItems: [customItem],
          queryKeys,
        };
      } else {
        newCart[cartItemId] = {
          id: cartItemId,
          productCategoryId: product.productCategoryId,
          productCategoryName: product.productCategoryName,
          qty: parseInt(product.qty),
          notes: product.remarks,
          queryKeys,
        };
      }
    }
  });

  return newCart;
};

const isOnOpenCloseTimePeriod = (openCloseTime: OpenCloseTime) => {
  if (!openCloseTime?.openTime && !openCloseTime?.closeTime) {
    return false;
  }

  const openTimeInSeconds = openCloseTime?.openTime || 0;
  const closeTimeInInSeconds = openCloseTime?.closeTime || 0;

  const now = new Date();
  const today = startOfDay(now);
  const openTime = addSeconds(today, openTimeInSeconds);
  const closeTime = addSeconds(today, closeTimeInInSeconds);

  const isOpen = isAfter(now, openTime) && isBefore(now, closeTime);

  return isOpen;
};

const isNearCloseTimePeriod = (openCloseTime: OpenCloseTime, processTime = 30) => {
  if (!openCloseTime?.openTime && !openCloseTime?.closeTime) {
    return false;
  }

  const closeTimeInInSeconds = openCloseTime?.closeTime || 0;

  const now = new Date();
  const today = startOfDay(now);
  const closeTime = addSeconds(today, closeTimeInInSeconds);

  const lastOrderTime = subMinutes(closeTime, processTime);

  return (
    isFuture(closeTime) &&
    isWithinInterval(now, {
      start: lastOrderTime,
      end: closeTime,
    })
  );
};

/**
 * To get location open status
 * @param {OpeningHour} openingHour
 * @returns {boolean} isOpen
 */
export const isBranchOpen = (openingHour?: OpeningHour) => {
  if (isEmpty(openingHour)) {
    return false;
  }

  const localDay = toLower(format(new Date(), 'EEEE')) as OpeningHourDay;

  if (openingHour?.[localDay]?.alwaysOpen) {
    return true;
  }

  const todayOpeningHours = openingHour?.[localDay].schedules || [];
  if (Array.isArray(todayOpeningHours)) {
    return todayOpeningHours.some(isOnOpenCloseTimePeriod);
  }
};

/**
 * To check is branch is near close time
 * @param {OpeningHour} openingHour
 * @param {number} processTime in minutes
 * @returns {Date | undefined} closeTime in seconds
 */
export const getBranchNearCloseTime = (openingHour?: OpeningHour, processTime = 30): undefined | Date => {
  if (isEmpty(openingHour)) {
    return;
  }

  const localDay = toLower(format(new Date(), 'EEEE')) as OpeningHourDay;

  if (openingHour?.[localDay]?.alwaysOpen) {
    return;
  }

  const todayOpeningHours = openingHour?.[localDay].schedules || [];
  if (Array.isArray(todayOpeningHours)) {
    const nearCloseTime = todayOpeningHours.find((openCloseTime) => isNearCloseTimePeriod(openCloseTime, processTime));
    if (nearCloseTime) {
      const closeTimeInInSeconds = nearCloseTime?.closeTime || 0;
      const now = new Date();
      const today = startOfDay(now);
      const closeTime = addSeconds(today, closeTimeInInSeconds);
      return closeTime;
    }
  }
};

/**
 * To remove unknown product from branch cart
 * @param {BpeningHour} branchCart
 * @param {Array<Product>} productList
 * @param {number }activeLocationId
 */
export const removeUnknownProduct = (
  branchCart: BranchCart,
  productList: Product[],
  activeLocationId: number,
  orderType: OrderType
) => {
  const productIds = Object.keys(branchCart);
  const removedProductIds = productIds.filter((productId) => !productList.some(({ id }) => productId === String(id)));
  const cleanCart = omit(branchCart, removedProductIds) as BranchCart;
  cartStore.setItem(activeLocationId, cleanCart, orderType);
};

export const getItemTagList = (product: Product, item: CustomItem) => {
  const productOptionConfiguration = product?.productOptionSets || [];
  const itemOptionSets = item?.productOptionSets || [];

  return itemOptionSets.reduce((accumulator: TagProps[], productOptionSet) => {
    const optionSetId = productOptionSet?.optionSetId;

    const configurationList = productOptionConfiguration?.find(
      (config) => config?.optionSetId === optionSetId
    )?.optionSetOptions;

    const tagOptionList = productOptionSet?.optionSetOptionIds?.map((optionSetOptionId) => {
      const matchOption = configurationList?.find((config) => config?.id === optionSetOptionId);

      return {
        text: matchOption?.productName || '',
      };
    });

    return [...accumulator, ...tagOptionList];
  }, []);
};

export const getProductListFromMergedDineInDetailsResponse = (
  mergedDetails: GetMergedOpenBillOrderResponse['mergedDetails']
): ProductOrder[] => {
  return (
    mergedDetails?.flatMap((detail) => {
      if (detail?.productId) {
        const parsedQty = calcDecimal(detail?.qty);
        const qty = parsedQty instanceof Decimal ? parsedQty?.toString() : '0';

        const parsedPrice = calcDecimal(detail?.price);

        const calcPrice = parsedPrice instanceof Decimal ? parsedPrice.mul(qty) : detail?.price;

        const priceTotal = detail?.taxSetting === 'price_include_tax' ? detail?.amountAfterTax : calcPrice.toString();

        return [
          {
            id: detail.productId,
            qty,
            name: detail?.name,
            price: detail?.price,
            priceTotal,
            imageUrl: detail.imageUrl,
            productCategoryId: detail.productCategoryId,
            productCategoryName: detail.productCategoryName,
            optionSets: detail?.optionSets ?? [],
            adjustment: detail?.adjustment,
          },
        ];
      }

      return [];
    }) ?? []
  );
};

export const getOptionProductNames = (product: ProductOrder): string[] => {
  if (product.optionSets) {
    return product.optionSets.reduce<string[]>((acc, optionSet) => {
      const optionProductNames = optionSet.optionSetOptions.map((optionSetOption) => optionSetOption.productName ?? '');
      return [...acc, ...optionProductNames];
    }, []);
  }
  return [];
};

export const productQueryParams = (orderDetail?: GetOrderDetailResponse) => {
  return {
    closedBillToken: orderDetail && 'closedBill' in orderDetail ? orderDetail.closedBill.closedBillToken : undefined,
  };
};

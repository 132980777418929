import { format } from 'date-fns';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { OrderListCostomerOrder } from '@api/order/types';
import { Image, Stack, Tag, Typography } from '@components/common';
import { ORDER_DEFAULT_TIMER, ORDER_TIME_FORMAT } from '@constants/order';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useOrderCountdownTimer, useOrderStatusTag, useOrderTypeLabel } from '@hooks/orders';
import { useProductPrice } from '@hooks/products';
import { CardActionArea, Divider } from '@mui/material';

interface Props {
  order: OrderListCostomerOrder;
  brandName: string;
  brandLogo: string;
}

const OrderListCard = ({ order, brandName, brandLogo }: Props) => {
  const { t } = useTranslation();
  const tagPropsFromOrderStatus = useOrderStatusTag(order.status);
  const orderTypeLabel = useOrderTypeLabel(order.type);
  const { label: totalAmount } = useProductPrice(order?.totalAmount);
  const { query } = usePersistentQueryParams();
  const timer = useOrderCountdownTimer(ORDER_DEFAULT_TIMER, order.status, order.paidAt);

  return (
    <Link
      passHref
      href={{
        pathname: `${routes.ORDER_DETAIL}`,
        query: {
          ...query,
          orderId: order.id,
        },
      }}
    >
      <CardActionArea>
        <Stack
          borderRadius={'default'}
          borderWidth={'bolder'}
          borderColor={'uiLightSecondary'}
          paddingLeft={'m'}
          paddingY={'m'}
          spacing={'xs'}
        >
          <Stack direction={'row'} spacing={'xs'} overflow={'hidden'}>
            <Image
              alt={t('general.alt_logo_image', { brandName })}
              objectFit={'contain'}
              width={40}
              height={40}
              src={brandLogo}
            />
            <Stack flexGrow={1} overflow={'hidden'} paddingRight={'m'} spacing={'xs'}>
              <Stack direction={'row'} spacing={'xs'}>
                <Stack flexGrow={1} overflow={'hidden'}>
                  <Typography noWrap textOverflow={'ellipsis'} variant={'medium'}>
                    {order.locationName}
                  </Typography>
                </Stack>
                <Stack minWidth={'max-content'}>
                  <Typography variant={'light'}>{orderTypeLabel}</Typography>
                  <Typography variant={'light'} textAlign={'right'}>
                    {`(${format(new Date(order.createdAt), ORDER_TIME_FORMAT)})`}
                  </Typography>
                </Stack>
              </Stack>
              <Typography>{totalAmount}</Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack
            direction={'row'}
            spacing={'xs'}
            paddingRight={'m'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack direction={'row'} spacing={'xs'} alignItems={'center'} overflow={'hidden'} paddingRight={'m'}>
              <Tag {...tagPropsFromOrderStatus} textProps={{ noWrap: true }} />
              <Typography noWrap textOverflow={'ellipsis'} color={'uiDarkSecondary'}>
                {order.deliveryAddress}
              </Typography>
            </Stack>
            <Typography variant={'light'} textAlign={'right'}>
              {timer}
            </Typography>
          </Stack>
        </Stack>
      </CardActionArea>
    </Link>
  );
};

export default OrderListCard;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Hoverable, IconButton, Image, Popover, Stack, Typography } from '@components/common';
import { ChevronIcon } from '@components/icons';
import { usePopupState } from '@hooks/common';
import { useCommonStore } from '@hooks/storage';
import { useQueryBrandInfo } from '@queries/commons';
import { useQuerySubBrands } from '@queries/subBrands';
import SubBrandLocations from './SubBrandLocations';

const SubBrandLocationPicker = () => {
  const { t } = useTranslation();
  const { data } = useQueryBrandInfo();
  const { popupState, anchorProps, triggerProps, popoverProps } = usePopupState({ popupId: 'SubBrandLocationPicker' });
  const { storageState, isFinishInitiated, updateStorage } = useCommonStore();
  const { data: subBrandsData, isFetching: isLoadingSubBrand } = useQuerySubBrands();

  useEffect(() => {
    if (!!data?.brand && isFinishInitiated && !isLoadingSubBrand) {
      const mainSubBrand = subBrandsData?.subBrands.find((subBrand) => subBrand.id === data?.brand.mainSubBrandId);
      if (mainSubBrand) {
        if (!storageState.subBrand?.id) {
          updateStorage({ subBrand: mainSubBrand });
          return;
        }

        const isValidSubBrand = subBrandsData?.subBrands?.some((subBrand) => subBrand.id === storageState.subBrand?.id);
        if (!isValidSubBrand) {
          /*
              Why we need this? because we need to make sure the indexDB have valid sub brand 
            */
          updateStorage({ subBrand: mainSubBrand });
          return;
        }
      }
    }
  }, [
    data?.brand,
    isFinishInitiated,
    isLoadingSubBrand,
    storageState.subBrand?.id,
    subBrandsData,
    subBrandsData?.subBrands,
    updateStorage,
  ]);

  const brandName = storageState?.subBrand?.name;
  const brandLogo = storageState?.subBrand?.imageUrl;

  return (
    <Hoverable flexGrow={1} {...anchorProps}>
      <Stack direction={'row'} alignItems={'center'} spacing={'xs'} flexGrow={1} {...triggerProps}>
        <Stack direction={'row'} alignItems={'center'} spacing={'xs'}>
          <Image
            width={60}
            height={60}
            src={brandLogo}
            alt={t('general.alt_logo_image', { brandName })}
            objectFit={'contain'}
          />
          <Typography size={'m'} variant={'bold'}>
            {brandName}
          </Typography>
        </Stack>

        <IconButton padding={0}>
          <ChevronIcon rotate={popupState.isOpen ? -180 : 0} />
        </IconButton>
      </Stack>

      <Popover {...popoverProps}>
        <Stack marginTop={'xs'} flexDirection={'column'} spacing={'xs'}>
          <Typography size={'xs'} color={'uiGreenPrimary'}>
            {t('sub_brands.choose_sub_brands_menu')}
          </Typography>
          <Stack background={'borderSubtle'} padding={'xxs'} borderRadius={'default'} maxHeight={300} overflow={'auto'}>
            <SubBrandLocations open={popupState.isOpen} onClose={popoverProps?.onClose} />
          </Stack>
        </Stack>
      </Popover>
    </Hoverable>
  );
};

export default SubBrandLocationPicker;

import { useRouter } from 'next/router';
import { FunctionComponent, ReactElement, useCallback, useContext, useEffect, useMemo } from 'react';
import { AuthDialogContext } from 'src/context/AuthDialogProvider';
import { InstallPrompt, Navbar, Stack } from '@components/common';
import routes from '@constants/routes';
import { usePersistentQueryParams } from '@hooks/common';
import { useCommonStore } from '@hooks/storage';
import { getUserCookies } from '@utils/auth';

interface Props {
  children: ReactElement;
  hideNavbar?: boolean;
  NavbarComponent?: FunctionComponent;
  unauthorizedHandler?: 'modal' | 'page';
}

const ProtectedLayout = ({ children, hideNavbar, NavbarComponent = Navbar, unauthorizedHandler = 'page' }: Props) => {
  const router = useRouter();
  const { query } = usePersistentQueryParams();

  const { isLoggedIn } = getUserCookies();

  const authDialogContext = useContext(AuthDialogContext);
  const { storageState, isFinishInitiated } = useCommonStore();
  const activeLocation = storageState.activeLocation;

  const onSuccessLogin = useCallback(() => {
    router.reload();
  }, [router]);

  /**
   * This effect is to check whether the user is login or not
   * if the user is not login yet, will display unauthorized handler based on the props
   * If the handler is page, it will be redirected to login page, else the modal login page will be shown
   * if the user is already login, will check whether the user is already select any branch or not
   * if the user is have not select any branch yet, the user will be redirected to product list page
   */
  useEffect(() => {
    if (!isLoggedIn) {
      if (unauthorizedHandler === 'page') {
        router.push({
          pathname: routes.PROFILE_LOGIN,
          query,
        });
      } else {
        authDialogContext.openDialog?.(() => onSuccessLogin);
      }
    } else if (isFinishInitiated && !activeLocation?.id) {
      router.push({
        pathname: routes.PRODUCT_LIST,
        query,
      });
    }
  }, [
    activeLocation?.id,
    authDialogContext,
    isFinishInitiated,
    isLoggedIn,
    onSuccessLogin,
    query,
    router,
    unauthorizedHandler,
  ]);

  const content = useMemo(() => {
    if (!isLoggedIn) {
      return null;
    }

    return (
      <>
        <InstallPrompt />
        {!hideNavbar && <NavbarComponent />}
        {children}
      </>
    );
  }, [NavbarComponent, children, hideNavbar, isLoggedIn]);

  return (
    <Stack direction={'column'} flexGrow={1}>
      {content}
    </Stack>
  );
};

export default ProtectedLayout;

import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomerAddress } from '@api/locations/types';
import { Stack, Transition } from '@components/common';
import { Dialog } from '@mui/material';
import { DeliveryAddressNavbar, GoogleMapsForm } from '..';

interface Props {
  open?: boolean;
  onClose: () => void;
}
const GoogleMapsFormDialog: React.FC<Props> = (props) => {
  const { open = false, onClose } = props;

  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<CustomerAddress>();

  const onSubmit = useCallback(
    (value: Pick<CustomerAddress, 'address' | 'latitude' | 'longitude'>) => {
      setValue('address', value.address);
      setValue('latitude', value.latitude);
      setValue('longitude', value.longitude);
      onClose();
    },
    [onClose, setValue]
  );

  const latitude = watch('latitude');
  const longitude = watch('longitude');
  const address = watch('address');
  const googleMapsInitialValue = useMemo(
    () => ({
      latitude,
      longitude,
      address,
    }),
    [address, latitude, longitude]
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DeliveryAddressNavbar onPressBack={onClose} title={t('order.your_address_title')} />
      <Stack padding={'m'}>
        <GoogleMapsForm open={open} onSubmit={onSubmit} initialValue={googleMapsInitialValue} />
      </Stack>
    </Dialog>
  );
};

export default GoogleMapsFormDialog;

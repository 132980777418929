import baseAPI, { handleDefaultError, handleDefaultSuccess } from '@api/baseAPI';
import {
  CreateCustomerOrderAPI,
  CreateCustomerOrderResponse,
  CreateOnlinePaymentAPI,
  CustomerOrderPaymentResponse,
  GetCustomerOrderPriceAPI,
  GetCustomerOrderPriceResponse,
  GetDeliveryGrabQuotationAPI,
  GetDeliveryLalamoveQuotationAPI,
  GetDeliveryQuotationResponse,
  GetOngoingOrdersAPI,
  GetOngoingOrdersResponse,
  GetOrderDetailAPI,
  GetOrderDetailResponse,
  GetOrderListAPI,
  GetOrderListResponse,
  GetSavedCreditCardsAPI,
  GetSavedCreditCardsResponse,
  GetUserBalanceAPI,
  GetUserBalanceResponse,
} from './types';

const API_DELIVERY_LALAMOVE_QUOTATIONS = '/api/delivery/quotations/';
const API_DELIVERY_GRAB_QUOTATIONS = '/api/delivery/grab_express_quotations/';
const API_CUSTOMER_ORDER = '/api/delivery/customer_orders/';
const API_CUSTOMER_ORDER_PRICE = '/api/delivery/customer_orders/price/';
const API_CREATE_ONLINE_PAYMENT = '/api/delivery/payments/';
const API_GET_CREDIT_CARDS = '/api/delivery/credit_cards/';
const API_GET_USER_BALANCE = '/api/delivery/users/balance';
const API_ONGOING_ORDERS = '/api/delivery/customer_orders/ongoing';

export const getDeliveryLalamoveQuotation: GetDeliveryLalamoveQuotationAPI = async (body) =>
  await baseAPI
    .post<GetDeliveryQuotationResponse>(API_DELIVERY_LALAMOVE_QUOTATIONS, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getDeliveryGrabQuotation: GetDeliveryGrabQuotationAPI = async (body) =>
  await baseAPI
    .post<GetDeliveryQuotationResponse>(API_DELIVERY_GRAB_QUOTATIONS, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const createCustomerOrder: CreateCustomerOrderAPI = async (body) =>
  await baseAPI
    .post<CreateCustomerOrderResponse>(API_CUSTOMER_ORDER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getOrderList: GetOrderListAPI = async (params) =>
  await baseAPI
    .get<GetOrderListResponse>(API_CUSTOMER_ORDER, { params })
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getOrderDetail: GetOrderDetailAPI = async (id: string) => {
  if (typeof id === 'undefined' || !id) {
    return Promise.reject(new Error('Invalid request getOrderDetail'));
  }

  return await baseAPI
    .get<GetOrderDetailResponse>(`${API_CUSTOMER_ORDER}${id}`)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const createOnlinePayment: CreateOnlinePaymentAPI = async (body) =>
  await baseAPI
    .post<CustomerOrderPaymentResponse>(API_CREATE_ONLINE_PAYMENT, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getSavedCreditCards: GetSavedCreditCardsAPI = async () =>
  await baseAPI
    .get<GetSavedCreditCardsResponse>(API_GET_CREDIT_CARDS)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getCustomerOrderPrice: GetCustomerOrderPriceAPI = async (body) => {
  if (typeof body?.locationId === 'undefined') {
    return Promise.reject(new Error('Invalid request getCustomerOrderPrice'));
  }

  return await baseAPI
    .post<GetCustomerOrderPriceResponse>(API_CUSTOMER_ORDER_PRICE, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getUserBalance: GetUserBalanceAPI = async () =>
  await baseAPI.get<GetUserBalanceResponse>(API_GET_USER_BALANCE).then(handleDefaultSuccess).catch(handleDefaultError);

export const getOngoingOrders: GetOngoingOrdersAPI = async () =>
  await baseAPI.get<GetOngoingOrdersResponse>(API_ONGOING_ORDERS).then(handleDefaultSuccess).catch(handleDefaultError);

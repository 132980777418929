import { useRouter } from 'next/router';
import { FC } from 'react';
import { Button, Stack } from '@components/common';
import { ArrowIcon } from '@components/icons';
import routes from '@constants/routes';

interface Props {
  onPressBack?: () => void;
}

const AuthNavbar: FC<Props> = (props) => {
  const { onPressBack } = props;

  const router = useRouter();

  const { query } = router;

  const handleOnPressBack = () => {
    if (onPressBack) {
      onPressBack();
      return;
    }

    if (query?.guestMode) {
      router.push({
        pathname: routes.ROOT,
      });

      return;
    }

    return router.back();
  };

  return (
    <Stack padding={'m'} background={'uiLightPrimary'} alignItems={'flex-start'} zIndex={1}>
      <Button onClick={handleOnPressBack} variant={'text'} padding={0}>
        <ArrowIcon />
      </Button>
    </Stack>
  );
};

export default AuthNavbar;

import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreditCardFormSchema } from 'src/formSchemas';
import { PaymentMethod } from '@api/order/types';
import { Button, Checkbox, IconButton, Image, Stack, Typography } from '@components/common';
import RHFTextFieldMask from '@components/common/RHFTextFieldMask';
import { CloseIcon } from '@components/icons';
import config from '@constants/config';
import { yupResolver } from '@hookform/resolvers/yup';
import useGuestStore from '@hooks/storage/useGuestStore';
import { FormControlLabel } from '@mui/material';

const paymentMethodLogoUrlList = [
  '/payment-method-logo/master-card.png',
  '/payment-method-logo/gopay.png',
  '/payment-method-logo/ovo.png',
  '/payment-method-logo/shopeepay.png',
  '/payment-method-logo/va-transfer.png',
];

interface FormProps {
  value: PaymentMethod;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  onClose: () => void;
}

const CreditCardForm: React.FC<FormProps> = (props) => {
  const { t } = useTranslation();
  const { guestData } = useGuestStore();
  const { onSubmit, onClose, value } = props;
  const defaultValues = useMemo(() => {
    if (value?.type === 'credit-card') {
      return {
        ...value,
        tokenId: '',
      };
    }
    return {
      accountNumber: '',
      expiredPeriod: '',
      cvc: '',
      shouldLinkToPersonalAccount: false,
      tokenId: '',
    };
  }, [value]);

  const {
    watch,
    control,
    handleSubmit: handleFormSubmit,
  } = useForm<PaymentMethod>({
    resolver: yupResolver(useCreditCardFormSchema()),
    defaultValues,
  });

  const checkmarkLabel = (
    <Typography size={'hs'} color={'uiDarkSecondary'}>
      {t('order.credit_card_link_to_account_check_label')}
    </Typography>
  );

  const handleSubmitPaymentMethod = useCallback(
    (value: PaymentMethod) => {
      onSubmit?.({
        ...value,
        type: 'credit-card',
      });
    },
    [onSubmit]
  );

  const currentExpiredPeriodValue = watch('expiredPeriod');
  const customExpiredPeriodFormatter = useCallback(
    (value: string) => {
      const currentValueLength = currentExpiredPeriodValue?.length || 0;
      const newValueLength = value?.length || 0;

      if (newValueLength >= currentValueLength) {
        if (newValueLength === 1) {
          if (Number(value) > 1) {
            return currentExpiredPeriodValue as string;
          }
        }
        if (value.length === 3) {
          if (Number(value?.replace('/', '')) > 12) {
            return currentExpiredPeriodValue as string;
          }
        }
      }
      return value;
    },
    [currentExpiredPeriodValue]
  );

  return (
    <Stack padding={'m'} spacing={'xxl'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'bold'} size={'xxl'}>
          {t('order.credit_card_form_title')}
        </Typography>
        <IconButton onClick={onClose} padding={0}>
          <CloseIcon width={15} height={15} />
        </IconButton>
      </Stack>

      <RHFTextFieldMask
        name={'accountNumber'}
        control={control}
        label={t('order.credit_card_number_label')}
        mask={'9999 9999 9999 9999'}
        inputProps={{
          placeholder: t('order.credit_card_number_placeholder'),
          inputProps: {
            inputMode: 'numeric',
          },
        }}
      />

      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} spacing={'hs'}>
        <RHFTextFieldMask
          name={'expiredPeriod'}
          control={control}
          label={t('order.credit_card_validity_label')}
          mask={'99/99'}
          customValueFormatter={customExpiredPeriodFormatter}
          inputProps={{
            placeholder: t('order.credit_card_validity_placeholder'),
            inputProps: {
              inputMode: 'numeric',
            },
          }}
        />

        <RHFTextFieldMask
          name={'cvc'}
          control={control}
          label={t('order.credit_card_cvn_label')}
          mask={'999'}
          inputProps={{
            type: 'password',
            inputProps: {
              inputMode: 'numeric',
            },
          }}
        />
      </Stack>

      {!guestData && config.featureToggle.enableXenditSavedCc && (
        <Controller
          control={control}
          name={'shouldLinkToPersonalAccount'}
          render={({ field: { value, ref, onChange } }) => (
            <FormControlLabel
              control={<Checkbox ref={ref} checked={value} onChange={onChange} />}
              label={checkmarkLabel}
            />
          )}
        />
      )}

      <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={'hm'}>
        {paymentMethodLogoUrlList.map((url, index) => (
          <Image key={index} width={24} src={url} />
        ))}
      </Stack>

      <Typography size={'xs'} color={'uiDarkSecondary'} textAlign={'center'}>
        {t('order.credit_card_form_description')}
      </Typography>

      <Button variant={'contained'} onClick={handleFormSubmit(handleSubmitPaymentMethod)}>
        <Typography size={'hm'} variant={'medium'}>
          {t('order.credit_card_submit_button_label')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default CreditCardForm;

import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DecimalNumber } from '@api/baseAPI/types';
import { PaymentMethod } from '@api/order/types';
import { Button, IconButton, Stack, Typography } from '@components/common';
import { CloseIcon } from '@components/icons';
import VirtualAccountSelect from './VirtualAccountSelect';

interface FormProps {
  value: PaymentMethod;
  onSubmit: (paymentMethod: PaymentMethod) => void;
  onClose: () => void;
  totalAmount: DecimalNumber;
}

const VirtualAccountForm: React.FC<FormProps> = (props) => {
  const { t } = useTranslation();
  const { onSubmit, onClose, value } = props;

  const defaultValues = useMemo(() => {
    if (value?.type === 'va-transfer') {
      return value;
    }

    return {
      bankId: 'BNI',
    } as PaymentMethod;
  }, [value]);

  const {
    watch,
    control,
    handleSubmit: handleFormSubmit,
  } = useForm<PaymentMethod>({
    defaultValues,
  });

  const handleSubmitPaymentMethod = useCallback(
    (value: PaymentMethod) => {
      onSubmit?.({
        ...value,
        type: 'va-transfer',
      });
    },
    [onSubmit]
  );
  const virtualAccountOptions = [
    /*
      TODO-PAYMENT: disable BCA for temp until service ready
    */
    // { label: 'BCA Virtual Account', value: 'BCA', icon: '/payment-method-logo/bca.png' },
    { label: 'BNI Virtual Account', value: 'BNI', icon: '/payment-method-logo/bni.png' },
    { label: 'BRI Virtual Account', value: 'BRI', icon: '/payment-method-logo/bri.png' },
  ];

  return (
    <Stack padding={'m'} spacing={'xxl'}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'bold'} size={'xxl'}>
          {t('order.virtual_account_form_title')}
        </Typography>

        <IconButton onClick={onClose} padding={0}>
          <CloseIcon width={15} height={15} />
        </IconButton>
      </Stack>

      <Controller
        name={'bankId'}
        control={control}
        render={({ field }) => {
          const { value, onChange } = field;
          return (
            <VirtualAccountSelect
              options={virtualAccountOptions}
              value={value}
              onChange={onChange}
              label={t('order.virtual_account_form_bank_id_label')}
              totalAmount={props.totalAmount}
            />
          );
        }}
      />

      <Stack>
        {t('order.virtual_account_form_description', { virtualAccountId: watch('bankId') })
          ?.split('|')
          .map((description, index) => (
            <Typography key={index} size={'xs'} color={'uiDarkSecondary'}>
              {`${index + 1}. ${description}`}
            </Typography>
          ))}
      </Stack>

      <Button variant={'contained'} onClick={handleFormSubmit(handleSubmitPaymentMethod)}>
        <Typography size={'hm'} variant={'medium'}>
          {t('order.virtual_account_submit_button_label')}
        </Typography>
      </Button>
    </Stack>
  );
};

export default VirtualAccountForm;

import { ChangeEvent } from 'react';
import { TokenFontSize } from '@interfaces/token';
import { Radio as MuiRadio } from '@mui/material';
import styles from './styles';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  fontSize?: TokenFontSize;
  value?: string;
}

const Radio = ({ checked = false, value, disabled, onChange, fontSize = 's' }: Props) => (
  <MuiRadio
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    value={value}
    css={[styles.container, fontSize && styles.fontSize(fontSize)]}
  />
);

export default Radio;

import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const CircleIcon: FC<Props> = ({ width = 8, height = 8, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg xmlns={'http://www.w3.org/2000/svg'} width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <circle fill={fillColor} cx={width / 2} cy={height / 2} r={width / 2} />
      </svg>
    </BaseIcon>
  );
};

export default CircleIcon;

import React from 'react';
import { LocationOpenStatus } from '@api/locations/types';
import { Stack, Typography } from '@components/common';
import { CircleIcon } from '@components/icons';
import Token from '@constants/token';
import { useLocationStatusBadge } from '@hooks/locations';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

interface Props {
  status: LocationOpenStatus;
}

const Badge = styled(Paper)(() => ({ status }: Props) => ({
  color: status === 'open' ? Token.color.uiGreenPrimary : Token.color.uiDarkSecondary,
  backgroundColor: status === 'open' ? Token.color.uiGreenSecondary : Token.color.uiLightSecondary,
  width: 'fit-content',
  height: 'fit-content',
  borderRadius: Token.borderRadius.small,
}));

const LocationStatusBadge: React.FC<Props> = ({ status }) => {
  const { label: statusLabel } = useLocationStatusBadge(status);
  return (
    <Badge elevation={0} status={status}>
      <Stack direction={'row'} alignItems={'center'} spacing={'xs'} paddingX={'xs'}>
        <CircleIcon color={status === 'open' ? 'uiGreenPrimary' : 'uiDarkSecondary'} />
        <Typography size={'hs'}>{statusLabel}</Typography>
      </Stack>
    </Badge>
  );
};

export default LocationStatusBadge;

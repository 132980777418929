import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { useDeliveryAddressFormSchema } from 'src/formSchemas';
import { CustomerAddress } from '@api/locations/types';
import {
  Button,
  CustomTextField,
  Dialog,
  IconButton,
  PhoneInputField,
  RHFTextField,
  Stack,
  Typography,
} from '@components/common';
import { CurrentLocationIcon } from '@components/icons';
import config from '@constants/config';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentProfile } from '@hooks/auth';
import { useCurrentPosition } from '@hooks/common';
import { useCommonStore } from '@hooks/storage';
import { GoogleMapsFormDialog } from '..';
import DeliveryAddressAutoCompleteDialog from './DeliveryAddressAutoCompleteDialog';

const defaultLocation = {
  lat: -6.175392,
  lng: 106.827153,
};

interface Props {
  onSubmit?: (address: CustomerAddress) => Promise<void>;
  isLoading?: boolean;
  address?: CustomerAddress;
}
const DeliveryAddressForm: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const snackbarContext = useContext(SnackbarContext);

  const { onSubmit, isLoading, address } = props;
  const [isOpenGoogleMaps, setIsOpenGoogleMaps] = useState(false);
  const [isOpenAddressNameInputDialog, setIsOpenAddressNameInputDialog] = useState(false);
  const [isOpenAutoCompleteDialog, setIsOpenAutoCompleteDialog] = useState(false);

  const [{ detail }] = useCurrentProfile();

  const addressFormDefaultValue: Partial<CustomerAddress> = useMemo(
    () => ({
      receiverName: detail?.name,
      contactNumber: detail?.contactNumber,
      address: address?.address ?? '',
      addressDetail: address?.addressDetail ?? '',
      instruction: address?.instruction ?? '',
      ...address,
    }),
    [address, detail?.contactNumber, detail?.name]
  );

  const rhfMethods = useForm<CustomerAddress>({
    defaultValues: addressFormDefaultValue,
    resolver: yupResolver(useDeliveryAddressFormSchema()),
    reValidateMode: 'onBlur',
  });

  const { watch, setValue, handleSubmit: handleFormSubmit, control } = rhfMethods;

  const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: config.apiKey.googleMapApiKey,
    language: 'id-ID',
  });

  const { storageState, isFinishInitiated } = useCommonStore();

  const longitude = useMemo(() => Number(watch('longitude')) || defaultLocation?.lng, [watch]);
  const latitude = useMemo(() => Number(watch('latitude')) || defaultLocation?.lat, [watch]);

  const hasLongLat = useMemo(() => {
    return latitude !== undefined || longitude !== undefined;
  }, [latitude, longitude]);

  const enableGetPosition = useMemo(() => {
    return isFinishInitiated && !hasLongLat;
  }, [hasLongLat, isFinishInitiated]);

  /**
   * Method to Set Default Longitude and Latitude
   * will set the location into active branch if user dont allow to track location
   * will fallback into default location if failed to get active branch location
   */
  const setDefaultLongLat = useCallback(() => {
    const activeBranch = storageState?.activeLocation;
    if (activeBranch?.latitude !== undefined && activeBranch?.latitude !== undefined) {
      setValue('latitude', activeBranch?.latitude);
      setValue('longitude', activeBranch?.longitude);
      setValue('address', activeBranch?.address);
    } else {
      setValue('latitude', String(defaultLocation?.lat));
      setValue('longitude', String(defaultLocation?.lng));
    }
  }, [setValue, storageState?.activeLocation]);

  useCurrentPosition({
    enabled: enableGetPosition,
    onError: () => {
      setDefaultLongLat();
    },
    onSuccess: (position) => {
      const longLat = {
        lng: position?.coords?.longitude,
        lat: position?.coords?.latitude,
      };
      setValue('latitude', String(longLat.lat));
      setValue('longitude', String(longLat.lng));
    },
  });

  useEffect(() => {
    if (!enableGetPosition && !hasLongLat && isFinishInitiated) {
      setDefaultLongLat();
    }
  }, [enableGetPosition, hasLongLat, isFinishInitiated, setDefaultLongLat]);

  const onCloseGoogleMaps = () => {
    setIsOpenGoogleMaps(false);
  };

  const onOpenGoogleMaps = () => {
    setIsOpenGoogleMaps(true);
  };

  const handleCloseAddressNameInputDialog = () => {
    setIsOpenAddressNameInputDialog(false);
  };

  const handleOpenAddressNameInputDialog = () => {
    setIsOpenAddressNameInputDialog(true);
  };

  const handleOpenAutoCompleteDialog = () => {
    setIsOpenAutoCompleteDialog(true);
  };

  const handleCloseAutoCompleteDialog = () => {
    setIsOpenAutoCompleteDialog(false);
  };

  const handleSubmit = useCallback(
    async (value: CustomerAddress) => {
      try {
        await onSubmit?.(value);
        handleCloseAddressNameInputDialog();
      } catch (e) {
        const { message = t('error.general_error_label') } = e as Error;
        snackbarContext?.openSnackbar?.({
          message,
          alertProps: {
            severity: 'error',
          },
        });
      }
    },
    [onSubmit, snackbarContext, t]
  );

  const onSelectOption = useCallback(
    (item: google.maps.places.AutocompletePrediction) => {
      placesService?.getDetails({ placeId: item?.place_id }, (res) => {
        const resLat = String(res?.geometry?.location?.lat());
        const resLng = String(res?.geometry?.location?.lng());

        setValue('address', item?.description);
        setValue('latitude', resLat);
        setValue('longitude', resLng);
      });
    },
    [placesService, setValue]
  );

  return (
    <Stack>
      <Stack spacing={'xxl'} padding={'m'} height={'100%'} minHeight={'max-content'} overflow={'hidden'}>
        <Typography size={'xxl'} variant={'bold'}>
          {t('order.delivery_address_title')}
        </Typography>

        <RHFTextField name={'receiverName'} control={control} label={t('order.delivery_contact_name_label')} />

        <Controller
          name={'contactNumber'}
          control={control}
          render={({ field: { value, ref, onChange, onBlur }, fieldState: { error } }) => (
            <PhoneInputField
              phoneInputRef={ref}
              valueText={value}
              onChangeText={onChange}
              onBlur={onBlur}
              error={error?.message}
            />
          )}
        />

        <Stack spacing={'hs'}>
          <Typography size={'hs'}>{t('order.delivery_address_input_label')}</Typography>
          <Controller
            name={'address'}
            control={control}
            render={({ field, fieldState }) => {
              const { onBlur, ref, value } = field;
              const { error } = fieldState;
              return (
                <CustomTextField
                  onBlur={onBlur}
                  rows={3}
                  multiline
                  error={!!error?.message}
                  helperText={error?.message}
                  value={value}
                  inputRef={ref}
                  InputProps={{
                    componentsProps: {
                      input: {
                        onClick: handleOpenAutoCompleteDialog,
                      },
                    },
                    contentEditable: false,
                    endAdornment: (
                      <IconButton padding={0} onClick={onOpenGoogleMaps}>
                        <Stack
                          background={'uiLightPrimary'}
                          boxShadow={`0px 0.75px 1.5px rgba(0, 0, 0, 0.15)`}
                          minWidth={32}
                          height={32}
                          alignItems={'center'}
                          justifyContent={'center'}
                          borderRadius={'form'}
                        >
                          <CurrentLocationIcon />
                        </Stack>
                      </IconButton>
                    ),
                  }}
                />
              );
            }}
          />
        </Stack>

        <RHFTextField
          name={'addressDetail'}
          label={t('order.delivery_address_detail_input_label')}
          control={control}
          inputProps={{ rows: 3, multiline: true }}
        />

        <RHFTextField
          name={'instruction'}
          label={t('order.delivery_address_instruction_input_label')}
          control={control}
        />

        <Button onClick={handleFormSubmit(handleOpenAddressNameInputDialog)} variant={'contained'} padding={'m'}>
          <Typography size={'hm'} variant={'medium'}>
            {t('order.delivery_address_submit_button_label')}
          </Typography>
        </Button>

        <FormProvider {...rhfMethods}>
          <GoogleMapsFormDialog open={isOpenGoogleMaps} onClose={onCloseGoogleMaps} />

          <Dialog fullWidth onClose={handleCloseAddressNameInputDialog} open={isOpenAddressNameInputDialog}>
            <Stack padding={'m'} spacing={'xxl'}>
              <RHFTextField
                name={'addressName'}
                label={t('order.delivery_address_name_input_label')}
                control={control}
              />
              <Button
                disabled={!watch('addressName')}
                onClick={handleFormSubmit(handleSubmit)}
                variant={'contained'}
                padding={'m'}
                isLoading={isLoading}
              >
                <Typography size={'hm'} variant={'medium'}>
                  {t('order.delivery_address_name_input_button_label')}
                </Typography>
              </Button>
            </Stack>
          </Dialog>
          <DeliveryAddressAutoCompleteDialog
            latitude={latitude}
            longitude={longitude}
            open={isOpenAutoCompleteDialog}
            onClose={handleCloseAutoCompleteDialog}
            defaultValue={watch('address')}
            placePredictions={placePredictions}
            getPlacePredictions={getPlacePredictions}
            onSelectOption={onSelectOption}
          />
        </FormProvider>
      </Stack>
    </Stack>
  );
};

export default DeliveryAddressForm;

import baseAPI, { handleDefaultError, handleDefaultSuccess } from '@api/baseAPI';
import { DEFAULT_LOCATION_SEARCH_SPEC } from '@constants/location';
import {
  CreateCustomerAddressAPI,
  CreateCustomerAddressResponse,
  DeleteCustomerAddressAPI,
  GetCustomerAddressesAPI,
  GetCustomerAddressesResponse,
  GetLocationDetailAPI,
  GetLocationDetailResponse,
  GetLocationsAPI,
  GetLocationsResponse,
  UpdateCustomerAddressAPI,
} from './types';

const API_LOCATIONS = '/api/delivery/branches';
const API_CUSTOMER_ADDRESSES = '/api/delivery/customer_addresses';

export const getLocations: GetLocationsAPI = async (request = DEFAULT_LOCATION_SEARCH_SPEC) => {
  const params = {
    ...(request.subBrandId && { subBrandId: request.subBrandId }),
    ...(request.latitude && { latitude: request.latitude }),
    ...(request.longitude && { longitude: request.longitude }),
  };

  return await baseAPI
    .get<GetLocationsResponse>(API_LOCATIONS, { params })
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getLocationDetail: GetLocationDetailAPI = async (param) => {
  if (typeof param?.id === 'undefined') {
    return Promise.reject(new Error('Invalid request getLocationDetail'));
  }

  return await baseAPI
    .get<GetLocationDetailResponse>(`${API_LOCATIONS}/${param?.id}`)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getCustomerAddresses: GetCustomerAddressesAPI = async () =>
  await baseAPI
    .get<GetCustomerAddressesResponse>(API_CUSTOMER_ADDRESSES)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const createCustomerAddress: CreateCustomerAddressAPI = async (body) =>
  await baseAPI
    .post<CreateCustomerAddressResponse>(API_CUSTOMER_ADDRESSES, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const updateCustomerAddress: UpdateCustomerAddressAPI = async ({ id, ...body }) =>
  await baseAPI
    .patch<CreateCustomerAddressResponse>(`${API_CUSTOMER_ADDRESSES}/${id}`, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const deleteCustomerAddress: DeleteCustomerAddressAPI = async (id) =>
  await baseAPI.delete(`${API_CUSTOMER_ADDRESSES}/${id}`).then(handleDefaultSuccess).catch(handleDefaultError);

import { CSSProperties, ReactNode } from 'react';
import { StyleProps } from '@interfaces/common';
import type { TokenColor, TokenSpacing } from '@interfaces/token';
import { TableCell as MuiTableCell } from '@mui/material';
import styles from './styles';

export interface TableCellProps
  extends Pick<CSSProperties, 'position' | 'left' | 'width' | 'minWidth' | 'maxWidth' | 'height' | 'zIndex'> {
  children?: ReactNode;
  padding?: TokenSpacing;
  margin?: TokenSpacing;
  paddingLeft?: TokenSpacing;
  paddingRight?: TokenSpacing;
  paddingTop?: TokenSpacing;
  paddingBottom?: TokenSpacing;
  colSpan?: number;
  rowSpan?: number;
  align?: 'center' | 'left' | 'right';
  valign?: CSSProperties['verticalAlign'];
  backgroundColor?: TokenColor;
  color?: string;
  borderBottomColor?: TokenColor;
  borderBottomWidth?: number;
  customStyles?: StyleProps;
}

const TableCell = ({
  children,
  width,
  minWidth,
  maxWidth,
  height,
  padding,
  margin,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  colSpan = 1,
  rowSpan = 1,
  align = 'left',
  valign,
  backgroundColor,
  color,
  borderBottomColor,
  borderBottomWidth,
  position,
  left,
  zIndex,
  customStyles,
}: TableCellProps) => (
  <MuiTableCell
    rowSpan={rowSpan}
    colSpan={colSpan}
    align={align}
    css={[
      styles.padding(padding),
      width && styles.width(width),
      minWidth && styles.minWidth(minWidth),
      maxWidth && styles.maxWidth(maxWidth),
      height && styles.height(height),
      margin && styles.margin(margin),
      paddingLeft && styles.paddingLeft(paddingLeft),
      paddingRight && styles.paddingRight(paddingRight),
      paddingTop && styles.paddingTop(paddingTop),
      paddingBottom && styles.paddingBottom(paddingBottom),
      backgroundColor && styles.backgroundColor(backgroundColor),
      color && styles.color(color),
      styles.borderBottomColor(borderBottomColor),
      borderBottomWidth && styles.borderBottomWidth(borderBottomWidth),
      valign && styles.valign(valign),
      position && styles.position(position),
      typeof left !== undefined && styles.left(left),
      typeof zIndex !== undefined && styles.zIndex(zIndex),
      customStyles,
    ]}
  >
    {children}
  </MuiTableCell>
);

export default TableCell;

import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserLanguage } from '@api/auth/types';
import { Button, Dialog, Hoverable, IconButton, Stack, Transition, Typography } from '@components/common';
import { CloseIcon, EnglishFlagIcon, IndonesiaFlagIcon } from '@components/icons';
import { useCommonStore } from '@hooks/storage';
import { DialogProps, Divider } from '@mui/material';

interface Props {
  onClose: () => void;
}

const LanguageDialog: FC<DialogProps & Props> = (props) => {
  const { t } = useTranslation();
  const { updateStorage, storageState, isFinishInitiated } = useCommonStore();
  const [selectedLanguage, setSelectedLanguage] = useState<UserLanguage>();

  useEffect(() => {
    if (isFinishInitiated) {
      setSelectedLanguage(storageState.language);
    }
  }, [isFinishInitiated, storageState.language, props.open]);

  const languages = useMemo(
    () => [
      {
        value: 'id' as UserLanguage,
        label: t('general.bahasa'),
        icon: <IndonesiaFlagIcon width={22} height={16} />,
      },
      {
        value: 'en' as UserLanguage,
        label: t('general.english'),
        icon: <EnglishFlagIcon width={22} height={16} />,
      },
    ],
    [t]
  );

  const onSelectLanguage = (value: UserLanguage) => () => {
    setSelectedLanguage(value);
  };

  const handleClickSave = () => {
    updateStorage({ language: selectedLanguage });
    props.onClose();
  };

  return (
    <Dialog TransitionComponent={Transition} {...props}>
      <Stack direction={'column'} width={280} minWidth={250}>
        <Stack paddingX={'xxl'} paddingY={'m'} direction={'row'} justifyContent={'space-between'}>
          <Typography size={'s'} variant={'medium'}>
            {t('general.select_your_language')}
          </Typography>
          <IconButton onClick={props.onClose} padding={0}>
            <CloseIcon width={12} height={12} />
          </IconButton>
        </Stack>
        <Divider />
        <Stack padding={'xxl'} direction={'column'} rowGap={'m'}>
          {languages.map((language, index) => {
            const isSelected = selectedLanguage === language.value;
            return (
              <Hoverable key={index} onClick={onSelectLanguage(language.value)}>
                <Stack
                  padding={'m'}
                  background={isSelected ? 'uiPrimaryLight' : 'uiLightPrimary'}
                  borderColor={isSelected ? 'uiPrimaryLight' : 'borderLight'}
                  borderWidth={'bold'}
                  borderRadius={'default'}
                  direction={'row'}
                  columnGap={'m'}
                  alignContent={'center'}
                  alignItems={'center'}
                >
                  {language.icon}
                  <Typography color={'uiDarkPrimary'} size={'hs'}>
                    {language.label}
                  </Typography>
                </Stack>
              </Hoverable>
            );
          })}
        </Stack>
        <Divider />
        <Stack paddingX={'xxl'} paddingY={'m'} direction={'row'}>
          <Button variant={'contained'} fullWidth onClick={handleClickSave}>
            <Typography size={'s'} variant={'medium'}>
              {t('actions.save')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default LanguageDialog;

const color = {
  transparent: 'transparent',
  uiPrimaryLight: '#FFF4D9',
  uiPrimaryMain: '#FDBD10',
  uiPrimaryDark: '#FFA000',
  uiPrimaryDarker: '#171A1F',
  uiPrimaryContrastText: '#202328',
  uiSecondaryLight: '#202328',
  uiSecondaryMain: '#202328',
  uiSecondaryDark: '#202328',
  uiSecondaryContrastText: '#FFFFFF',
  uiSuccessLight: '#D0E6E5',
  uiSuccessMain: '#11867B',
  uiSuccessDark: '#11867B',
  uiLightPrimary: '#FFFFFF',
  uiLightStain: '#F4F4F4',
  uiLightStainSecondary: '#646464',
  uiLightNeutral: '#F2F3F3',
  uiLightSecondary: '#F9F9F9',
  uiLightTertiary: '#888787',
  uiGreyLight: '#F6F6F6',
  uiDarkPrimary: '#03121A',
  uiDarkStain: '#1C2930',
  uiDarkNeutral: '#354148',
  uiDarkSecondary: '#8C8C8C',
  uiDarkTertiary: '#444B56',
  uiGreenPrimary: '#11867B',
  uiGreenSecondary: '#D0E6E5',
  uiBluePrimary: '#1769aa',
  uiBlueSecondary: '#2196f3',
  uiBlueStain: '#4dabf5',
  uiRedDark: '#E7090E',
  uiRedLight: '#FCE3E4',
  uiRedPrimary: '#F4555A',
  uiRedSecondary: '#EC3A3E',
  uiRedTertiary: '#E55555',
  uiLightSoftPrimary: '#FEF4E5',
  borderDivide: '#E9E9E9',
  borderSubtle: '#E9E9E9',
  borderLight: '#E5E5E5',
  textMuted: '#8C8C8C',
  actionPrimary: '#FD550A',
  contentTertiary: '#6E7A86',
  lineLight: '#EAEAEA',
  softGrey: '#F0F0F0',
  none: 'none',
  backgroundNeutralSecondary: '#F7F7F8',
  backgroundPositive: '#12A56F',
};

const spacing = {
  xxxs: 2,
  xxs: 4,
  hxxs: 6,
  xs: 8,
  hs: 10,
  s: 12,
  hm: 14,
  m: 16,
  hxl: 18,
  xl: 20,
  xxl: 24,
  xxxl: 28,
  '1xxl': 32,
  '2xxl': 48,
  '3xxl': 72,
  '4xxl': 96,
};

const fontSize = {
  xxxs: 6,
  xxs: 8,
  hxxs: 10,
  xs: 12,
  hs: 14,
  s: 16,
  hm: 18,
  m: 20,
  xl: 22,
  xxl: 26,
  xxxl: 30,
  '2xxl': 50,
  '3xxl': 74,
};

const borderRadius = {
  form: '4px',
  small: '6px',
  default: '8px',
  large: '12px',
  circle: '50%',
  rounded: 999,
};

const borderWidth = {
  thin: 0.5,
  thick: 1,
  bold: 2,
  bolder: 5,
};

const typography = {
  DEFAULT: 'SfPro',
};

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 600,
  bolder: 700,
};

const Token = {
  borderRadius,
  color,
  spacing,
  typography,
  fontSize,
  fontWeight,
  borderWidth,
};

export default Token;

import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { createCustomerAddress, deleteCustomerAddress, updateCustomerAddress } from '@api/locations';
import { CustomerAddress } from '@api/locations/types';
import { Dialog, Transition } from '@components/common';
import { GetCustomerAddressesQueryKey } from '@queries/locations/useQueryCustomerAddresses';
import { DeliveryAddressForm, DeliveryAddressNavbar } from '..';

interface Props {
  open?: boolean;
  onClose: () => void;
  onSuccessSubmitLocation?: (address: CustomerAddress) => void;
  address?: CustomerAddress;
  queryKeys?: GetCustomerAddressesQueryKey[];
}

const DeliveryAddressFormDialog: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { open = false, onClose, onSuccessSubmitLocation, address, queryKeys } = props;
  const snackbarContext = useContext(SnackbarContext);
  const queryClient = useQueryClient();

  const { isLoading: isCreating, mutateAsync: createAsync } = useMutation(createCustomerAddress, {
    onSuccess: (res) => {
      onSuccessSubmitLocation?.(res);
      onClose();
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const { isLoading: isUpdating, mutateAsync: updateAsync } = useMutation(updateCustomerAddress, {
    onSuccess: (res) => {
      onSuccessSubmitLocation?.(res);
      onClose();
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const onSuccessUpdateCustomerAddress = () => queryClient.invalidateQueries(queryKeys);

  const { isLoading: isDestroying, mutate: mutateDestroy } = useMutation(deleteCustomerAddress, {
    onSuccess: () => {
      onSuccessUpdateCustomerAddress();
      onClose();
    },
  });

  const onDeleteAddress = (id: number) => {
    mutateDestroy(id);
  };

  const isLoading = isCreating || isUpdating || isDestroying;

  const handleSubmit = useCallback(
    async (value: CustomerAddress) => {
      try {
        if (value.id) {
          await updateAsync(value);
          onClose();
          return;
        }
        await createAsync(value);
        onClose();
      } catch (e) {
        //TODO: Remove This
        onSuccessSubmitLocation?.(value);
        onClose();
      }
    },
    [createAsync, onClose, updateAsync, onSuccessSubmitLocation]
  );

  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <DeliveryAddressNavbar
        onPressBack={onClose}
        isLoading={isDestroying}
        onDeleteAddress={onDeleteAddress}
        id={address?.id}
      />
      <DeliveryAddressForm isLoading={isLoading} onSubmit={handleSubmit} address={address} />
    </Dialog>
  );
};

export default DeliveryAddressFormDialog;

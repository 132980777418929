import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stepper, Typography } from '@components/common';

interface Props {
  value: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  disabledIncrement?: boolean;
  disabledDecrement?: boolean;
}
const ProductTotalInput: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { value = 0, onChange, disabled, disabledDecrement, disabledIncrement } = props;

  const onPressAddProduct = () => onChange?.(value + 1);

  if (value > 0) {
    return (
      <Stepper
        disabledDecrement={disabledDecrement}
        disabledIncrement={disabledIncrement}
        disabled={disabled}
        onChange={onChange}
        value={value}
      />
    );
  }

  return (
    <Button
      stopPropagation
      disabled={disabled || disabledIncrement}
      size={'small'}
      variant={'outlined'}
      onClick={onPressAddProduct}
    >
      <Typography>{t('products.action_add_label')}</Typography>
    </Button>
  );
};

export default ProductTotalInput;

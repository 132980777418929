import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { NotFound } from '@components/common';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const title = t('error.page_not_found_title');
  const description = t('error.page_not_found_description');

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <NotFound title={title} description={description} />
    </>
  );
};

export default NotFoundPage;

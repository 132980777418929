export const OPEN_BILL_QUERY_PARAMS = 'dine_in_token';
export const DINE_IN_TIME_FORMAT = 'HH:mm';

export const CLOSED_BILL_QUERY_PARAMS = 'closed_bill_token';
export const TABLE_NO_QUERY_PARAMS = 'tableNo';

export const MERGED_OPEN_BILL_DETAIL_REFETCH_INTERVAL_OPTIONS = {
  refetchOnWindowFocus: true,
  refetchInterval: 20000,
};

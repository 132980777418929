import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

const useCommonPaymentFormSchema = () => {
  const { t } = useTranslation();

  const requiredErrorMessage = t('error.required_label');

  return object().shape({
    accountNumber: string().required(requiredErrorMessage).min(1, requiredErrorMessage),
  });
};

export default useCommonPaymentFormSchema;

import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { CustomTextField, Stack, Typography } from '@components/common';
import { TextFieldProps } from '@mui/material';
import { StackProps } from '../Stack';
import { TypographyProps } from '../Typography';

interface Props<T = string> {
  name: Path<T>;
  label?: string;
  labelProps?: TypographyProps;
  containerProps?: StackProps;
  inputProps?: TextFieldProps;
  control: Control<T, object>;
}

const RHFTextField = <T extends FieldValues>(props: Props<T>) => {
  const { name, label, labelProps, containerProps, inputProps, control } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { onBlur, ref, value, onChange } = field;
        const { error } = fieldState;

        return (
          <Stack spacing={'hs'} {...containerProps}>
            {!!label && (
              <Typography size={'hs'} {...labelProps}>
                {label}
              </Typography>
            )}
            <CustomTextField
              inputRef={ref}
              onBlur={onBlur}
              value={value}
              onChange={onChange}
              error={!!error?.message}
              helperText={error?.message}
              {...inputProps}
            />
          </Stack>
        );
      }}
    />
  );
};

export default RHFTextField;

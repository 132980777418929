import React, { InputHTMLAttributes, KeyboardEvent, memo, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { CustomTextField, Stack } from '@components/common';
import Token from '@constants/token';
import styled from '@emotion/styled';

const CustomOTPInputField = styled(CustomTextField)({
  '& .MuiOutlinedInput-root': {
    fontSize: Token.fontSize.xxxl,
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
});

export interface OTPInputProps {
  length: number;
  onChangeOTP: (otp: string) => void;
  disabled?: boolean;
  otp: string;
}

const OTPInput: React.FC<OTPInputProps> = memo(({ otp, disabled, length, onChangeOTP }) => {
  const inputRefs = useRef(new Array(length).fill(undefined));
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(''));

  useEffect(() => {
    const otpArray = otp.split('').slice(0, length);
    const updatedOTPValues = [...otpArray, ...Array(length - otpArray.length).fill('')];
    setOTPValues(updatedOTPValues);

    if (!otpArray.length) {
      inputRefs.current[0]?.focus();
    }
  }, [length, otp]);

  const handleOnChangeOtp =
    (index: number): InputHTMLAttributes<HTMLInputElement>['onChange'] =>
    (e) => {
      const targetValue = e.target.value;
      const updatedValues = otpValues.map((val, idx) => (idx === index ? targetValue : val));
      setOTPValues(updatedValues);
      onChangeOTP(updatedValues.join(''));

      if (targetValue.length && index < length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    };

  const handleOnKeyDown = (index: number) => (event: KeyboardEvent<HTMLInputElement>) => {
    if (event?.key === 'Backspace') {
      const currValue = (event?.target as HTMLInputElement)?.value;
      if (!currValue) {
        const prevInput = inputRefs.current[index - 1];
        if (prevInput) {
          prevInput.focus();
          setTimeout(() => {
            prevInput.select();
          }, 50);
        }
      }
    }

    if (event?.key === 'ArrowLeft') {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput) {
        prevInput.focus();
        setTimeout(() => {
          prevInput.select();
        }, 50);
      }
    }

    if (event?.key === 'ArrowRight') {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput) {
        nextInput.focus();
        setTimeout(() => {
          nextInput.select();
        }, 50);
      }
    }
  };

  const singleInputWidth = `${100 / length - 1}%`;

  const handlePasteOTP =
    (index: number): InputHTMLAttributes<HTMLInputElement>['onPaste'] =>
    (event) => {
      const clipboardData = event.clipboardData.getData('text').slice(0, length);
      const updatedValues = otpValues.map((otp, indexOtp) =>
        indexOtp >= index && indexOtp < index + clipboardData.length ? clipboardData[indexOtp - index] : otp
      );

      setOTPValues(updatedValues);
      onChangeOTP(updatedValues.join(''));

      if (index + clipboardData.length < length) {
        inputRefs.current[index + clipboardData.length]?.focus();
      }
    };

  return (
    <Stack alignItems={'center'} justifyContent={'space-between'} flexDirection={'row'}>
      {Array(length)
        .fill('')
        .map((_, index) => (
          <Stack key={index} width={singleInputWidth}>
            <InputMask
              onChange={handleOnChangeOtp(index)}
              mask={'9'}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore InputMask PropTypes is incorrect from the package.
              maskChar={null}
              value={otpValues?.[index]}
              onPaste={handlePasteOTP(index)}
            >
              {() => (
                <CustomOTPInputField
                  inputProps={{
                    // NOTE: To show input number only, https://github.com/sanniassin/react-input-mask/issues/110
                    type: 'tel',
                    inputMode: 'numeric',
                  }}
                  InputProps={{
                    inputRef: (ref) => {
                      inputRefs.current[index] = ref;
                    },
                    disabled,
                    onKeyDown: handleOnKeyDown(index),
                  }}
                />
              )}
            </InputMask>
          </Stack>
        ))}
    </Stack>
  );
});

OTPInput.displayName = 'OTPInput';

export default OTPInput;

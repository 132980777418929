import { addSeconds } from 'date-fns';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SnackbarContext } from 'src/context/SnackbarProvider';
import { getOTP } from '@api/auth';
import { Button, Image, Stack, Typography } from '@components/common';
import { RESEND_OTP_TIME, USER_OTP_REQUEST_TIME_KEY } from '@constants/auth';
import routes from '@constants/routes';
import Token from '@constants/token';
import { usePersistentQueryParams } from '@hooks/common';
import { useCommonStore, useGuestStore } from '@hooks/storage';
import { setInternalCookie } from '@utils/auth';

const RUNCHISE_LOGO = '/logo/logo.png';

interface Props {
  onClose: () => void;
  isDineIn: boolean;
}

const MenuDialogGuest = ({ onClose, isDineIn }: Props) => {
  const { t } = useTranslation();
  const { query } = usePersistentQueryParams();
  const snackbarContext = useContext(SnackbarContext);
  const router = useRouter();

  const { guestData, setGuestInfo } = useGuestStore();

  const { storageState } = useCommonStore();

  const { mutate, isLoading } = useMutation(getOTP, {
    onSuccess: async (_, request) => {
      const time = new Date().getTime();
      const reqCompletePhoneNumber = `${request?.phoneNumber.countryCode}${request?.phoneNumber.contactNumber}`;
      const cookieKey = `${USER_OTP_REQUEST_TIME_KEY}/${reqCompletePhoneNumber}`;
      const exp = addSeconds(new Date(), RESEND_OTP_TIME);
      setInternalCookie(null, cookieKey, String(time), {
        expires: new Date(exp),
        secure: true,
        sameSite: true,
        path: '/',
      });

      router.replace({
        pathname: routes.PROFILE_OTP,
        query: {
          ...query,
          contactNumber: request?.phoneNumber.contactNumber,
          countryCode: request?.phoneNumber.countryCode,
          guestMode: true,
        },
      });
    },
    onError: ({ message = t('error.general_error_label') }) => {
      snackbarContext?.openSnackbar?.({
        message,
        alertProps: {
          severity: 'error',
        },
      });
    },
  });

  const requestOtp = () => {
    if (guestData?.contactNumber && guestData?.countryCode) {
      mutate({
        phoneNumber: {
          contactNumber: guestData.contactNumber,
          countryCode: guestData.countryCode,
        },
        otpChannel: 'whatsapp',
      });

      return;
    }

    router.push({
      pathname: routes.PROFILE_LOGIN,
      query,
    });
  };

  const onHandleLogin = () => {
    onClose();
    if (
      storageState.activeLocation?.guestModeDefault &&
      !storageState.activeLocation?.requireGuestName &&
      !storageState.activeLocation?.requireGuestPhoneNumber &&
      isDineIn
    ) {
      setGuestInfo({
        name: t('sessions.guest'),
        contactNumber: '',
        countryCode: '',
      });

      return;
    }

    if (
      storageState.activeLocation?.guestModeDefault &&
      storageState.activeLocation?.requireGuestName &&
      !storageState.activeLocation?.requireGuestPhoneNumber &&
      isDineIn
    ) {
      router.push({
        pathname: routes.GUEST,
        query: {
          ...query,
        },
      });
      return;
    }

    router.push({
      pathname: routes.PROFILE_LOGIN,
      query,
    });
  };

  return (
    <Stack direction={'column'} alignItems={'center'} paddingTop={'2xxl'}>
      <Image width={40} height={40} src={RUNCHISE_LOGO} alt={'Runchise'} objectFit={'contain'} />

      <Stack direction={'column'} alignItems={'center'} marginY={'xxl'}>
        <Typography size={'hs'}>{t('menu.guest_title')}</Typography>
        <Typography size={'xs'} color={'textMuted'}>
          {t('menu.guest_subtitle')}
        </Typography>
      </Stack>

      {guestData ? (
        <Button onClick={requestOtp} variant={'contained'} padding={Token.spacing.xs} disabled={isLoading} fullWidth>
          <Typography size={'hs'} variant={'medium'}>
            {t('menu.login')}
          </Typography>
        </Button>
      ) : (
        <Button onClick={onHandleLogin} variant={'contained'} padding={Token.spacing.xs} fullWidth>
          <Typography size={'hs'} variant={'medium'}>
            {t('menu.login')}
          </Typography>
        </Button>
      )}
    </Stack>
  );
};

export default MenuDialogGuest;

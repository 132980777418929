import baseAPI, { handleDefaultError, handleDefaultSuccess } from '@api/baseAPI';
import {
  CalculateOpenBillPaymentRequest,
  CalculateOpenBillPaymentResponse,
  CancelPayClosedBillToCashierRequest,
  CancelPayMergedOpenBillToCashierRequest,
  CreateDineInCustomerOrderAPI,
  CreateDineInCustomerOrderResponse,
  CreateOpenBillOrderRequest,
  CreateOpenBillOrderResponse,
  DineInFeeSettingResponse,
  GetClosedBillOrderRequest,
  GetClosedBillOrderResponse,
  GetDineInCustomerCheckRequest,
  GetDineInCustomerCheckResponse,
  GetDineInCustomerOrderPriceAPI,
  GetDineInCustomerOrderPriceResponse,
  GetMergedOpenBillOrderRequest,
  GetMergedOpenBillOrderResponse,
  GetOpenBillOrderRequest,
  GetOpenBillOrderResponse,
  PayClosedBillToCashierRequest,
  PayMergedOpenBillToCashierRequest,
} from './types';

const API_GET_OPEN_BILL_ORDER = '/api/dine_in/open_bills/check';
const API_GET_MERGED_OPEN_BILL_ORDER = '/api/dine_in/merged_open_bills/detail';
const API_CALCULATE_OPEN_BILL_PAYMENT = '/api/dine_in/merged_open_bills/calculate_payment';
const API_CREATE_OPEN_BILL_ORDER = '/api/dine_in/merged_open_bills/closing';
const API_PAY_OPEN_BILL_TO_CASHIER = '/api/dine_in/merged_open_bills/want_to_pay_with_cash';
const API_CANCEL_PAY_OPEN_BILL_TO_CASHIER = '/api/dine_in/merged_open_bills/cancel_want_to_pay_with_cash';

const API_GET_CLOSED_BILL_ORDER = '/api/dine_in/closed_bills/check';
const API_PAY_CLOSED_BILL_TO_CASHIER = '/api/dine_in/closed_bills/pay_via_cashier';
const API_CANCEL_PAY_CLOSED_BILL_TO_CASHIER = '/api/dine_in/closed_bills/cancel_pay_via_cashier';

const API_DINE_IN_FEE_SETTING = '/api/delivery/dine_in_fee_settings';
const API_DINE_IN_CUSTOMER_ORDER_PRICE = '/api/dine_in/customer_orders/price/';
const API_DINE_IN_CUSTOMER_ORDER = '/api/dine_in/customer_orders/';
const API_DINE_IN_CUSTOMER_CHECK = '/api/dine_in/customers/check';

export const getOpenBillOrder = async (request: Partial<GetOpenBillOrderRequest>) => {
  if (typeof request?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request getOpenBillOrder'));
  }

  return await baseAPI
    .get<GetOpenBillOrderResponse>(`${API_GET_OPEN_BILL_ORDER}/${request.uuid}`)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getMergedOpenBillOrder = async (params: Partial<GetMergedOpenBillOrderRequest>) => {
  if (typeof params?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request getMergedOpenBillOrder'));
  }

  return await baseAPI
    .get<GetMergedOpenBillOrderResponse>(API_GET_MERGED_OPEN_BILL_ORDER, { params })
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const calculateOpenBillPayment = async (body: Partial<CalculateOpenBillPaymentRequest>) => {
  if (typeof body?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request calculateOpenBillPayment'));
  }

  return await baseAPI
    .post<CalculateOpenBillPaymentResponse>(API_CALCULATE_OPEN_BILL_PAYMENT, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const createOpenBillOrder = async (body: Partial<CreateOpenBillOrderRequest>) => {
  if (typeof body?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request createOpenBillOrder'));
  }

  return await baseAPI
    .post<CreateOpenBillOrderResponse>(API_CREATE_OPEN_BILL_ORDER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const payMergedOpenBillToCashier = async (body: Partial<PayMergedOpenBillToCashierRequest>) => {
  if (typeof body?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request payMergedOpenBillToCashier'));
  }

  return await baseAPI
    .post<void>(API_PAY_OPEN_BILL_TO_CASHIER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const cancelPayMergedOpenBillToCashier = async (body: Partial<CancelPayMergedOpenBillToCashierRequest>) => {
  if (typeof body?.uuid === 'undefined') {
    return Promise.reject(new Error('Invalid request cancelPayMergedOpenBillToCashier'));
  }

  return await baseAPI
    .post<void>(API_CANCEL_PAY_OPEN_BILL_TO_CASHIER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getClosedBillOrder = async (request: Partial<GetClosedBillOrderRequest>) => {
  if (typeof request?.closedBillToken === 'undefined') {
    return Promise.reject(new Error('Invalid request getClosedBillOrder'));
  }

  return await baseAPI
    .get<GetClosedBillOrderResponse>(`${API_GET_CLOSED_BILL_ORDER}/${request.closedBillToken}`)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const payClosedBillToCashier = async (body: Partial<PayClosedBillToCashierRequest>) => {
  if (typeof body?.customerOrderId === 'undefined') {
    return Promise.reject(new Error('Invalid request payClosedBillToCashier'));
  }

  return await baseAPI
    .post<void>(API_PAY_CLOSED_BILL_TO_CASHIER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const cancelPayClosedBillToCashier = async (body: Partial<CancelPayClosedBillToCashierRequest>) => {
  if (typeof body?.customerOrderId === 'undefined') {
    return Promise.reject(new Error('Invalid request cancelPayClosedBillToCashier'));
  }

  return await baseAPI
    .post<void>(API_CANCEL_PAY_CLOSED_BILL_TO_CASHIER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getDineFeeSetting = async () =>
  await baseAPI
    .get<DineInFeeSettingResponse>(API_DINE_IN_FEE_SETTING)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const createDineInCustomerOrder: CreateDineInCustomerOrderAPI = async (body) =>
  await baseAPI
    .post<CreateDineInCustomerOrderResponse>(API_DINE_IN_CUSTOMER_ORDER, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);

export const getDineInCustomerOrderPrice: GetDineInCustomerOrderPriceAPI = async (body) => {
  if (typeof body?.locationId === 'undefined') {
    return Promise.reject(new Error('Invalid request getDineInCustomerOrderPrice'));
  }

  return await baseAPI
    .post<GetDineInCustomerOrderPriceResponse>(API_DINE_IN_CUSTOMER_ORDER_PRICE, body)
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

export const getDineInCustomerCheck = async (params: GetDineInCustomerCheckRequest) => {
  if (typeof params?.phoneNumber === 'undefined' || typeof params?.phoneNumberCountryCode === 'undefined') {
    return Promise.reject(new Error('Invalid request getDineInCustomerCheck'));
  }

  return await baseAPI
    .get<GetDineInCustomerCheckResponse>(API_DINE_IN_CUSTOMER_CHECK, { params })
    .then(handleDefaultSuccess)
    .catch(handleDefaultError);
};

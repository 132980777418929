import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from 'react-query';
import { getPhoneCountries } from '@api/auth';
import { GetPhoneCountriesResponse } from '@api/auth/types';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { QUERY_KEY_PHONE_COUNTRIES } from '@constants/queryKey';

export interface GetPhoneCountriesQueryKeys {
  key: string;
}

type UseQueryPhoneCountriesOptions = UseQueryOptions<
  GetPhoneCountriesResponse,
  APIDefaultErrors,
  GetPhoneCountriesResponse,
  GetPhoneCountriesQueryKeys[]
>;

const useQueryPhoneCountries = (config?: UseQueryPhoneCountriesOptions) => {
  const queryKeys: GetPhoneCountriesQueryKeys[] = useMemo(
    () => [
      {
        key: QUERY_KEY_PHONE_COUNTRIES,
      },
    ],
    []
  );

  const { isError, data, isFetching, isSuccess } = useQuery(queryKeys, getPhoneCountries, {
    cacheTime: 600_000,
    staleTime: 600_000,
    retry: 3,
    ...config,
  });

  return {
    queryKeys,
    data,
    isFetching,
    isError,
    isSuccess,
  };
};

export default useQueryPhoneCountries;

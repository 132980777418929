import { FC } from 'react';
import Token from '@constants/token';
import { TokenColor } from '@interfaces/token';
import BaseIcon from './BaseIcon';
import { IconProps } from './types';

interface Props extends IconProps {
  color?: TokenColor;
}

const ChefHatIcon: FC<Props> = ({ width = 24, height = 24, color = 'uiDarkPrimary', ...baseIconProps }) => {
  const fillColor = Token.color[color];

  return (
    <BaseIcon {...baseIconProps}>
      <svg width={width} height={height} viewBox={'0 0 16 16'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
        <path
          d={
            'M7.99942 2.42969C9.18675 2.42969 10.1785 3.2654 10.4199 4.38031C11.0539 4.20874 11.7301 4.29604 12.2997 4.62302C12.8693 4.95 13.2857 5.48987 13.4573 6.12385C13.6288 6.75784 13.5415 7.43402 13.2145 8.00363C12.8876 8.57325 12.3477 8.98964 11.7137 9.16121V13.5725H4.28513V9.16121C3.97121 9.07618 3.67712 8.93014 3.41963 8.73145C3.16215 8.53276 2.94633 8.2853 2.78448 8.0032C2.62264 7.72109 2.51794 7.40988 2.47637 7.08731C2.4348 6.76475 2.45717 6.43715 2.5422 6.12324C2.62724 5.80932 2.77327 5.51522 2.97196 5.25774C3.17065 5.00026 3.41812 4.78443 3.70022 4.62259C3.98232 4.46074 4.29354 4.35604 4.6161 4.31447C4.93867 4.2729 5.26626 4.29527 5.58018 4.38031C5.70017 3.82785 6.0056 3.33308 6.4457 2.97823C6.8858 2.62338 7.43408 2.42981 7.99942 2.42969Z'
          }
          stroke={fillColor}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M4.38477 11.1013L11.7137 11.0957'}
          stroke={fillColor}
          strokeWidth={'1.5'}
          strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </svg>
    </BaseIcon>
  );
};

export default ChefHatIcon;

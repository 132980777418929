import { useTranslation } from 'react-i18next';
import { number, object, setLocale, string } from 'yup';

const useDeliveryAddressFormSchema = () => {
  const { t } = useTranslation();

  const requiredErrorMessage = t('error.required_label');

  const phoneNumberTest = (value = '') => new RegExp(/^[0-9]{6,14}$/).test(value);

  setLocale({
    mixed: {
      notType: requiredErrorMessage,
      default: requiredErrorMessage,
      required: requiredErrorMessage,
    },
    string: {
      min: requiredErrorMessage,
    },
    number: {
      min: requiredErrorMessage,
    },
  });

  return object().shape({
    latitude: number().required(),
    longitude: number().required(),
    receiverName: string().required().min(1),
    contactNumber: string().test('phoneNumberText', t('error.phone_number_invalid_length'), phoneNumberTest),
    address: string().required().min(1),
  });
};

export default useDeliveryAddressFormSchema;
